import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaProfilesIconMui} from "../icons/DynaProfilesIcon";

export const routeProfileCreatePaths: IAppRoute = {
  title: 'Create new Profile',
  icon: <DynaProfilesIconMui/>,
  routePath: '/profiles/create',
  getRoutePath: () => routeProfileCreatePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  requiresLatestClientVersion: true,
  render: () => null,
};
