import {Box} from "ui-components/dist/Box";
import {AppFooterLinks} from "../../../../core/application/public-components";
import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
} from "../../../../core/dyna-cms/public-components";

import {routeAppSettingsPage} from "../../../../core/application/routes/routeAppSettingsPage";
import {routeAdoreTermsOfUse} from "../../routes/static/routeAdoreTermsOfUse";
import {routeAdoreGrAbout} from "../../routes/static/routeAdoreGrAbout";

export interface IAdoreGrFooterLinksProps {
  onClick?: () => void;
}

export const AdoreGrFooterLinks = (props: IAdoreGrFooterLinksProps): JSX.Element => {
  const {onClick} = props;

  return (
    <Box>
      <DynaCMSFontFamily fontFamily={EDynaCMSFontFamily.F135_MODERN_BASE_NOTO}>
        <AppFooterLinks
          links={[
            {
              label: 'Home',
              link: '/',
            },
            {
              label: 'Όροι χρήσης',
              link: routeAdoreTermsOfUse.getRoutePath(),
            },
            {
              label: 'Ρυθμίσεις',
              link: routeAppSettingsPage.getRoutePath(),
            },
            {
              label: 'About',
              link: routeAdoreGrAbout.getRoutePath(),
            },
          ]}
          onClick={onClick}
        />
      </DynaCMSFontFamily>
    </Box>
  );
};
