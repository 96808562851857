import moment from "moment/moment";

import {DynaImage} from "dyna-image";

import {
  GridContainer,
  GridItem,
} from "ui-components/dist/Grid";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "ui-components/dist/FlexContainer";
import {
  LabelContainer,
  ELabelContainerVariant,
} from "ui-components/dist/LabelContainer";
import {Typography} from "ui-components/dist/Typography";
import {FormatDateTime} from "ui-components/dist/FormatDateTime";

import {useLoadAppVersion} from "../useLoadAppVersion";

import {IAppStore} from "../../../../state/IAppStore";

export interface IAppVersionProps {
  store: IAppStore;
  dataComponentName?: string;
}

export const AppVersion = (props: IAppVersionProps): JSX.Element => {
  const {
    dataComponentName,
    store: {
      app: {state: {companyId}},
      userAuth: {
        state: {
          user: {
            id: userId,
            avatarUrl,
            authUserId,
            firstName,
            lastName,
            email,
            displayName,
          },
          userTokenExpiresAt,
        },
      },
    },
  } = props;

  const {
    client,
    server,
    error,
  } = useLoadAppVersion();

  const err = error ? 'err' : '';

  const expiresInDays =
    Math.round(
      (moment(userTokenExpiresAt).diff(Date.now(), 'hours') / 24) * 10,
    )
    / 10;

  return (
    <GridContainer
      dataComponentName={["AppVersion", dataComponentName]}
      spacing={2}
    >
      <GridItem>
        <Typography v="h1">Application version info</Typography>

        <LabelContainer
          label="App version"
          variant={ELabelContainerVariant.OUTLINED}
        >
          <p>Server version: {err || server.version} ({err || server.codeName})</p>
          <p>Client version: {client.version} ({client.codeName})</p>
          <p>Built at: {client.buildAt})</p>
          <p>Needs update: {err || (server.version !== client.version ? 'YES' : 'Up to date')}</p>
        </LabelContainer>
      </GridItem>
      <GridItem>
        <LabelContainer
          label="User info"
          variant={ELabelContainerVariant.OUTLINED}
        >
          <FlexContainerHorizontal spacing={1}>
            {avatarUrl && (
              <FlexItemMin>
                <DynaImage
                  style={{height: 200}}
                  src={avatarUrl}
                />
              </FlexItemMin>
            )}
            <FlexItemMax>
              <Typography>Company ID: <code>{companyId || 'err!'}</code></Typography>
              <Typography>Display name: {displayName || '-'}</Typography>
              <Typography>Real name: {firstName || '-'} {lastName || '-'}</Typography>
              <Typography>Primary email: <code>{email}</code></Typography>
              <Typography>User id: <code>{userId}</code></Typography>
              <Typography>Auth provider: <code>{authUserId.split('/')[0]}</code></Typography>
              <Typography>
                User token expires at <FormatDateTime value={userTokenExpiresAt}/> in <code>{expiresInDays}</code> days.
              </Typography>
            </FlexItemMax>
          </FlexContainerHorizontal>
        </LabelContainer>
      </GridItem>

    </GridContainer>
  );
};
