import * as React from "react";

import {Collapse} from "ui-components/dist/Collapse";
import {Box} from "ui-components/dist/Box";
import {
  InputSwitch,
  EInputSwitchColor,
} from "ui-components/dist/InputSwitch";
import {IconViewer} from "ui-components/dist/IconViewer";

import {createIcon} from "ui-components/dist/IconComponent";
import LockIcon from '@mui/icons-material/Lock';

export interface IRightValueEditorProps {
  show?: boolean;
  color?: EInputSwitchColor;
  right: string;
  label: string;
  title: string;
  readonly: boolean;
  disabled: boolean;
  debugNoRestrictions: boolean;
  value: boolean;
  onChange: (value: boolean, right: string) => void;
}

export const RightValueEditor: React.FC<IRightValueEditorProps> = (
  {
    show = true,
    color,
    right,
    label,
    title,
    readonly,
    disabled: userDisabled,
    debugNoRestrictions,
    value,
    onChange,
  },
) => {
  const disabled =
    debugNoRestrictions
      ? false
      : userDisabled;
  return (
    <Box sx={{position: 'relative'}}>
      <InputSwitch
        name={right}
        hidden={!show}
        color={color}
        ariaLabel={
          disabled
            ? `You cannot change the ${right} right`
            : title
        }
        label={label}
        title={title}
        readOnly={readonly}
        disabled={disabled}
        value={value}
        onChange={onChange as any}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: -8,
          left: 8,
          opacity: 0.8,
        }}
      >
        <Collapse expanded={!readonly && userDisabled}>
          <IconViewer
            width={12}
            Icon={createIcon.byMuiIcon(LockIcon)}
          />
        </Collapse>
      </Box>
    </Box>
  );
};
