import {connect} from "react-dynadux";

import {
  ViewerHeading as Component,
} from "./ViewerHeading";
export const ViewerHeading = connect(Component);

export type {
  IViewerHeadingProps,
} from "./ViewerHeading";
