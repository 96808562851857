import {
  useRef,
  useMemo,
} from "react";

import {areValuesEqual} from "utils-library/dist/commonJs/utils";

export const usePerformanceMemo = <TResult>(callback: () => TResult, dependencies: any): TResult => {
  const memoizedCallback = useRef(callback);
  const memoizedDependencies = useRef(dependencies);

  if (!areValuesEqual(memoizedDependencies.current, dependencies)) {
    memoizedCallback.current = callback;
    memoizedDependencies.current = dependencies;
  }

  return useMemo(() => memoizedCallback.current(), [memoizedDependencies.current]);
};
