import {
  IDynaCMSBlockImage,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {DynaCMSImageCore} from "../../commons/DynaCMSImageCore";
import {DynaCMSTextContentHover} from "../../commons/DynaCMSTextContentHover";

export interface IViewerImageProps {
  content: IDynaCMSBlockImage;
  showTexts?: boolean;
  showCredits?: boolean;
  showPortraitsSmaller?: boolean;
}

export const ViewerImage = (props: IViewerImageProps): JSX.Element => {
  const {
    content,
    content: {
      label,
      labelPosition,
      labelForeground,
      labelBackground,
      title,
      caption,
      showCaption,
      credits,
      orientation,
    },
    showTexts = true,
    showCredits = true,
    showPortraitsSmaller = false,
  } = props;
  const {isMobile} = useBreakpointDevice();

  return (
    <Box
      fullWidth
      fullHeight
    >
      <DynaCMSTextContentHover
        sx={{
          margin: 'auto',
          width: (showPortraitsSmaller && !isMobile && orientation === "portrait") ? '70%' : undefined,
        }}
        title={title}
        subtitle={caption}
        showCaption={showCaption}
        label={label}
        showTexts={showTexts}
        credits={showCredits ? credits : undefined}
        labelColor={{
          color: labelForeground,
          backgroundColor: labelBackground,
        }}
        labelPosition={labelPosition}
      >
        <DynaCMSImageCore content={content}/>
      </DynaCMSTextContentHover>
    </Box>
  );
};
