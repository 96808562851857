import {IAppRoute} from "./IAppRoute";
import {routesAppCore} from "../routes";
import {routesUserManagement} from "../../user-management";
import {routesUserAuthentication} from "../../user-authnentication";
import {routesDynaProfiles} from "../../dyna-profiles/routes";

import {routesDynaCMSManagement} from "../../dyna-cms";
import {routesFileStorage} from "../../file-storage";

import {routesShortURL} from "../../short-url/routes";

import {routesTestPages} from "../../test-pages";

import {
  ECompany,
  appConfig,
} from "./appConfig";

const appCoreRoutes: IAppRoute[] = Array<IAppRoute>().concat(
  routesAppCore,
  routesUserManagement,
  routesUserAuthentication,
  routesDynaProfiles,

  routesDynaCMSManagement,
  routesFileStorage,

  routesShortURL,

  routesTestPages,
);

// If (appConfig.company === ECompany.ADORE_GR) appRoutes.push()

export const getAppRoutes = async (): Promise<IAppRoute[]> => {
  const appRoutes = appCoreRoutes.concat();

  if (appConfig.company === ECompany.NET_FASHION) {
    const {routesNetFashion} = await import("../../../companies/net-fashion");
    routesNetFashion.forEach(route => appRoutes.push(route));
  }

  if (appConfig.company === ECompany.ADORE_GR) {
    const {routesAdoreGr} = await import("../../../companies/adore-gr");
    const routes = await routesAdoreGr();
    routes.forEach(route => appRoutes.push(route));
  }

  if (appConfig.company === ECompany.THE_GIRL_GUNS) {
    const {routesTheGirlGunsCom} = await import("../../../companies/tgg-com");
    routesTheGirlGunsCom.forEach(route => appRoutes.push(route));
  }

  return appRoutes
    .reduce((acc: IAppRoute[], route) => {
      // Override exact pages.
      const findIndex = acc.findIndex(scanRoute => scanRoute.routePath === route.routePath && scanRoute.exact);
      if (findIndex > -1) acc[findIndex] = route; else acc.push(route);
      return acc;
    }, []);
};
