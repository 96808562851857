import {Condition} from "ui-components/dist/Condition";

import {
  ECompany,
  appConfig,
} from "./appConfig";
import {IAppMenu} from "./IAppMenu";

import {NetFashionAppMenu} from "../../../companies/net-fashion/environment/NetFashionAppMenu";
import {AdoreGrMainMenu} from "../../../companies/adore-gr/environment/AdoreGrMainMenu";
import {TheGirlGunsComMainMenu} from "../../../companies/tgg-com/environment/TheGirlGunsComMainMenu";

export interface IAppMenuProps {
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
}

export const AppMenu = (props: IAppMenuProps): JSX.Element => {
  const {
    selectedMenuId,
    menus,
  } = props;
  return (
    <>
      <Condition if={appConfig.company === ECompany.NET_FASHION}>
        <NetFashionAppMenu
          selectedMenuId={selectedMenuId}
          menus={menus}
        />
      </Condition>
      <Condition if={appConfig.company === ECompany.ADORE_GR}>
        <AdoreGrMainMenu
          selectedMenuId={selectedMenuId}
          menus={menus}
        />
      </Condition>
      <Condition if={appConfig.company === ECompany.THE_GIRL_GUNS}>
        <TheGirlGunsComMainMenu
          selectedMenuId={selectedMenuId}
          menus={menus}
        />
      </Condition>
      <Condition if={appConfig.company === ECompany.UNKNOWN}>
        <div>Unknown company, no menu</div>
      </Condition>
    </>
  );
};
