import {IDynaCMSContentBlockValidation} from "../../../../interfaces/IDynaCMSContentValidation";
import {
  IDynaCMSArticleBlock,
  IDynaCMSArticleStats,
} from "../../../../db/IDynaCMSArticle";

export const API_PATH_ApiArticleContentUpdate = '/services/dyna-cms/apiArticleContentUpdate';

export interface IApiArticleContentUpdateBodyRequest<TBlockContent> {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
  content: TBlockContent;
  show: boolean;
  historyReason?: string;
}

export interface IApiArticleContentUpdateResponse<TBlockContent> {
  show: boolean;
  updateArticleCoverMedia?: IDynaCMSArticleBlock;
  validation: IDynaCMSContentBlockValidation<TBlockContent>;
  stats: IDynaCMSArticleStats;
  updatedAt: number;
  updatedBy: string;
}
