import {IDynaProfileUserRight} from "./IDynaProfileUserRight";
import {
  EDynaProfileUserRight,
  DELEGATE_SLASH,
} from "./EDynaProfileUserRight";

export const dynaProfileUserRightsReader = (rights: IDynaProfileUserRight[]) => {
  const rightsDic: Record<string, true> =
    rights
      .reduce((acc: Record<string, true>, right) => {
        if (right.valid) acc[right.right] = true;
        return acc;
      }, {});

  return {
    get rights(): IDynaProfileUserRight[] {
      return rights;
    },

    filter: (
      {
        rightType = "both",
        valid,
      }: {
        rightType?:
          | "regular"
          | "delegate"
          | "both";
        valid?: boolean; // Undefined for all
      },
    ) => {
      const filteredRights =
        rights.filter(right => {
          const isDelegate = right.right.startsWith(DELEGATE_SLASH);
          const rightTypeCheck =
            rightType === "both"
              ? true
              : rightType === "regular"
                ? !isDelegate
                : isDelegate;
          const validCheck =
            valid === undefined
              ? true
              : valid === right.valid;
          return rightTypeCheck && validCheck;
        });
      return {
        rights: filteredRights,
        values: filteredRights.map(right => right.right),
        has: !!filteredRights.length,
        hasNot: !filteredRights.length,
        count: filteredRights.length,
      };
    },

    get dic(): Record<string, true> {
      const output: Record<string, true> = {};
      this
        .filter({valid: true})
        .values
        .forEach(right => output[right] = true);
      return output;
    },

    get isOwner(): boolean {
      return this.hasRight(EDynaProfileUserRight.OWNER);
    },

    hasRight: (right: string): boolean => {
      return !!rightsDic[right] === true;
    },
    isOwnerOrHasRight: (right: string): boolean => {
      return !!(rightsDic[EDynaProfileUserRight.OWNER] || rightsDic[right]);
    },
    hasAnyOfRights: (...rightsToCheck: string[]): boolean => {
      return rightsToCheck.some((right) => rightsDic[right] === true);
    },
    hasAllRights: (...rightsToCheck: string[]): boolean => {
      // Implementing the 'hasAllRights' function
      return rightsToCheck.every((right) => rightsDic[right] === true);
    },
  };
};

export type IDynaProfileUserRightsReader = ReturnType<typeof dynaProfileUserRightsReader>;
