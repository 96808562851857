import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaCMSMainPageProps} from "./DynaCMSMainPage";

const DynaCMSMainPageLazy = lazy(() => import("./DynaCMSMainPage"));

export const DynaCMSMainPage = connect((props: IDynaCMSMainPageProps) => (
  <LazyLoadComponent>
    <DynaCMSMainPageLazy {...props}/>
  </LazyLoadComponent>
));
