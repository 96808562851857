import {IDBEntityHistoryDocument} from "utils-library/dist/commonJs/db-entity";

import {IDynaCMSArticle} from "../../../../db/IDynaCMSArticle";

export const API_PATH_ApiArticleHistoryGet = '/services/dyna-cms/apiArticleHistoryGet';

export interface IApiArticleHistoryGetQueryRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  id: string;
  searchText?: string;
  skip: number;
  limit: number;
}

export interface IApiArticleHistoryGetResponse {
  items: IDBEntityHistoryDocument<IDynaCMSArticle>[];
}
