import {connect} from "react-dynadux";

import {ETKAppCore} from "server-app";

import {ButtonBar} from "ui-components/dist/ButtonBar";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";
import {IAppStore} from "../../../state/IAppStore";

import {
  DynaIntlProvider,
  DynaIntlFormatter,
} from "../../dyna-intl/components-public";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";

export interface IGoHomeButtonProps {
  store: IAppStore;
  inline?: boolean;
}

export const GoBackHomeButtons = connect((props: IGoHomeButtonProps): JSX.Element => {
  const {
    store: {app: {actions: {navigateTo}}},
    inline,
  } = props;

  return (
    <DynaIntlProvider tks={tks}>
      <ButtonBar inline={inline}>
        <Button
          icon={<ArrowBackIosIcon/>}
          size={EButtonSize.LARGE}
          onClick={() => navigateTo({url: '<'})}
        >
          <DynaIntlFormatter
            tk={tks.back}
            default="Back"
          />
        </Button>
        <Button
          icon={<HomeIcon/>}
          size={EButtonSize.LARGE}
          onClick={() => navigateTo({url: '/'})}
        >
          <DynaIntlFormatter
            tk={tks.home}
            default="Home"
          />
        </Button>
      </ButtonBar>
    </DynaIntlProvider>
  );
});

const tks = {
  back: ETKAppCore.GO_BACK,
  home: ETKAppCore.MAIN_PAGE,
};
