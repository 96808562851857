import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {IconButton} from "../../../../../../IconButton";
import {INotificationMenuOption} from "../../../../../interfaces";

export interface IEntityMenuProps {
  menuId: string;
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  options: INotificationMenuOption[];
  onMenuClose: () => void;
  onOptionClick: (onClick?: () => void) => void;
}

export const EntityMenu: React.FC<IEntityMenuProps> = (
  {
    menuId,
    anchorEl,
    isMenuOpen,
    options,
    onMenuClose,
    onOptionClick,
  },
) => {
  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={onMenuClose}
    >
      {options
        .filter(option => !option.hidden)
        .map((
          {
            disabled,
            Icon,
            label,
            onClick,
          },
          index,
        ) => (
          <MenuItem
            key={index}
            disabled={disabled}
            onClick={() => onOptionClick(onClick)}
          >
            <IconButton
              ariaLabel={label}
              Icon={Icon}
            />
            {label}
          </MenuItem>
        ))}
    </Menu>
  );
};
