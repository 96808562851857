import * as React from "react";
import {useState} from "react";

import {scrollToElement} from "utils-library/dist/commonJs/web";

import {Box} from "../Box";
import {StickyTopContainer} from "../StickyTopContainer";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";

import {PopUpMenu} from "./components/PopUpMenu";
import {MenuLine} from "./components/MenuLine";

import {IAppMenu} from "./interfaces";

import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

export interface ILightHorizontalMenuProps {
  logoMain?: JSX.Element;
  logoPopUpHeader?: JSX.Element;
  logoPopUpFooter?: JSX.Element;
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
  fontSize?: 1 | 2 | 3 | 4;
  stickyMenuOnScrollY: number;
  stickyMaxWidth: number;
  additionalOptions?: (props: { onClick?: () => void }) => JSX.Element;
  footer?: (props: { onClick?: () => void }) => JSX.Element;
  palette?: {
    selected: string;
  };
}

export const LightHorizontalMenu = (props: ILightHorizontalMenuProps): JSX.Element => {
  const {
    logoMain,
    logoPopUpHeader,
    logoPopUpFooter,
    selectedMenuId,
    menus,
    fontSize,
    footer,
    additionalOptions,
    stickyMenuOnScrollY,
    stickyMaxWidth,
    palette = {selected: 'red'},
  } = props;

  const [show, setShow] = useState<boolean>(false);

  const handleScrollToTop = () => {
    scrollToElement({
      selector: 'html',
      animation: true,
      position: 'start',
    });
  };

  const handleMenuOpenClick = () => {
    document.body.style.overflow = 'hidden';
    setShow(true);
  };
  const handleMenuCloseClick = () => {
    document.body.style.overflow = 'auto';
    setShow(false);
  };

  return (
    <>
      <style>{`@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');`}</style>

      <style>{`
        html, body {
          scroll-behavior: smooth
        }

        `}</style>

      <Box
        sx={{borderBottom: theme => `2px solid ${theme.palette.text.primary}`}}
      >
        <MenuLine
          selectedMenuId={selectedMenuId}
          menus={menus}
          fontSize={fontSize}
          palette={palette}
          onPopUpOpen={handleMenuOpenClick}
        />
      </Box>

      <PopUpMenu
        show={show}
        logoPopUpHeader={logoPopUpHeader}
        logoPopUpFooter={logoPopUpFooter}
        selectedMenuId={selectedMenuId}
        menus={menus}
        additionalOptions={additionalOptions}
        footer={footer}
        palette={palette}
        onClose={handleMenuCloseClick}
      />

      <StickyTopContainer
        showAboveScrollY={stickyMenuOnScrollY}
        maxWidth={stickyMaxWidth}
      >
        <FlexContainerHorizontal>
          <FlexItemMax overFlowX>
            <MenuLine
              logo={logoMain}
              selectedMenuId={selectedMenuId}
              menus={menus}
              fontSize={fontSize}
              palette={palette}
              onPopUpOpen={handleMenuOpenClick}
            />
          </FlexItemMax>
          <FlexItemMin>
            <Box
              sx={{cursor: 'pointer'}}
              onClick={handleScrollToTop}
            >
              <ArrowCircleUpIcon
                sx={{
                  width: 52,
                  height: 52,
                }}
              />
            </Box>
          </FlexItemMin>
        </FlexContainerHorizontal>
      </StickyTopContainer>
    </>
  );
};
