import {Box} from "ui-components/dist/Box";
import {Condition} from "ui-components/dist/Condition";

import {
  ECompany,
  appConfig,
} from "./appConfig";

import {NetFashionFooter} from "../../../companies/net-fashion/environment/NetFashionFooter";
import {AdoreGrFooter} from "../../../companies/adore-gr/environment/AdoreGrFooter";
import {TheGirlGunsComFooter} from "../../../companies/tgg-com/environment/TheGirlGunsComFooter";

export const AppFooter = (): JSX.Element => {
  return (
    <>
      <Box sx={{height: 32}}/>
      <Condition if={appConfig.company === ECompany.NET_FASHION}>
        <NetFashionFooter/>
      </Condition>
      <Condition if={appConfig.company === ECompany.ADORE_GR}>
        <AdoreGrFooter/>
      </Condition>
      <Condition if={appConfig.company === ECompany.THE_GIRL_GUNS}>
        <TheGirlGunsComFooter/>
      </Condition>
      <Condition if={appConfig.company === ECompany.UNKNOWN}>
        <Box>Unknown company!</Box>
      </Condition>
    </>
  );
};
