import {guid} from "dyna-guid";

import {TObject} from "../typescript";
import {getRuntimeStack} from "../utils";
import {getDurationString} from "../utils";

const global: any = typeof window !== "undefined" ? window : process;

export const createDebugSetup = (setupName: string) => {
  const globalName = getDebugSetupKey(setupName);

  if (global[globalName]) console.warn(`createDebug("${setupName}") already called!`);

  const debugSetup = {
    get enabled(): boolean {
      return getSetupActive(setupName);
    },
    set enabled(enabled: boolean) {
      setSetupActive(setupName, enabled);
    },
    get consoleEnabled(): boolean {
      return getSetupConsoleEnabled(setupName);
    },
    set consoleEnabled(console: boolean) {
      setSetupConsoleEnabled(setupName, console);
    },
    operations: [],
    lastOperation: undefined,
    startOperation: (operationConfig: Omit<IDebugOperationConfig, "setupName"> = {}): DebugOperation => {
      const debugInstance = new DebugOperation({
        setupName,
        ...operationConfig,
      });
      if (getSetupActive(setupName)) {
        global[globalName].operations.push(debugInstance);
        global[globalName].lastOperation = debugInstance;
      }
      return debugInstance;
    },
  };

  // Update the global _debug_.nnnDebugSetup
  if (!global._debug_) global._debug_ = {};
  global._debug_[setupName] = debugSetup;

  // Some global utils
  global._debug_.list = () =>
    Object.keys(global._debug_)
      .reduce((acc: any[], key) => {
        if (key === 'list') return acc;
        acc.push({
          setupName: key,
          enabled: global._debug_[key].enabled,
          consoleEnabled: global._debug_[key].consoleEnabled,
          operations: global._debug_[key].operations,
          setup: global._debug_[key],
        });
        return acc;
      }, []);
  global._debug_.logAll = (message: string, data?: TObject): void => {
    global._debug_
      .list()
      .forEach((item: any) => item.setup.lastOperation?.log(message, data));
  };

  // Update the global _debug_nnnDebugSetup
  global[globalName] = debugSetup;

  return debugSetup;
};

export type IDebugSetup = ReturnType<typeof createDebugSetup>;

interface IDebugLog {
  after: string;      // Duration since any previous log of the operation
  message: string;         // The message of the log
  data?: any;
  capturedData: TObject;
  time: string;
  timeValue: number;
  logIndex: number;
  stack: string[];
}

interface IDebugOperationConfig {
  setupName: string;
  operationName?: string;
  onLogCaptureData?: () => TObject;
}

export class DebugOperation {
  //#region "Private"

  private lastLogAt = Date.now();

  constructor(private readonly config: IDebugOperationConfig) {
    if (!config.operationName) config.operationName = '---';
    this.log(
      [
        'Operation',
        this.config.operationName && `[${this.config.operationName}]`,
        'started',
        this.enabled ? '' : `but currently is not enabled, nothing will be collected, call: \`${getDebugSetupKey(this.config.setupName)}.enabled=true\` to start`,
      ]
        .filter(Boolean)
        .join(' ')
      + '.',
    );
  }

  public get enabled(): boolean {
    return getSetupActive(this.config.setupName);
  }

  public readonly logs: IDebugLog[] = [];

  //#endregion "Private"

  //#region "Log methods"

  /**
   * Logs a message with optional data that can be hard-copied! (by default) or not.
   *
   * Captured data of onLogCaptureData are always hard-copied!.
   */
  public log = (message: string, _data?: TObject, hardcopyData = true): void => {
    if (!this.enabled) return;
    const data =
      _data === undefined
        ? undefined
        : hardcopyData
          ? hardCopy(_data)
          : {
            ..._data,
            "Debug operation": "Warning: This data is not hard-copied!",
          };
    const now = Date.now();
    const log: IDebugLog = {
      after:
        (now - this.lastLogAt > 1000 ? "S<" : "  ") // Indicate the elapsed time that is greater than second
        + getDurationString(this.lastLogAt, now, 1).padStart(8),
      message,
      data,
      capturedData:
        this.config.onLogCaptureData
          ? hardCopy(this.config.onLogCaptureData())
          : {"Debug operation": "Info: No onLogCaptureData provided"},
      time: formatDate(new Date(now)),
      timeValue: now,
      logIndex: this.logs.length,
      stack: getRuntimeStack().slice(2),
    };
    this.lastLogAt = now;
    if (log.data === undefined) delete log.data;
    this.logs.push(log);
    if (getSetupConsoleEnabled(this.config.setupName)) {
      console.log(
        `${getDebugSetupKey(this.config.setupName)}:`,
        `after: ${log.after.trim()}`,
        log.message,
        log,
      );
    }
  };

  public logValue = <TValue>(message: string, value: TValue): TValue => {
    this.log(message, value);
    return value;
  };

  private timeKeys: { [timeKey: string]: number } = {};

  public time = (timeKey: string): void => {
    if (!this.enabled) return;
    if (this.timeKeys[timeKey]) {
      console.error(`DebugOperation.time(): time key [${timeKey}] already initialized (is not timeEnd()ed)`);
    }
    this.timeKeys[timeKey] = performance.now();
  };

  public timeEnd = (timeKey: string): void => {
    if (!this.enabled) return;
    if (!this.timeKeys[timeKey]) {
      console.error(`DebugOperation.timeEnd(): time key [${timeKey}] doesn't exist`);
      return;
    }
    this.log(`Time: [${timeKey}] ${performance.now() - this.timeKeys[timeKey]}ms`);
    delete this.timeKeys[timeKey];
  };

  public promiseTimeCounter = async <TResolve>(title: string, promisedMethod: () => Promise<TResolve>): Promise<TResolve> => {
    if (!this.enabled) return promisedMethod();
    const start = performance.now();
    const resolve = await promisedMethod();
    this.log(`promiseTimeCounter: [${title}] ${performance.now() - start}ms`);
    return resolve;
  };

  public logMethod = <TArgs extends any[], TReturn, >(message: string, method: (...args: TArgs) => TReturn) => {
    const methodId = guid(1);
    return (...args: TArgs) => {
      this.log(
        message + '>before',
        {
          methodId,
          args,
        },
      );
      const started = Date.now();
      const output = method(...args);
      this.log(
        message + '> after',
        {
          args,
          methodId,
          output,
          duration: getDurationString(started, Date.now()),
        },
      );
      return output;
    };
  };

  /**
   * Monitor and log all class's method calls
   *
   * Note: args are not hard-copied but cbCaptureData's data are always hard-copied.
   */
  public logInstanceMethods = (
    {
      instance,
      logPrefix,
      _this = instance,
    }: {
      instance: TObject;
      logPrefix?: string;
      _this?: any;
    },
  ): void => {
    if (!this.enabled) return;
    global.classInstance = instance;
    const keys =
      Object.keys(instance)
        .concat(Object.getOwnPropertyNames(instance.__proto__))
        .concat("setState");
    this.log(
      'logInstanceMethods started',
      {monitoringMethods: keys.filter(key => typeof instance[key] === 'function')},
    );
    for (const key of keys) {
      if (typeof instance[key] === 'function') {
        const originalMethod = instance[key];
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const prefix = logPrefix ? `${logPrefix}>` : "";
        instance[key] = (...args: any[]) => {
          self.log(
            `${prefix}before > ${key}()`,
            {
              args,
              "Debug operation": "Warning: These args are not hard-copied!",
            },
            false,
          );
          const output = originalMethod.apply(_this, args);
          self.log(
            `${prefix} after > ${key}()`,
            {
              methodArgs: args,
              methodOutput: output,
              "Debug operation": "Warning: Output is not hard-copied!",
            },
            false,
          );
          return output;
        };
      }
    }
  };

  //#endregion "Log methods"

  //#region "Children operations"

  public startChildOperation(operationConfig: Omit<IDebugOperationConfig, "setupName"> = {}): DebugOperation {
    const childOperation = new DebugOperation({
      setupName: this.config.setupName,
      ...operationConfig,
    });
    this.log(
      `Start CHILD operation${operationConfig.operationName ? `: ${operationConfig.operationName}` : ""}`,
      childOperation,
      false,
    );
    return childOperation;
  }

  //#endregion "Children operations"
}

// Utils

const getDebugSetupKey = (setupName: string) => `_debug_${setupName}`;

const setSetupActive = (setupName: string, active: boolean): void => {
  setLs(`${getDebugSetupKey(setupName)}/active`, active);
};
const getSetupActive = (setupName: string): boolean => {
  return getLs(`${getDebugSetupKey(setupName)}/active`, false);
};

const setSetupConsoleEnabled = (setupName: string, console: boolean): void => {
  setLs(`${getDebugSetupKey(setupName)}/console`, console);
};
const getSetupConsoleEnabled = (setupName: string): boolean => {
  return getLs(`${getDebugSetupKey(setupName)}/console`, true);
};

const getLs = <T>(lsKey: string, defaultValue: T): T => {
  const lsData = localStorage.getItem(lsKey);
  if (!lsData) {
    return defaultValue;
  }

  const data = JSON.parse(lsData);
  if (data === null) {
    return defaultValue;
  }

  return data as T;
};

const setLs = <T>(lsKey: string, value: T): void => {
  const data = JSON.stringify(value);
  localStorage.setItem(lsKey, data);
};

const formatDate = (date: Date = new Date()) => {
  const year = date.getFullYear().toString()
    .padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString()
    .padStart(2, '0');
  const hours = date.getHours().toString()
    .padStart(2, '0');
  const minutes = date.getMinutes().toString()
    .padStart(2, '0');
  const seconds = date.getSeconds().toString()
    .padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString()
    .padStart(3, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
};

const hardCopy = (obj: TObject): TObject => JSON.parse(debugJsonStringify(obj));

const debugJsonStringify = (obj: any, spacing = 0) => {
  const cache = new WeakMap();

  const replacer = (key: string, value: any): any => {
    key;
    if (value === global) return "[global]";
    if (value instanceof Error) return `[Error] ${value.message || "Unknown error"}`;
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) return '[circular-ref]';
      cache.set(value, true);
    }
    if (typeof value === 'function') return `[function] ${value.toString()}`;
    return value;
  };

  return JSON.stringify(obj, replacer, spacing);
};
