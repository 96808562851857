import {TDBEntityDataOnly} from "utils-library/dist/commonJs/db-entity-interfaces";

import {IDynaCMSSettings} from "../../../db/DBDynaCMSSettings.interfaces";

export const API_PATH_ApiDynaCMSSettingsPut = '/services/dyna-cms/apiDynaCMSSettingsPut';

export type IApiDynaCMSSettingsPutRequest = Partial<TDBEntityDataOnly<IDynaCMSSettings>>;

export interface IApiDynaCMSSettingsPutResponse {
  ok: true;
}
