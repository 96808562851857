import {convertDictionaryToViewLabelArray} from "utils-library/dist/commonJs/utils";

export enum EDynaCMSRightsDEPRECATED {
  ADMINISTRATOR = "DCMS_AD",  // Full access
  EDITOR = "DCMS_E",          // Full Article access
  AUTHOR = "DCMS_A",          // Can create and editor only its own articles
  REVIEWER = "DCMS_R",        // Can only review (review part not implemented for now)
}

const labelsDic: Record<EDynaCMSRightsDEPRECATED, string> = {
  [EDynaCMSRightsDEPRECATED.ADMINISTRATOR]: "CMS / Administrator",
  [EDynaCMSRightsDEPRECATED.EDITOR]: "CMS / Editor",
  [EDynaCMSRightsDEPRECATED.AUTHOR]: "CMS / Author",
  [EDynaCMSRightsDEPRECATED.REVIEWER]: "CMS / Reviewer (n/a)",
};

export const EDynaCMSRightsArrayDEPRECATED = convertDictionaryToViewLabelArray(labelsDic);
