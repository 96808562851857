import {connect} from "react-dynadux";

import {
  SocialNetworksMetaTags as Component,
} from "./SocialNetworksMetaTags";
export const SocialNetworksMetaTags = connect(Component);

export type {
  ISocialNetworksMetaTagsProps,
} from "./SocialNetworksMetaTags";
