import {
  useState,
  useEffect,
} from "react";

export interface IScreenSize {
  width: number;
  height: number;
}

export const useWindowResize = (): IScreenSize => {
  const [screenSize, setScreenSize] = useState<IScreenSize>(getScreenSize());

  useEffect(() => {
    const handleResize = () => setScreenSize(getScreenSize());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};

const getScreenSize = (): IScreenSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
});
