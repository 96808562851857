import * as React from "react";
import {useState} from "react";

import {Box} from "../Box";
import {IconViewer} from "../IconViewer";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {
  IIconComponent,
  createIcon,
} from "../IconComponent";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import UserFallbackIcon from '@mui/icons-material/AccountCircle';

export interface IProfileIconProps {
  src?: string;
  alt: string;
  title?: string;
  FallbackIcon?: IIconComponent;
  size?: number;
}

export const ProfileIcon: React.FC<IProfileIconProps> = (
  {
    src,
    alt,
    title,
    FallbackIcon = createIcon.byMuiIcon(UserFallbackIcon),
    size = 128,
  },
) => {
  const [loadError, setLoadError] = useState<Error | null>(null);

  const handleLoadError = (error: any): void => setLoadError(error);

  const sxIcon: SxProps<Theme> = {
    transform: 'scale(1.5)',
    opacity: 0.2,
  };

  return (
    <Box
      sx={{
        width: size + 'px',
        height: size + 'px',
        background: 'transparent',
        borderRadius: "50%",
        overflow: 'hidden',
      }}
      dataComponentName="ProfileIconComponent"
      title={title}
    >
      <img
        key={src}
        style={{
          width: size + 'px',
          height: size + 'px',
          display: loadError || !src ? 'none' : undefined,
        }}
        src={src}
        alt={alt}
        referrerPolicy="no-referrer"
        onError={handleLoadError}
      />
      <IconViewer
        sx={sxIcon}
        show={!!src && !!loadError}
        width={size}
        Icon={createIcon.byMuiIcon(BrokenImageIcon)}
      />
      <IconViewer
        sx={sxIcon}
        show={!src}
        width={size}
        Icon={FallbackIcon}
      />
    </Box>
  );
};
