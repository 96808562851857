import {connect} from "react-dynadux";

import {
  DynaIntlProvider as Component,
} from "./DynaIntlProvider";
export const DynaIntlProvider = connect(Component);

export type {
  IDynaIntlProviderProps,
} from "./DynaIntlProvider";
