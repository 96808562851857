import {
  useState,
  useEffect,
} from "react";
import moment from "moment";

import {
  IAppOptions,
  getDefaultAppOptions,
} from "server-app";

import {FormatDateTime} from "ui-components/dist/FormatDateTime";

import {apiAppOptionsGet} from "../../api/options/apiAppOptionsGet";

export const useAppOptions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appOptions, setAppOptions] = useState<IAppOptions>(getDefaultAppOptions());
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const appOptions = await apiAppOptionsGet();
        setAppOptions(appOptions);
      }
      catch (e) {
        console.error('useAppOptions: load error', e);
        setError(e);
      }
      finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    appOptionsReady: !isLoading && !error,
    isLoading,
    appOptions,
    error,
    getPermanentDeletionDateMessage: (deletedAt: number): JSX.Element => {
      const date =
        moment(deletedAt)
          .add(appOptions.deleteForeverAfterDays, 'days')
          .toDate();
      const willBePermanentlyDeletedInDays =
        moment(deletedAt)
          .add(appOptions.deleteForeverAfterDays, 'days')
          .diff(new Date(), 'days');
      return (
        <span>
          Will be deleted permanently on <FormatDateTime value={date}/> (in {willBePermanentlyDeletedInDays} days).
        </span>
      );
    },
  };
};
