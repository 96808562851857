export const API_PATH_ApiArticleContentSetAtArticleCoverMedia = '/services/dyna-cms/apiArticleContentSetAtArticleCoverMedia';

export interface IApiArticleContentSetAtArticleCoverMediaBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
  setIt: boolean;
}

export interface IApiArticleContentSetAtArticleCoverMediaResponse {
  updatedAt: number;
  updatedBy: string;
}
