import {IDynaProfile} from "../../../interfaces";

export const API_PATH_ApiDynaProfilesForUserSearchGet = '/services/dyna-profiles/apiDynaProfilesForUserSearchGet';

export interface IApiDynaProfilesForUserSearchGetRequest {
  search: string;
  skip: number;
  limit: number;
  archived?: boolean | "both";
  deleted?: boolean | "both";
}

export interface IApiDynaProfilesForUserSearchGetResponse {
  profiles: IDynaProfile[];
}
