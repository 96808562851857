import {
  IRouteArgs,
  routeDynaCMSPageViewerPaths,
} from "./routeDynaCMSPageViewer.paths";

import {IAppRoute} from "../../application/config/IAppRoute";

import {ArticleViewer} from "../pages/ArticleViewer";

export const routeDynaCMSPageViewer: IAppRoute<IRouteArgs> = {
  ...routeDynaCMSPageViewerPaths,
  render: (
    {
      pathParams: {
        countryId,
        languageId,
        groupId,
        urlId,
      },
    },
  ) => (
    <ArticleViewer
      countryId={countryId}
      languageId={languageId}
      groupId={groupId}
      idType="urlId"
      id={urlId}
      loadPurpose="view"
    />
  ),
};
