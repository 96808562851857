import {enumInfo} from "utils-library/dist/commonJs/utils";

import {ETKDynaCMS} from "../intl/dynaCMSHardcodedTranslations";

export enum EDynaCMSProfileUserRights {
  EDITOR = "DCMS_E",          // Full Article access
  AUTHOR = "DCMS_A",          // Can create and editor only its own articles
  REVIEWER = "DCMS_R",        // Can only review (review part not implemented for now)
}
export const dynaCMSProfileUserRights = enumInfo<EDynaCMSProfileUserRights>({
  [EDynaCMSProfileUserRights.EDITOR]: {
    label: {id: ETKDynaCMS.USER_PROFILE_RIGHT_EDITOR_LABEL},
    description: {id: ETKDynaCMS.USER_PROFILE_RIGHT_EDITOR_DESCRIPTION},
  },
  [EDynaCMSProfileUserRights.AUTHOR]: {
    label: {id: ETKDynaCMS.USER_PROFILE_RIGHT_AUTHOR_LABEL},
    description: {id: ETKDynaCMS.USER_PROFILE_RIGHT_AUTHOR_DESCRIPTION},
  },
  [EDynaCMSProfileUserRights.REVIEWER]: {
    label: {id: ETKDynaCMS.USER_PROFILE_RIGHT_REVIEWER_LABEL},
    description: {id: ETKDynaCMS.USER_PROFILE_RIGHT_REVIEWER_DESCRIPTION},
  },
});
