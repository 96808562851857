import {IDynaCMSArticleHeading} from "server-app";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "ui-components/dist/FlexContainer";
import {Box} from "ui-components/dist/Box";
import {Typography} from "ui-components/dist/Typography";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {BlockViewer} from "../../../../block-viewers/BlockViewer";

export interface ITinySmallHeadingViewerProps {
  article: IDynaCMSArticleHeading;
  showPhoto: boolean;
}

export const TinySmallHeadingViewer = (props: ITinySmallHeadingViewerProps): JSX.Element => {
  const {
    article: {
      title,
      subtitle,
      description,
      coverMediaBlockCopy,
    },
    showPhoto,
  } = props;
  const {isMobile} = useBreakpointDevice();

  return (
    <FlexContainerHorizontal
      dataComponentName="TinySmallHeadingViewer"
      sx={{
        background: theme => theme.palette.grayShades.gray1,
        marginBottom: '1px',
        width: isMobile ? undefined : '70%',
      }}
      alignVertical="middle"
      spacing={1}
    >
      <FlexItemMin
        sx={{
          minWidth: 64,
          maxWidth: 64,
        }}
        show={showPhoto}
      >
        <BlockViewer
          block={coverMediaBlockCopy}
          showPhotoTexts={false}
          showPhotoCredits={false}
          showPhotoPortraitSmaller={false}
        />
      </FlexItemMin>
      <FlexItemMax>
        <Typography
          variant="h1"
          sx={{fontSize: theme=>(theme.typography.fontSize * 1.2) + 'px !important'}}
        >
          {title}
        </Typography>
        <Box show={!isMobile}><b>{subtitle}</b></Box>
        <Box show={!isMobile}><i>{description}</i></Box>
      </FlexItemMax>
    </FlexContainerHorizontal>
  );
};
