import {connect} from "react-dynadux";

import {
  DynaProfileManageForm as Component,
} from "./DynaProfileManageForm";
export const DynaProfileManageForm = connect(Component);

export type {
  IDynaProfileManageFormProps,
} from "./DynaProfileManageForm";
