export const getWidthHeightFromScript = (script: string): {
  width: number;
  height: number;
  error: string;
} => {
  const width = getValueFromScript(script, "width");
  const height = getValueFromScript(script, "height");
  const error = [
    width.error,
    height.error,
  ].filter(Boolean).join(' ');
  return error
    ? {
      width: -1,
      height: -1,
      error,
    }
    : {
      width: width.value,
      height: height.value,
      error: "",
    };
};

const getValueFromScript = (script: string, propertyName: string): {
  value: number;
  error: string;
} => {
  const splitResult = script.split(propertyName);
  if (splitResult.length === 1) {
    return {
      value: -1,
      error: `Property [${propertyName}] not found`,
    };
  }
  if (splitResult.length > 2) {
    return {
      value: -1,
      error: `Property [${propertyName}] found more than once`,
    };
  }
  return {
    value: parseFloat(splitResult[1].split('px')[0].trim().slice(1)),
    error: "",
  };
};
