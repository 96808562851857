import {IDynaCMSArticleStats} from "../../../../db/IDynaCMSArticle";

export const API_PATH_ApiArticleContentPropertyUpdate = '/services/dyna-cms/apiArticleContentPropertyUpdate';

export interface IApiArticleContentUpdatePropertyBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
  contentProperty: string;
  value: any;
  historyReason?: string;
}

export interface IApiArticleContentUpdatePropertyResponse {
  validationError: string;
  stats: IDynaCMSArticleStats;
  updatedAt: number;
  updatedBy: string;
}
