export const API_PATH_ApiArticleHeaderDelete = '/services/dyna-cms/apiArticleHeaderDelete';

export interface IApiArticleHeaderDeleteBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  deleteIt: boolean;
  historyReason?: string;
}

export interface IApiArticleHeaderDeleteResponse {
  deletedAt: number;
  deletedBy: string;
}
