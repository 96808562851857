import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

export const sxHeadingLetter: SxProps<Theme> = {
  float: 'left',
  textTransform: 'uppercase',
  fontSize: theme => theme.typography.fontSize * 2,
  fontWeight: 'bold',
  lineHeight: 1,
  marginTop: theme => theme.spacing(1),
  marginBottom: theme => theme.spacing(-1.33),
  marginLeft: theme => theme.spacing(1.5),
  marginRight: theme => theme.spacing(1.5),
};
