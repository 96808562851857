import {IApiAppVersionGetResponse} from "server-app";

import {useLoadData} from "ui-components/dist/useLoadData";

import {apiAppVersionGet} from "../../api/version/apiAppVersionGet";
import {appConfig} from "../../config/appConfig";

export const useLoadAppVersion = (
  {loadOnStartup = true}: {
    loadOnStartup?: boolean;
  }
  = {},
) => {
  const {
    data: {
      version: serverVersion,
      codeName: serverCodeName,
      builtAt: serverBuiltAt,
    },
    load,
    isLoading,
    loaded,
    error,
  } = useLoadData<IApiAppVersionGetResponse>({
    defaultData: {
      version: 'loading...',
      codeName: '...',
      builtAt: '...',
    },
    loadOnStartup,
    load: apiAppVersionGet,
  });

  return {
    server: {
      version: serverVersion,
      codeName: serverCodeName,
      builtAt: serverBuiltAt,
    },
    client: {
      version: appConfig.appVersion,
      codeName: appConfig.appCodeName,
      buildAt: appConfig.builtAt,
    },
    load,
    isLoading,
    loaded,
    error,
  };
};
