import {ITag} from "../../../db/DBDynaCMSTagsEntity";

export const API_PATH_ApiTagsSearch = '/services/dyna-cms/apiTagsSearch';

export interface IApiTagsSearchQueryRequest {
  search: string;
  skip: number;
  limit: number;
}

export interface IApiTagsSearchResponse {
  tags: ITag[];
}
