import * as React from "react";

import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";

import {InputModalButton} from "ui-components/dist/InputModalButton";
import {FormLocal} from "ui-components/dist/FormLocal";
import {GridItem} from "ui-components/dist/Grid";
import {
  Input,
  EInputVariant,
} from "ui-components/dist/Input";

import {createIcon} from "ui-components/dist/IconComponent";
import AddUserIcon from "@mui/icons-material/AddCircleOutline";

export interface IAddUserButtonModalProps {
  show?: boolean;
  disabled: boolean;
  onUserSelection: (userId: string) => void;
}

interface IFormData {
  userId: string;
}

export const AddUserButtonModal: React.FC<IAddUserButtonModalProps> = (
  {
    show = true,
    disabled,
    onUserSelection,
  },
) => {
  return (
    <InputModalButton<any, string>
      show={show}
      inline={true}
      internalName=""
      value=""
      hideLabelOnMobile
      modalMaxWidth={520}
      disabled={disabled}
      Icon={createIcon.byMuiIcon(AddUserIcon)}
      label="Add user"
      labelWrap={false}
      renderModalPicker={
        (
          {
            onSubmit,
            onCancel,
          },
        ) => {
          const handleSubmit = async (data: IFormData): Promise<void> => onSubmit(data.userId);
          return (
            <FormLocal<IFormData>
              mode="edit"
              title="Add user"
              data={{userId: ""}}
              validationRules={{userId: ({value}) => validateDataMethods.isGuid(value)}}
              renderFields={
                (
                  {
                    data: {userId},
                    validationErrors,
                  },
                ) => {
                  return (
                    <GridItem>
                      <Input<IFormData>
                        name="userId"
                        label="User id"
                        variant={EInputVariant.OUTLINED}
                        value={userId}
                        autoFocus
                        helperLabel="User id can be found from the Application Settings page, clicing on the user's icon."
                        validationError={validationErrors.userId}
                      />
                    </GridItem>
                  );
                }
              }
              onSubmit={handleSubmit}
              onCancel={onCancel}
            />
          );
        }
      }
      onChange={onUserSelection}
    />
  );
};
