import {
  IDynaProfile,
  IDynaProfileUserRight,
} from "../../../interfaces";

export const API_PATH_ApiDynaProfileDefaultForUserGet = '/services/dyna-profiles/apiDynaProfileDefaultForUserGet';

export interface IApiDynaProfileDefaultForUserGetRequest {
}

export interface IApiDynaProfileDefaultForUserGetResponse {
  profile: IDynaProfile;
  rights: IDynaProfileUserRight[];
}
