import * as React from "react";
import {
  useState,
  ReactElement,
} from "react";

import {Box} from "../Box";
import {
  Button,
  EButtonVariant,
} from "../Button";
import {Modal} from "../Modal";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {IIconComponent} from "../IconComponent";

export interface IInputModalButtonProps<TData, TValue> {
  show?: boolean;
  inline?: boolean;
  internalName: keyof TData;
  variant?: EButtonVariant;
  Icon?: IIconComponent;              // The icon of the button
  label: string;                      // Label of the input control
  labelWrap?: boolean;
  modalFullWidth?: boolean;
  modalMinWidth?: number;
  modalMaxWidth?: number;
  fullMode?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
  hideLabelOnTablet?: boolean;
  value: TValue;
  labelValue?: ReactElement | string; // Label of the current value
  renderModalPicker: (                // Render callback for the picker shown in modal window
    args: {
      value: TValue;
      onSubmit: (value: TValue) => void;
      onCancel: () => void;
    },
  ) => ReactElement;
  helperLabel?: string;
  validationError?: string;
  onChange: (value: TValue, internalName: keyof TData) => void;
}

export const InputModalButton = <TData, TValue>(props: IInputModalButtonProps<TData, TValue>): ReactElement | null => {
  const {
    show = true,
    inline = false,
    internalName,
    variant,
    Icon,
    label,
    labelWrap = true,
    fullMode = false,
    readOnly = false,
    disabled = false,
    hideLabelOnMobile,
    hideLabelOnTablet,
    modalFullWidth,
    modalMinWidth,
    modalMaxWidth,
    renderModalPicker,
    value,
    labelValue,
    helperLabel,
    validationError,
    onChange,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleButtonClick = () => setShowModal(true);

  const handleSubmit = (value: TValue): void => {
    onChange(value, internalName);
    setShowModal(false);
  };

  const handleCancel = (): void => {
    setShowModal(false);
  };

  if (!show) return null;

  const button = (
    <Button
      dataComponentName="InputModalButton"
      sx={{
        width: inline ? undefined : '100%',
        pointerEvents: readOnly ? 'none' : undefined,
      }}
      variant={variant}
      wrap={labelWrap}
      Icon={Icon}
      children={label}
      disabled={disabled}
      hideLabelOnMobile={hideLabelOnMobile}
      hideLabelOnTablet={hideLabelOnTablet}
      fullWidth
      description={labelValue}
      onClick={handleButtonClick}
    />
  );

  const modal = (
    <Modal
      show={showModal}
      fullMode={fullMode}
      fullWidth={modalFullWidth}
      minWidth={modalMinWidth}
      maxWidth={modalMaxWidth}
    >
      {renderModalPicker({
        value,
        onSubmit: handleSubmit,
        onCancel: handleCancel,
      })}
    </Modal>
  );

  return helperLabel || validationError
    ? (
      <Box inline={inline}>
        {button}
        <HelperText>{helperLabel}</HelperText>
        <HelperText type={EHelperTextType.ERROR}>{validationError}</HelperText>
        {modal}
      </Box>
    )
    : (
      <>
        {button}
        {modal}
      </>
    );
};
