import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDynaCMSArticleCreatePaths} from "./routeDynaCMSArticleCreate.paths";

import {ArticleCreate} from "../pages/ArticleCreate";

export const routeDynaCMSArticleCreate: IAppRoute<{
  countryId: string;
  languageId: string;
}> = {
  ...routeDynaCMSArticleCreatePaths,
  render: ({
    pathParams: {
      countryId,
      languageId,
    },
  }) => (
    <ArticleCreate
      countryId={countryId}
      languageId={languageId}
    />
  ),
};
