import {IStorageApi} from "../data-limited-container";

const memory: Record<string, any> = {};

export const memoryStorageApi: IStorageApi = {
  onLoad: async (container, id) => {
    return memory[getKey(container, id)] || null;
  },
  onSave: async (container, id, data) => {
    memory[getKey(container, id)] = data;
  },
  onDelete: async (container, id) => {
    delete memory[getKey(container, id)];
  },
};
const getKey = (container: string, id: string): string => `MEM-${container}-${id}`;
