import * as React from "react";
import {
  useState,
  useMemo,
  useEffect,
} from "react";

import {
  IDynaCMSArticleBlock,
  EDynaCMSBlockType,
  IDynaCMSBlockBody,
  EDynaCMSRightsDEPRECATED,
  EViewWidthType,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {Condition} from "ui-components/dist/Condition";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";
import {ErrorBanner} from "ui-components/dist/ErrorBanner";
import {InViewPort} from "ui-components/dist/InViewPort";
import {
  ShareButtons,
  EShareButtons,
} from "ui-components/dist/ShareButtons";
import {InputSwitch} from "ui-components/dist/InputSwitch";
import {IsLoadingBox} from "ui-components/dist/IsLoadingBox";
import {useLocalStorageState} from "ui-components/dist/useLocalStorageState";

import {IAppStore} from "../../../../../state/IAppStore";

import {
  AppPageTitle,
  AppPageDescription,
  CanonicalLink,
  CopyToClipboardNotificationModal,
} from "../../../../application/public-components";

import {SocialNetworksMetaTags} from "../../commons/SocialNetworksMetaTags";

import {HeaderViewerEva} from "../../article-header-viewers/HeaderViewerEva";
import {BlockViewer} from "../../block-viewers/BlockViewer";

import {
  Ad,
  EDynaAdOrientation,
} from "../../../../dyna-ads/public-components";

import {
  DynaCMSFont,
  DynaCMSRelatedArticles,
  ERelatedArticlesFormat,
  useLoadArticleCache,
} from "../../../public-components";

import {
  AdLinkWiseSephora,
  AdLinkWisePoliHome,
} from "../../../../dyna-ads/drivers/LinkWi.se";

import {routeDynaCMSArticleMgtmPaths} from "../../../routes/routeDynaCMSArticleMgtm.paths";

import EditIcon from '@mui/icons-material/Edit';

export interface IArticleViewerEvaProps {
  store: IAppStore;
  groupId: string;
  languageId: string;
  countryId: string;
  id: string;
  idType: 'urlId' | 'articleId';
  loadPurpose?: "view" | "preview" | "manage";
  showHeader?: boolean;
  contentSpacing?: number;
}

export const ArticleViewerEva = (props: IArticleViewerEvaProps): JSX.Element => {
  const {
    store: {
      userAuth: {
        state: {user: {id: userId}},
        utils: {userHasAnyOfRights},
      },
      dynaCMS: {
        state: {
          settings: {
            articleShareButtons: _articleShareButtons,
            applicationTwitterAccount,
          },
        },
      },
    },
    groupId,
    languageId,
    countryId,
    id,
    idType,
    loadPurpose = 'view',
    showHeader = true,
    contentSpacing = 3,
  } = props;

  const articleShareButtons: EShareButtons[] =
    Object.entries(_articleShareButtons)
      .filter(a => a[1])
      .map(a => a[0] as any);


  const {
    isLoading,
    article,
    article: {
      showRelatedInsideBody,
      showRelatedAtTheEnd,
      showAds,
      publishedAt,
    },
    defaultArticleFontFamilyGeneral,
    loadError,
  } = useLoadArticleCache({
    search: {
      groupId,
      languageId,
      countryId,
      articleId: idType === "articleId" ? id : undefined,
      urlId: idType === "urlId" ? id : undefined,
      purpose: loadPurpose,
    },
    cache: {
      containerName: 'dyna-cms--articleViewerEva',
      maxSizeInBytes: 500000,
      updateSilentlyExistedContent: true,
    },
    reloadDep: id,
  });

  const [readyToShowRelatedAtFooter, setReadyToShowRelatedAtFooter] = useState<boolean>(false);

  const [openEditInNewTab, setOpenEditInNewTab] = useLocalStorageState<boolean>({
    default: false,
    key: 'dyna-cms--open-edit-is-new-tab',
  });

  useEffect(() => {
    if (!id) return;
    // Wait for the render to be completed
    // ...otherwise, the InViewPort will in the viewport, and the related articles will be loaded before the render of the article.
    setTimeout(() => setReadyToShowRelatedAtFooter(true), 2000);
  }, [id]);

  const {
    id: articleId,
    fontFamilyGeneral: articleFontFamily,
    coverMediaBlockId,
    content: {
      order,
      blocks: _blocks,
    },
    showShareButtons,
    viewWidthType = EViewWidthType.EASY_READ,
    createdBy,
  } = article;

  const canEdit =
    userHasAnyOfRights([
      EDynaCMSRightsDEPRECATED.EDITOR,
      EDynaCMSRightsDEPRECATED.AUTHOR,
    ])
    || (
      userHasAnyOfRights([EDynaCMSRightsDEPRECATED.AUTHOR])
      && createdBy === userId
    );

  const editButtonHref: string = (() => {
    if (articleId) {
      return routeDynaCMSArticleMgtmPaths.getRoutePath({
        countryId,
        groupId,
        languageId,
        articleId,
      });
    }
    else if (idType === "articleId") {
      return routeDynaCMSArticleMgtmPaths.getRoutePath({
        countryId,
        groupId,
        languageId,
        articleId: id,
      });
    }
    else {
      return ""; // The address has urlId, and for some reason the article couldn't be loaded
    }
  })();
  const editButton = (
    <Box
      show={canEdit}
      sx={{
        textAlign: 'center',
        margin: theme => theme.spacing(2),
      }}
    >
      <Button
        icon={<EditIcon/>}
        size={EButtonSize.LARGE}
        href={editButtonHref}
        disabled={isLoading || !editButtonHref}
        hrefNewWindow={openEditInNewTab}
      >
        Edit article
      </Button>
      <br/>
      <Box inline>
        <InputSwitch
          ariaLabel="Open in new tab"
          label="In new tab"
          value={openEditInNewTab}
          onChange={setOpenEditInNewTab}
        />
      </Box>
    </Box>
  );

  const blocks = useMemo(
    () =>
      order
        .map(order => _blocks[order])
        .filter(Boolean)
        .filter(block => block.show && block.blockId !== coverMediaBlockId)
        .reduce((acc: IDynaCMSArticleBlock[], block: IDynaCMSArticleBlock) => {
          // Break (only) the EDynaCMSBlockType.BODY to multiple block to be easily inject ads and others
          if (block.content.type === EDynaCMSBlockType.BODY) {
            const body: IDynaCMSBlockBody = block.content as any;
            const paragraphs = body.html.split('</p>').filter(Boolean)
              .map(t => t + '</p>');
            paragraphs.forEach((paragraph, index) => {
              acc.push({
                ...block,
                content: {
                  ...body,
                  headingFirstLetter: index === 0 ? body.headingFirstLetter : false,
                  html: paragraph,
                },
              });
            });
          }
          else {
            acc.push(block);
          }
          return acc;
        }, []),
    [order, _blocks],
  );

  const paragraphIndexers =
    blocks.reduce((acc: number[], block, index) => {
      if (block.content.type === EDynaCMSBlockType.BODY) acc.push(index);
      return acc;
    }, []);
  const headingsIndexers =
    blocks.reduce((acc: number[], block, index) => {
      if (block.content.type === EDynaCMSBlockType.HEADING) acc.push(index);
      return acc;
    }, []);

  const smallRelatedArticlesIndex =
    (() => {
      if (!showRelatedInsideBody) return -1;
      if (headingsIndexers.length > 1) return headingsIndexers[1] - 1;
      return (
        paragraphIndexers[3] ||
        paragraphIndexers[2] ||
        paragraphIndexers[1] ||
        paragraphIndexers[0]
      );
    })();

  const bigRelatedArticlesIndex =
    (() => {
      if (!showRelatedInsideBody) return -1;
      const thirdFromEnd =
        paragraphIndexers
          .concat()
          .slice(0, -3)
          .pop();
      if (!thirdFromEnd) return -1;
      if (thirdFromEnd === smallRelatedArticlesIndex) return -1;
      return thirdFromEnd;
    })();

  return (
    <Box dataComponentName="ArticleViewerEva">
      <SocialNetworksMetaTags article={article}/>
      <AppPageTitle>
        {article.title}
      </AppPageTitle>
      <AppPageDescription>
        {article.description}
      </AppPageDescription>
      <CanonicalLink href={article.canonicalHref}/>
      <Box sx={{textAlign: 'center'}}>
        <IsLoadingBox
          inline
          isLoading={isLoading}
        />
      </Box>
      {editButton}
      <DynaCMSFont
        font={[
          defaultArticleFontFamilyGeneral,
          articleFontFamily,
        ]}
      >
        <Box
          component="article"
          sx={{
            margin: 'auto',
            padding: theme => theme.spacing(1),
            '& > *': {
              marginTop: theme => theme.spacing(contentSpacing),
              marginBottom: theme => theme.spacing(contentSpacing),
            },
          }}
        >
          {loadError && <ErrorBanner error={loadError}/>}

          <Condition if={showHeader}>
            <HeaderViewerEva
              h={1}
              article={article}
              bigTitle
              target="view"
              showPhotoTexts
              showPhotoCredits
              showDescription
              showPhotoPortraitSmaller
              showVideoPreviewOnly={false}
            />
          </Condition>

          <Condition if={!isLoading && !!publishedAt && showShareButtons}>
            <aside>
              <ShareButtons
                shareButtons={articleShareButtons}
                websiteTwitterAccount={applicationTwitterAccount}
              />
            </aside>
          </Condition>

          {blocks.map((block, index) => (
            <React.Fragment key={index}>
              <BlockViewer
                article={article}
                block={block}
                viewWidthType={viewWidthType}
                contentSpacing={contentSpacing}
                showPhotoPortraitSmaller
                showVideoPreviewOnly={false}
              />
              <Condition if={index === smallRelatedArticlesIndex}>
                <DynaCMSRelatedArticles
                  h={2}
                  topBottomSpacing={0}
                  loadPublishedBeforeSourceArticle={false}
                  sourceArticle={article}
                  format={ERelatedArticlesFormat.TWO_READ_MORE_INLINE}
                />
              </Condition>
              <Condition if={index === bigRelatedArticlesIndex}>
                <DynaCMSRelatedArticles
                  h={2}
                  topBottomSpacing={0}
                  loadPublishedBeforeSourceArticle={false}
                  sourceArticle={article}
                  format={ERelatedArticlesFormat.TINY_SMALL_PHOTO_TEXT}
                />
              </Condition>
            </React.Fragment>
          ))}
        </Box>

        <Condition if={!isLoading && !!publishedAt && showShareButtons}>
          <aside>
            <ShareButtons
              label="Share this article"
              shareButtons={articleShareButtons}
              websiteTwitterAccount={applicationTwitterAccount}
            />
          </aside>
        </Condition>
      </DynaCMSFont>

      {editButton}

      {/*
        TEMPORARY: AD66D-00228 All beauty articles should have Sephora affiliate banner
        Will removed by DNAD-60342 Linkwise Affiliate integration https://trello.com/c/MozXHYpw
      */}
      <Box sx={{height: 32}}/>
      <AdLinkWiseSephora show={groupId === "beauty"}/>
      <AdLinkWisePoliHome show={groupId === "deco"}/>

      <Condition if={showAds}>
        <Box sx={{my: theme => theme.spacing(6)}}>
          <Ad
            show={!!articleId}
            orientation={EDynaAdOrientation.RECTANGLE}
            groupIds={[article.groupId]}
          />
        </Box>
      </Condition>

      <InViewPort
        show={showRelatedAtTheEnd && readyToShowRelatedAtFooter}
        rootMargin={400}
      >
        <DynaCMSRelatedArticles
          h={2}
          sourceArticle={article}
          header="more..."
          loadPublishedBeforeSourceArticle
          topBottomSpacing={6}
          format={ERelatedArticlesFormat.SIX_PACK}
        />
      </InViewPort>

      <Condition if={showAds}>
        <Box sx={{my: theme => theme.spacing(6)}}>
          <Ad
            show={!!articleId}
            orientation={EDynaAdOrientation.HORIZONTAL}
            groupIds={[article.groupId]}
          />
        </Box>
      </Condition>

      <CopyToClipboardNotificationModal/>
    </Box>
  );
};
