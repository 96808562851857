import {
  IApiArticleContentGetResponse,
  IDynaCMSArticleBlockAddress,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {SxProps} from "ui-components/dist/ThemeProvider";
import {IsLoadingBox} from "ui-components/dist/IsLoadingBox";
import {ErrorBanner} from "ui-components/dist/ErrorBanner";
import {useLoadData} from "ui-components/dist/useLoadData";

import {BlockViewer} from "../../components/block-viewers/BlockViewer";
import {apiArticleContentGet} from "../../api/articles/content/apiArticleContentGet";

export interface IDynaCMSArticleBlockViewerProps {
  sx?: SxProps;
  dataComponentName?: string;
  blockAddress: IDynaCMSArticleBlockAddress;
  showIsLoading?: boolean;
  showPhotoCredits?: boolean;
  showPhotoPortraitSmaller?: boolean;
  showVideoPreviewOnly?: boolean;
}

export const DynaCMSArticleBlockViewer = (props: IDynaCMSArticleBlockViewerProps): JSX.Element => {
  const {
    sx,
    dataComponentName,
    blockAddress,
    blockAddress: {
      groupId,
      languageId,
      countryId,
      articleId,
      blockId,
    },
    showIsLoading = true,
    showPhotoCredits = true,
    showPhotoPortraitSmaller = true,
    showVideoPreviewOnly = false,
  } = props;

  const {
    isLoading,
    data: {block},
    error,
  } = useLoadData<IApiArticleContentGetResponse>({
    defaultData: {
      block: {
        blockId: '',
        content: null as any,
        show: true,
        updatedAt: 0,
        updatedBy: '',
      },
    },
    reloadDep: Object.values(blockAddress),
    load: async () => apiArticleContentGet({
      groupId,
      languageId,
      countryId,
      articleId,
      blockId,
    }),
  });

  if (error) return <ErrorBanner error={error}/>;

  return (
    <Box
      sx={sx}
      dataComponentName={[dataComponentName, "DynaCMSArticleBlockViewer"]}
    >
      <IsLoadingBox isLoading={showIsLoading && isLoading}/>
      <BlockViewer
        block={isLoading ? null : block}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
      />
    </Box>
  );
};
