import {connect} from "react-dynadux";

import {
  ViewerMarkdown as Component,
} from "./ViewerMarkdown";
export const ViewerMarkdown = connect(Component);

export type {
  IViewerMarkdownProps,
} from "./ViewerMarkdown";
