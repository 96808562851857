import {EDynaProfileType} from "../../../interfaces";

export const API_PATH_ApiDynaProfilePost = '/services/dyna-profiles/apiDynaProfilePost';

export interface IApiDynaProfilePostRequest {
  type: EDynaProfileType;
  displayName: string;
  shortName: string;
  profileImageUrl: string;
  backgroundImageUrl: string;
  slogan: string;
  description: string;
  bioShort: string;
  bioUrl: string;
}

export interface IApiDynaProfilePostResponse {
  dataId: string;
}
