export const API_PATH_ApiArticleContentCreateShortUrl = '/services/dyna-cms/apiArticleContentCreateShortUrl';

export interface IApiArticleContentCreateShortUrlRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
}

export interface IApiArticleContentCreateShortUrlResponse {
  shortUrl: string;
}
