import {IDynaCMSBlockMarkdown} from "../../../../../db/EDynaCMSBlockType";
import {IDynaCMSArticleBlock} from "../../../../../db/IDynaCMSArticle";

export const API_PATH_ApiArticleContentMarkdownSplit = '/services/article-mgtm/apiArticleContentMarkdownSplit';

export interface IApiArticleContentMarkdownSplitRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
}

export interface IApiArticleContentMarkdownSplitResponse {
  updatedContent: IDynaCMSBlockMarkdown | null;
  newFollowUpBlocks: IDynaCMSArticleBlock[];
}
