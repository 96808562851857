import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaProfileManageForm} from "../components/DynaProfileManageForm";
import {routeProfileViewPaths} from "./routeProfileView.paths";

export const routeProfileView: IAppRoute<{ profileId: string }> = {
  ...routeProfileViewPaths,
  render: ({pathParams: {profileId}}) => (
    <DynaProfileManageForm
      profileId={profileId}
      viewMode="view"
    />
  ),
};
