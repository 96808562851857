import * as React from "react";

import {useInViewPort} from "../useInViewPort";

export interface IInViewPortProps {
  show?: boolean;               // Default is true. High priority flag to show the content or not.
  showOnlyInViewPort?: boolean; // Default is true. If false is shown always.
  delay?: number;
  rootMargin?: number;
  remain?: boolean;             // Default is true. Remain on the screen after viewed in viewport
  fullHeight?: boolean;
  children: any;
  onInViewPortChange?: (inViewPort: boolean) => void;
}

export const InViewPort = (props: IInViewPortProps) => {
  const {
    show = true,
    showOnlyInViewPort = true,
    delay,
    rootMargin,
    remain = true,
    fullHeight,
    children,
    onInViewPortChange,
  } = props;
  const {
    ref,
    inViewPort,
    viewed,
  } = useInViewPort({
    delay,
    rootMargin,
    onInViewPortChange,
  });

  if (!show) return null;

  return (
    <div
      ref={ref}
      style={{height: fullHeight ? '100%' : undefined}}
    >
      {
        show
        && ((remain && viewed) || (!showOnlyInViewPort || inViewPort))
          ? children
          : null
      }
    </div>
  );
};
