import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IViewerVideoPlayerFileProps} from "./ViewerVideoPlayerFile";

const ViewerVideoPlayerFileLazy = lazy(() => import("./ViewerVideoPlayerFile"));

export const ViewerVideoPlayerFile = (props: IViewerVideoPlayerFileProps) => (
  <LazyLoadComponent>
    <ViewerVideoPlayerFileLazy {...props}/>
  </LazyLoadComponent>
);
