import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IArticleMgtmProps} from "./ArticleMgtm";

const ArticleMgtmLazy = lazy(() => import("./ArticleMgtm"));
export type {
  IArticleMgtmProps,
} from "./ArticleMgtm";
export type {
  IArticleEditorAPI,
} from "./IArticleEditorAPI";

export const ArticleMgtm = (props: IArticleMgtmProps) => (
  <LazyLoadComponent>
    <ArticleMgtmLazy {...props}/>
  </LazyLoadComponent>
);
