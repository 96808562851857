import * as React from "react";
import {ReactElement} from "react";

import {Box} from "../Box";

import {IIconComponent} from "../IconComponent";

import {IconViewer} from "../IconViewer";
import {createIcon} from "../IconComponent";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const TikTokIcon = require("./assets/tiktok.svg");

export interface ISocialButtonsProps {
  facebookPage?: string;
  youTubeAccount?: string;
  twitterAccount?: string;
  instagramAccount?: string;
  pinterestAccount?: string;
  tiktokAccount?: string;
  linkedinCompanyAccount?: string;
  onClick?: () => void;
}

export const SocialButtons = (props: ISocialButtonsProps): ReactElement => {
  const {
    facebookPage,
    youTubeAccount,
    twitterAccount,
    instagramAccount,
    pinterestAccount,
    tiktokAccount,
    linkedinCompanyAccount,
    onClick,
  } = props;

  const renderIcon = (link: string, accountName: string | undefined, Icon: IIconComponent): ReactElement | null => {
    if (!accountName) return null;
    return (
      <a
        href={link + accountName}
        target="_blank"
        style={{color: 'inherit'}}
        rel="noreferrer"
        onClick={onClick}
      >
        <Box
          inline
          sx={{
            border: '1px solid',
            borderRadius: theme => theme.spacing(5),
            padding: theme => theme.spacing(1),
            margin: theme => theme.spacing(0.5),
            fontSize: 0,
            cursor: 'pointer',
          }}
        >
          <IconViewer
            Icon={Icon}
            width={28}
            height={28}
          />
        </Box>
      </a>
    );
  };

  return (
    <Box
      dataComponentName="SocialButtons"
      sx={{
        minWidth: 200,
        opacity: 0.8,
      }}
    >
      {renderIcon(`https://www.facebook.com/`, facebookPage, createIcon.byMuiIcon(FacebookIcon))}
      {renderIcon(`https://twitter.com/`, twitterAccount, createIcon.byMuiIcon(TwitterIcon))}
      {renderIcon(`https://www.instagram.com/`, instagramAccount, createIcon.byMuiIcon(InstagramIcon))}
      {renderIcon(`https://www.youtube.com/`, youTubeAccount, createIcon.byMuiIcon(YouTubeIcon))}
      {renderIcon(`https://pinterest.com/`, pinterestAccount, createIcon.byMuiIcon(PinterestIcon))}
      {renderIcon(`https://www.tiktok.com/`, tiktokAccount, createIcon.bySVGImage(TikTokIcon))}
      {renderIcon(`https://www.linkedin.com/company/`, linkedinCompanyAccount, createIcon.byMuiIcon(LinkedInIcon))}
    </Box>
  );
};
