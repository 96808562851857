export const API_PATH_ApiDynaProfilesRemoveUser = '/services/dyna-profiles/apiDynaProfilesRemoveUser';

export interface IApiDynaProfilesRemoveUserRequest {
  profileId: string;
  userId: string;
}

export interface IApiDynaProfilesRemoveUserResponse {
  deleted: boolean;
  code: number;
  info: string;
}
