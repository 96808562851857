import ReactGA from "react-ga4";
import {appConfig} from "../application/config/appConfig";

(() => {
  if (!appConfig.gaTrackingId) return;

  (window as any).gtag('config', appConfig.gaTrackingId);

  ReactGA.initialize([
    {
      trackingId: appConfig.gaTrackingId,
      gaOptions: undefined,
      gtagOptions: undefined,
    },
  ]);
})();
