import * as React from "react";

import {Box} from "../Box";

export interface ILegendProps {
  show?: boolean;
  text: string | number;
}

// Place it a container with css `position: relative`;

/**
 * Legend React Component shows a text as a Legend in the owner's container.
 * It is mandatory to place it in a container with css `position: relative`
 * @param props
 * @constructor
 */
export const Legend = (props: ILegendProps): JSX.Element => {
  const {
    show,
    text,
  } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
      show={show}
    >
      <Box
        sx={{
          color: 'white',
          backgroundColor: 'black',
          padding: '0 4px',
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

