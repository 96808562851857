import {ReactElement} from "react";

import {EDynaProfileUserRight} from "server-app";

import {INotificationMenuOption} from "ui-components/dist/AppContainerFlow";

import {IAppStore} from "../../../state/IAppStore";
import {ESignStatus} from "../../user-authnentication/state/userAuthSection";

import DefaultProfileIcon from '@mui/icons-material/AssignmentInd';
import UpdateProfileIcon from '@mui/icons-material/AccountBox';

import {routeProfileCreatePaths} from "../../dyna-profiles/routes/routeProfileCreate.paths";
import {routeProfileEditPaths} from "../../dyna-profiles/routes/routeProfileEdit.paths";

import {createIcon} from "ui-components/dist/IconComponent";
import {getNotificationIcon} from "./utils/getNotificationIcon";
import CreateNewProfileIcon from '@mui/icons-material/AddCircleOutline';

export const getProfileNotificationIcon = (
  {
    userAuth: {
      state: {
        signStatus,
        user: {id: userId},
      },
    },
    profiles: {
      state: {
        isLoadingProfile,
        profile: {
          id,
          profileImageUrl,
          displayName,
        },
      },
    },
  }: IAppStore,
): ReactElement | undefined => {
  if (signStatus === ESignStatus.SIGNING_IN) return;
  if (isLoadingProfile) return;
  if (!userId) return;

  if (id) {
    return (
      getNotificationIcon({
        imgUrl: profileImageUrl,
        defaultIcon: createIcon.byMuiIcon(DefaultProfileIcon),
        alt: displayName,
      })
    );
  }

  return (
    // This case is actually not possible, since there is always a profile in action
    getNotificationIcon({
      defaultIcon: createIcon.byMuiIcon(DefaultProfileIcon),
      alt: 'No profile',
      opacity: 0.5,
    })
  );
};

export const getProfileNotificationIconOptions = (
  {
    app: {actions: {navigateTo}},
    userAuth: {state: {user: {id: userId}}},
    profiles: {
      state: {
        profile: {
          id: profileId,
          profileImageUrl,
        },
        availableProfiles,
      },
      actions: {switchProfile},
    },
  }: IAppStore,
): INotificationMenuOption[] => {
  const isOwnerOfTheCurrent =
    availableProfiles
      .find(p => p.profile.id === profileId)
      ?.isOwner
    || false;
  const canChangeThisProfile =
    !!availableProfiles
      .find(p => p.profile.id === profileId)
      ?.rights[EDynaProfileUserRight.CHANGE_PROFILE];
  const profilesToSwitch =
    availableProfiles
      .filter(profile => profile.profile.id !== profileId);
  return [
    {
      hidden: !isOwnerOfTheCurrent && !canChangeThisProfile,
      label: 'Update this profile',
      Icon:
        profileImageUrl
          ? createIcon.byUrl(profileImageUrl)
          : createIcon.byMuiIcon(UpdateProfileIcon),
      onClick: () => navigateTo({url: routeProfileEditPaths.getRoutePath({profileId})}),
    },
    {
      hidden: availableProfiles.filter(p => p.profile.id !== userId).length === 0,
      label: 'Switch profile...',
      disabled: true,
    },
    ...profilesToSwitch
      .map(profile => ({
        label: profile.profile.displayName + (profile.isOwner ? ' (owner)' : ''),
        Icon:
          profile.profile.profileImageUrl
            ? createIcon.byUrl(profile.profile.profileImageUrl)
            : createIcon.byMuiIcon(UpdateProfileIcon),
        onClick: () => switchProfile(profile.profile.id),
      })),
    {
      label: 'Create new profile',
      hidden: !profileId,
      Icon: createIcon.byMuiIcon(CreateNewProfileIcon),
      onClick: () => navigateTo({url: routeProfileCreatePaths.getRoutePath()}),
    },
  ];
};
