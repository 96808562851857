import * as React from "react";

import {IDynaError} from "dyna-error";
import {DynaLocalStorageData} from "dyna-local-storage";

import {Box} from "../Box";
import {Typography} from "../Typography";
import {Modal} from "../Modal";
import {Collapse} from "../Collapse";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {
  InputUploadFiles,
  TUploadFileType,
} from "../InputUploadFiles";
import {
  InputCheckbox,
  ECheckBoxPosition,
} from "../InputCheckbox";
import {
  Button,
  EButtonColor,
} from "../Button";

import {UploadFile} from "../UploadFile";

import {TUploadMethod} from "./interfaces";

import CancelIcon from '@mui/icons-material/Cancel';

export interface IUploadFilesProps {
  id: string;
  show: boolean;

  uploadMethod: TUploadMethod;
  validateFile?: (file: File) => Promise<string>;

  label: string;
  ariaLabel?: string;

  multiple?: boolean; // Default is true
  maxFiles?: number;  // Default is 0 = unlimited

  fileTypes: TUploadFileType[];

  children?: any;    // Rendered on top

  onCancel: () => void;
  onDone: (urls: string[], allFilesStatus: IUploadFileStatus[]) => void;   // Returns the uploaded files
}

export interface IUploadFileStatus {
  filename: string;
  url: string;
  error: IDynaError | null;
}

interface IUploadFilesState {
  mode: EMode;
  files: File[];
  rejectedFiles: File[];
  uploadAutoClose: boolean;
}

enum EMode {
  SELECT_FILES = "SELECT_FILES",
  UPLOAD_FILES = "UPLOAD_FILES",
  FINISHED = "FINISHED",
}

interface ILSData {
  uploadAutoClose: boolean;
}

export class UploadFilesModal extends React.Component<IUploadFilesProps, IUploadFilesState> {
  private readonly ls = new DynaLocalStorageData<ILSData>(
    `UploadFilesModal--autoCloseOnFinish--${this.props.id}`,
    {uploadAutoClose: false},
  );

  public state: IUploadFilesState = {
    mode: EMode.SELECT_FILES,
    files: [],
    rejectedFiles: [],
    uploadAutoClose: this.ls.data.uploadAutoClose,
  };

  private uploadStatus: Array<IUploadFileStatus> = [];

  private handleSelectFiles = (files: File[], rejectedFiles: File[]): void => {
    if (!files.length) return;
    this.setState({
      files,
      rejectedFiles,
      mode: EMode.UPLOAD_FILES,
    });
  };

  private checkForCompletion(): void {
    if (this.uploadStatus.length !== this.state.files.length) return;
    this.setState({mode: EMode.FINISHED});
    const fileUploadErrors = !!this.uploadStatus.filter(s => !!s.error).length;
    if (this.state.uploadAutoClose && !fileUploadErrors) this.handleDone();
  }

  private handleFileUploadCompleted = (index: number, url: string) => {
    const {files} = this.state;
    this.uploadStatus.push({
      filename: files[index].name,
      url,
      error: null,
    });
    this.checkForCompletion();
  };
  private handleFileUploadFailed = (index: number, error: IDynaError) => {
    const {files} = this.state;
    this.uploadStatus.push({
      filename: files[index].name,
      url: '',
      error,
    });
    this.checkForCompletion();
  };

  private handleAutoCloseChange = (value: boolean) => {
    this.setState({uploadAutoClose: value});
    this.ls.data.uploadAutoClose = value;
    this.ls.save();
  };

  private handleCancel = (): void => {
    const {onCancel} = this.props;
    onCancel();
  };

  private handleDone = (): void => {
    const {onDone} = this.props;
    onDone(
      this.uploadStatus
        .concat()
        .filter(us => !us.error)
        .map(us => us.url),
      this.uploadStatus
        .concat(),
    );
    this.setState({
      files: [],
      rejectedFiles: [],
      mode: EMode.SELECT_FILES,
    });
    this.uploadStatus = [];
  };

  public render(): JSX.Element {
    const {
      show,
      label,
      ariaLabel,
      fileTypes,
      validateFile,
      uploadMethod,
      children,
    } = this.props;
    const {
      mode,
      files,
      rejectedFiles,
      uploadAutoClose,
    } = this.state;

    return (
      <Modal show={show}>
        <Box sx={{padding: 2}}>

          <Typography
            variant="h1"
            ariaLabel={ariaLabel}
          >
            {label}
          </Typography>

          {children}

          <Collapse expanded={mode === EMode.SELECT_FILES}>
            <InputUploadFiles
              label={label}
              fileTypes={fileTypes}
              showRejectedFiles
              onChange={this.handleSelectFiles}
            />
          </Collapse>

          <Collapse expanded={mode === EMode.UPLOAD_FILES || mode === EMode.FINISHED}>
            {files.map((file, index) => (
              <UploadFile
                key={index}
                file={file}
                validateFile={validateFile}
                uploadMethod={uploadMethod}
                onComplete={url => this.handleFileUploadCompleted(index, url)}
                onFail={(error) => this.handleFileUploadFailed(index, error)}
              />
            ))}
          </Collapse>

          <Collapse expanded={!!rejectedFiles.length}>
            <Box sx={{color: theme => theme.palette.error.main}}>
              <Box>
                <b>Note!</b> Some files are not accepted and not uploaded.
              </Box>
              <ul>
                {rejectedFiles.map((file, index) => (
                  <li key={index}>{file.name} - {file.size / 1024}kb</li>
                ))}
              </ul>
            </Box>
          </Collapse>

          <br/>

          <FlexContainerHorizontal>
            <FlexItemMin>
              <Collapse
                expanded={mode === EMode.SELECT_FILES}
                orientation="horizontal"
              >
                <Button
                  icon={<CancelIcon/>}
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </Collapse>
              <Collapse
                expanded={mode === EMode.FINISHED}
                orientation="horizontal"
              >
                <Button
                  color={mode === EMode.UPLOAD_FILES ? EButtonColor.SUCCESS : undefined}
                  icon={<CancelIcon/>}
                  onClick={this.handleDone}
                >
                  Done
                </Button>
              </Collapse>
            </FlexItemMin>
            <FlexItemMax>
              <InputCheckbox
                label="Close on finish"
                position={ECheckBoxPosition.RIGHT}
                value={uploadAutoClose}
                onChange={this.handleAutoCloseChange}
              />
            </FlexItemMax>
          </FlexContainerHorizontal>
        </Box>
      </Modal>
    );
  }
}
