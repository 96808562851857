import {IAppMenu} from "./IAppMenu";

import {routeBackOfficePagePaths} from "../routes/routeBackOfficePage.paths";
import {routeDynaCMSMainPagePaths} from "../../dyna-cms/routes/routeDynaCMSMainPage.paths";
import {routeListUsersPaths} from "../../user-management/routes/routeListUsers.paths";
import {routeAppSettingsPage} from "../routes/routeAppSettingsPage";
import {routeProfileUserRights} from "../../dyna-profiles/routes/routeProfileUserRights";

import {appConfig} from "./appConfig";
import {ECompany} from "./appConfig";

import {convertRouteToAppMenu} from "./IAppMenu";

export const getAppMenusMain = async (): Promise<Array<IAppMenu | "DIVIDER">> => {
  const appMenusMain: (IAppMenu | "DIVIDER")[] = [];

  if (appConfig.company === ECompany.NET_FASHION) {
    const {netFashionAppMenusMain} = await import("../../../companies/net-fashion");
    netFashionAppMenusMain.forEach(menu => appMenusMain.push(menu));
  }

  if (appConfig.company === ECompany.ADORE_GR) {
    const {appMenusAdoreGr} = await import("../../../companies/adore-gr");
    const menus = await appMenusAdoreGr();
    menus.forEach(menu => appMenusMain.push(menu));
  }

  if (appConfig.company === ECompany.THE_GIRL_GUNS) {
    const {appMenusTheGirlGunsCom} = await import("../../../companies/tgg-com");
    appMenusTheGirlGunsCom.forEach(menu => appMenusMain.push(menu));
  }

  appMenusMain.push(
    convertRouteToAppMenu(
      routeBackOfficePagePaths, // Menu id##backoffice
      {
        children: [
          // These children are added, but the menu might not show the nested options
          convertRouteToAppMenu(routeDynaCMSMainPagePaths),
          convertRouteToAppMenu(routeListUsersPaths),
          convertRouteToAppMenu(routeAppSettingsPage),
          convertRouteToAppMenu(routeProfileUserRights),
        ],
      },
    ),
  );

  return appMenusMain;
};
