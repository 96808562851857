import {LightHorizontalMenu} from "ui-components/dist/LightHorizontalMenu";

import {appConfig} from "../../../../core/application/config/appConfig";
import {IAppMenu} from "../../../../core/application/config/IAppMenu";

export interface INetFashionAppMenuProps {
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
}

export const NetFashionAppMenu = (props: INetFashionAppMenuProps): JSX.Element => {
  const {
    selectedMenuId,
    menus,
  } = props;

  return (
    <LightHorizontalMenu
      selectedMenuId={selectedMenuId}
      menus={menus}
      fontSize={2}
      stickyMenuOnScrollY={152}
      stickyMaxWidth={appConfig.pageWidth}
    />
  );
};
