import * as React from 'react';
import {useEffect} from 'react';

import {ITwitterCardProps} from "./ITwitterCardProps";
import {createMetaTags} from "./createMetaTags";

export const TwitterMetaCard: React.FC<ITwitterCardProps> = (props): null => {
  const metaTagRefs = React.useRef<HTMLMetaElement[]>([]);

  useEffect(() => {
    // Remove existing Twitter card meta tags created by this component
    metaTagRefs.current.forEach((tag) => {
      if (tag.parentNode) {
        tag.parentNode.removeChild(tag);
      }
    });
    metaTagRefs.current = [];

    // Add new Twitter card meta tags

    createMetaTags(props)
      .forEach((
        {
          name,
          content,
        },
      ) => {
        if (content === undefined || content === "") return;
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        metaTag.setAttribute('content', content);
        document.head.appendChild(metaTag);
        metaTagRefs.current.push(metaTag);
      });

    return () => {
      // Clean up: Remove the generated Twitter card meta tags on unmount
      metaTagRefs.current.forEach((tag) => {
        tag.parentNode && tag.parentNode.removeChild(tag);
      });
    };
  }, Object.values(props));

  return null;
};
