import {IDynaProfileUserRight} from "../../../../interfaces";

export const API_PATH_ApiProfileUserRightsGet = '/services/dyna-profiles/apiProfileUserRightsGet';

export interface IApiProfileUserRightsGetRequest {
  profileId: string;
  rights?: string[];
  rightsType?:        // Default is "both"
    | "regular"
    | "delegate"
    | "both";
  skip: number;
  limit: number;
}

export interface IApiProfileUserRightsGetResponse {
  rights: IDynaProfileUserRight[];
}
