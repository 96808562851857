import {
  useEffect,
  useState,
} from "react";

import {useInView} from "react-intersection-observer";

export const useInViewPort =
  (
    {
      delay,
      rootMargin,
      onInViewPortChange,
    }: {
      show?: boolean;
      delay?: number;
      rootMargin?: number;
      onInViewPortChange?: (inViewPort: boolean) => void;
    }
    = {},
  ): {
    ref: (node?: Element | null) => void;
    inViewPort: boolean;
    viewed: boolean;
  } => {
    const {
      ref,
      inView: inViewPort,
    } = useInView({
      delay,
      rootMargin: rootMargin === undefined ? undefined : rootMargin + 'px',
    });

    const [viewed, setViewed] = useState<boolean>(inViewPort);

    useEffect(() => {
      if (inViewPort && !viewed) setViewed(true);
      onInViewPortChange && onInViewPortChange(inViewPort);
    }, [inViewPort]);

    return {
      ref,
      inViewPort,
      viewed,
    };
  };
