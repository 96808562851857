import {
  IRouteArgs,
  routeDynaCMSArticlePreViewerPaths,
} from "./routeDynaCMSArticlePreViewer.paths";

import {IAppRoute} from "../../application/config/IAppRoute";

import {ArticleViewer} from "../pages/ArticleViewer";

export const routeDynaCMSArticlePreViewer: IAppRoute<IRouteArgs> = {
  ...routeDynaCMSArticlePreViewerPaths,
  render: (
    {
      pathParams: {
        countryId,
        languageId,
        groupId,
        articleId,
      },
    },
  ) => (
    <ArticleViewer
      countryId={countryId}
      languageId={languageId}
      groupId={groupId}
      idType="articleId"
      id={articleId}
      loadPurpose="preview"
    />
  ),
};
