import * as React from "react";

export interface IUnsortedListProps {
  inline?: boolean;
  children: any[];
}

/**
 * UnsortedList renders ul & li cleaning the layout styles
 */
export const UnsortedList = (props: IUnsortedListProps): JSX.Element => {
  const {
    inline = false,
    children,
  } = props;

  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      {children
        .map((child, index) => (
          <li
            key={index}
            style={{
              listStyleType: 'none',
              display: inline ? 'inline' : undefined,
            }}
          >
            {child}
          </li>
        ))}
    </ul>
  );
};
