import React from 'react';
import ReactDOM from 'react-dom';

import {redirectToHttps} from "utils-library/dist/commonJs/web";

import {reportWebVitals} from "./reportWebVitals";

import "./consoleWarnings";
import "./utils/debug-mode";
import "./core/application/config/sentryConfig";
import "./updateAppHtml";
import "./core/analytics/gaInit";

import {App} from "./core/application/app/App";

import "./index.css";

(window as any)._webApp_mainBundleLoaded = true;

redirectToHttps();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
