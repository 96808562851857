import {routeSignInPage} from "./routeSignInPage";
import {routeOAuthSignResolutionInPage} from "./routeOAuthSignResolutionInPage";
import {routeUserProfilePage} from "./routeUserProfilePage";
import {IAppRoute} from "../../application/config/IAppRoute";

export const routesUserAuthentication: IAppRoute<any, any>[] = [
  routeSignInPage,
  routeOAuthSignResolutionInPage,
  routeUserProfilePage,
];
