import {IHardcodedTranslations} from "../../dyna-intl/interfaces/IHardcodedTranslations";

export enum ETKDynaCMS {
  CMS_PROFILE_RIGHTS_BUTTON_LABEL = "CMS_PROFILE_RIGHTS_BUTTON_LABEL",
  CMS_PROFILE_RIGHTS_BUTTON_DESCRIPTION = "CMS_PROFILE_RIGHTS_BUTTON_DESCRIPTION",
  USER_PROFILE_RIGHT_EDITOR_LABEL = "USER_PROFILE_RIGHT_EDITOR_LABEL",
  USER_PROFILE_RIGHT_EDITOR_DESCRIPTION = "USER_PROFILE_RIGHT_EDITOR_DESCRIPTION",
  USER_PROFILE_RIGHT_AUTHOR_LABEL = "USER_PROFILE_RIGHT_AUTHOR_LABEL",
  USER_PROFILE_RIGHT_AUTHOR_DESCRIPTION = "USER_PROFILE_RIGHT_AUTHOR_DESCRIPTION",
  USER_PROFILE_RIGHT_REVIEWER_LABEL = "USER_PROFILE_RIGHT_REVIEWER_LABEL",
  USER_PROFILE_RIGHT_REVIEWER_DESCRIPTION = "USER_PROFILE_RIGHT_REVIEWER_DESCRIPTION",
  NEW_ITEMS_AVAILABLE_TITLE = "NEW_ITEMS_AVAILABLE_TITLE",
  NEW_ITEMS_AVAILABLE_SUBTITLE = "NEW_ITEMS_AVAILABLE_SUBTITLE",
}

export const dynaCMSHardcodedTranslations: IHardcodedTranslations<ETKDynaCMS> = {
  [ETKDynaCMS.CMS_PROFILE_RIGHTS_BUTTON_LABEL]: {
    en: "Users rights of the {profileName} profile",
    el: "Δικαιώματα χρηστών στο {profileName} προφίλ",
    de: "Benutzerrechte des Profils {profileName}",
  },
  [ETKDynaCMS.CMS_PROFILE_RIGHTS_BUTTON_DESCRIPTION]: {
    en: "Rights of other users who use this profile",
    el: "Δικαιώματα άλλων χρηστών που χρησιμοποιούν αυτό το προφίλ",
    de: "Rechte anderer Benutzer, die dieses Profil verwenden",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_EDITOR_LABEL]: {
    en: "Editor",
    el: "Επιμελητής",
    de: "Editor",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_EDITOR_DESCRIPTION]: {
    en: "Editor's rights for creating and modifying content",
    el: "Δικαιώματα επιμελητή για δημιουργία και τροποποίηση περιεχομένου",
    de: "Editor-Rechte zum Erstellen und Bearbeiten von Inhalten",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_AUTHOR_LABEL]: {
    en: "Author",
    el: "Συγγραφέας",
    de: "Author",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_AUTHOR_DESCRIPTION]: {
    en: "Author of articles, journalist, contributor.",
    el: "Συγγραφέας άρθρων, δημοσιογράφος, συνεισφέρων.",
    de: "Autor von Artikeln, Journalist, Beitragender.",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_REVIEWER_LABEL]: {
    en: "Reviewer",
    el: "Ελεγκτής",
    de: "Reviewer",
  },
  [ETKDynaCMS.USER_PROFILE_RIGHT_REVIEWER_DESCRIPTION]: {
    en: "Reviewer of articles, identifies issues in articles.",
    el: "Ελεγκτής άρθρων, εντοπίζει προβλήματα στα άρθρα.",
    de: "Reviewer von Artikeln, identifiziert Probleme in Artikeln.",
  },
  [ETKDynaCMS.NEW_ITEMS_AVAILABLE_TITLE]: {
    en: "View newer",
    el: "Προβολή νέων",
    de: "Neu anzeigen",
  },
  [ETKDynaCMS.NEW_ITEMS_AVAILABLE_SUBTITLE]: {
    en: "There are new articles, click here to view them",
    el: "Υπάρχουν νέα άρθρα πάτηστε εδώ για να τα δείτε",
    de: "Es gibt neue Artikel, klicken Sie hier, um sie zu sehen",
  },
};
