import * as React from "react";
import {useRef} from "react";

import {
  ModalButton,
  IModalButtonRef,
} from "ui-components/dist/ModalButton";

import {
  IManageRight,
  DynaProfileUserRightsAssigmentGrid,
} from "../DynaProfileUserRightsAssigmentGrid";

import {createIcon} from "ui-components/dist/IconComponent";
import ProfileUserIcon from '@mui/icons-material/ContactEmergency';

export interface IDynaProfileUserRightsButtonModalProps {
  show?: boolean;
  label: string;
  description: string;
  disabled?: boolean;
  manageRights: IManageRight[];   // For which rights the grid will work
  manageDelegateRights?: boolean;   // Shown only if the current user is the owner
}

export const DynaProfileUserRightsButtonModal: React.FC<IDynaProfileUserRightsButtonModalProps> = (
  {
    show = true,
    label,
    description,
    disabled = false,
    manageRights,
    manageDelegateRights = false,
  },
) => {
  const refModal = useRef<IModalButtonRef>(null);

  const handleClose = (): void => refModal.current?.close();

  return (
    <ModalButton
      ref={refModal}
      show={show}
      Icon={createIcon.byMuiIcon(ProfileUserIcon)}
      disabled={disabled}
      label={label}
      fullModal
      description={description}
    >
      <DynaProfileUserRightsAssigmentGrid
        manageRights={manageRights}
        manageDelegateRights={manageDelegateRights}
        onClose={handleClose}
      />
    </ModalButton>
  );
};
