import * as React from "react";
import {
  Button,
  EButtonVariant,
  EButtonColor,
} from "../Button";

import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export interface IButtonLinearProps {
  sx?: SxProps<Theme>;
  href?: string;
  hrefNewWindow?: boolean;    // Open href on new window/tab
  show?: boolean;
  fontFamilyInherit?: boolean;        // Default is false, this is for title and description
  fontWeightBold?: boolean;           // Default is true, this is for Title only (not for description)
  children: any;
  onClick?: () => void;
}

export const ButtonLinear = (props: IButtonLinearProps): JSX.Element => {
  const {
    sx,
    href,
    hrefNewWindow,
    show,
    fontFamilyInherit,
    fontWeightBold,
    children,
    onClick,
  } = props;

  return (
    <Button
      sx={{
        color: theme => theme.palette.text.primary,
        padding: theme => theme.spacing(2),
        borderWidth: '2px',
        borderStyle: 'solid',
        fontWeight: 'normal',
        borderColor: theme => theme.palette.text.primary,
        ':hover': {
          borderWidth: '2px',
          borderColor: theme => theme.palette.text.primary,
          backgroundColor: theme => theme.palette.action.hover,
          textDecoration: 'none !important',
        },
        ...sx,
      }}
      variant={EButtonVariant.TRANSPARENT}
      color={EButtonColor.WHITE}
      href={href}
      hrefNewWindow={hrefNewWindow}
      show={show}
      fontFamilyInherit={fontFamilyInherit}
      fontWeightBold={fontWeightBold}
      children={children}
      onClick={onClick}
    />
  );
};
