export interface IDynaCMSArticleAddress {
  countryId: string;
  languageId: string;
  groupId: string;
  articleId: string;
}

export const getDefaultDynaCMSArticleAddress = (): IDynaCMSArticleAddress => ({
  countryId: '',
  languageId: '',
  groupId: '',
  articleId: '',
});

export const validateDynaCMSArticleAddress = (
  {
    articleAddress,
    allowEmpty,
  }: {
    articleAddress: IDynaCMSArticleAddress;
    allowEmpty: boolean;
  },
): string => {
  return validateFields(articleAddress as any, allowEmpty);
};

export interface IDynaCMSArticleBlockAddress extends IDynaCMSArticleAddress {
  blockId: string;
}

export const getDefaultDynaCMSArticleBlockAddress = (): IDynaCMSArticleBlockAddress => ({
  ...getDefaultDynaCMSArticleAddress(),
  blockId: '',
});

export const validateDynaCMSArticleBlockAddress = (
  {
    articleBlockAddress,
    allowEmpty,
  }: {
    articleBlockAddress: IDynaCMSArticleBlockAddress;
    allowEmpty: boolean;
  },
): string => {
  return validateFields(articleBlockAddress as any, allowEmpty);
};

const validateFields = (value: Record<string, string>, allowEmpty: boolean): string => {
  if (allowEmpty && Object.values(value).filter(Boolean).length === 0) return '';
  return Object.entries(value)
    .map(([key, value]) => {
      if (!value) return `${key} is required`;
      return "";
    })
    .filter(Boolean)
    .join(', ');
};
