import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {DynaIntlProvider} from "../../../dyna-intl/components-public";
import {IArticlesMgtmProps} from "./ArticlesMgtm";

const ArticlesMgtmLazy = lazy(() => import("./ArticlesMgtm"));

export const ArticlesMgtm = connect((props: IArticlesMgtmProps) => (
  <LazyLoadComponent>
    <DynaIntlProvider tks={{}}>
      <ArticlesMgtmLazy {...props}/>
    </DynaIntlProvider>
  </LazyLoadComponent>
));
