import * as React from "react";

import {INotificationIcon} from "../../interfaces";
import {INotificationMenuOption} from "../../interfaces";

import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../../../FlexContainer";
import {Link} from "../../../Link";

import {AppNotificationIcons} from "./components/AppNotificationIcons/AppNotificationIcons";

import {useTheme} from "../../../ThemeProvider";
import {ReactElement} from "react";

export interface IAppHeaderProps {
  appLogo: JSX.Element;
  notificationIcons?: INotificationIcon[];

  profileIcon?: ReactElement;
  profileName?: string;
  profileOptions?: INotificationMenuOption[];

  userIcon?: ReactElement;
  userName?: string;
  userOptions?: INotificationMenuOption[];

  onAppLogoClick?: () => void;
}


export const AppHeader = (props: IAppHeaderProps): JSX.Element => {
  const {
    appLogo,
    notificationIcons,
    profileIcon,
    profileName,
    profileOptions = [],
    userIcon,
    userName,
    userOptions = [],
    onAppLogoClick,
  } = props;
  const theme = useTheme();

  return (
    <FlexContainerHorizontal
      dataComponentName="AppHeader"
      alignVertical="middle"
      sx={{
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      }}
    >

      <FlexItemMax
        sx={{
          margin: 0,
          paddingLeft: theme.spacing(2),
          cursor: 'pointer',
        }}
        onClick={onAppLogoClick}
      >
        <Link href="/">
          {appLogo}
        </Link>
      </FlexItemMax>


      <FlexItemMin>
        <AppNotificationIcons
          notificationIcons={notificationIcons}
          profileIcon={profileIcon}
          profileName={profileName}
          profileOptions={profileOptions}
          userIcon={userIcon}
          userName={userName}
          userOptions={userOptions}
        />
      </FlexItemMin>
    </FlexContainerHorizontal>
  );
};
