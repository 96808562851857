import {FormattedTime} from "react-intl";

export interface IDynaIntlTimeFormatterProps {
  date?: Date | number;
  mode?: "24" | "12";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
}

export const DynaIntlTimeFormatter = (props: IDynaIntlTimeFormatterProps): JSX.Element => {
  const {
    date,
    mode = "24",
    hour,
    minute,
  } = props;

  return (
    <FormattedTime
      value={date}
      hour={hour}
      minute={minute}
      hour12={mode === "12"}
    />
  );
};
