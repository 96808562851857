import * as React from "react";
import {createRef} from "react";
import {dynaDebounce} from "dyna-debounce";
import {syncFunctions} from "dyna-sync";

import {Box} from "../../Box";

import {
  ENavArrowsMode,
  ISlide,
} from "../interfaces";

import {
  SxProps,
  Theme,
} from "../../ThemeProvider";
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';


export interface ISlideViewerProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  index: number;
  slides: ISlide[];
  animation?: number;         // Default 250ms
  preload?: number;           // Default is 1, How may slides will be preloaded for the lazy load
  onChange: (index: number) => void;
}

interface ISlideViewerState {
  width: number;
  slides: ISlideState[];
}

interface ISlideState {
  prepare: boolean;
  show: boolean;
  left: number;
}

const DEFAULT_PRELOAD = 3;
const DEFAULT_ANIMATION = 250;

export class SlideViewer extends React.Component<ISlideViewerProps, ISlideViewerState> {
  private containerRef = createRef<HTMLDivElement>();
  public state: ISlideViewerState = {
    width: 0,
    slides: this.props.slides.map((slide, index) => {
      slide; // 4TS
      return {
        left: -window.innerWidth,
        prepare: this.getPrepare(index),
        show: index === this.props.index,
      };
    }),
  };

  constructor(props: ISlideViewerProps) {
    super(props);
    this.observeWidth = dynaDebounce(this.observeWidth as any, 100);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.observeWidth(0);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  public componentDidUpdate(prevProps: Readonly<ISlideViewerProps>) {
    if (prevProps.index !== this.props.index) this.showProperSlide();
  }

  private getLeft(showIndex: number, testIndex: number): number {
    const {width} = this.state;
    return (testIndex - showIndex) * width;
  }

  private getPrepare(testIndex: number): boolean {
    const {
      index,
      preload = DEFAULT_PRELOAD,
    } = this.props;
    return Math.abs(index - testIndex) < preload;
  }

  private showProperSlide(): void {
    const {
      index: showIndex,
      animation = DEFAULT_ANIMATION,
    } = this.props;
    syncFunctions(
      done => this.setState({
        slides: this.state.slides.map((slide, index) => {
          const newSlide = {
            ...slide,
            prepare: this.getPrepare(index),
            left: this.getLeft(showIndex, index),
          };
          if (index === showIndex) newSlide.show = true;
          return newSlide;
        }),
      }, done),
      done => setTimeout(done, animation),
      requestAnimationFrame,
      done => this.setState({
        // This hides the slide that now is hidden, after the animation.
        slides: this.state.slides.map((slide, index) => ({
          ...slide,
          show: index === showIndex,
        })),
      }, done),
    );
  }

  private handleWindowResize = (): void => {
    this.observeWidth();
  };

  private observeWidth = async (delay: number = 100): Promise<void> => {
    await new Promise(r => setTimeout(r, delay));
    const {current: container} = this.containerRef;
    if (!container) return;

    const width = parseFloat(getComputedStyle(container).width);
    if (this.state.width === width) return;

    syncFunctions(
      done => this.setState({width}, done),
      done => {
        this.showProperSlide();
        done();
      },
    );
  };

  private handleArrowClick(direction: number): void {
    const {
      index, onChange,
    } = this.props;
    onChange(index - direction);
  }

  private renderArrow(style: SxProps, direction: number): JSX.Element {
    const {
      index,
      slides,
    } = this.props;
    const potentialIndex = index + (direction * -1);
    const activeButton = potentialIndex >= 0 && potentialIndex < slides.length;
    return (
      <Box
        sx={{
          ...style,
          height: '100%',
          display: 'flex',
          width: '10%',
          minWidth: 42,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          transition: 'background-color 250ms',
          '& > svg': {
            color: '#d7d7d7',
            width: 42,
            height: 42,
            opacity: 0.6,
            transition: 'opacity 250ms',
          },
          ...(
            activeButton
              ? {
                cursor: "pointer",
                '&:hover': {backgroundColor: '#80808094'},
                '& > svg': {color: '#868686'},
                '&:hover > svg': {opacity: 1},
              }
              : {}
          ),
        }}
        onClick={() => activeButton && this.handleArrowClick(direction)}
      >
        {direction === 1
          ? <LeftArrow/>
          : <RightArrow/>
        }
      </Box>
    );
  }

  private renderSlideContent(slide: ISlide, prepare: boolean, show: boolean): JSX.Element {
    switch (slide.navArrowsMode || ENavArrowsMode.OVERLAY) {
      case ENavArrowsMode.HIDDEN:
        return (
          <div style={{height: "100%"}}>
            {slide.renderContent({
              prepare,
              show,
            })}
          </div>
        );
      case ENavArrowsMode.OVERLAY:
        return (
          <div
            style={{
              height: '100%',
              position: "relative",
            }}
          >
            {slide.renderContent({
              prepare,
              show,
            })}
            {this.renderArrow(
              {
                position: 'absolute',
                bottom: 0,
              },
              1,
            )}
            {this.renderArrow(
              {
                position: 'absolute',
                bottom: 0,
                right: 0,
              },
              -1,
            )}
          </div>
        );
      case ENavArrowsMode.ASIDE:
        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            {this.renderArrow(
              {
                flex: '0 1',
                minWidth: '10% !important',
              },
              1,
            )}
            <div style={{flex: '1 1'}}>
              {slide.renderContent({
                prepare,
                show,
              })}
            </div>
            {this.renderArrow(
              {
                flex: '0 1',
                minWidth: '10% !important',
              },
              -1,
            )}
          </div>
        );
    }
  }

  private renderSlide = (slide: ISlide, index: number): JSX.Element => {
    const {animation = DEFAULT_ANIMATION} = this.props;
    const {
      slides,
      width,
    } = this.state;
    const {
      left,
      prepare,
      show,
    } = slides[index];
    return (
      <div
        key={index}
        style={{
          position: 'absolute',
          height: '100%',
          width,
          left,
          transition: `left ${animation}ms ease-in-out`,
        }}
      >
        {this.renderSlideContent(slide, prepare, show)}
      </div>
    );
  };

  public render(): JSX.Element {
    const {
      sx = {},
      dataComponentName,
      slides,
    } = this.props;
    return (
      <Box
        dataComponentName={[dataComponentName, "SlideViewer"]}
        ref={this.containerRef}
        sx={{
          overflow: 'hidden',
          position: 'relative',
          height: '100%',
          ...sx,
        }}
      >
        {slides.map(this.renderSlide)}
      </Box>
    );
  }
}
