import {IUser} from "../../../../user-authentication/interfaces/IUser";

export const API_PATH_ApiDynaProfilesGetUserInfoGet = '/services/dyna-profiles/apiDynaProfilesGetUserInfoGet';

export interface IApiDynaProfilesGetUserInfoGetRequest {
  profileId: string;
  userId: string;
}

export type IApiDynaProfilesGetUserInfoGetResponse = IUser;
