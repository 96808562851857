export const loadAll = async <TData>(
  {
    loadSize,
    onLoad,
  }: {
    loadSize: number;
    onLoad: (
      args: {
        skip: number;
        limit: number;
        loadedData: TData[];
      },
    ) => Promise<TData[]>;
  },
): Promise<TData[]> => {
  let data: TData[] = [];
  let done = false;
  while (!done) {
    const loadedData = await onLoad({
      skip: data.length,
      limit: loadSize,
      loadedData: data,
    });
    data = data.concat(loadedData);
    done = loadedData.length < loadSize;
  }

  return data;
};
