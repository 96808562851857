import {
  API_PATH_ApiDynaProfilesSearchUsersForProfileGet,
  IApiDynaProfilesSearchUsersForProfileGetRequest,
  IApiDynaProfilesSearchUsersForProfileGetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilesSearchUsersForProfileGet = async (args: IApiDynaProfilesSearchUsersForProfileGetRequest): Promise<IApiDynaProfilesSearchUsersForProfileGetResponse> => {
  return apiFetch.request<IApiDynaProfilesSearchUsersForProfileGetRequest, null, IApiDynaProfilesSearchUsersForProfileGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaProfilesSearchUsersForProfileGet,
    query: args,
  });
};
