import {connect} from "react-dynadux";

import {
  DynaCMSArticleViewerMultiLanguage as Component,
} from "./DynaCMSArticleViewerMultiLanguage";
export const DynaCMSArticleViewerMultiLanguage = connect(Component);

export type {
  IDynaCMSArticleViewerMultiLanguageProps,
} from "./DynaCMSArticleViewerMultiLanguage";
