import {useMemo} from "react";

import {hashMd5} from "utils-library/dist/commonJs/hash-md5";

import {
  EDynaCMSBlockType,
  IDynaCMSBlockMultimedia,
  IDynaCMSBlockMultimediaMedia,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";
import {
  Alert,
  EAlertType,
} from "ui-components/dist/Alert";
import {Condition} from "ui-components/dist/Condition";
import {Carousel} from "ui-components/dist/Carousel";

import {DynaCMSLabel} from "../../commons/DynaCMSLabel";

import {ViewerImage} from "../ViewerImage";
import {ViewerVideoPlayerFile} from "../ViewerVideoPlayerFile";
import {ViewerVideoPlayerVimeo} from "../ViewerVideoPlayerVimeo";
import {ViewerVideoPlayerYouTube} from "../ViewerVideoPlayerYouTube";
import {ViewerSoundCloudItem} from "../ViewerSoundCloudItem";

export interface IViewerMultimediaProps {
  content: IDynaCMSBlockMultimedia;
  showPortraitsSmaller: boolean;
}

export const ViewerMultimedia = (props: IViewerMultimediaProps): JSX.Element => {
  const {
    content: {
      label,
      title,
      subtitle,
      media,
      showMode,
    },
    showPortraitsSmaller,
  } = props;

  const id = useMemo(() => hashMd5(JSON.stringify(media)), []);

  const renderMedia = (media: IDynaCMSBlockMultimediaMedia, index: number = 0): JSX.Element => {
    const mediaType = media.type;
    switch (mediaType) {
      case EDynaCMSBlockType.IMAGE:
        return (
          <ViewerImage
            key={index}
            content={media as any}
            showPortraitsSmaller={showPortraitsSmaller}
          />
        );
      case EDynaCMSBlockType.VIDEO_FILE:
        return (
          <ViewerVideoPlayerFile
            key={index}
            content={media as any}
          />
        );
      case EDynaCMSBlockType.VIDEO_YOUTUBE:
        return (
          <ViewerVideoPlayerYouTube
            key={index}
            content={media as any}
          />
        );
      case EDynaCMSBlockType.VIDEO_VIMEO:
        return (
          <ViewerVideoPlayerVimeo
            key={index}
            content={media as any}
          />
        );
      case EDynaCMSBlockType.SOUND_SOUNDCLOUD:
        return (
          <ViewerSoundCloudItem
            key={index}
            content={media as any}
          />
        );
      default:
        return (
          <div>ViewerMultimedia: [{mediaType}] is unknown type for the client</div>
        );
    }
  };

  const renderThumbnailContent = (content: any, index: number = 0): JSX.Element => (
    <Box key={index}>{content}</Box>
  );

  const renderThumbnail = (media: IDynaCMSBlockMultimediaMedia, index: number = 0): JSX.Element => {
    const mediaType = media.type;
    switch (mediaType) {
      case EDynaCMSBlockType.IMAGE:
        return (
          <ViewerImage
            key={index}
            content={media as any}
          />
        );
      case EDynaCMSBlockType.VIDEO_FILE:
        return renderThumbnailContent('Video', index);
      case EDynaCMSBlockType.VIDEO_YOUTUBE:
        return renderThumbnailContent('YouTube', index);
      case EDynaCMSBlockType.VIDEO_VIMEO:
        return renderThumbnailContent('Vimeo', index);
      case EDynaCMSBlockType.SOUND_SOUNDCLOUD:
        return renderThumbnailContent('SoundCloud', index);
      default:
        return renderThumbnailContent('?', index);
    }
  };

  return (
    <Box
      sx={{
        '> *': {
          marginTop: theme => theme.spacing(2),
          marginBottom: theme => theme.spacing(2),
        },
      }}
    >
      <FlexContainerHorizontal alignVertical="middle">
        <FlexItemMax>
          <Box
            component="h2"
            show={!!title}
            sx={{fontWeight: 'bold'}}
          >
            {title}
          </Box>
        </FlexItemMax>
        <FlexItemMin>
          <DynaCMSLabel children={label}/>
        </FlexItemMin>
      </FlexContainerHorizontal>
      <Box
        show={!!subtitle}
        sx={{paddingLeft: theme => theme.spacing(3)}}
      >
        {subtitle}
      </Box>

      <Condition if={showMode === "all"}>
        {media.map(renderMedia)}
      </Condition>
      <Box
        sx={{height: 640}}
        show={showMode === "carousel"
        }
      >
        <Alert type={EAlertType.WARNING}>Carousel view is not fully implemented</Alert>
        {/* Todo: DCMS-00332 ViewerMultimedia Carousel finish the implementation https://trello.com/c/YsmzNFSG */}
        <Carousel
          id={id}
          slides={media.map(media => ({
            renderContent: () => renderMedia(media),
            renderThumbnail: () => renderThumbnail(media),
          }))}
        />
      </Box>
    </Box>
  );
};
