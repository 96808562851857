export const API_PATH_ApiArticleContentDelete = '/services/dyna-cms/apiArticleContentDelete';

export interface IApiArticleContentDeleteBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
  historyReason?: string;
}

export interface IApiArticleContentDeleteResponse {
  resetArticleCoverMedia: boolean;
}
