import {
  IDynaCMSArticleStats,
  EShowDate,
  EViewWidthType,
  EDynaCMSHeadingStyle,
  EDynaCMSArticleChangeFrequency,
} from "../../../../db/IDynaCMSArticle";
import {IDynaCMSFont} from "../../../../db/IDynaCMSFont";

export const API_PATH_ApiArticleHeaderUpdate = '/services/dyna-cms/apiArticleHeaderUpdate';

export interface IApiArticleHeaderUpdateBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;

  historyReason?: string;

  urlId?: string;

  title?: string;
  subtitle?: string;
  label?: string;
  description?: string;

  eventStart?: number;  // PK for event of any type
  eventEnd?: number;

  geolocation?: {       // When this took place
    lon: number;
    lat: number;
  };

  mapAddress?: string;  // Google map address

  tags?: string[];
  usedAsPage?: boolean;
  seoAvailable?: boolean;
  seoKeywords?: string;
  canonicalHref?: string;
  changeFrequency?: EDynaCMSArticleChangeFrequency;

  showPublishDateInList?: EShowDate;
  showUpdateDateInList?: EShowDate;
  showPublishDateInView?: EShowDate;
  showUpdateDateInView?: EShowDate;

  showAds?: boolean;
  showShareButtons?: boolean;
  showRelatedInsideBody?: boolean;
  showRelatedAtTheEnd?: boolean;

  fontFamilyGeneral?: IDynaCMSFont;
  fontFamilyHeader?: IDynaCMSFont;
  fontFamilyHeaders?: IDynaCMSFont;
  viewWidthType?: EViewWidthType;
  headingStyleList?: EDynaCMSHeadingStyle;
  headingStyleView?: EDynaCMSHeadingStyle;

  publishAt?: number;
  publishedAt?: number;

  isContentValid?: boolean;

  doNotHistory?: boolean;
}

export interface IApiArticleHeaderUpdateResponse {
  stats: IDynaCMSArticleStats;
}
