import {IAppRoute} from "../../application/config/IAppRoute";

export interface IRouteArgs {
  countryId: string;
  languageId: string;
  groupId: string;
  urlId: string;
}

export const routeDynaCMSPageViewerPaths: IAppRoute<IRouteArgs> = {
  title: 'Page',
  routePath: '/page/:countryId/:languageId/:groupId/:urlId',
  exact: true,
  getRoutePath: (
    {
      countryId,
      languageId,
      groupId,
      urlId,
    } = {
      countryId: '',
      languageId: '',
      groupId: '',
      urlId: '',
    },
  ) =>
    routeDynaCMSPageViewerPaths.routePath
      .replace(':countryId', countryId)
      .replace(':languageId', languageId)
      .replace(':groupId', groupId)
      .replace(':urlId', urlId),
  userHasAllRights: [],
  userHasAnyOfRights: [],
  requiresLatestClientVersion: false,
  render: () => null,
};
