import {ITwitterCardProps} from "./ITwitterCardProps";

export const createMetaTags = (
  {
    cardType,
    ownerTwitterAccount,
    creatorTwitterAccount,
    title,
    description,
    imageUrl,
    imageAlt,
  }: ITwitterCardProps,
): { name: string; content: string | undefined }[] => [
  {
    name: 'twitter:card',
    content: cardType,
  },
  {
    name: 'twitter:site',
    content: ownerTwitterAccount,
  },
  {
    name: 'twitter:creator',
    content: creatorTwitterAccount,
  },
  {
    name: 'twitter:title',
    content: title,
  },
  {
    name: 'twitter:description',
    content: description,
  },
  {
    name: 'twitter:image',
    content: imageUrl,
  },
  {
    name: 'twitter:image:alt',
    content: imageAlt,
  },
];
