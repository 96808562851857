import {IDynaCMSArticleBlock} from "../../../../db/IDynaCMSArticle";

export const API_PATH_ApiArticleContentGet = '/services/dyna-cms/apiArticleContentGet';

export interface IApiArticleContentGetRequest {
  countryId: string;
  languageId: string;
  groupId: string;
  articleId: string;
  blockId: string;
}

export interface IApiArticleContentGetResponse {
  block: IDynaCMSArticleBlock;
}
