import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {Paper} from "../Paper";
import {Condition} from "../Condition";
import {ContainerSection} from "../ContainerSection";

import {checkForAdBlocker} from "./checkForAdBlocker.util";

import AdBlockedIcon from '@mui/icons-material/ViewInAr';
import {createIcon} from "../IconComponent";

export interface ICheckForAdBlockerProps {
  messageType: "allow-ads" | "blocked-content-by-mistake";
  children: any;
}

export const CheckForAdBlocker = (props: ICheckForAdBlockerProps): JSX.Element => {
  const {
    messageType,
    children,
  } = props;

  const [hasBlocker, setHasBlocker] = useState(false);

  useEffect(() => {
    (async () => {
      const has = await checkForAdBlocker();
      setHasBlocker(has);
    })();
  }, []);

  if (!hasBlocker) return children;

  return (
    <Paper>
      <ContainerSection
        h={2}
        Icon={createIcon.byMuiIcon(AdBlockedIcon)}
        title="Uh oh. Looks you are using ad blocker."
        subtitle={
          [
            messageType === "allow-ads" && "We charge advertisers instead of our audience.",
            messageType === "blocked-content-by-mistake" && "Ad-blockers are not blocking only ads but other valuable content also!",
          ].filter(Boolean).join()
        }
      />
      <Condition if={messageType === "allow-ads"}>
        <p>Please disable the ad block to show your support for {window.location.host}.</p>
      </Condition>
      <Condition if={messageType === "blocked-content-by-mistake"}>
        <p>Please disable the ad block for the {window.location.host} to have full access.</p>
      </Condition>
    </Paper>
  );
};
