import {IDynaCMSBlockBase} from "../../../../db/EDynaCMSBlockType";

export const API_PATH_ApiArticleContentValidate = '/services/dyna-cms/apiArticleContentValidate';

export interface IApiArticleContentValidateBodyRequest {
  blocks: IDynaCMSBlockBase[];
}

export interface IApiArticleContentValidateResponse {
  validations: Array<{[fieldName: string]: string}>;
}
