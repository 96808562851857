import * as React from "react";

import {Box} from "../Box";
import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IContainerStickyProps {
  sx?: SxProps<Theme>;

  show?: boolean;
  sticky?: boolean;

  top?: number;
  zIndex?: number;

  children: any;
}

export const ContainerSticky: React.FC<IContainerStickyProps> = (
  {
    sx,
    show,
    sticky = true,
    top = 0,
    zIndex = 0,
    children,
  },
) => {
  return (
    <Box
      dataComponentName={`ContainerSticky${sticky ? '' : '(inactive)'}`}
      show={show}
      sx={{
        ...sx,
        position:
          sticky
            ? 'sticky'
            : undefined,
        top:
          sticky
            ? top
            : undefined,
        zIndex,
      }}
    >
      {children}
    </Box>
  );
};
