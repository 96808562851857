import {TExclude} from "utils-library/dist/commonJs/typescript";

import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {
  EShowDate,
  EDynaCMSHeadingStyle,
} from "./IDynaCMSArticle";
import {
  IDynaCMSFont,
  getDefaultIDynaCMSFont,
} from "./IDynaCMSFont";
import {
  IDynaCMSArticleBlockAddress,
  getDefaultDynaCMSArticleBlockAddress,
} from "./IDynaCMSArticleAddress";


export interface IDynaCMSSettings extends IDBEntityBase {
  // Basic settings
  countries: IDynaCMSCountry[];
  languages: IDynaCMSLanguage[];
  groups: IDynaCMSGroup[];

  // Article default
  articleShareButtons: Record<EDynaCMSShareButtons, boolean>;
  defaultCountryId: string;
  defaultLanguageId: string;
  defaultArticleFontFamilyGeneral: IDynaCMSFont;
  defaultArticleFontFamilyHeader: IDynaCMSFont;
  defaultArticleFontFamilyHeaders: IDynaCMSFont;
  defaultArticleShowPublishDateInList: boolean;
  defaultArticleShowUpdateDateInList: boolean;
  defaultArticleShowPublishDateInView: boolean;
  defaultArticleShowUpdateDateInView: boolean;
  defaultHeadingStyleList: EDynaCMSHeadingStyle;
  defaultHeadingStyleView: EDynaCMSHeadingStyle;

  // Social global settings
  applicationFacebookPage: string;
  applicationInstagramAccount: string;
  applicationTwitterAccount: string;
  applicationPinterestAccount: string;
  applicationYoutubeAccount: string;
  applicationTikTikAccount: string;
  applicationLinkedinCompanyAccount: string;

  // Backend/Admin settings
  googleYoutubeApiKey: string;
}

export const dynaCMSSettingSensitiveProperties: Array<keyof IDynaCMSSettings> = [
  "googleYoutubeApiKey",
];

interface IAdminSettings {
  // The IDynaCMSSettings represent the complete settings for the Dyna CMS service, but certain properties, such as API keys, are sensitive and should not be exposed to the client app.
  // Here are the excluded properties.
  googleYoutubeApiKey: string;
}

export type IDynaCMSSettingsBasic = TExclude<IDynaCMSSettings, IAdminSettings>;

export const convertDynaCMSSettingsToBasic = (dynaCMSSettings: IDynaCMSSettings): IDynaCMSSettingsBasic => {
  const output: any = {...dynaCMSSettings};
  dynaCMSSettingSensitiveProperties.forEach(propertyName => delete output[propertyName]);
  return output;
};

export interface IDynaCMSCountry {
  id: string;   // ISO 3166 Alpha 2, 2chars, https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
  label: string;
}

export interface IDynaCMSLanguage {
  id: string;   // ISO 639-1, 2chars,  https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  label: string;
}

export interface IDynaCMSGroup {
  id: string;
  coverBlockAddress: IDynaCMSArticleBlockAddress;
  label: string;
  pagePath: string;
  includeInSitemap: boolean;
  hasContent: boolean;              // Has content for the end users, for SEO, false for internal pages
  articleCreationEnabled: boolean;  // Enable article creation. Set it to false for any business logic scenarios (e.g., abandoned group).
  showPublishDateInList: EShowDate;
  showUpdateDateInList: EShowDate;
  showPublishDateInView: EShowDate;
  showUpdateDateInView: EShowDate;
  internalTags: string; // Coma separated
  notes: string;
}

export enum EDynaCMSShareButtons {
  NATIVE_SHARE = "NATIVE_SHARE",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  GOOGLE_PLUS = "GOOGLE_PLUS",
  PINTEREST = "PINTEREST",
  POCKET = "POCKET",
  REDDIT = "REDDIT",
  LINKEDIN = "LINKEDIN",
  WORDPRESS = "WORDPRESS",
  PINBOARD = "PINBOARD",
  EMAIL = "EMAIL",
}

export const getDefaultDynaCMSSettings = () =>
  getDefaultDBEntityDocument<IDynaCMSSettings>({
    countries: [],
    languages: [],
    groups: [],
    articleShareButtons:
      Object.values(EDynaCMSShareButtons)
        .reduce((acc: any, shareButton) => {
          acc[shareButton] = true;
          if (shareButton === EDynaCMSShareButtons.INSTAGRAM) acc[shareButton] = false;
          return acc;
        }, {}),
    defaultCountryId: '',
    defaultLanguageId: '',
    defaultArticleFontFamilyGeneral: getDefaultIDynaCMSFont(),
    defaultArticleFontFamilyHeader: getDefaultIDynaCMSFont(),
    defaultArticleFontFamilyHeaders: getDefaultIDynaCMSFont(),
    defaultArticleShowPublishDateInList: false,
    defaultArticleShowUpdateDateInList: false,
    defaultArticleShowPublishDateInView: true,
    defaultArticleShowUpdateDateInView: false,
    defaultHeadingStyleList: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
    defaultHeadingStyleView: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
    applicationFacebookPage: '',
    applicationInstagramAccount: '',
    applicationTwitterAccount: '',
    applicationPinterestAccount: '',
    applicationYoutubeAccount: '',
    applicationTikTikAccount: '',
    applicationLinkedinCompanyAccount: '',
    googleYoutubeApiKey: '',
  });

export const getDefaultDynaCMSSettingsBasic = () => convertDynaCMSSettingsToBasic(getDefaultDynaCMSSettings());

export const getDefaultDynaCMSGroup = (): IDynaCMSGroup => ({
  id: '',
  coverBlockAddress: getDefaultDynaCMSArticleBlockAddress(),
  label: '',
  pagePath: '',
  includeInSitemap: true,
  hasContent: true,
  articleCreationEnabled: true,
  showPublishDateInList: EShowDate.INHERIT,
  showUpdateDateInList: EShowDate.INHERIT,
  showPublishDateInView: EShowDate.INHERIT,
  showUpdateDateInView: EShowDate.INHERIT,
  internalTags: '',
  notes: '',
});
