import {
  IDynaCMSArticle,
  EDynaCMSBlockType,
  IDynaCMSBlockImage,
  IDynaCMSBlockImages,
  IDynaCMSBlockVideoYouTube,
} from "server-app";

import {OpenGraphMetaTags} from "ui-components/dist/OpenGraphMetaTags";
import {EOpenGraphType} from "ui-components/dist/OpenGraphMetaTags";
import {
  TwitterMetaCard,
  ECardType,
} from "ui-components/dist/TwitterMetaCard";

import {IAppStore} from "../../../../../state/IAppStore";
import {appConfig} from "../../../../application/config/appConfig";

export interface ISocialNetworksMetaTagsProps {
  store: IAppStore;
  article: IDynaCMSArticle;
}

interface ICoverImage {
  imageUrl: string;
  imageAlt: string;
}

const emptyCoverImage: ICoverImage = {
  imageUrl: '',
  imageAlt: '',
};

// Similar code 20230725121128 - Generate SEO meta tags for Twitter cards and other platforms as part of the article view.

export const SocialNetworksMetaTags = (
  {
    store: {
      dynaCMS: {
        state: {
          settings: {
            groups,
            applicationTwitterAccount,
          },
        },
      },
    },
    article: {
      groupId,
      shortUrl,
      countryId,
      languageId,
      title,
      description,
      coverMediaBlockCopy,
    },
  }: ISocialNetworksMetaTagsProps,
) => {

  const mainImage: ICoverImage = (() => {
    if (!coverMediaBlockCopy) return emptyCoverImage; // The article has no Cover media block, exit

    if (coverMediaBlockCopy.content.type === EDynaCMSBlockType.IMAGE) {
      const image: IDynaCMSBlockImage = coverMediaBlockCopy.content as any;
      return {
        imageUrl: image.imageUrl,
        imageAlt: image.caption,
      };
    }

    if (coverMediaBlockCopy.content.type === EDynaCMSBlockType.IMAGES) {
      const images: IDynaCMSBlockImages = coverMediaBlockCopy.content as any;
      const image = images.images.concat().shift();
      if (!image) return emptyCoverImage;
      return {
        imageUrl: image.imageUrl,
        imageAlt: image.caption,
      };
    }

    if (coverMediaBlockCopy.content.type === EDynaCMSBlockType.VIDEO_YOUTUBE) {
      const video: IDynaCMSBlockVideoYouTube = coverMediaBlockCopy.content as any;
      return {
        imageUrl: video.previewImageUrl,
        imageAlt: video.title,
      };
    }

    console.error(`SocialNetworksMetaTags, couldn't resolve the mainImage for block type [${coverMediaBlockCopy.content.type}] url: [${window.location.href}]`);

    return emptyCoverImage;
  })();

  return (
    <>
      <OpenGraphMetaTags
        country={countryId}
        langauge={languageId}
        articleSection={groups.find(group => group.id === groupId)?.label}
        articlePublisherUrl={  // Todo: Here we should get the profile of the main author, and use it's profile url
          window.location.href
            .split('/')
            .slice(0, 3)
            .join('/')
        }
        type={EOpenGraphType.ARTICLE}
        title={title}
        description={description}
        url={shortUrl || window.location.href}
        siteName={appConfig.appName}
      />
      <TwitterMetaCard
        cardType={ECardType.SUMMARY}
        ownerTwitterAccount={applicationTwitterAccount}
        title={title}
        description={description}
        imageUrl={mainImage.imageUrl}
        imageAlt={mainImage.imageAlt}
      />
    </>
  );
};
