import {Box} from "ui-components/dist/Box";
import {
  Link,
  ELinkColor,
  ELinkUnderline,
  ELinkDisplay,
} from "ui-components/dist/Link";

import {
  EDynaCMSFontFamily,
  DynaCMSFontFamily,
} from "../DynaCMSFontFamily";

export interface IDynaCMSGroupLinksProps {
  title: string;
  textAlign?: 'left' | 'center' | 'right';
  titleFont: EDynaCMSFontFamily;
  subTitle?: string;
  subTitleFont?: EDynaCMSFontFamily;
  links: ILinks[];
  linkPerLine?: boolean;
  onClick?: () => void;
}

export interface ILinks {
  link: string;
  label: string;
}

export const DynaCMSGroupLinks = (props: IDynaCMSGroupLinksProps): JSX.Element => {
  const {
    title,
    titleFont,
    textAlign,
    subTitle,
    subTitleFont = titleFont,
    links,
    linkPerLine = false,
    onClick,
  } = props;

  return (
    <Box
      dataComponentName="DynaCMSGroupLinks"
      sx={{textAlign}}
    >
      <DynaCMSFontFamily
        fontFamily={titleFont}
        fontSize={3}
        sx={{whiteSpace: 'nowrap'}}
      >
        {title}
      </DynaCMSFontFamily>
      <DynaCMSFontFamily
        fontFamily={subTitleFont}
        fontSize={1.5}
      >
        {subTitle}
      </DynaCMSFontFamily>
      <Box sx={{marginTop: theme => theme.spacing(1)}}/>
      <nav>
        <ul style={{
          margin: 0,
          padding: 0,
        }}
        >
          {links
            .map((
              {
                label,
                link,
              },
              index,
            ) => (
              <Box
                component="li"
                key={index}
                sx={{
                  display: 'inline',
                  opacity: 0.8,
                  lineHeight: 0.5,
                }}
              >
                <DynaCMSFontFamily fontFamily={EDynaCMSFontFamily.F135_MODERN_BASE_NOTO}>
                  <Link
                    sx={{
                      color: 'inherit',
                      transition: '150ms opacity',
                      marginRight: theme => theme.spacing(linkPerLine ? 0 : 2),
                      '&:hover': {opacity: 0.5},
                    }}
                    display={ELinkDisplay.BLOCK}
                    color={ELinkColor.INHERIT}
                    underline={ELinkUnderline.NONE}
                    href={link}
                    onClick={onClick}
                  >
                    {label}
                  </Link>
                </DynaCMSFontFamily>
                <Box
                  component="br"
                  show={linkPerLine}
                />
              </Box>
            ))}
        </ul>
      </nav>
    </Box>
  );
};
