import {
  IDynaCMSArticleHeading,
  EDynaCMSBlockImageLabelPosition
  ,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {ContainerSizeMonitor} from "ui-components/dist/ContainerSizeMonitor";
import {sxTextShadow} from "ui-components/dist/sxTextShadow";

import {DynaCMSLabel} from "../../../../commons/DynaCMSLabel";
import {BlockViewer} from "../../../../block-viewers/BlockViewer";

import {ArticleDateViewer} from "../components/ArticleDateViewer";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

export interface IExceptionalHeadingViewerProps {
  h: number;  // Heading number for h1, h2, etc..
  article: IDynaCMSArticleHeading;
  bigTitle: boolean;
  showDescription: boolean;
  target: 'list' | 'view';
  textAlign?: 'left' | 'center' | 'right';
  negative?: EExceptionalNegative;
  showPhotoCredits: boolean;
  showPhotoPortraitSmaller: boolean;
  showVideoPreviewOnly: boolean;
  showDate: boolean;
  showDateIcon: boolean;
  showUpdatedAt: boolean;
}

enum EBreakpoints {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum EExceptionalNegative {
  ALL = "ALL",
  TEXTS = "TEXTS",
}

export const ExceptionalHeadingViewer = (props: IExceptionalHeadingViewerProps): JSX.Element => {
  const {
    h,
    article,
    article: {
      title,
      subtitle,
      label,
      description,
      coverMediaBlockCopy,
    },
    bigTitle,
    showDescription,
    target,
    textAlign,
    negative,
    showPhotoCredits,
    showPhotoPortraitSmaller,
    showVideoPreviewOnly,
    showDate,
    showDateIcon,
    showUpdatedAt,
  } = props;

  const sxNegative: SxProps<Theme> = {
    color: theme => theme.palette.background.default,
    background: theme => theme.palette.text.primary,
  };

  return (
    <ContainerSizeMonitor<EBreakpoints>
      breakpoints={{
        [EBreakpoints.XSMALL]: 86,
        [EBreakpoints.SMALL]: 320,
        [EBreakpoints.MEDIUM]: 640,
        [EBreakpoints.LARGE]: 860,
      }}
      render={
        (
          {
            isInAndBelow,
            isInAndAbove,
          },
        ) => {
          const showPhoto: boolean = (() => {
            if (isInAndBelow(EBreakpoints.XSMALL)) return false;
            if (!coverMediaBlockCopy) return false;
            return true;
          })();

          // This is the vertical version
          return (
            <Box
              dataComponentName="ExceptionalHeadingViewer"
              sx={{
                py: 2,
                maxWidth: 640,
                margin: 'auto',
                position: 'relative',
                ...(negative === EExceptionalNegative.ALL ? sxNegative : undefined),
              }}
            >
              <DynaCMSLabel
                show={!!label && isInAndAbove(EBreakpoints.SMALL)}
                position={EDynaCMSBlockImageLabelPosition.TOP_RIGHT}
                children={label}
              />
              <Box
                show={showPhoto}
                sx={{
                  paddingLeft: theme => theme.spacing(3),
                  paddingRight: theme => theme.spacing(5),
                }}
              >
                <BlockViewer
                  block={coverMediaBlockCopy}
                  showPhotoTexts={false}
                  showPhotoCredits={showPhotoCredits}
                  showPhotoPortraitSmaller={showPhotoPortraitSmaller}
                  showVideoPreviewOnly={showVideoPreviewOnly}
                />
              </Box>
              <Box
                dataComponentName="Title"
                component={`h${h}` as any}
                sx={{
                  position: 'relative',
                  top: showPhoto ? "-34px" : undefined,
                  m: 0,
                  mb: showPhoto ? "-34px" : undefined,
                  textAlign: textAlign ? textAlign : undefined,
                  paddingLeft: theme => theme.spacing(12),
                  fontFamily: 'inherit !important',
                  letterSpacing: 'inherit !important',
                  fontSize:
                    theme =>
                      bigTitle
                        ? theme.spacing(4) + ' !important'
                        : isInAndBelow(EBreakpoints.SMALL)
                          ? theme.spacing(3) + ' !important'
                          : theme.spacing(4) + ' !important',
                  textShadow: theme => sxTextShadow({
                    strong: 2,
                    color:
                      negative
                        ? theme.palette.text.primary
                        : theme.palette.background.default,
                    blur: 1,
                  }),
                  ...(negative === EExceptionalNegative.TEXTS ? sxNegative : {} as any),
                }}
              >
                {title}
              </Box>
              <Box
                dataComponentName="Subtitle"
                component="p"
                show={!!subtitle && isInAndAbove(EBreakpoints.SMALL)}
                sx={{
                  fontWeight: 'bold',
                  fontSize: theme => theme.typography.fontSize * 1.5,
                  textAlign: textAlign ? textAlign : 'right',
                  paddingRight: theme => theme.spacing(7),
                  ...(negative === EExceptionalNegative.TEXTS ? sxNegative : {} as any),
                }}
              >
                {subtitle}
              </Box>
              <Box
                dataComponentName="Description"
                component="p"
                show={showDescription && isInAndAbove(EBreakpoints.SMALL)}
                sx={{
                  fontWeight: 'bold',
                  m: 0,
                  paddingLeft: theme => theme.spacing(13),
                  paddingRight: theme => theme.spacing(13),
                  fontSize: theme => theme.typography.fontSize * 1.1,
                  textAlign: textAlign ? textAlign : 'right',
                  ...(negative === EExceptionalNegative.TEXTS ? sxNegative : {} as any),
                }}
              >
                <em>
                  {description}
                </em>
              </Box>
              <Box sx={{textAlign: 'center'}}>
                <ArticleDateViewer
                  articleHeading={article}
                  show={showDate}
                  target={target}
                  showIcon={showDateIcon}
                  showUpdatedAt={showUpdatedAt}
                />
              </Box>
            </Box>
          );
        }}
    />
  );
};
