import * as React from "react";
import {
  ReactElement,
  RefObject,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";

import {EButtonColor} from "../Button";
import {Button} from "../Button";
import {Modal} from "../Modal";
import {Link} from "../Link";
import {IconViewer} from "../IconViewer";
import {IIconComponent} from "../IconComponent";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import CloseIcon from '@mui/icons-material/Close';

export interface IModalButtonProps {
  sx?: SxProps<Theme>;
  show?: boolean;

  ref?: RefObject<IModalButtonRef>;

  // Button
  Icon?: IIconComponent;
  color?: EButtonColor;
  label?: string;
  description?: string;
  buttonType?: EModalButtonType;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
  hideLabelOnTablet?: boolean;

  // Modal
  fullModal?: boolean;
  closeButton?: boolean;
  closeOnBackdropClick?: boolean;
  maxWidth?: number;
  children: any;
}

export interface IModalButtonRef {
  close: () => void;
}

export enum EModalButtonType {
  BUTTON = "BUTTON",
  LINK = "LINK",
}

export {
  EButtonColor,
};

export const ModalButton = forwardRef<IModalButtonRef, IModalButtonProps>((props, ref): ReactElement | null => {

  const {
    sx,
    show = true,
    Icon,
    color,
    buttonType = EModalButtonType.BUTTON,
    label,
    description,
    disabled,
    hideLabelOnMobile,
    hideLabelOnTablet,
    fullModal,
    closeButton = false,
    closeOnBackdropClick = true,
    maxWidth,
    children,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({close: () => setShowModal(false)}));

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(true);
    return false;
  };

  const handleBackdropClick = (): void => {
    if (closeOnBackdropClick) setShowModal(false);
  };
  const handleCloseButtonClick = (): void => {
    setShowModal(false);
  };

  if (!show) return null;

  return (
    <>
      <Button
        sx={sx}
        show={buttonType === EModalButtonType.BUTTON}
        Icon={Icon}
        color={color}
        ariaLabel={label}
        description={description}
        disabled={disabled}
        hideLabelOnMobile={hideLabelOnMobile}
        hideLabelOnTablet={hideLabelOnTablet}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Link
        hidden={buttonType !== EModalButtonType.LINK}
        href=""
        disabled={disabled}
        onClick={handleClick}
      >
        <IconViewer
          sx={{
            marginRight: 1,
            position: 'relative',
            top: 2,
          }}
          Icon={Icon}
        />
        {label}
      </Link>
      <Modal
        show={showModal}
        fullMode={fullModal}
        maxWidth={maxWidth}
        onBackdropClick={handleBackdropClick}
      >
        {children}
        <Button
          show={closeButton}
          sx={{
            position: 'absolute',
            top: theme => theme.spacing(2),
            right: theme => theme.spacing(2),
          }}
          color={EButtonColor.WHITE}
          icon={<CloseIcon/>}
          onClick={handleCloseButtonClick}
        />
      </Modal>
    </>
  );
});
