import * as React from "react";
import * as ReactDOM from "react-dom";
import {CSSProperties} from "react";

export interface IPortalContainerProps {
  show?: boolean;
  style?: CSSProperties;
  children: any;
}

export class PortalContainer extends React.Component<IPortalContainerProps> {
  private readonly refFloatContainer: HTMLDivElement;

  constructor(props: IPortalContainerProps) {
    super(props);
    this.refFloatContainer = document.createElement('div');
    this.applyStyle();
  }

  public componentDidMount(): void {
    document.body.appendChild(this.refFloatContainer);
  }

  public componentDidUpdate() {
    this.applyStyle();
  }

  private applyStyle(): void {
    const {style} = this.props;
    if (!style) return;
    Object.keys(style)
      .forEach(styleProp => this.refFloatContainer.style[styleProp] = style[styleProp]);
  }

  public get innerRef(): HTMLDivElement {
    return this.refFloatContainer;
  }

  public componentWillUnmount(): void {
    document.body.removeChild(this.refFloatContainer);
  }

  public render(): JSX.Element | null {
    const {
      show = true,
      children,
    } = this.props;

    if (!show) return null;

    return ReactDOM.createPortal(
      children,
      this.refFloatContainer,
    );
  }
}
