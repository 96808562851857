import {EPublishedFilter} from "server-app";

import {parseJSONDefault} from "utils-library/dist/commonJs/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

import {
  IDynaCMSArticleListSearchObject,
  routeDynaCMSArticlesMgtmPaths,
} from "./routeDynaCMSArticlesMgtm.paths";

import {ArticlesMgtm} from "../pages/ArticlesMgtm";

export const routeDynaCMSArticlesMgtm: IAppRoute<{ searchObject: string }> = {
  ...routeDynaCMSArticlesMgtmPaths,
  render: (
    {pathParams: {searchObject = "{}"}},
  ) => {
    const {
      countryId,
      languageId,
      groupIds,
      searchText,
      tags,
      published,
      archived,
      deleted,
    } = parseJSONDefault<IDynaCMSArticleListSearchObject>({
      serialized: searchObject,
      default: {
        countryId: '',
        languageId: '',
        groupIds: [],
        searchText: '',
        tags: [],
        published: EPublishedFilter.ALL,
        archived: false,
        deleted: false,
      },
    },
    );
    return (
      <ArticlesMgtm
        countryId={countryId}
        languageId={languageId}
        groupIds={groupIds}
        searchText={searchText}
        tags={tags}
        published={published}
        archived={archived}
        deleted={deleted}
      />
    );
  },
};
