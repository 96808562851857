import {connect} from "react-dynadux";

import {
  DynaCMSArticleViewer as Component,
} from "./DynaCMSArticleViewer";
export const DynaCMSArticleViewerEva = connect(Component);

export type {
  IDynaCMSArticleViewerProps,
} from "./DynaCMSArticleViewer";
