import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDynaCMSMainPagePaths} from "./routeDynaCMSMainPage.paths";

import {DynaCMSMainPage} from "../pages/DynaCMSMainPage";

export const routeDynaCMSMainPage: IAppRoute = {
  ...routeDynaCMSMainPagePaths,
  render: () => (
    <DynaCMSMainPage/>
  ),
};
