import * as React from "react";
import {
  useState,
  useMemo,
} from "react";

import {TEnum} from "utils-library/dist/commonJs/typescript";
import {useContainerResizeEvent} from "../useContainerResizeEvent";
import {getDataComponentName} from "../web-utils";

export interface IContainerSizeMonitorProps<TEBreakpoint extends TEnum> {
  dataComponentName?: string;
  fullHeight?: boolean;
  breakpoints?: Record<TEBreakpoint, number>;  // Number is the starting px point for each breakpoint.
  render: (
    args: {
      width: number;
      height: number;
      breakpoint: TEBreakpoint | null;         // Returns boolean only when breakpoints is not defined
      isIn: (breakpoint: TEBreakpoint) => boolean;
      isInAndAbove: (breakpoint: TEBreakpoint) => boolean;
      isInAndBelow: (breakpoint: TEBreakpoint) => boolean;
    },
  ) => any;
}

interface IBreakpoint<TBreakpointEnum extends TEnum> {
  startsAtPx: number;
  enum: TBreakpointEnum;
}

/**
 * Container that based on container (and not screen!) width, calls the render.
 *
 * The render function is called with the current width and height.
 *
 * If `TEBreakpoint` and the `breakpoints` are provided, the current breakpoint will be provided too making easier the conditional rendering.
 * @param props
 * @constructor
 */
export const ContainerSizeMonitor = <TBreakpointEnum extends TEnum, >(props: IContainerSizeMonitorProps<TBreakpointEnum>): JSX.Element => {
  const {
    dataComponentName,
    fullHeight = false,
    breakpoints: _breakpoints = {},
    render,
  } = props;

  const breakpoints = useMemo(() =>
    Object.entries(_breakpoints)
      .map(([_enum, startsAtPx]: Array<any>): IBreakpoint<TBreakpointEnum> => ({
        startsAtPx: startsAtPx,
        enum: _enum,
      }))
      .sort((a, b) => a.startsAtPx - b.startsAtPx),
  [_breakpoints],
  );
  const [divSize, setDivSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const {
    width,
    height,
  } = divSize;

  const {ref} = useContainerResizeEvent<HTMLDivElement>({
    refreshRate: 100,
    skipOnMount: false,
    onResize: (
      {
        width,
        height,
      },
    ) => setDivSize({
      width,
      height,
    }),
  });

  const breakpoint: IBreakpoint<TBreakpointEnum> | null = (() => {
    if (!breakpoints.length) return null;
    return breakpoints.reduce((acc: IBreakpoint<TBreakpointEnum>, breakpoint) => {
      if (divSize.width >= breakpoint.startsAtPx) return breakpoint;
      return acc;
    }, breakpoints[0]);
  })();

  const isIn = (testBreakpoint: TBreakpointEnum): boolean => {
    return testBreakpoint === breakpoint?.enum;
  };

  const isInAndBelow = (testBreakpoint: TBreakpointEnum): boolean => {
    // @ts-ignore
    return width < _breakpoints[testBreakpoint];
  };
  const isInAndAbove = (testBreakpoint: TBreakpointEnum): boolean => {
    // @ts-ignore
    return width >= _breakpoints[testBreakpoint];
  };

  return (
    <div
      data-component-name={getDataComponentName(dataComponentName, "ContainerSizeMonitor")}
      ref={ref}
      style={{height: fullHeight ? '100%' : undefined}}
    >
      {render({
        width,
        height,
        breakpoint: breakpoint ? breakpoint.enum : null,
        isIn,
        isInAndBelow,
        isInAndAbove: isInAndAbove,
      })}
    </div>
  );
};
