import * as React from "react";
import {useMemo} from "react";

import {IDynaCMSArticleBlockAddress} from "server-app";

import {pickRandomItems} from "utils-library/dist/commonJs/utils";
import {
  GridItem,
  TGridColumn,
} from "ui-components/dist/Grid";

import {DynaCMSGoToPageCoverLink} from "../DynaCMSGoToPageCoverLink";

export interface IDynaCMSRandomGoToPageCoverLinksProps {
  links: {
    cover: IDynaCMSArticleBlockAddress;
    link: string;
  }[];
  count: number;
  gridMobileColumns?: TGridColumn;
}

export const DynaCMSRandomGoToPageCoverLinks: React.FC<IDynaCMSRandomGoToPageCoverLinksProps> = (
  {
    links: allLinks,
    count,
    gridMobileColumns,
  },
) => {
  const links = useMemo(
    () => pickRandomItems(allLinks, count),
    [allLinks.join('-')],
  );

  return (
    <>
      {links
        .map((
          {
            cover,
            link,
          },
          index,
        ) => (
          <GridItem
            key={index}
            mobile={gridMobileColumns}
          >
            <DynaCMSGoToPageCoverLink
              cover={cover}
              link={link}
            />
          </GridItem>
        ))}
    </>
  );
};
