import {
  API_PATH_ApiArticleGet,
  IApiArticleGetRequestRequest,
  IApiArticleGetResponse,
} from "server-app";

import {apiFetch} from "../../../../../api/apiFetch";

export const apiArticleGet = async (args: IApiArticleGetRequestRequest): Promise<IApiArticleGetResponse> => {
  return apiFetch.request<IApiArticleGetRequestRequest, null, IApiArticleGetResponse>({
    method: 'GET',
    path: API_PATH_ApiArticleGet,
    query: args,
  });
};
