import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeListUsersPaths} from "./routeListUsers.paths";

const UserManagementListPage = lazy(() => import( "../pages/UserManagementListPage/UserManagementListPage"));

export const routeListUsers: IAppRoute<{
  search?: string;
}> = {
  ...routeListUsersPaths,
  render: ({pathParams}) => (
    <LazyLoadComponent>
      <UserManagementListPage
        search={pathParams.search || ''}
      />
    </LazyLoadComponent>
  ),
};
