import {IAppRoute} from "../../../../core/application/config/IAppRoute";

import {routeDynaCMSArticle} from "../../../../core/dyna-cms/public-components";

export const routeAdoreContactContentTeam: IAppRoute = routeDynaCMSArticle({
  path: "contact-content-team",
  multiLangArticles: [
    {
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: 'b6079da4-2f8490ffab5e37101a',
    },
  ],
});
