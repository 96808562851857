import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDynaCMSArticleMgtmPaths} from "./routeDynaCMSArticleMgtm.paths";

import {ArticleMgtm} from "../pages/ArticleMgtm";

export const routeDynaCMSArticleMgtm: IAppRoute<{
  countryId: string;
  languageId: string;
  groupId: string;
  articleId: string;
}> = {
  ...routeDynaCMSArticleMgtmPaths,
  render: (
    {
      pathParams: {
        countryId,
        languageId,
        groupId,
        articleId,
      },
    },
  ) => {
    return (
      <ArticleMgtm
        countryId={countryId}
        languageId={languageId}
        groupId={groupId}
        articleId={articleId}
      />
    );
  },
};
