import {FormattedDate} from "react-intl";

export interface IDynaIntlDateFormatterProps {
  date?: Date | number;
  day?: "numeric" | "2-digit";
  weekday?: "long" | "short" | "narrow";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
}

export const DynaIntlDateFormatter = (props: IDynaIntlDateFormatterProps): JSX.Element => {
  const {
    date,
    weekday = "short",
    day = "numeric",
    month = "numeric",
    year = "numeric",
  } = props;

  return (
    <FormattedDate
      value={date}
      day={day}
      weekday={weekday}
      month={month}
      year={year}
    />
  );
};
