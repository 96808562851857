import * as React from "react";
import {IDynaError} from "dyna-error";

import {LinearProgress} from "@mui/material";

import {roundToString} from "utils-library/dist/commonJs/utils";

import {Box} from "../Box";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";
import {
  LabelContainer,
  ELabelContainerVariant,
} from "../LabelContainer";
import {
  HelperText,
  EHelperTextType,
} from "../HelperText";
import {IconButton} from "../IconButton";

import {IsLoadingBox} from "../IsLoadingBox";

import CancelIcon from '@mui/icons-material/Cancel';

export const EProgressBarVariant = {...ELabelContainerVariant};
export type EProgressBarVariant = ELabelContainerVariant; // eslint-disable-line

export enum EProgressBarColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

export interface IProgressBarProps {
  label?: string;
  ariaLabel?: string;
  progressLabelAction?: string;
  progressLabelItem?: string;   // Shown with ellipsis on the left
  variant?: EProgressBarVariant;
  color?: EProgressBarColor;
  value?: number;           // The actual value of something, range: 0..complete
  complete?: number;        // Default is 100. The complete value
  buffer?: number;          // The buffered value, range: 0..complete
  showPercentage?: boolean; // Default is true
  inProgress?: boolean;   // Default is false. Show the Is loading spinner
  error?: IDynaError | null;
  leftPlace?: JSX.Element;  // Content to be placed on the left
  rightPlace?: JSX.Element; // Content to be placed on the right
  onCancel?: () => void;
}

export const ProgressBar = (props: IProgressBarProps): JSX.Element => {
  const {
    label,
    ariaLabel = label || 'Progress bar',
    progressLabelAction,
    progressLabelItem,
    variant = ELabelContainerVariant.NONE,
    color,
    value,
    complete = 100,
    buffer,
    showPercentage = true,
    inProgress = false,
    error,
    leftPlace,
    rightPlace,
    onCancel,
  } = props;

  const getPercentage = (value?: number): number | undefined => {
    if (value === undefined) return undefined;
    return 100 * value / complete;
  };

  return (
    <LabelContainer
      label={label}
      variant={variant}
    >
      <FlexContainerHorizontal
        sx={{marginTop: theme => theme.spacing(-2)}}
        spacing={2}
        alignVertical="middle"
      >
        <FlexItemMin show={!!leftPlace}>
          {leftPlace}
        </FlexItemMin>
        <FlexItemMax>
          <LinearProgress
            aria-label={ariaLabel}
            color={color}
            variant={(() => {
              if (value === undefined) return 'indeterminate';
              if (buffer !== undefined) return 'buffer';
              return 'determinate';
            })()}
            value={getPercentage(value)}
            valueBuffer={getPercentage(buffer)}
          />
        </FlexItemMax>
        <FlexItemMin
          show={showPercentage && value !== undefined}
          sx={{fontWeight: 'bold'}}
        >
          {roundToString(getPercentage(value) || 0, 1)}%
        </FlexItemMin>
        <FlexItemMin show={inProgress}>
          <IsLoadingBox
            spacing={0}
            iconSize={2}
          />
        </FlexItemMin>
        <FlexItemMin show={!!onCancel}>
          <IconButton
            ariaLabel="Cancel"
            icon={<CancelIcon/>}
            onClick={onCancel}
          />
        </FlexItemMin>
        <FlexItemMin show={!!rightPlace}>
          {rightPlace}
        </FlexItemMin>
      </FlexContainerHorizontal>
      <Box
        sx={{
          position: 'relative',
          top: '-12px',
          paddingLeft: theme => theme.spacing(2),
          paddingRight: theme => theme.spacing(2),
        }}
      >
        <Box
          show={!!progressLabelAction}
          sx={{
            fontWeight: 'bold',
            fontSize: theme => theme.typography.fontSize * 0.9,
            opacity: 0.7,
          }}
        >
          {progressLabelAction}
        </Box>
        <Box
          show={!!progressLabelItem}
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            direction: 'rtl',
            textAlign: 'left',
            fontSize: theme => theme.typography.fontSize * 0.8,
            opacity: 0.7,
          }}
        >
          {progressLabelItem}
        </Box>
        <HelperText type={EHelperTextType.ERROR}>
          {error ? error.userMessage || 'Error' : undefined}
        </HelperText>
      </Box>
    </LabelContainer>
  );
};
