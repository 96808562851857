import {
  Theme,
  SxProps,
} from "../ThemeProvider";

export const sxScrollMoreEffect = (): SxProps<Theme> => ({
  background: 'linear-gradient(0deg, #8f8f8f36, transparent)',
  backgroundSize: '1000% 45px',
  backgroundRepeat: 'repeat-x',
  backgroundPositionY: '100%',
});
