import {ReactElement} from "react";

import {Box} from "ui-components/dist/Box";
import {Collapse} from "ui-components/dist/Collapse";
import {ButtonLinear} from "ui-components/dist/ButtonLinear";

import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
  ETextTransform,
} from "../index";
import {IsLoadingBox} from "ui-components/dist/IsLoadingBox";
import {arrayRandom} from "utils-library/dist/commonJs/array";

export interface IDynaCMSLoadMoreButtonProps {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

export const DynaCMSLoadMoreButton = (props: IDynaCMSLoadMoreButtonProps): ReactElement | null => {
  const {
    isLoading,
    isLoadingMore,
    hasMore,
    onLoadMore,
  } = props;

  return (
    <Box sx={{textAlign: 'center'}}>
      <IsLoadingBox
        isLoading={isLoadingMore}
        inline
      >
        <i>
          {arrayRandom([
            "Engaging quantum loading algorithms...",
            "Harmonizing data frequencies...",
            "Synchronizing ethereal components...",
            "Initiating transcendental loading sequence...",
            "Curating a symphony of binary orchestration...",
            "Elevating the loading experience to an art form...",
            "Conducting a ballet of asynchronous processes...",
            "Navigating the corridors of digital anticipation...",
            "Unveiling the enigma of loading mysteries...",
            "Infusing the essence of loading with sophistication...",
            "Embarking on a journey through loading dimensions...",
            "Crafting a tapestry of loading elegance...",
            "Embracing the ballet of loading choreography...",
            "Weaving the fabric of loading serendipity...",
            "Harvesting the fruits of loading sophistication...",
            "Unleashing loading phenomena of grandeur...",
            "Elegantly orchestrating the loading ballet...",
            "Unraveling the secrets of loading finesse...",
            "Immersing in the loading ballet of elegance...",
            "Bespoke loading experience for connoisseurs...",
          ])}
        </i>
      </IsLoadingBox>
      <Collapse expanded={!isLoading && hasMore}>
        <ButtonLinear
          sx={{
            margin: theme => theme.spacing(4),
            padding: theme => theme.spacing(4),
          }}
          onClick={onLoadMore}
        >
          <DynaCMSFontFamily
            fontFamily={EDynaCMSFontFamily.F186_FASHION_EMPIRE_FS}
            fontSize={64}
            bold
            textTransform={ETextTransform.UPPERCASE}
          >
            Load more...
          </DynaCMSFontFamily>
        </ButtonLinear>
      </Collapse>
    </Box>
  );
};
