import {EUserAuthenticationRights} from "server-app";

import {IAppRoute} from "../../application/config/IAppRoute";
import ProfileUserRights from "../pages/ProfileUserRights/ProfileUserRights";
import {DynaProfilesIconMui} from "../icons/DynaProfilesIcon";

export const routeProfileUserRights: IAppRoute = {
  icon: <DynaProfilesIconMui/>,
  title: 'Profile user rights',
  description: 'Users and rights for the current profile',
  routePath: '/dyna-profiles/user-rights',
  getRoutePath: () => routeProfileUserRights.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  render: () => <ProfileUserRights/>,
};
