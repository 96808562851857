import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDynaCMSArticlesMgtm} from "./routeDynaCMSArticlesMgtm";
import {routeDynaCMSArticleCreate} from "./routeDynaCMSArticleCreate";
import {routeDynaCMSArticleMgtm} from "./routeDynaCMSArticleMgtm";
import {routeDynaCMSMainPage} from "./routeDynaCMSMainPage";
import {routeDynaCMSSettings} from "./routeDynaCMSSettings";
import {routeDynaCMSArticlePreViewer} from "./routeDynaCMSArticlePreViewer";
import {routeDynaCMSArticleViewerByUrlId} from "./routeDynaCMSArticleViewerByUrlId";
import {routeDynaCMSArticleViewerByArticleId} from "./routeDynaCMSArticleViewerByArticleId";
import {routeDynaCMSPageViewer} from "./routeDynaCMSPageViewer";

export const routesDynaCMSManagement: IAppRoute<any>[] = [
  routeDynaCMSMainPage,
  routeDynaCMSArticlesMgtm,
  routeDynaCMSArticleCreate,
  routeDynaCMSArticleMgtm,
  routeDynaCMSSettings,
  routeDynaCMSArticlePreViewer,
  routeDynaCMSArticleViewerByUrlId,
  routeDynaCMSArticleViewerByArticleId,
  routeDynaCMSPageViewer,
];
