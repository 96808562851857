import {ICreateStoreAPI} from "dynadux";
import {IDynaError} from "dyna-error";
import {DynaLocalStorageData} from "dyna-local-storage";

import {loadAll} from "utils-library/dist/commonJs/db";

import {
  IDynaProfile,
  IDynaProfileUserRight,
  IDynaProfileUserRightsReader,
  dynaProfileUserRightsReader,
  getDefaultDynaProfile,
} from "server-app";

import {IAppState} from "../../../state/IAppState";
import {EUserAuthAction} from "../../user-authnentication/state/userAuthSection";
import {EAppAction} from "../../application/state/appSection";

import {apiDynaProfileForUserGet} from "../api/profiles/apiDynaProfileForUserGet";
import {apiDynaProfileDefaultForUserGet} from "../api/profiles/apiDynaProfileDefaultForUserGet";
import {apiDynaProfilesForUserWithAnyAccess} from "../api/profiles/apiDynaProfilesForUserWithAnyAccess";
import {apiProfileUserRightsGet} from "../api/rights/apiProfileUserRightsGet";

export interface IProfilesSection {
  profile: IDynaProfile;
  rights: IDynaProfileUserRightsReader;
  availableProfiles: {
    profile: IDynaProfile;
    isOwner: boolean;
    rights: Record<string, true>;
  }[];
  isLoadingProfile: boolean;
  loadProfileError: Error | IDynaError | null;
}

export enum EProfileActions {
  INITIALIZE_CURRENT_USER_PROFILES = "DN-PROFILES--INITIALIZE_CURRENT_USER_PROFILES", // Payload: void
  SET_PROFILE = "DN-PROFILES--SET_PROFILE",                 // Payload: ISetProfilePayload
  SWITCH_PROFILE = "DN-PROFILES--SWITCH_PROFILE",           // Payload: string (is the profileId)
  SET_AVAIL_PROFILES = "DN-PROFILES--SET_AVAIL_PROFILES",   // Payload: ISetAvailProfilesPayload
  SET_LOAD_PROGRESS = "DN-PROFILES--SET_LOAD_PROGRESS",     // Payload: ISetProgressPayload
  UPDATE_PROFILE = "DN-PROFILES--UPDATE_PROFILE",           // Payload: IDynaProfile
}

interface ISetProfilePayload {
  profile: IDynaProfile;
  rights: IDynaProfileUserRight[];
}

type ISetAvailProfilesPayload = {
  profile: IDynaProfile;
  isOwner: boolean;
  rights: Record<string, true>;
}[];

interface ISetProgressPayload {
  isLoadingProfile: boolean;
  loadProfileError: Error | IDynaError | null;
}

const getProfileSectionInitialState = (): IProfilesSection => ({
  profile: getDefaultDynaProfile({}),
  rights: dynaProfileUserRightsReader([]),
  availableProfiles: [],
  isLoadingProfile: false,
  loadProfileError: null,
});

type TProfileIdPerUser = Record<string, string>;
const lastProfileByUser = new DynaLocalStorageData<TProfileIdPerUser>("last-profile-by-user", {});

type TReducerReturn = Partial<IProfilesSection> | undefined;

export const createDynaProfilesSection = ({store}: {
  store: ICreateStoreAPI<IAppState>;
}) => {
  const section = store.createSection<IProfilesSection>({
    section: 'profiles',
    initialState: getProfileSectionInitialState(),
    reducers: {
      [EAppAction.RESET_APP]: (): TReducerReturn => {
        return getProfileSectionInitialState();
      },
      [EUserAuthAction.SIGNED_IN]: ({dispatch}) => {
        dispatch<void>(EProfileActions.INITIALIZE_CURRENT_USER_PROFILES);
      },
      [EProfileActions.INITIALIZE_CURRENT_USER_PROFILES]: ({dispatch}) => {
        (async () => {
          try {
            const userId = store.state.userAuth.user.id;

            dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
              isLoadingProfile: true,
              loadProfileError: null,
            });

            const lastUsedProfileId = lastProfileByUser.data[userId];

            const loadDefaultUserProfile = async (): Promise<void> => {
              const {
                profile,
                rights,
              } = await apiDynaProfileDefaultForUserGet();

              dispatch<ISetProfilePayload>(EProfileActions.SET_PROFILE, {
                profile,
                rights,
              });
            };

            if (lastUsedProfileId) {
              // Try to load the last used profile by this user
              try {
                const {
                  profile,
                  rights,
                } = await apiDynaProfileForUserGet({profileId: lastUsedProfileId});
                if (
                  profile.deletedAt
                  || !dynaProfileUserRightsReader(rights).filter({valid: true}).has
                ) {
                  await loadDefaultUserProfile();
                }
                else {
                  dispatch<ISetProfilePayload>(EProfileActions.SET_PROFILE, {
                    profile,
                    rights,
                  });
                }
              }
              catch (e: any) {
                if (e.status === 403) {
                  // User has no anymore access to this profile, load the default one.
                  await loadDefaultUserProfile();
                }
                else {
                  throw e;
                }
              }

            }
            else {
              // No last used user profile, load the default one
              await loadDefaultUserProfile();
            }

            dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
              isLoadingProfile: false,
              loadProfileError: null,
            });

            const {profiles} = await apiDynaProfilesForUserWithAnyAccess({});
            dispatch<ISetAvailProfilesPayload>(EProfileActions.SET_AVAIL_PROFILES, profiles);
          }
          catch (e: any) {
            dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
              isLoadingProfile: false,
              loadProfileError: e,
            });
            console.error('Dyna Profiles Section, Setting profile after sign in failed: ', e.message, e);
          }
        })();
      },
      [EUserAuthAction.SIGN_OUT]: (): TReducerReturn => {
        return getProfileSectionInitialState();
      },
      [EProfileActions.SWITCH_PROFILE]: (
        {
          payload,
          dispatch,
        },
      ): void => {
        (async () => {
          const profileId: string = payload;
          const newProfile = section.state.availableProfiles.find(p => p.profile.id === profileId);
          if (!newProfile) {
            console.error(`Internal error 20231120115916: Profile with id ${profileId} is not available`);
            return;
          }

          dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
            isLoadingProfile: true,
            loadProfileError: null,
          });

          try {
            const rights = await loadAll<IDynaProfileUserRight>({
              loadSize: 10,
              onLoad: async (
                {
                  skip,
                  limit,
                },
              ) => {
                const {rights} = await apiProfileUserRightsGet({
                  profileId: newProfile.profile.id,
                  skip,
                  limit,
                });
                return rights;
              },
            });

            dispatch<ISetProfilePayload>(EProfileActions.SET_PROFILE, {
              profile: newProfile.profile,
              rights,
            });

            dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
              isLoadingProfile: false,
              loadProfileError: null,
            });
          }
          catch (e: any) {
            dispatch<ISetProgressPayload>(EProfileActions.SET_LOAD_PROGRESS, {
              isLoadingProfile: false,
              loadProfileError: e,
            });
          }
        })();
      },
      [EProfileActions.SET_PROFILE]: ({payload}): TReducerReturn => {
        const userId = store.state.userAuth.user.id;
        const {
          profile,
          rights,
        }: ISetProfilePayload = payload;
        lastProfileByUser.data[userId] = profile.id;
        lastProfileByUser.save();
        return {
          profile,
          rights: dynaProfileUserRightsReader(rights),
        };
      },
      [EProfileActions.SET_AVAIL_PROFILES]: ({payload}): TReducerReturn => {
        const availableProfiles: ISetAvailProfilesPayload = payload;
        return {availableProfiles};
      },
      [EProfileActions.UPDATE_PROFILE]: (
        {
          payload,
          state,
        },
      ): TReducerReturn => {
        const profile: IDynaProfile = payload;
        const newState: TReducerReturn = {};

        if (section.state.profile.id === profile.id) {
          newState.profile = {
            ...newState.profile,
            ...profile,
          };
        }

        newState.availableProfiles =
          state.availableProfiles
            .map(scanProfile => {
              if (scanProfile.profile.id !== profile.id) return scanProfile;
              return {
                ...scanProfile,
                profile,
              };
            });

        return newState;
      },
      [EProfileActions.SET_LOAD_PROGRESS]: ({payload}): TReducerReturn => {
        const {
          isLoadingProfile,
          loadProfileError,
        }: ISetProgressPayload = payload;
        return {
          isLoadingProfile,
          loadProfileError,
        };
      },
    },
  });

  return {
    get state() {
      return section.state;
    },
    actions: {
      initializeCurrentUserProfiles: () => {
        section.dispatch<void>(EProfileActions.INITIALIZE_CURRENT_USER_PROFILES);
      },
      updateProfile: async (profile: IDynaProfile): Promise<void> => {
        section.dispatch<IDynaProfile>(EProfileActions.UPDATE_PROFILE, profile);
      },
      switchProfile: (profileId: string): void => {
        section.dispatch<string>(EProfileActions.SWITCH_PROFILE, profileId);
      },
    },
  };
};
