import {useState} from "react";
import {IDynaError} from "dyna-error";
import {TObject} from "utils-library/dist/commonJs/typescript";

export interface IUseSaveDataArgs<TData extends TObject> {
  save: (data: TData) => Promise<void>;
}

export interface IUseSaveDataApi<TData extends TObject> {
  isSaving: boolean;
  error: IDynaError | null;
  save: (data: TData) => Promise<void>;
}

export const useSaveData = <TData extends TObject>(
  {save}: IUseSaveDataArgs<TData>,
): IUseSaveDataApi<TData> => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<IDynaError | null>(null);

  return {
    isSaving,
    error,
    save: async (data: TData): Promise<void> => {
      try {
        setIsSaving(true);
        setError(null);
        await save(data);
      }
      catch (e: any) {
        setError(e);
        throw e;
      }
      finally {
        setIsSaving(false);
      }
    },
  };
};
