import {IDynaCMSArticleHeading} from "server-app";

import {Box} from "ui-components/dist/Box";
import {
  FlexItemMax,
} from "ui-components/dist/FlexContainer";
import {ContainerSizeMonitor} from "ui-components/dist/ContainerSizeMonitor";
import {FlexContainerResponsive} from "ui-components/dist/FlexContainer";
import {CenterContainer} from "ui-components/dist/CenterContainer";

import {ArticleDateViewer} from "../components/ArticleDateViewer";

import {DynaCMSLabel} from "../../../../commons/DynaCMSLabel";

import {BlockViewer} from "../../../../block-viewers/BlockViewer";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

export interface ICleanHeadingViewerProps {
  h: number;  // Heading number for h1, h2, etc..
  article: IDynaCMSArticleHeading;
  bigTitle: boolean;
  target: 'list' | 'view';
  orientation: 'horizontal' | 'vertical';
  textAlign?: 'left' | 'center' | 'right';
  negative?: ECleanHeadingNegative;
  order: 'photo-text' | 'text-photo';
  showPhotoTexts: boolean;
  showPhotoCredits: boolean;
  showPhotoPortraitSmaller: boolean;
  showVideoPreviewOnly: boolean;
  showDescription: boolean;
  showDate: boolean;
  showDateIcon: boolean;
  showUpdatedAt: boolean;
}

enum EBreakpoints {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum ECleanHeadingNegative {
  ALL = "ALL",
  TEXTS = "TEXTS",
}

export const CleanHeadingViewer = (props: ICleanHeadingViewerProps): JSX.Element => {
  const {
    h,
    article,
    article: {
      title,
      subtitle,
      label,
      description,
      coverMediaBlockCopy,
    },
    bigTitle,
    target,
    orientation,
    textAlign,
    negative,
    order,
    showPhotoTexts,
    showPhotoCredits,
    showPhotoPortraitSmaller,
    showVideoPreviewOnly,
    showDescription,
    showDate,
    showDateIcon,
    showUpdatedAt,
  } = props;

  const sxNegative: SxProps<Theme> = {
    color: theme => theme.palette.background.default,
    background: theme => theme.palette.text.primary,
  };

  return (
    <Box dataComponentName="CleanHeadingViewer">
      <ContainerSizeMonitor<EBreakpoints>
        breakpoints={{
          [EBreakpoints.XSMALL]: 120,
          [EBreakpoints.SMALL]: 320,
          [EBreakpoints.MEDIUM]: 640,
          [EBreakpoints.LARGE]: 860,
        }}
        render={
          (
            {
              isInAndBelow,
              isInAndAbove,
            },
          ) => {
            const titleContent = (
              <Box
                component={`h${h}` as any}
                dataComponentName="HeadingTitle"
                sx={{
                  fontFamily: 'inherit !important',
                  fontSize:
                    bigTitle
                      ? 32 + 'px !important'
                      : isInAndBelow(EBreakpoints.MEDIUM)
                        ? 18 + 'px !important'
                        : 22 + 'px !important',
                  letterSpacing: 'inherit !important',
                  textAlign: textAlign ? textAlign : undefined,
                  margin: 0,
                  padding: theme => theme.spacing(1),
                  ...(negative === ECleanHeadingNegative.TEXTS ? sxNegative : undefined),
                }}
                children={title}
              />
            );

            const subtitleContent = (
              <Box
                component="p"
                show={!!subtitle && isInAndAbove(EBreakpoints.SMALL)}
                sx={{
                  margin: 0,
                  padding: theme => theme.spacing(1),
                  fontWeight: 'bold',
                  fontSize: theme => theme.typography.fontSize * 1,
                  textAlign: textAlign ? textAlign : undefined,
                  ...(negative === ECleanHeadingNegative.TEXTS ? sxNegative : undefined),
                }}
              >
                {subtitle}
              </Box>
            );

            const labelContent = (
              label
                ? (
                  <DynaCMSLabel
                    show={isInAndAbove(EBreakpoints.SMALL)}
                    children={label}
                  />
                )
                : null
            );

            const coverImageContent = (() => {
              if (isInAndBelow(EBreakpoints.XSMALL)) return null;
              if (!coverMediaBlockCopy) return null;
              return (
                <Box sx={{marginBottom: theme => theme.spacing(0.5)}}>
                  <BlockViewer
                    block={coverMediaBlockCopy}
                    showPhotoTexts={showPhotoTexts}
                    showPhotoCredits={showPhotoCredits}
                    showPhotoPortraitSmaller={showPhotoPortraitSmaller}
                    showVideoPreviewOnly={showVideoPreviewOnly}
                  />
                </Box>
              );
            })();

            const descriptionContent = (
              <Box
                component="p"
                dataComponentName="descriptionContent"
                show={
                  showDescription
                  && isInAndAbove(EBreakpoints.SMALL)
                }
                sx={{
                  maxWidth: 480,
                  fontWeight: 'bold',
                  m: 0,
                  ml: theme => theme.spacing(4),
                  padding: theme => theme.spacing(1),
                  fontSize: theme => theme.typography.fontSize * 1.5,
                  textAlign: textAlign ? textAlign : undefined,
                  ...(negative === ECleanHeadingNegative.TEXTS ? sxNegative : undefined),
                }}
              >
                <em>
                  {description}
                </em>
              </Box>
            );

            // This is the horizontal version
            if (orientation === "horizontal" && !isInAndBelow(EBreakpoints.MEDIUM)) {
              return (
                <FlexContainerResponsive
                  sx={negative === ECleanHeadingNegative.ALL ? sxNegative : undefined}
                  spacing={2}
                  verticalOnMobile
                  reverseOrder={order === "photo-text"}
                >
                  <FlexItemMax flex={1}>
                    <CenterContainer fullHeight>
                      <Box
                        show={!!labelContent}
                        sx={{textAlign: order === "text-photo" ? 'left' : 'right'}}
                      >
                        {labelContent}
                      </Box>
                      {titleContent}
                      {subtitleContent}
                      {descriptionContent}
                      <ArticleDateViewer
                        articleHeading={article}
                        target={target}
                        show={showDate}
                        showIcon={showDateIcon}
                        showUpdatedAt={showUpdatedAt}
                      />
                    </CenterContainer>
                  </FlexItemMax>
                  <FlexItemMax
                    show={!!coverImageContent}
                    flex={2}
                  >
                    {coverImageContent}
                  </FlexItemMax>
                </FlexContainerResponsive>
              );
            }

            // This is the vertical version
            return (
              <Box
                sx={{
                  textAlign,
                  ...(negative === ECleanHeadingNegative.ALL ? sxNegative : undefined),
                }}
              >
                {order === "photo-text" && coverImageContent}
                {labelContent}
                {titleContent}
                {subtitleContent}
                {order === "text-photo" && coverImageContent}
                {descriptionContent}
                <ArticleDateViewer
                  articleHeading={article}
                  target={target}
                  show={showDate}
                  showIcon={showDateIcon}
                  showUpdatedAt={showUpdatedAt}
                />
              </Box>
            );
          }}
      />
    </Box>
  );
};
