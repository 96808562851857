import {connect} from "react-dynadux";

import {
  AppVersion as Component,
} from "./AppVersion";
export const AppVersion = connect(Component);

export type {
  IAppVersionProps,
} from "./AppVersion";
