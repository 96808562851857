import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaCMSSettingsProps} from "./DynaCMSSettings";

const DynaCMSSettingsLazy = lazy(() => import("./DynaCMSSettings"));

export const DynaCMSSettings = connect((props: IDynaCMSSettingsProps) => (
  <LazyLoadComponent>
    <DynaCMSSettingsLazy {...props}/>
  </LazyLoadComponent>
));
