import {Box} from "ui-components/dist/Box";

import {useTheme} from "ui-components/dist/ThemeProvider";
import {Typography} from "ui-components/dist/Typography";
import {TheGirlGunsComLogo} from "../TheGirlGunsComLogo";

export const TheGirlGunsComFooter = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{borderTop: `4px solid ${theme.palette.text.primary}`}}
      >
        <TheGirlGunsComLogo height={42}/>
        <Typography>Copyright &copy; 2022-{(new Date()).getFullYear()}</Typography>
      </Box>
    </Box>
  );
};
