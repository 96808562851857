import * as React from "react";
import {useEffect} from "react";

import {IMuiIcon} from "utils-library/dist/commonJs/typescript";

import {Box} from "../Box";
import {UnsortedList} from "../UnsortedList";
import {
  Link,
  ELinkColor,
} from "../Link";
import {ELinkUnderline} from "../Link";
import {useBreakpointDevice} from "../useBreakpointDevice";

import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import PinterestIcon from '@mui/icons-material/Pinterest';
import PocketIcon from '@mui/icons-material/Shield'; // Todo: fix this icon
import RedditIcon from '@mui/icons-material/Reddit';
import WordpressIcon from '@mui/icons-material/Book'; // Todo: Fix this icon
import Pinboard from '@mui/icons-material/FiberPin'; // Todo: fix this icon
import EMailIcon from '@mui/icons-material/Mail';

export interface IShareButtonsProps {
  label?: string;
  shareUrl?: string;                // Default is the current addredd
  webSiteName?: string;             // Default is the host (without www)
  webSiteDescription?: string;      // Default is current window title
  websiteLogoPreview?: string;      // For Pinterest and WordPress only
  websiteTwitterAccount?: string;   // For Twitter only (optional), should start with "@"
  shareButtons: Array<EShareButtons>;
}

export enum EShareButtons {
  NATIVE_SHARE = "NATIVE_SHARE",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  GOOGLE_PLUS = "GOOGLE_PLUS",
  PINTEREST = "PINTEREST",
  POCKET = "POCKET",
  REDDIT = "REDDIT",
  LINKEDIN = "LINKEDIN",
  WORDPRESS = "WORDPRESS",
  PINBOARD = "PINBOARD",
  EMAIL = "EMAIL",
}

export const ShareButtons = (props: IShareButtonsProps): JSX.Element => {
  const {
    label,
    shareUrl = window.location.href,
    webSiteName = window.location.host
      .split('.')
      .filter(p => p !== 'www')
      .join('.'),
    webSiteDescription = document.querySelector('title')?.innerText || '',
    websiteLogoPreview,
    websiteTwitterAccount,
    shareButtons = [
      EShareButtons.FACEBOOK,
      EShareButtons.TWITTER,
      EShareButtons.LINKEDIN,
      EShareButtons.EMAIL,
    ],
  } = props;

  const {isMobile} = useBreakpointDevice();

  const encodedShareUrl: string = encodeURI(shareUrl);
  const encodedName: string = encodeURI(webSiteName);
  const encodedDescription: string = encodeURI(webSiteDescription);

  // Check if the props are correct
  useEffect(() => {
    if (!webSiteDescription && !document.querySelector('title')?.innerText) {
      console.error('ShareButtons: webSiteDescription and <title> is missing so cannot resolve fallback for webSiteDescription that is required');
    }
    shareButtons
      .forEach(socialButton => {
        const requiredProps = buttonsSetup[socialButton].requiredProps || [];
        requiredProps
          .forEach(requiredProp => {
            if (props[requiredProp] === undefined) console.error(`ShareButtons: Property ${requiredProp} is required for ESocialButtons.${socialButton}`);
          });
      });
  }, []);

  if (shareButtons.includes(EShareButtons.TWITTER) && !websiteTwitterAccount) console.error('ShareButtons: Property `websiteTwitterAccount` is required for ESocialButtons.TWITTER');

  const buttonsSetup: Record<
    EShareButtons,
    {
      name: string;
      title: string;
      href?: string;
      Icon: IMuiIcon;
      showTitle?: boolean;
      visible?: boolean;
      requiredProps?: Array<keyof IShareButtonsProps>;
      onClick?: () => void;
    }
  > = {
    [EShareButtons.NATIVE_SHARE]: {
      name: 'Native share',
      title: 'Share it',
      Icon: ShareIcon,
      visible: !!navigator.share,
      showTitle: true,
      onClick: () => {
        // Help https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
        if (!navigator.share) return;
        navigator.share({
          url: window.location.href,
          text: document.querySelector('title')?.innerText,
          title: document.querySelector('title')?.innerText,
        });
      },
    },
    [EShareButtons.FACEBOOK]: {
      name: 'Facebook',
      title: 'Share on Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}&quote=${encodedName}`,
      Icon: FacebookIcon,
    },
    [EShareButtons.TWITTER]: {
      name: 'Twitter',
      title: 'Tweet it',
      href: `https://twitter.com/intent/tweet?source=${encodedShareUrl}&text=${encodedName}:%20${encodedShareUrl}${websiteTwitterAccount ? `&via=${websiteTwitterAccount.substring(1)}` : ""}`,
      Icon: TwitterIcon,
      requiredProps: ['websiteTwitterAccount'],
    },
    [EShareButtons.GOOGLE_PLUS]: {
      name: 'Google+',
      title: 'Share on Google+',
      href: `https://plus.google.com/share?url=${encodedShareUrl}`,
      Icon: GoogleIcon,
    },
    [EShareButtons.PINTEREST]: {
      name: 'Pinterest',
      title: 'Pin it',
      href: `https://pinterest.com/pin/create/button/?url=${encodedShareUrl}&media=${websiteLogoPreview}&encodedDescription=${encodedDescription}`,
      Icon: PinterestIcon,
      requiredProps: ['websiteLogoPreview'],
    },
    [EShareButtons.POCKET]: {
      name: 'Pocket',
      title: 'Add to Pocket',
      href: `https://getpocket.com/save?url=${encodedShareUrl}&title=${encodedName}`,
      Icon: PocketIcon,
    },
    [EShareButtons.REDDIT]: {
      name: 'Reddit',
      title: 'Submit to Reddit',
      href: `https://www.reddit.com/submit?url=${encodedShareUrl}&title=${encodedName}`,
      Icon: RedditIcon,
    },
    [EShareButtons.LINKEDIN]: {
      name: 'Linkedin',
      title: 'Share on LinkedIn',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedShareUrl}&title=${encodedName}&summary=${encodedDescription}&source=${encodedShareUrl}`,
      Icon: RedditIcon,
    },
    [EShareButtons.WORDPRESS]: {
      name: 'WordPress',
      title: 'Publish on WordPress',
      href: `https://wordpress.com/press-this.php?u=${encodedShareUrl}&quote=${encodedName}&s=${encodedDescription}&i=${websiteLogoPreview}`,
      Icon: WordpressIcon,
      requiredProps: ['websiteLogoPreview'],
    },
    [EShareButtons.PINBOARD]: {
      name: 'Pinboard',
      title: 'Save to Pinboard',
      href: `https://pinboard.in/popup_login/?url=${encodedShareUrl}&title=${encodedName}&encodedDescription=${encodedDescription}`,
      Icon: Pinboard,
    },
    [EShareButtons.EMAIL]: {
      name: 'Email',
      title: 'Send email',
      href: `mailto:?subject=${encodedDescription}&body=${encodedDescription}:%20${encodedShareUrl}`,
      Icon: EMailIcon,
    },
  };

  return (
    <Box dataComponentName="ShareButtons">
      <UnsortedList inline>
        <Box
          inline={
            isMobile
              ? label
                ? false
                : true
              : true
          }
          sx={{
            fontSize: '22px',
            fontWeight: 'bold',
            position: 'relative',
            top: '-12px',
            padding: '0 16px',
          }}
        >
          <ShareIcon
            sx={{
              position: 'relative',
              top: '5px',
              marginRight: '8px',
              width: 28,
              height: 28,
            }}
          />
          {label}
        </Box>
        {...shareButtons
          .map(socialButton => buttonsSetup[socialButton])
          .map(
            (
              {
                href,
                name,
                title,
                Icon,
                visible = true,
                showTitle = false,
                onClick,
              },
              index,
            ) => {
              const buttonContent = (
                <>
                  <Icon
                    sx={{
                      margin: '8px 8px 4px',
                      width: 32,
                      height: 32,
                      opacity: 0.8,
                    }}
                    titleAccess={name}
                  />
                  {showTitle && (
                    <Box
                      inline
                      sx={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        paddingRight: '8px',
                        position: 'relative',
                        top: '-14px',
                      }}
                    >
                      {title}
                    </Box>
                  )}
                </>
              );

              return (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid',
                    margin: '2px',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  show={visible}
                  inline
                  onClick={onClick}
                >
                  {href && (
                    <Link
                      href={href}
                      target={onClick ? undefined : "_blank"}
                      title={title}
                      rel="noreferrer"
                      color={ELinkColor.INHERIT}
                      underline={ELinkUnderline.NONE}
                    >
                      {buttonContent}
                    </Link>
                  )}
                  {!href && buttonContent}
                </Box>
              );
            },
          )
        }
      </UnsortedList>
    </Box>
  );
};
