import {connect} from "react-dynadux";

import {
  ArticleViewerEva as Component,
} from "./ArticleViewerEva";
export const ArticleViewerEva = connect(Component);

export type {
  IArticleViewerEvaProps,
} from "./ArticleViewerEva";
