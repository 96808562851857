import * as React from "react";

import {EDynaAdOrientation} from "../../../interfaces";
import {AdViewerResponsiveByHTML} from "../../../utils";

export interface IAdLinkWiseSephoraProps {
  show?: boolean;
  orientation?: EDynaAdOrientation;
}

// Affiliate: https://affiliate.linkwi.se/en/AProgramCreatives.html?id=13026

export const AdLinkWiseSephora: React.FC<IAdLinkWiseSephoraProps> = (
  {
    show,
    orientation,
  },
) => (
  <AdViewerResponsiveByHTML
    show={show}
    orientation={orientation}
    ads={[
      {html: '<iframe id="lkws_66ae545916a33" name="lkws_66ae545916a33" src="//go.linkwi.se/delivery/ih.php?cn=13026-1770&amp;an=CD26445&amp;target=_blank&amp;" style="width:120px;height:600px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae5459171a0" name="lkws_66ae5459171a0" src="//go.linkwi.se/delivery/ih.php?cn=13026-1771&amp;an=CD26445&amp;target=_blank&amp;" style="width:160px;height:600px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae5459179d6" name="lkws_66ae5459179d6" src="//go.linkwi.se/delivery/ih.php?cn=13026-1772&amp;an=CD26445&amp;target=_blank&amp;" style="width:250px;height:250px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae5459183b2" name="lkws_66ae5459183b2" src="//go.linkwi.se/delivery/ih.php?cn=13026-1773&amp;an=CD26445&amp;target=_blank&amp;" style="width:300px;height:250px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae545918b32" name="lkws_66ae545918b32" src="//go.linkwi.se/delivery/ih.php?cn=13026-1774&amp;an=CD26445&amp;target=_blank&amp;" style="width:300px;height:600px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae54591944a" name="lkws_66ae54591944a" src="//go.linkwi.se/delivery/ih.php?cn=13026-1775&amp;an=CD26445&amp;target=_blank&amp;" style="width:336px;height:280px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae545919adb" name="lkws_66ae545919adb" src="//go.linkwi.se/delivery/ih.php?cn=13026-1776&amp;an=CD26445&amp;target=_blank&amp;" style="width:468px;height:60px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae54591a151" name="lkws_66ae54591a151" src="//go.linkwi.se/delivery/ih.php?cn=13026-1777&amp;an=CD26445&amp;target=_blank&amp;" style="width:728px;height:90px" scrolling="no" frameborder="0"></iframe>'},
    ]}
  />
);
