import {Box} from "ui-components/dist/Box";

import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {DynaCMSCountriesLanguageSelect} from "../../../../../core/dyna-cms/public-components";

import {AdoreGrSocialButtons} from "../../../components/AdoreGrSocialButtons";
import {AdoreGrContactLinks} from "../../../components/AdoreGrContactLinks";

export interface IPopUpMenuAdditionalProps {
  onClick?: () => void;
}

export const PopUpMenuAdditional = (props: IPopUpMenuAdditionalProps): JSX.Element => {
  const {onClick} = props;
  const {isMobile} = useBreakpointDevice();

  return (
    <Box
      sx={{'&>*': {marginBottom: theme => theme.spacing(3)}}}
    >
      <AdoreGrSocialButtons
        textAlign={isMobile ? 'center' : 'left'}
        onClick={onClick}
      />
      <AdoreGrContactLinks
        textAlign={isMobile ? 'center' : 'left'}
        onClick={onClick}
      />
      <DynaCMSCountriesLanguageSelect
        showLanguages={false}
        showCountries={false}
      />
    </Box>
  );
};
