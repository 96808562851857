import {
  API_PATH_apiAuthLogoutGet,
  IApiAuthLogoutGet,
} from "server-app";

import {getOnline} from "utils-library/dist/commonJs/web";

import {apiFetch} from "../../../api/apiFetch";
import {localstorageSignState} from "./localstorageSignState";

export const apiAuthLogoutGet = async (networkSignOff: boolean = true): Promise<void> => {
  if (getOnline()) {
    // Online
    localstorageSignState.data.offlineLogoff = false;
    localstorageSignState.save();
    if (networkSignOff) {
      try {
        await apiFetch.request<null, void, IApiAuthLogoutGet>({
          path: API_PATH_apiAuthLogoutGet,
          ignore401: true,
        });
      }
      catch (e) {
        const error = e as any;
        // Propagate all errors except 401
        // 401 could be done in case of offline logoff or expired token
        if (error.status !== 401) throw e;
      }
    }
  }
  else {
    // Offline
    localstorageSignState.data.lastUserLogin = null;
    localstorageSignState.data.offlineLogoff = true;
    localstorageSignState.save();
  }
};
