import {connect} from "react-dynadux";

import {
  DynaCMSCountriesLanguageSelect as Component,
} from "./DynaCMSCountriesLanguageSelect";
export const DynaCMSCountriesLanguageSelect = connect(Component);

export type {
  IDynaCMSCountriesLanguageSelectProps,
} from "./DynaCMSCountriesLanguageSelect";
