import * as React from "react";
import {DynaProfileUserRightsAssigmentGrid} from "../../public-components";
import {EDynaProfileUserRight} from "server-app";

export interface IProfileUserRightsProps {
}

const ProfileUserRights: React.FC<IProfileUserRightsProps> = () => {
  return (
    <DynaProfileUserRightsAssigmentGrid
      manageRights={[
        {
          right: EDynaProfileUserRight.OWNER,
          label: 'Owner',
          description: "Profile owner",
        },
        {
          right: EDynaProfileUserRight.CHANGE_PROFILE,
          label: 'Change profile',
          description: "Edit and change profile's details",
        },
        {
          right: EDynaProfileUserRight.ADD_USER,
          label: 'Add user',
          description: "Add new users in the profile",
        },
        {
          right: EDynaProfileUserRight.REMOVE_USER,
          label: 'Remove user',
          description: "Remove users from this profile",
        },
      ]}
      manageDelegateRights
    />
  );
};

export default ProfileUserRights;
