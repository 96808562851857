import {Box} from "ui-components/dist/Box";
import {Condition} from "ui-components/dist/Condition";

import {
  ECompany,
  appConfig,
} from "./appConfig";

import {NetFashionLogo} from "../../../companies/net-fashion/environment/NetFashionLogo";
import {AdoreGrLogo} from "../../../companies/adore-gr/environment/AdoreGrLogo";
import {TheGirlGunsComLogo} from "../../../companies/tgg-com/environment/TheGirlGunsComLogo";

export const AppLogo = (): JSX.Element => {
  return (
    <>
      <Condition if={appConfig.company === ECompany.NET_FASHION}>
        <NetFashionLogo fontSize={34}/>
      </Condition>
      <Condition if={appConfig.company === ECompany.ADORE_GR}>
        <AdoreGrLogo height={48}/>
      </Condition>
      <Condition if={appConfig.company === ECompany.THE_GIRL_GUNS}>
        <TheGirlGunsComLogo height={32}/>
      </Condition>
      <Condition if={appConfig.company === ECompany.UNKNOWN}>
        <Box>C?</Box>
      </Condition>
    </>
  );
};
