import {
  API_PATH_ApiProfileUserRightsGet,
  IApiProfileUserRightsGetRequest,
  IApiProfileUserRightsGetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiProfileUserRightsGet = async (args: IApiProfileUserRightsGetRequest): Promise<IApiProfileUserRightsGetResponse> => {
  return apiFetch.request<IApiProfileUserRightsGetRequest, null, IApiProfileUserRightsGetResponse>({
    method: 'GET',
    path: API_PATH_ApiProfileUserRightsGet,
    query: args,
  });
};
