import {connect} from "react-dynadux";

import {
  ViewerBody as Component,
} from "./ViewerBody";
export const ViewerBody = connect(Component);

export type {
  IViewerBodyProps,
} from "./ViewerBody";
