import * as React from "react";

import {
  asyncCalls,
  getCache,
  setCache,
} from "utils-library/dist/commonJs/utils";

import {Box} from "../Box";

import {ISlide} from "./interfaces";

import {SlideViewer} from "./components/SlideViewer";
import {Thumbnails} from "./components/Thumbnails";

export interface ICarouselProps {
  id: string;
  dataComponentName?: string;
  slides: ISlide[];
  animation?: number; // Default is 250
  preload?: number;   // Default is 3
  onChange?: (index: number) => void;
}

interface ICarouselState {
  index: number;
  performAnimation: boolean;
}

export class Carousel extends React.Component<ICarouselProps, ICarouselState> {
  public state: ICarouselState = {
    index: 0,
    performAnimation: false,
  };

  public componentDidMount() {
    const {id} = this.props;
    const {index} = this.state;

    if (getCache('carousel', id) === undefined) {
      setCache('carousel', id, index);
    }

    const cachedShowIndex = getCache('carousel', id);
    asyncCalls(
      done => {
        if (cachedShowIndex !== index) {
          this.setState({index: cachedShowIndex}, done);
          return;
        }
        done();
      },
      done => setTimeout(done, 100),
      requestAnimationFrame,
      done => this.setState({performAnimation: true}, done),
    );
  }

  private handleChange = (index: number): void => {
    const {
      id, onChange,
    } = this.props;
    setCache('carousel', id, index);
    this.setState({index});
    onChange && onChange(index);
  };

  public render(): JSX.Element {
    const {
      dataComponentName,
      slides,
      animation: animationDuration = 250,
      preload = 3,
    } = this.props;
    const {
      index,
      performAnimation,
    } = this.state;

    const applyAnimationDuration = performAnimation ? animationDuration : 0;

    return (
      <Box
        sx={{
          height: '100%',
          display: "flex",
          flexDirection: 'column',
        }}
        dataComponentName={[dataComponentName, "Carousel"]}
      >
        <SlideViewer
          sx={{flex: 'auto'}}
          index={index}
          slides={slides}
          animation={applyAnimationDuration}
          preload={preload}
          onChange={this.handleChange}
        />
        <Thumbnails
          sx={{paddingBottom: 19}} // This the size of the scrollbar
          index={index}
          slides={slides}
          animation={applyAnimationDuration}
          onChange={this.handleChange}
        />
      </Box>
    );
  }
}
