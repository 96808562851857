import {connect} from "react-dynadux";

import {
  AdoreGrSocialButtons as Component,
} from "./AdoreGrSocialButtons";
export const AdoreGrSocialButtons = connect(Component);

export type {
  IAdoreGrSocialButtonsProps,
} from "./AdoreGrSocialButtons";
