import {
  DynaCMSGroupLinks,
  EDynaCMSFontFamily,
} from "../../../../core/dyna-cms/public-components";

import {routeAdoreContact} from "../../routes/static/routeAdoreContact";
import {routeAdoreGrMarketingMainPage} from "../../routes/content-groups/routeAdoreGrMarketingMainPage";
import {routeAdoreContactAdsDepartment} from "../../routes/static/routeAdoreContactAdsDepartment";
import {routeAdoreContactBusiness} from "../../routes/static/routeAdoreContactBusiness";
import {routeAdoreContactContentTeam} from "../../routes/static/routeAdoreContactContentTeam";

export interface IAdoreGrContactLinksProps {
  textAlign?: 'left' | 'center' | 'right';
  onClick?: () => void;
}

export const AdoreGrContactLinks = (props: IAdoreGrContactLinksProps): JSX.Element => {
  const {
    textAlign,
    onClick,
  } = props;

  return (
    <DynaCMSGroupLinks
      title="CONTACT US"
      textAlign={textAlign}
      titleFont={EDynaCMSFontFamily.F131_ARTISTIC_LINES_MULTI_MONOTON}
      linkPerLine
      links={[
        {
          label: 'Επικοινωνία',
          link: routeAdoreContact.getRoutePath(),
        },
        {
          label: 'Marketing',
          link: routeAdoreGrMarketingMainPage.getRoutePath(),
        },
        {
          label: 'Διαφημίσεις',
          link: routeAdoreContactAdsDepartment.getRoutePath(),
        },
        {
          label: 'Business',
          link: routeAdoreContactBusiness.getRoutePath(),
        },
        {
          label: 'Συντάκτες',
          link: routeAdoreContactContentTeam.getRoutePath(),
        },
      ]}
      onClick={onClick}
    />
  );
};
