import {
  API_PATH_ApiDynaProfilePost,
  IApiDynaProfilePostRequest,
  IApiDynaProfilePostResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilePost = async (args: IApiDynaProfilePostRequest): Promise<IApiDynaProfilePostResponse> => {
  return apiFetch.request<null, IApiDynaProfilePostRequest, IApiDynaProfilePostResponse>({
    method: 'POST',
    path: API_PATH_ApiDynaProfilePost,
    body: args,
  });
};
