import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IBackOfficePageProps} from "./BackOfficePage";

const BackOfficePageLazy = lazy(() => import("./BackOfficePage"));

export const BackOfficePage = (props: IBackOfficePageProps) => (
  <LazyLoadComponent>
    <BackOfficePageLazy {...props}/>
  </LazyLoadComponent>
);
