import {
  IDynaCMSArticleHeading,
  EDynaCMSHeadingStyle,
} from "server-app";

import {
  Alert,
  EAlertType,
} from "ui-components/dist/Alert";
import {Box} from "ui-components/dist/Box";

import {IAppStore} from "../../../../../state/IAppStore";

import {
  DynaCMSFont,
  DynaCMSArticleLink,
} from "../../../public-components";

import {
  CleanHeadingViewer,
  ECleanHeadingNegative,
} from "./components/CleanHeadingViewer";
import {PhotoTileHeadingViewer} from "./components/PhotoTileHeadingViewer";
import {ExceptionalHeadingViewer} from "./components/ExceptionalHeadingViewer";
import {EExceptionalNegative} from "./components/ExceptionalHeadingViewer";
import {TinySmallHeadingViewer} from "./components/TinySmallHeadingViewer";

export interface IHeaderViewerEvaProps {
  store: IAppStore;
  h: number;  // Heading number for h1, h2, etc..
  article: IDynaCMSArticleHeading;
  bigTitle: boolean;
  textAlign?: 'left' | 'center' | 'right';
  target: 'list' | 'view';
  showPhotoTexts?: boolean;
  showPhotoCredits?: boolean;
  showPhotoPortraitSmaller?: boolean;
  showVideoPreviewOnly?: boolean;
  showDescription?: boolean;
}

const deprecatesSubstitutions: Record<string, EDynaCMSHeadingStyle> = {
  CLEAN_RICH_HORIZONTAL_PHOTO_TEXT: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
  CLEAN_RICH_HORIZONTAL_TEXT_PHOTO: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO,
  CLEAN_RICH_VERTICAL_PHOTO_TEXT: EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT,
  CLEAN_RICH_VERTICAL_TEXT_PHOTO: EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_TEXT_PHOTO,
};

export const HeaderViewerEva = (props: IHeaderViewerEvaProps): JSX.Element | null => {
  const {
    store: {
      dynaCMS: {
        state: {
          settings: {
            defaultArticleFontFamilyGeneral,
            defaultArticleFontFamilyHeader,
          },
        },
      },
    },
    h,
    article,
    article: {
      fontFamilyGeneral: articleFontFamily,
      fontFamilyHeader,
      headingStyleList,
      headingStyleView,
    },
    bigTitle,
    textAlign,
    target,
    showPhotoTexts = false,
    showPhotoCredits = false,
    showPhotoPortraitSmaller = false,
    showVideoPreviewOnly = true,
    showDescription = false,
  } = props;

  const style =
    target === 'list'
      ? headingStyleList
      : headingStyleView;

  if (target === "view" && style === EDynaCMSHeadingStyle.HIDDEN) {
    return (
      <Box hidden>
        <h1>{article.title}</h1>
      </Box>
    );
  }

  const renderHeader: Record<EDynaCMSHeadingStyle, () => JSX.Element | null> = {
    [EDynaCMSHeadingStyle.HIDDEN]: () => null,
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        order="photo-text"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_ALL]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        order="photo-text"
        negative={ECleanHeadingNegative.ALL}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_TEXTS]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        order="photo-text"
        negative={ECleanHeadingNegative.TEXTS}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        order="text-photo"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_ALL]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        negative={ECleanHeadingNegative.ALL}
        order="text-photo"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_TEXTS]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="horizontal"
        textAlign={textAlign}
        negative={ECleanHeadingNegative.TEXTS}
        order="text-photo"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="photo-text"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="photo-text"
        negative={ECleanHeadingNegative.ALL}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_TEXTS]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="photo-text"
        negative={ECleanHeadingNegative.TEXTS}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_TEXT_PHOTO]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="text-photo"
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_ALL]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="text-photo"
        negative={ECleanHeadingNegative.ALL}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_TEXTS]: () => (
      <CleanHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        orientation="vertical"
        textAlign={textAlign}
        order="text-photo"
        negative={ECleanHeadingNegative.TEXTS}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.TINY_SMALL_PHOTO_TEXT]: () => (
      <TinySmallHeadingViewer
        article={article}
        showPhoto
      />
    ),
    [EDynaCMSHeadingStyle.PHOTO_TILE]: () => (
      <PhotoTileHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        textAlign={textAlign}
        showPhotoTexts={showPhotoTexts}
        showPhotoCredits={showPhotoCredits}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        showDescription={showDescription}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.EXCEPTIONAL]: () => (
      <ExceptionalHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        showDescription={showDescription}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        textAlign={textAlign}
        showPhotoCredits={showPhotoCredits}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.EXCEPTIONAL_NEGATIVE_ALL]: () => (
      <ExceptionalHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        negative={EExceptionalNegative.ALL}
        showDescription={showDescription}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        textAlign={textAlign}
        showPhotoCredits={showPhotoCredits}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
    [EDynaCMSHeadingStyle.EXCEPTIONAL_NEGATIVE_TEXTS]: () => (
      <ExceptionalHeadingViewer
        h={h}
        article={article}
        target={target}
        bigTitle={bigTitle}
        negative={EExceptionalNegative.TEXTS}
        showDescription={showDescription}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
        textAlign={textAlign}
        showPhotoCredits={showPhotoCredits}
        showDate
        showDateIcon={target === "view"}
        showUpdatedAt={target === "view"}
      />
    ),
  };

  const headerContent = (() => {
    const render = renderHeader[style];
    if (render) return render();

    if (Object.keys(deprecatesSubstitutions).includes(style)) return renderHeader[deprecatesSubstitutions[style]]();

    return (
      <Alert
        type={EAlertType.WARNING}
        title={`Unknown heading style [${style}]`}
      >
        Looks like the client app is outdated or the block type is not supported anymore.
      </Alert>
    );
  })();

  if (target === 'view') {
    return (
      <DynaCMSFont
        dataComponentName="HeaderViewerEva--view"
        font={[
          defaultArticleFontFamilyGeneral,
          defaultArticleFontFamilyHeader,
          articleFontFamily,
          fontFamilyHeader,
        ]}
      >
        {headerContent}
      </DynaCMSFont>
    );
  }

  return (
    <Box component="article">
      <DynaCMSFont
        dataComponentName="HeaderViewerEva--list"
        font={[
          defaultArticleFontFamilyGeneral,
          defaultArticleFontFamilyHeader,
          articleFontFamily,
          fontFamilyHeader,
        ]}
      >
        <DynaCMSArticleLink article={article}>
          {headerContent}
        </DynaCMSArticleLink>
      </DynaCMSFont>
    </Box>
  );
};
