import {ICreateStoreAPI} from "dynadux";
import {DynaLocalStorageData} from "dyna-local-storage";
import {IDynaError} from "dyna-error";

import {areValuesEqual} from "utils-library/dist/commonJs/utils";

import {
  IDynaCMSSettingsBasic,
  getDefaultDynaCMSSettingsBasic,
} from "server-app";

import {apiDynaCMSSettingsBasicGet} from "../api/settings/apiDynaCMSSettingsBasicGet";

import {appConfig} from "../../application/config/appConfig";
import {EAppAction} from "../../application/state/appSection";

export interface IDynaCMSSection {
  settings: IDynaCMSSettingsBasic;
  isLoading: boolean;
  loadSettingsError: IDynaError | null;
  countryIds: string[];
  languageId: string;
}

enum EDynaCMSAction {
  LOAD_SETTINGS_REQUEST = "DCMSSettings__LOAD_SETTINGS_REQUEST",    // Payload cached: boolean
  LOAD_SETTINGS_SUCCESS = "DCMSSettings__LOAD_SETTINGS_SUCCESS",    // Payload IDynaCMSSettingsBasic
  LOAD_SETTINGS_FAILED = "DCMSSettings__LOAD_SETTINGS_FAILED",      // Payload IDynaError
  SET_COUNTRIES = "DCMSSettings__SET_COUNTRIES",      // Payload string[]
  SET_LANGUAGE = "DCMSSettings__SET_LANGUAGE",        // Payload string
}

const ls = new DynaLocalStorageData<{
  countryIds: string[];
  languageId: string;
}>(
  'dynaCMS-section--countries-language',
  {
    countryIds: [],
    languageId: appConfig.defaultLanguageId,
  },
);

const dynaCMSSectionState = (): IDynaCMSSection => ({
  settings: getDefaultDynaCMSSettingsBasic(),
  isLoading: false,
  loadSettingsError: null,
  countryIds: ls.data.countryIds,
  languageId: ls.data.languageId,
});

const cacheDynaCMSSettings = new DynaLocalStorageData<IDynaCMSSettingsBasic | null>("cache--dyna-cms-settings--v02", null);

export const createDynaCMSSection = (
  {store}: {
    store: ICreateStoreAPI;
  },
) => {
  const section = store.createSection<IDynaCMSSection>({
    section: 'dynaCMS',
    initialState: dynaCMSSectionState(),
    reducers: {
      [EAppAction.RESET_APP]: ({dispatch}) => {
        dispatch<boolean>(EDynaCMSAction.LOAD_SETTINGS_REQUEST, true);
      },
      [EDynaCMSAction.LOAD_SETTINGS_REQUEST]: (
        {
          payload,
          dispatch,
        },
      ): Partial<IDynaCMSSection> => {
        const cached: boolean = payload;
        (async () => {
          try {
            if (cacheDynaCMSSettings.data) {
              dispatch<IDynaCMSSettingsBasic>(EDynaCMSAction.LOAD_SETTINGS_SUCCESS, cacheDynaCMSSettings.data);
            }
            const settings = await apiDynaCMSSettingsBasicGet(cached);
            if (!areValuesEqual(settings, cacheDynaCMSSettings.data)) {
              dispatch<IDynaCMSSettingsBasic>(EDynaCMSAction.LOAD_SETTINGS_SUCCESS, settings);
              cacheDynaCMSSettings.data = settings;
              cacheDynaCMSSettings.save();
            }
          }
          catch (e: any) {
            dispatch<IDynaError>(EDynaCMSAction.LOAD_SETTINGS_FAILED, e);
          }
        })();
        return {
          loadSettingsError: null,
          isLoading: true,
        };
      },
      [EDynaCMSAction.LOAD_SETTINGS_SUCCESS]: (
        {
          payload,
          state,
        },
      ): Partial<IDynaCMSSection> => {
        const settings: IDynaCMSSettingsBasic = payload;
        return {
          settings,
          isLoading: false,
          countryIds: (() => {
            const applyCountryIds = state.countryIds.filter(countryId => settings.countries.find(scanCountry => scanCountry.id === countryId));
            return applyCountryIds.length
              ? applyCountryIds
              : state.countryIds;
          })(),
          languageId: (() => {
            if (settings.languages.find(l => l.id === state.languageId)) return state.languageId;
            return settings.defaultLanguageId;
          })(),
        };
      },
      [EDynaCMSAction.LOAD_SETTINGS_FAILED]: ({payload}): Partial<IDynaCMSSection> => {
        const error: IDynaError = payload;
        return {
          isLoading: false,
          loadSettingsError: error,
        };
      },
      [EDynaCMSAction.SET_COUNTRIES]: ({payload}): Partial<IDynaCMSSection> => {
        const countryIds: string[] = payload;
        ls.data.countryIds = countryIds;
        ls.save();
        return {countryIds};
      },
      [EDynaCMSAction.SET_LANGUAGE]: ({payload}): Partial<IDynaCMSSection> => {
        const languageId: string = payload;
        ls.data.languageId = languageId;
        ls.save();
        return {languageId};
      },
    },
  });

  return {
    get state(): IDynaCMSSection {
      return section.state;
    },
    actions: {
      reloadSettings: (cached: boolean) => section.dispatch<boolean>(EDynaCMSAction.LOAD_SETTINGS_REQUEST, cached),
      setCountries: (countyIds: string[]) => section.dispatch<string[]>(EDynaCMSAction.SET_COUNTRIES, countyIds),
      setLanguage: (languageId: string) => section.dispatch<string>(EDynaCMSAction.SET_LANGUAGE, languageId),
      updateCountries: (countyIds: string[]) => section.dispatch<string[]>(EDynaCMSAction.SET_COUNTRIES, countyIds),
    },
  };
};
