import * as React from "react";

import {IOpenGraphMetaTagsProps} from "./IOpenGraphMetaTagsProps";
import {createMetaTags} from "./createMetaTags";
import {renderToString} from "../renderToString";

export const openGraphMetaTagsToString = (props: IOpenGraphMetaTagsProps): string =>
  renderToString(
    <OpenGraphMetaTags {...props}/>,
    {pretty: true},
  );

/**
 * Builds the meta tags for Open Graph with meta tags for the <head>.
 * NOTE: this component is not reusable because the content of it should be in the <head>. For component use, use the OpenGraphMetaTags instead.
 */
const OpenGraphMetaTags: React.FC<IOpenGraphMetaTagsProps> = (props) => {
  return (
    <>
      {createMetaTags(props)
        .filter(metaTag => !!metaTag.content)
        .map(
          (
            {
              property,
              content,
            },
            index,
          ) => (
            <meta
              key={index}
              property={property}
              content={content}
            />
          ),
        )
      }
    </>
  );
};
