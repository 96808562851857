exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InputUploadFiles-module-container--ascLPjNOVwTx {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  font-family: sans-serif;\n  padding: 8px;\n}\n.InputUploadFiles-module-dropzone--2DIIIntBWdQd {\n  -ms-flex: 1 1;\n      flex: 1 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  padding: 20px;\n  border-width: 2px;\n  border-radius: 8px;\n  border-style: dashed;\n  outline: none;\n  -webkit-transition: border 0.24s ease-in-out;\n  -o-transition: border 0.24s ease-in-out;\n  transition: border 0.24s ease-in-out;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"container": "InputUploadFiles-module-container--ascLPjNOVwTx",
	"dropzone": "InputUploadFiles-module-dropzone--2DIIIntBWdQd"
};