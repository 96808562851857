import * as React from "react";
import {ReactElement} from "react";

import {
  INotificationIcon,
  INotificationMenuOption,
} from "../../../../interfaces";

import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";

import {
  FlexContainerHorizontal,
  FlexItemMin,
} from "../../../../../FlexContainer";
import {Box} from "../../../../../Box";

import {BreakpointDeviceContainer} from "../../../../../BreakpointDeviceContainer";
import {
  IconButton,
  EIconButtonSize,
} from "../../../../../IconButton";

import {EntityIcon} from "./components/EntityIcon";
import {EntityMenu} from "./components/EntityMenu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";

export interface IAppNotificationIconsProps {
  notificationIcons?: INotificationIcon[];

  profileIcon?: ReactElement;
  profileName?: string;
  profileOptions?: INotificationMenuOption[];

  userIcon?: ReactElement;
  userName?: string;
  userOptions?: INotificationMenuOption[];
}

export const AppNotificationIcons = (props: IAppNotificationIconsProps): JSX.Element => {
  const {
    notificationIcons = [],

    profileName,
    profileIcon,
    profileOptions = [],

    userName,
    userIcon,
    userOptions = [],
  } = props;

  const profileMenuId = 'app-menu--profile--desktop';
  const userMenuId = 'app-menu--user--desktop';
  const mobileMenuId = 'app-menu--mobile';

  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleNotificationIconClick = (disabled: boolean | undefined, onClick?: () => void): void => {
    handleMenuClose();
    if (disabled) return;
    onClick && onClick();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setProfileAnchorEl(null);
    setUserAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileOptionClick = (onClick?: () => void): void => {
    onClick && onClick();
    handleMenuClose();
  };

  return (
    <>
      <FlexContainerHorizontal
        alignVertical="middle"
        fullHeight
        spacing={0}
      >
        <BreakpointDeviceContainer allExcept mobile>
          {notificationIcons.map(
            (
              {
                icon,
                label,
                counter,
                disabled,
                onClick,
              },
              index,
            ) => (
              <FlexItemMin key={index}>
                <IconButton
                  sx={{color: theme => theme.palette.text.primary}}
                  icon={
                    <Badge badgeContent={counter} color="secondary">
                      {icon}
                    </Badge>
                  }
                  ariaLabel={label}
                  size={EIconButtonSize.LARGE}
                  disabled={disabled}
                  onClick={() => handleNotificationIconClick(disabled, onClick)}
                />
              </FlexItemMin>
            ))}
        </BreakpointDeviceContainer>

        <Box
          sx={{
            border: theme => `1px solid ${theme.palette.grayShades.gray4}`,
            marginRight: 2,
            height: theme => theme.spacing(3),
          }}
        />

        <EntityIcon
          icon={profileIcon}
          name={profileName}
          menuId={profileMenuId}
          onClick={handleProfileMenuOpen}
        />

        <EntityIcon
          icon={userIcon}
          name={userName}
          menuId={userMenuId}
          onClick={handleUserMenuOpen}
        />

        <BreakpointDeviceContainer mobile>
          <IconButton
            sx={{color: theme => theme.palette.text.primary}}
            icon={<MoreIcon/>}
            ariaLabel={`Show notifications${userIcon ? '& profile info' : ''}`}
            ariaControls={mobileMenuId}
            ariaHasPopUp
            size={EIconButtonSize.LARGE}
            onClick={handleMobileMenuOpen}
          />
        </BreakpointDeviceContainer>

      </FlexContainerHorizontal>

      {/* Pop up menus, should be always rendered by this order. */}

      <Menu
        id={mobileMenuId}
        anchorEl={mobileMoreAnchorEl}
        keepMounted
        open={!!mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleMobileMenuClose}
      >
        {
          notificationIcons
            .map(
              (
                {
                  icon,
                  label,
                  counter,
                  disabled,
                  onClick,
                },
                index,
              ) => (
                <MenuItem
                  key={index}
                  onClick={() => handleNotificationIconClick(disabled, onClick)}
                >
                  <IconButton
                    icon={
                      <Badge
                        badgeContent={counter}
                        color="secondary"
                      >
                        {icon}
                      </Badge>
                    }
                    ariaLabel={label}
                    size={EIconButtonSize.LARGE}
                    disabled={disabled}
                  />
                  <p>{label}</p>
                </MenuItem>
              ),
            )
        }
      </Menu>

      <EntityMenu
        menuId={profileMenuId}
        anchorEl={profileAnchorEl}
        isMenuOpen={!!profileAnchorEl}
        options={profileOptions}
        onMenuClose={handleMenuClose}
        onOptionClick={handleProfileOptionClick}
      />

      <EntityMenu
        menuId={userMenuId}
        anchorEl={userAnchorEl}
        isMenuOpen={!!userAnchorEl}
        options={userOptions}
        onMenuClose={handleMenuClose}
        onOptionClick={handleProfileOptionClick}
      />

    </>
  );
};
