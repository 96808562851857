import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IViewerVideoPlayerYouTubeProps} from "./ViewerVideoPlayerYouTube";

const ViewerVideoPlayerYouTubeLazy = lazy(() => import("./ViewerVideoPlayerYouTube"));

export const ViewerVideoPlayerYouTube = (props: IViewerVideoPlayerYouTubeProps) => (
  <LazyLoadComponent>
    <ViewerVideoPlayerYouTubeLazy {...props}/>
  </LazyLoadComponent>
);
