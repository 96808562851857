import {connect} from "react-dynadux";
import {IAppStore} from "../../../../state/IAppStore";
import {EAppTheme} from "../../../../core/application/state/appSection";

import blackLogo from '../../assets/adore-black.svg';
import whiteLogo from '../../assets/adore-white.svg';

export interface IAdoreGrLogoProps {
  store: IAppStore;
  height: number;
  color?:
    | 'inherit-system-foreground'
    | 'inherit-system-background'
    | 'white'
    | 'black';
}

export const AdoreGrLogo = connect((props: IAdoreGrLogoProps): JSX.Element => {
  const {
    store: {app: {state: {theme}}},
    height,
    color = 'inherit-system-foreground',
  } = props;

  return (
    <div>
      <img
        alt="adore.gr logo"
        src={(() => {
          if (color === 'white') return whiteLogo;
          if (color === 'black') return blackLogo;
          if (color === 'inherit-system-foreground') return theme === EAppTheme.DARK ? whiteLogo : blackLogo;
          return theme === EAppTheme.LIGHT ? whiteLogo : blackLogo;
        })()}
        height={height}
      />
    </div>
  );
});
