import {connect} from "react-dynadux";

import {
  DynaCMSRelatedArticles as Component,
} from "./DynaCMSRelatedArticles";
export const DynaCMSRelatedArticles = connect(Component);

export type {
  IDynaCMSRelatedArticlesProps,
} from "./DynaCMSRelatedArticles";

export {
  ERelatedArticlesFormat,
} from "./DynaCMSRelatedArticles";
