export interface IOpenGraphMetaTagsProps {
  country: string;              // https://en.wikipedia.org/wiki/ISO_3166-1
  langauge: string;             // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  type: EOpenGraphType;
  title: string;                // 55–60chars
  description: string;
  url: string;
  siteName: string;
  articleSection?: string;      // The category that the content belongs, i.e.: Fashion, or Beauty
  articlePublisherUrl?: string;
  imageUrl?: string;            // Should be https!
}

export enum EOpenGraphType {
  WEBSITE = "website",
  ARTICLE = "article",
  PRODUCT = "product",
  BOOK = "book",
  PLACE = "place",
  PROFILE = "profile",
  MOVIE = "video.movie",
}
