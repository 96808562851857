import md5 from "md5";
import {
  IDynaCMSFont,
  EDynaCMSFontFamily,
  EFontSize,
  EBold,
  EItalic,
  EUnderline,
  ETextAlign,
  ETextTransform,
  getDefaultIDynaCMSFont,
} from "server-app";

import {TNodeElement} from "utils-library/dist/commonJs/typescript";

import {Box} from "ui-components/dist/Box";

import {
  Theme,
  SxProps,
} from "ui-components/dist/ThemeProvider";
import {
  IDynaCMSFontSetup,
  IDynaCMSFontBoldSetup,
  IDynaCMSFontResources,
  dynaCMSFonts,
} from "./dynaCMSFonts";

const loadedFonts: { [fontFamily: string]: boolean } = {};

export interface IDynaCMSFontProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  show?: boolean;
  fullHeight?: boolean;
  component?: TNodeElement;
  font:
    | IDynaCMSFont
    | IDynaCMSFont[];
  ignoreFontSize?: boolean;
  ignoreScale?: boolean;
  children: any;
}

export const DynaCMSFont = (props: IDynaCMSFontProps): JSX.Element | null => {
  const {
    sx: userSx = {},
    dataComponentName,
    show = true,
    fullHeight = false,
    component = "div",
    font,
    ignoreFontSize = false,
    ignoreScale = false,
    children,
  } = props;

  if (!show) return null;

  const {
    fontFamily,
    fontSize,
    bold = EBold.INHERIT,
    italic,
    underline,
    textAlign,
    textTransform,
    letterSpacingInherit,
    letterSpacing,
    fontStretchInherit,
    fontStretchPercentage,
    strokeInherit,
    strokeWidth,
    strokeColor,
    scale,
    scaleX,
    scaleY,
    opacityInherit,
    opacity,
  }: IDynaCMSFont = (() => {
    const processFonts: IDynaCMSFont[] =
      !Array.isArray(font)
        ? [font]
        : font;
    const outputFont = getDefaultIDynaCMSFont();
    processFonts
      .filter(font => typeof font === "object")
      .forEach(_font => {
        const font = {
          ...getDefaultIDynaCMSFont(),
          ..._font,
        };
        if (font.fontFamily !== EDynaCMSFontFamily.INHERIT) outputFont.fontFamily = font.fontFamily;
        if (font.fontSize !== EFontSize.INHERIT) outputFont.fontSize = font.fontSize;
        if (font.bold !== EBold.INHERIT) outputFont.bold = font.bold;
        if (font.italic !== EItalic.INHERIT) outputFont.italic = font.italic;
        if (font.underline !== EUnderline.INHERIT) outputFont.underline = font.underline;
        if (font.textAlign !== ETextAlign.INHERIT) outputFont.textAlign = font.textAlign;
        if (font.textTransform !== ETextTransform.INHERIT) outputFont.textTransform = font.textTransform;
        if (!font.letterSpacingInherit) {
          outputFont.letterSpacingInherit = false;
          outputFont.letterSpacing = font.letterSpacing;
        }
        if (!font.fontStretchInherit) {
          outputFont.fontStretchInherit = false;
          outputFont.fontStretchPercentage = font.fontStretchPercentage;
        }
        if (font.scale) {
          outputFont.scale = true;
          outputFont.scaleX = font.scaleX;
          outputFont.scaleY = font.scaleY;
        }
        if (!font.strokeInherit) {
          outputFont.strokeInherit = false;
          outputFont.strokeWidth = font.strokeWidth;
          outputFont.strokeColor = font.strokeColor;
        }
        if (!font.opacityInherit) {
          outputFont.opacityInherit = false;
          outputFont.opacity = font.opacity;
        }
      });
    return outputFont;
  })();

  const fontSetup: IDynaCMSFontSetup = (() => {
    const setup = dynaCMSFonts[fontFamily];
    if (setup) return setup;

    console.error(`DynaCMSFont: [${fontFamily}] is not supported`, {fontFamily});
    return dynaCMSFonts[EDynaCMSFontFamily.INHERIT];
  })();

  const boldSetup: {
    setup: IDynaCMSFontBoldSetup;
    boldValue: number;
  } =
    (() => {
      if (bold === EBold.BOLD) {
        return {
          setup:
            fontSetup.bold[EBold.B500] ||
            fontSetup.bold[EBold.B400] ||
            Object.values(fontSetup.bold)[0],
          boldValue: -3849274243, // Generic bold EBold.Bold
        };
      }

      if (fontSetup.bold[bold]) {
        return {
          setup: fontSetup.bold[bold],
          boldValue: getBoldNumber(bold),
        };
      }
      if (fontSetup.bold[EBold.B500]) {
        return {
          setup: fontSetup.bold[EBold.B500],
          boldValue: getBoldNumber(EBold.B500),
        };
      }
      if (fontSetup.bold[EBold.B400]) {
        return {
          setup: fontSetup.bold[EBold.B400],
          boldValue: getBoldNumber(EBold.B400),
        };
      }

      const requestedBoldNumber = getBoldNumber(bold);
      const availableBolds: number[] = Object.keys(fontSetup.bold).map(getBoldNumber as any);

      const thinner =
        availableBolds
          .concat()
          .reverse()
          .find(scanBold => scanBold <= requestedBoldNumber);
      if (thinner) {
        const outputBold: EBold = getEBold(thinner);
        return {
          setup: fontSetup.bold[outputBold],
          boldValue: thinner,
        };
      }
      const thicker =
        availableBolds
          .concat()
          .find(scanBold => scanBold >= requestedBoldNumber);
      if (thicker) {
        const outputBold: EBold = getEBold(thicker);
        return {
          setup: fontSetup.bold[outputBold],
          boldValue: thicker,
        };
      }

      const firstBold: EBold = Object.keys(fontSetup.bold)[0] as any;
      if (!fontSetup.bold[firstBold]) throw new Error('Internal error 90834708245247452'); // 4TS
      return {
        setup: fontSetup.bold[firstBold] as any,
        boldValue: getBoldNumber(firstBold),
      };
    })();

  const fontResources: IDynaCMSFontResources =
    italic === EItalic.ITALIC && boldSetup.setup.italic
      ? boldSetup.setup.italic
      : boldSetup.setup.regular;

  const fontScriptHash = md5(fontResources.script);
  if (!loadedFonts[fontScriptHash]) {
    const el = document.createElement('div');
    el.innerHTML = fontResources.script;
    Array.from(el.childNodes).forEach(el => document.head.appendChild(el));
    loadedFonts[fontScriptHash] = true;
  }

  const sx: SxProps<Theme> = {
    ...(fontResources.cssProperties as any || {}),
    fontFamily: fontSetup.cssFamilyName + ' !important',
    fontSize:
      ignoreFontSize || fontSize === EFontSize.INHERIT
        ? undefined
        : fontSize + ' !important',
    fontWeight:
      boldSetup.boldValue === -1
        ? undefined
        : boldSetup.boldValue === -3849274243
          ? 'bold !important' // Generic bold EBold.Bold
          : boldSetup.boldValue + ' !important',
    fontStyle:
      (() => {
        if (italic === EItalic.ITALIC) return 'italic !important';
        if (italic === EItalic.NON_ITALIC) return 'normal !important';
        return undefined;
      })(),
    textDecoration:
      (() => {
        if (underline === EUnderline.UNDERLINE) return 'underline !important';
        if (underline === EUnderline.NON_UNDERLINE) return 'none !important';
        return undefined;
      })(),
    textAlign,
    textTransform,
    letterSpacing:
      !letterSpacingInherit
        ? letterSpacing + 'px !important'
        : fontResources.cssProperties?.letterSpacing !== undefined
          ? fontResources.cssProperties.letterSpacing + ' !important'
          : undefined,
    fontStretch:
      !fontStretchInherit
        ? fontStretchPercentage + '% !important'
        : fontResources.cssProperties?.fontStretch !== undefined
          ? fontResources.cssProperties.fontStretch + ' !important'
          : underline,
    WebkitTextStrokeWidth:
      !strokeInherit
        ? strokeWidth + 'px'
        : undefined,
    WebkitTextStrokeColor:
      theme => {
        if (strokeInherit) return undefined;
        if (strokeColor === "background") return theme.palette.background.default;
        if (strokeColor === "foreground") return theme.palette.text.primary;
        return strokeColor;
      },
    transform:
      scale && !ignoreScale
        ? `scale(${scaleX}, ${scaleY})`
        : undefined,
    opacity:
      !opacityInherit
        ? opacity
        : undefined,
    ...userSx,
  };

  return (
    <Box
      sx={sx}
      fullHeight={fullHeight}
      dataComponentName={["DynaCMSFont", fontFamily, dataComponentName]}
      component={component}
      children={children}
    />
  );
};

export const getEBold = (bold: number): EBold => `B${bold}` as any;
export const getBoldNumber = (eBold: EBold): number => parseInt(eBold.slice(1)) as any;
