import * as React from "react";
import {
  ReactElement,
  useState,
} from "react";

import {getImageDimensionByFile} from "utils-library/dist/commonJs/web";
import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";

import {IDynaProfile} from "server-app";

import {Box} from "ui-components/dist/Box";
import {ProfileIcon} from "ui-components/dist/ProfileIcon";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";
import {
  ImageResponsive,
  EImageMode,
} from "ui-components/dist/ImageResponsive";
import {
  UploadFilesModal,
  TUploadMethod,
} from "ui-components/dist/UploadFilesModal";

import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
  EFontSize,
  EBold,
} from "../../../dyna-cms/public-components";

import defaultProfileBackground from "./assets/default-profile-background.jpg";

import {apiDynaProfileUploadProfileImage} from "../../api/profiles/apiDynaProfileUploadProfileImage";

import {
  sxTransition,
  ECSSDuration,
} from "ui-components/dist/sxTransition";
import {createIcon} from "ui-components/dist/IconComponent";
import FileUploadIcon from '@mui/icons-material/FileUpload';

export interface IDynaProfileHeaderProps {
  show?: boolean;
  profile: IDynaProfile;
  editable?: boolean;                               // Default is false
  onChange?: (value: any, propertyName: keyof IDynaProfile) => void;
}

export const DynaProfileHeader: React.FC<IDynaProfileHeaderProps> = (
  {
    show = true,
    profile: {
      id: profileId,
      displayName,
      slogan,
      backgroundImageUrl,
      profileImageUrl,
    },
    editable = false,
    onChange,
  },
) => {
  const [showUploadImageModalProperty, setShowUploadImageModalProperty] = useState<
    | "backgroundImageUrl"
    | "profileImageUrl"
    | null
  >(
    null,
  );

  const uploadMethod: TUploadMethod = (
    {
      file,
      onProgress,
      onSuccess,
      onFail,
    },
  ) => {
    return apiDynaProfileUploadProfileImage({
      profileId,
      file,
      imageType:
        showUploadImageModalProperty === "profileImageUrl"
          ? "user-profile"
          : "profile-background",
      onProgress,
      onSuccess,
      onFail,
    });
  };

  const handleProfileBackgroundChange = (): void => setShowUploadImageModalProperty("backgroundImageUrl");
  const handleProfileImageChange = (): void => setShowUploadImageModalProperty("profileImageUrl");

  const validateImage = async (file: File): Promise<string> => {
    const {
      width,
      height,
    } = await getImageDimensionByFile(file);

    if (showUploadImageModalProperty === "backgroundImageUrl") {
      return [
        validateDataMethods.hasValidWidthHeight({
          width,
          height,
          minWidth: 1000,
          maxWidth: 6000,
        }),
        validateDataMethods.hasValidRatio({
          width,
          height,
          min: {
            width: 1.4,
            height: 1,
          },
          max: {
            width: 5,
            height: 1,
          },
        }),
      ].filter(Boolean).join(" ");
    }

    if (showUploadImageModalProperty === "profileImageUrl") {
      return [
        validateDataMethods.hasValidWidthHeight({
          width,
          height,
          minWidth: 512,
          maxWidth: 6000,
        }),
        validateDataMethods.hasValidRatio({
          width,
          height,
          min: {
            width: 1,
            height: 1,
          },
          max: {
            width: 3,
            height: 1,
          },
        }),
      ].filter(Boolean).join(" ");
    }

    return "";
  };

  const handleUploadCancel = () => {
    setShowUploadImageModalProperty(null);
  };
  const handleUploadDone = (urls: string[]) => {
    if (!onChange) return;                      // 4TS
    if (!showUploadImageModalProperty) return;  // 4TS

    const url = urls[0];
    if (!url) {
      console.error('Nothing uploaded');
      return;
    }

    onChange(url, showUploadImageModalProperty);

    setShowUploadImageModalProperty(null);
  };

  const ChangeImageButton = (
    {
      label,
      position,
      onClick,
    }: {
      label: string;
      position:
        | 'right-bottom'
        | 'right-top';
      onClick: () => void;
    },
  ): ReactElement | null => {
    if (!editable) return null;
    return (
      <Box
        show={editable}
        sx={{
          position: 'absolute',
          right: theme => '-' + theme.spacing(1),
          top: theme => position === "right-top" ? theme.spacing(1) : undefined,
          bottom: theme => position === "right-bottom" ? theme.spacing(1) : undefined,
          opacity: 0.2,
          transform: 'scale(0.75)',
          transition: theme => sxTransition(theme, "opacity", ECSSDuration.SHORT),
          ':hover': {opacity: 1},
        }}
      >
        <Button
          size={EButtonSize.SMALL}
          Icon={createIcon.byMuiIcon(FileUploadIcon)}
          onClick={onClick}
        >
          {label}
        </Button>
      </Box>
    );
  };

  if (!show) return null;

  const profileImageSize = 128;
  const imageTear = profileImageSize / 2;

  return (
    <>
      <Box
        dataComponentName="DynaProfileHeader"
        sx={{
          position: "relative",
          marginBottom: imageTear + 'px',
        }}
      >
        <Box
          sx={{
            height: 240,
            position: 'relative',
          }}
        >
          <ImageResponsive
            baseSrc={backgroundImageUrl || defaultProfileBackground}
            alt="Profile background"
            showBrokenImageOnFail={false}
            mode={EImageMode.FILL}
          />
          <ChangeImageButton
            label="Change background"
            position="right-bottom"
            onClick={handleProfileBackgroundChange}
          />
        </Box>
        <Box
          dataComponentName="ProfileImageContainer"
          sx={{
            position: "absolute",
            bottom: '-' + imageTear + 'px',
            left: theme => theme.spacing(1),
          }}
        >
          <Box
            sx={{
              width: profileImageSize,
              height: profileImageSize,
              marginLeft: (imageTear / 3) + 'px',
              borderRadius: '100%',
              overflow: 'hidden',
              border: theme => `2px solid ${theme.palette.background.default}`,
            }}
          >
            <ProfileIcon
              alt="Profile image"
              size={128}
              src={profileImageUrl}
            />
            <ChangeImageButton
              label="Change image"
              position="right-bottom"
              onClick={handleProfileImageChange}
            />
          </Box>
        </Box>
        <Box
          dataComponentName="ProfileDataContainer"
          sx={{
            position: 'absolute',
            height: profileImageSize / 2,
            bottom: -profileImageSize / 2,
            left: (profileImageSize + ((profileImageSize / 3))),
            right: 0,
          }}
        >
          <DynaCMSFontFamily
            component="div"
            fontFamily={EDynaCMSFontFamily.F100_PLAIN_ROBOTO}
            fontSize={EFontSize.PX32}
            bold={EBold.B1000}
          >
            {displayName}

          </DynaCMSFontFamily>
          <DynaCMSFontFamily
            component="div"
            fontFamily={EDynaCMSFontFamily.F100_PLAIN_ROBOTO}
            fontSize={EFontSize.PX14}
          >
            {slogan}

          </DynaCMSFontFamily>
        </Box>
      </Box>

      <UploadFilesModal
        id={`profile-upload--${showUploadImageModalProperty}`}
        show={!!showUploadImageModalProperty}
        label="Change image"
        uploadMethod={uploadMethod}
        validateFile={validateImage}
        multiple={false}
        maxFiles={1}
        fileTypes={['jpg', "jpeg"]}
        onCancel={handleUploadCancel}
        onDone={handleUploadDone}
      />

    </>
  );
};
