import {ITag} from "../../../db/DBDynaCMSTagsEntity";

export const API_PATH_ApiTagsGetAll = '/services/dyna-cms/apiTagsGetAll';

export interface IApiTagsGetAllQueryRequest {
}

export interface IApiTagsGetAllResponse {
  tags: ITag[];
}

