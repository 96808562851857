// The config of the app is ALSO the `env_variables.sh`!
// Here we have some standards only configs.

import {getHost} from "utils-library/dist/commonJs/utils";

export enum EAppMode {
  PRODUCTION = "PRODUCTION",
  DEVELOPMENT = "DEVELOPMENT",
}

export interface IAppConfig {
  companies: ICompanyConfig[];
  mode: EAppMode;
  disableCorsForClients: string[];
  defaultSrc: string[];
}

export interface ICompanyConfig {
  companyId: string;
  serverAddress: string;
  clientAddress: string;
  appName: string;
  appShortName: string;
  appDescription: string;
  headScript: string;
}

export const getDefaultAppConfigCompany = (): ICompanyConfig => ({
  companyId: '',
  serverAddress: '',
  clientAddress: '',
  appName: '',
  appShortName: '',
  appDescription: '',
  headScript: '',
});

interface IAppInfoPerCompanyId {
  [companyId: string]: {
    appName: string;
    appShortName: string;
    appDescription: string;
    headScript: string; // Similar code to headScript at #1083504624 packages/web-app/public/index.html
  };
}

const processEnv =
  typeof process === "undefined"
    ? {}
    : process.env;

export const appInfoPerCompanyId: IAppInfoPerCompanyId = {
  'adore-gr': {
    appName: 'Adore GR',
    appShortName: 'adore.gr',
    appDescription: 'Μόδα, Ομορφιά, Διακοσμήση, Celebs και Lifestyle',
    // Similar code to headScript at #1083504624 packages/web-app/public/index.html
    headScript: `
      <!-- GoogleAdSense -->
      <script
        async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6486460928215437"
        crossorigin="anonymous"
      ></script>
      <!-- monetag -->
      <meta name="monetag" content="17958504474e78d3d03898da33b7b4a5">
    `,
  },
  'tgg-com': {
    appName: 'The girl guns',
    appShortName: 'The girl guns',
    appDescription: 'Girls portal - Fashion, Beauty, Sex & Lifestyle',
    // Similar code to headScript at #1083504624 packages/web-app/public/index.html
    headScript: ``,
  },
  'net-fashion': {
    appName: 'net.fashion',
    appShortName: 'net.fashion',
    appDescription: 'Fashion portal - Beauty, Deco, Celebs & Lifestyle',
    // Similar code to headScript at #1083504624 packages/web-app/public/index.html
    headScript: ``,
  },
};

// Note: The main source of the server's config, are the env_variables.sh, not this const.
export const appConfig: IAppConfig = {
  companies:
    (processEnv.COMPANIES || '')
      .split(' ')
      .filter(Boolean)
      .map((companyServerAddress): ICompanyConfig => {
        const companyId = companyServerAddress.split('|')[0] || '';
        return {
          companyId,
          serverAddress: (() => {
            const address = companyServerAddress.split('|')[1] || '';
            if (address && !address.endsWith('/')) return address + '/';
            return address;
          })(),
          clientAddress: (() => {
            return companyServerAddress.split('|')[2] || '';
          })(),
          appName: appInfoPerCompanyId[companyId]?.appName || `Unknown company ${companyId}`,
          appShortName: appInfoPerCompanyId[companyId]?.appShortName || `Unknown company`,
          appDescription: appInfoPerCompanyId[companyId]?.appDescription || `Unknown company ${companyId}`,
          headScript: appInfoPerCompanyId[companyId]?.headScript || '',
        };
      })
      .filter(company => !!company.companyId),
  mode:
    processEnv.PRODUCTION === "true"
      ? EAppMode.PRODUCTION
      : EAppMode.DEVELOPMENT,
  disableCorsForClients: [],
  defaultSrc: [/* Updated just next... */],
};

appConfig.defaultSrc = [ // The default-src
  "'self'",
  "'unsafe-inline'",
  "'unsafe-eval'",
  'data:',
  'blob:',
  'localhost',

  // Apps 3rd party deps
  'herokuapp.com',
  "*.sentry.io",
  'dyna-cms.s3.amazonaws.com',
  'dyna-cms-dev.s3.amazonaws.com',

  // General 3rd party dependencies
  'cdnjs.cloudflare.com',
  '*.google.com',
  '*.google.co.in',
  '*.googleadservices.com',
  '*.googleadservices.at',  // Todo: We have to add this for every country!
  '*.googleadservices.be',
  '*.googletagservices.com',
  '*.googleusercontent.com',
  '*.ampproject.org',
  '*.fontawesome.com',
  '*.bootstrapcdn.com',
  '*.googleapis.com',
  'doubleclick.net',
  '*.doubleclick.net',
  '*.gstatic.com',
  'youtu.be',
  '*.youtu.be',
  '*.youtube.com',
  '*.youtube.com',
  'vimeo.com',
  '*.vimeo.com',
  "*.cloudflare.com",
  "*.typekit.net",
  '*.cookie-script.com',
  '*.ytimg.com',    // Youtube images

  // Google Analytics
  '*.google-analytics.com',

  // Google AdSense
  'googlesyndication.com',
  'googletagmanager.com',
  'adservice.google.com',
  'adservice.google.co.uk',
  'adservice.google.at',
  'adservice.google.de',
  'adservice.google.gr',
  '*.googlesyndication.com',
  '*.googletagmanager.com',
  '*.adservice.google.com',
  '*.adservice.google.co.uk',
  '*.adservice.google.at',
  '*.adservice.google.de',
  '*.adservice.google.gr',

  // Cookie script com
  'cdn.cookie-script.com',

  // Social networks
  "tiktok.com",
  "*.tiktok.com",
  "ttwstatic.com",    // For tiktok content
  "*.ttwstatic.com",  // For tiktok content
  "*.facebook.com",
  "*.facebook.net",
  "*.instagram.com",
  "*.twitter.com",
  "linkedin.com",
  "*.linkedin.com",

  // Leaflet maps
  'unpkg.com',
  '*.openstreetmap.org',
  '*.tiles.stadiamaps.com',

  // Ad providers
  // Monetag
  '*.linkwi.se',
  'inklinkor.com',
  'ibrapush.com',
  'fleraprt.com',
  'nanouwho.com',
  '*.rtmark.net',
  'betotodilea.com',
  'offerimage.com',
  // Adsterra
  'https://www.profitabledisplaynetwork.com',

  // For all companies addresses
  ...appConfig.companies.map(c => getHost(c.serverAddress)),
  ...appConfig.companies.map(c => '*.' + getHost(c.serverAddress)),
];

appConfig.companies.forEach(({clientAddress}) => appConfig.disableCorsForClients.push(clientAddress));

// Validate the content of the COMPANIES
appConfig.companies.forEach(
  companyConfig => {
    if (!companyConfig.companyId) console.error(`COMPANIES var has error, a company has no companyId`, companyConfig);
    if (!companyConfig.serverAddress) console.error(`COMPANIES var has error, a company has no serverAddress`, companyConfig);
    if (!companyConfig.clientAddress) console.error(`COMPANIES var has error, a company has no clientAddress`, companyConfig);
  });
