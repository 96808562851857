import {DynaLocalStorageData} from "dyna-local-storage";
import {dynaError} from "dyna-error";

import {IApiAuthCurrentUserInfoGetResponse} from "server-app";

export interface ILocalstoraSignStateState {
  lastUserLogin: IApiAuthCurrentUserInfoGetResponse | null;
  offlineLogoff: boolean;
}

export const localstorageSignState = new DynaLocalStorageData<ILocalstoraSignStateState>(
  'app_localstorageSignState',
  {
    lastUserLogin: null,
    offlineLogoff: false,
  },
);

export const getOfflineUserSignedIn = (): {
  hasUser: boolean;
  tokenExpired: boolean;
} => {
  const hasUser: boolean =
    !!localstorageSignState.data.lastUserLogin
    && !!localstorageSignState.data.lastUserLogin.user
    && !!localstorageSignState.data.lastUserLogin.user.id;

  const tokenExpired: boolean =
    hasUser &&
    (localstorageSignState?.data?.lastUserLogin?.tokenExpiresAt || 0) < Date.now();

  return {
    hasUser,
    tokenExpired,
  };
};

/**
 * CheckOfflineUserSignedIn check if the offline user is still valid.
 * If not it throws an error with userMessage.
 */
export const checkOfflineUserSignedIn = (): void | never => {
  const check = getOfflineUserSignedIn();
  if (!check.hasUser) {
    throw dynaError({
      code: 202106010814,
      message: 'No user has been logged in',
      userMessage: 'You have not logged in before and you are offline.\nIn order to use the offline feature you must first login while you are online.',
    });
  }
  if (check.tokenExpired) {
    throw dynaError({
      code: 202106010815,
      message: 'Offline user was logged in but the token has been expired',
      userMessage: [
        'You have been offline for too long and you are now logged out.',
        'Checking your User Profile will allow you to see how many days are remaining before you can no longer use the application offline.',
      ].join('\n'),
    });
  }
};
