import * as React from "react";
import {ITwitterCardProps} from "./ITwitterCardProps";
import {renderToString} from "../renderToString";
import {createMetaTags} from "./createMetaTags";

export const twitterMetaCardContentToString = (props: ITwitterCardProps): string =>
  renderToString(
    <TwitterMetaCardContentToString {...props}/>,
    {pretty: true},
  );

/**
 * Builds the meta tags for Twitter card with meta tags for the <head>.
 * NOTE: this component is not reusable because the content of it should be in the <head>. For component use, use the TwitterMetaCard instead.
 */
const TwitterMetaCardContentToString: React.FC<ITwitterCardProps> = (props) => {
  return (
    <>
      {createMetaTags(props)
        .filter(metaTag => !!metaTag.content)
        .map(
          (
            {
              name,
              content,
            },
            index,
          ) => (
            <meta
              key={index}
              name={name}
              content={content}
            />
          ),
        )

      }
    </>
  );
};
