const mapCleanChar = (v: string): string => {
  const output = v.trim();
  return output
    ? output
    : " ";    // Keep the single space!
};
const inChars = '!,",§,$,%,&,/,(,),=,?,_,:,;,.,-, ,0,1,2,3,4,5,6,7,8,9,ς,ε,ρ,τ,υ,θ ,ι,ο,π,α,σ,δ,φ,γ,η,ξ,κ,λ,ζ,χ,ψ ,ω,β,ν,μ,ά,έ,ύ,ί,ό,ή,ώ,Σ,Ε,Ρ,Τ,Υ,Θ ,Ι,Ο,Π,Α,Σ,Δ,Φ,Γ,Η,Ξ,Κ,Λ,Ζ,Χ,Ψ ,Ω,Β,Β,Ν,Μ,ü,ö,ä,Ά,Έ,Ή,Ί,Ό,Ύ,Ώ'.split(',').map(mapCleanChar);
const enChars = '-,-,-,-,-,-,-,-,-,-,-,-,-,-,-,-,-,0,1,2,3,4,5,6,7,8,9,s,e,r,t,y,th,i,o,p,a,s,d,f,g,i,x,k,l,z,h,ps,o,v,n,m,a,e,y,i,o,i,o,S,E,R,T,Y,TH,I,O,P,A,S,D,F,G,I,x,K,L,Z,H,PS,O,V,B,N,M,u,o,a,A,E,I,I,O,I,O,q,Q,w,W,c,C,x,X,u,U,j,J,b,B'.split(',').map(mapCleanChar);

export const createUrlId = (text: string = "", date?: number): string => {
  const base =
    text
      .replace(/,/g, ' ')
      .split('')
      .reduce((acc: string, letter: string) => {
        const grIndex = inChars.indexOf(letter);
        if (grIndex > -1) return acc + enChars[grIndex].toLowerCase();
        const enIndex = enChars.indexOf(letter);
        if (enIndex > -1) return acc + enChars[enIndex].toLowerCase();
        return acc;
      }, '')
      .split(' ')
      .filter(Boolean)
      .join('-');

  if (!date) return base;

  const dateSuffix =
    (new Date(date))
      .toISOString()
      .split('-')
      .slice(0, 2)
      .reverse()
      .join('-');

  return `${base}--${dateSuffix}`;
};
