import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaProfileManageForm} from "../components/DynaProfileManageForm";
import {routeProfileEditPaths} from "./routeProfileEdit.paths";

export const routeProfileEdit: IAppRoute<{profileId: string}> = {
  ...routeProfileEditPaths,
  render: ({pathParams: {profileId}}) => (
    <DynaProfileManageForm
      profileId={profileId}
      viewMode="edit"
    />
  ),
};
