import {DynaIntlDateFormatter} from "../DynaIntlDateFormatter";
import {DynaIntlTimeFormatter} from "../DynaIntlTimeFormatter";

export interface IDynaIntlDateTimeFormatterProps {
  date?: Date | number;
  day?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  timeMode?: "24" | "12";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  order?: "date-time" | "time-date";
}

export const DynaIntlDateTimeFormatter = (props: IDynaIntlDateTimeFormatterProps): JSX.Element => {
  const {
    date,
    day,
    month,
    year,
    timeMode,
    hour,
    minute,
    order = "time-date",
  } = props;

  const dateContent = (
    <DynaIntlDateFormatter
      date={date}
      day={day}
      month={month}
      year={year}
    />
  );

  const timeContent = (
    <DynaIntlTimeFormatter
      date={date}
      mode={timeMode}
      hour={hour}
      minute={minute}
    />
  );

  return order === "date-time"
    ? <>{dateContent}{" "}{timeContent}</>
    : <>{timeContent}{" "}{dateContent}</>;
};
