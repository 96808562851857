export interface INetFashionLogoProps {
  fontSize?: number;
}

export const NetFashionLogo = (props: INetFashionLogoProps): JSX.Element => {
  const {fontSize = 34} = props;
  return (
    <div>
      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,600;1,800&family=Roboto+Condensed&display=swap');
      `}</style>
      <div
        style={{
          color: 'gray',
          fontSize: 9,
          fontFamily: "'Bowlby One', cursive",
          position: 'relative',
          top: 14,
        }}
      >
        www.
      </div>
      <span
        style={{
          fontSize: fontSize,
          fontFamily: "'Bowlby One', cursive",
        }}
      >
        net
      </span>
      <span
        style={{
          color: 'red',
          fontSize: fontSize,
          fontFamily: "'Bowlby One', cursive",
        }}
      >
        .
      </span>
      <span
        style={{
          fontSize: fontSize,
          fontFamily: "'Roboto Condensed', sans-serif",
        }}
      >
        fashion
      </span>
    </div>
  );
};
