import {
  useMemo,
  useState,
} from "react";
import {IDynaError} from "dyna-error";

import {DataLimitedContainerMemory} from "utils-library/dist/commonJs/data-limited-container-memory";
import {hashMd5} from "utils-library/dist/commonJs/hash-md5";

import {useLoadData} from "../useLoadData";
import {usePerformanceMemo} from "../usePerformanceMemo";

export enum ESource {
  DEFAULT = "DEFAULT",
  CACHE = "CACHE",
  ACTUAL = "ACTUAL",
}

export const useLoadDataCache = <TData, TSearch>(
  {
    defaultData,
    search,
    cache: {
      containerName,
      maxSizeInBytes,
      updateSilentlyExistedContent = true,
    },
    reloadDep,
    load,
  }: {
    defaultData: TData;
    search: TSearch;
    cache: {
      containerName: string;
      maxSizeInBytes: number;
      updateSilentlyExistedContent: boolean;
    };
    reloadDep?: any;
    load: (search: TSearch) => Promise<TData>;
  },
): {
  isLoading: boolean;
  data: TData;
  source: ESource;
  error?: IDynaError;
} => {
  const [source, setSource] = useState<ESource>(ESource.DEFAULT);

  const cache = useMemo(
    () => new DataLimitedContainerMemory<TData>({
      containerName,
      maxSizeInBytes,
    }),
    [],
  );
  const searchId = usePerformanceMemo(() => hashMd5(search), search);

  const {data: cachedDoc} = useLoadData<TData | null>({
    defaultData: null,
    load: async () => {
      const result = await cache.load(searchId);
      return result?.data || null;
    },
    onLoad: data => {
      if (!data) return;
      setSource(ESource.CACHE);
    },
    reloadDep,
  });

  const {
    isLoading,
    data: actualDoc,
    error,
  } = useLoadData<TData | null>({
    defaultData: null,
    load: ()=>load(search),
    onLoad: data => {
      if (!data) return;
      setSource(ESource.ACTUAL);
      cache
        .save(searchId, data)
        .catch(error => console.error('useLoadDataCache error 20240208095920: Error saving to cache', error));
    },
    reloadDep,
  });

  return {
    isLoading: !cachedDoc && isLoading,
    data:
      (
        updateSilentlyExistedContent
          ? actualDoc || cachedDoc
          : cachedDoc || actualDoc
      ) || defaultData,
    source,
    error: cachedDoc ? undefined : error,
  };
};

