import image from './the-girl-guns-logo-temp.png';

export interface ITheGirlGunsComLogoProps {
  height: number;
}

export const TheGirlGunsComLogo = (props: ITheGirlGunsComLogoProps): JSX.Element => {
  const {height} = props;

  return (
    <div>
      <img
        alt="Under Construction"
        src={image}
        height={height}
      />
    </div>
  );
};
