import {
  Theme,
  SxProps,
} from "ui-components/dist/ThemeProvider";

const paddingFactor = 1;

export const getContentPaddingSx = (heading: number = 0): SxProps<Theme> => {
  return {
    paddingLeft: theme => getContentPadding(theme, heading),
    paddingRight: theme => getContentPadding(theme, heading),
  };
};

const getContentPadding = (theme: Theme, heading: number): string => {
  if (heading === 0) return theme.spacing(5 * paddingFactor);
  if (heading === 1) return "4px";
  return theme.spacing((heading - 1) * paddingFactor);
};
