export const API_PATH_ApiArticleHeaderArchive = '/services/dyna-cms/apiArticleHeaderArchive';

export interface IApiArticleHeaderArchiveBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  archiveIt: boolean;
}

export interface IApiArticleHeaderArchiveResponse {
  archivedAt: number;
  archivedBy: string;
}
