import * as React from "react";

import {IAppContainerFlowProps} from "./interfaces";

import {Box} from "../Box";

import {AppHeader} from "./components/AppHeader/AppHeader";

export const AppContainerFlow = (props: IAppContainerFlowProps): JSX.Element => {
  const {
    appLogo,

    maxWidth,

    notificationIcons,

    profileIcon,
    profileName,
    profileOptions,

    userIcon,
    userName,
    userOptions,

    children,

    onAppLogoClick,
  } = props;

  return (
    <Box
      dataComponentName="AppContainerFlow"
      sx={{
        maxWidth,
        margin: 'auto',
      }}
    >
      <AppHeader
        appLogo={appLogo}
        notificationIcons={notificationIcons}

        profileIcon={profileIcon}
        profileName={profileName}
        profileOptions={profileOptions}

        userIcon={userIcon}
        userName={userName}
        userOptions={userOptions}

        onAppLogoClick={onAppLogoClick}
      />
      {children}
    </Box>
  );
};
