import {IAppRoute} from "../config/IAppRoute";
import {routeAppVersion} from "./routeAppVersion";

import {routeAppSettingsPage} from "./routeAppSettingsPage";
import {routeBackOfficePage} from "./routeBackOfficePage";
import {routeBroadcasterClientConnectionTestPage} from "./routeBroadcasterClientConnectionTestPage";

export const routesAppCore: IAppRoute[] = [
  routeAppVersion,
  routeAppSettingsPage,
  routeBackOfficePage,
  routeBroadcasterClientConnectionTestPage,
];
