import * as React from "react";
import {
  ReactElement,
  useRef,
} from "react";

import {guid} from "dyna-guid";

import {
  validationEngineEmptyRules,
  IValidateDataEngineRules,
} from "utils-library/dist/commonJs/validation-engine";

import {scrollToElement} from "utils-library/dist/commonJs/web";

import {Box} from "../Box";
import {
  GridContainer,
  GridItem,
} from "../Grid";

import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "../FlexContainer";
import {IsLoading} from "../IsLoading";
import {
  Alert,
  EAlertType,
} from "../Alert";
import {FormatDateTime} from "../FormatDateTime";
import {
  FormToolbar,
  IFormToolbarLabels,
} from "../FormToolbar";
import {ButtonBar} from "../ButtonBar";
import {
  Button,
  EButtonVariant,
  EButtonColor,
} from "../Button";

import {ErrorBanner} from "../ErrorBanner";
import {
  useForm,
  EFormMode,
  IUseFormApi,
  EFormType,
  EUpdateMode,
} from "../useForm";

import {
  TDataComponentName,
  getDataComponentName,
} from "../ui-interfaces";
import {TGridSpacing} from "../ui-interfaces";

import ArchiveIcon from '@mui/icons-material/Inventory2';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';
import CloseIcon from '@mui/icons-material/Close';

export interface IFormProps<TData, TDataId = string> {
  dataComponentName?: TDataComponentName;

  formType: EFormType;
  updateMode?: EUpdateMode;     // Default is EUpdateMode.ON_BLUR, this makes the form to be rendered on input's blur events instead of on change event, this has significant performance impact.
  emptyFormData: TData;         // Needed for the first render, showing the fields empty
  loadDataId: TDataId | null;   // Load form data by id. Null to create data by this form.

  formToolbarPosition?: EFormToolbarPosition;   // Default is BOTTOM_RIGHT
  gridSpacing?: TGridSpacing;   // Default is 2

  userCanCreate?: boolean;      // Default is true
  userCanEdit?: boolean;        // Default is true
  userCanArchive?: boolean;     // Default is true
  userCanUnarchive?: boolean;   // Default is true
  userCanDelete?: boolean;      // Default is true
  userCanUnDelete?: boolean;    // Default is true

  isModalForm: boolean;         // If true, the Cancel button is shown as Close. Set true if the Cancel is going to close the form.
  isLoading?: boolean;          // You can use this to combine progress information with external sources.

  disabledEditOnArchived?: boolean;             // Default is false
  disabledEditOnDeleted?: boolean;              // Default is false
  hideCancelButtonOnCreateFormMore?: boolean;   // Default is false
  /**
   * Disable automatic mappings:
   * - input type=checkbox mapped to a boolean
   * - input type=number mapped to number
   */
  disableAutoMapping?: boolean;

  renderHeader?: (formApi: IUseFormApi<TData>) => ReactElement | null;      // Use always <GridItems> as output without <GridContainer>
  renderForm: (formApi: IUseFormApi<TData>) => ReactElement;                // Use always <GridItems> as output without <GridContainer>
  renderAdditionalButtons?: (formApi: IUseFormApi<TData>) => ReactElement;  // Rendered in the ButtonBar of form's buttons

  /**
   * Triggered on any property change, ability to map the value.
   * You should return the final value of the property, ideally the `newValue`.
   * You can compare the old and new or even the whole form data.
   * The `newValue` is already the result of default mapping (checkboxes, numbers)
   * @param args
   */
  onChangeMapValue?: (args: {
    input: HTMLInputElement;
    propName: string;
    oldValue: any;
    newValue: any;
    formData: TData;
  }) => any;

  validationRules?: IValidateDataEngineRules<TData>;

  // Api methods
  onApiPost?: (data: TData) => Promise<{ dataId: TDataId; data?: TData }>;
  onApiGet?: (dataId: TDataId, currentData: TData) => Promise<TData>;
  onApiPut?: (data: TData, currentData: TData) => Promise<void | TData>;
  onApiArchive?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiUnarchive?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiDelete?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;
  onApiUndelete?: (dataId: TDataId, currentData: TData) => Promise<void | TData>;

  // Form events, triggered when the operation is completed
  onBeforeFormSave?: (data: TData) => TData | void; // Optionally change the data before save them (post or put)
  onFormSave?: (data: TData) => void;               // The passed data are the most up to date (from onFormSaveBefore or from server's return)
  onFormCancel?: () => void;
  onFormArchive?: (data: TData) => void;
  onFormUnarchive?: (data: TData) => void;
  onFormDelete?: (data: TData) => void;
  onFormUndelete?: (data: TData) => void;

  labels?: IFormToolbarLabels;

  _debug_consoleFormChange?: string;
}

export {
  EFormMode,
  EFormType,
  EUpdateMode,
  IValidateDataEngineRules,
};

export enum EFormToolbarPosition {
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export const Form = <TData, TDataId = string>(props: IFormProps<TData, TDataId>): ReactElement => {
  const {
    formType,
    dataComponentName,
    updateMode = EUpdateMode.ON_BLUR,
    emptyFormData,
    loadDataId,
    formToolbarPosition = EFormToolbarPosition.BOTTOM_RIGHT,
    gridSpacing = 2,

    userCanCreate = true,
    userCanEdit = true,
    userCanArchive = true,
    userCanUnarchive = true,
    userCanDelete = true,
    userCanUnDelete = true,

    isModalForm,
    isLoading: userIsLoading = false,

    disabledEditOnArchived = false,
    disabledEditOnDeleted = false,
    hideCancelButtonOnCreateFormMore = false,

    disableAutoMapping = false,

    validationRules = validationEngineEmptyRules<TData>(emptyFormData),

    renderHeader,
    renderForm,
    renderAdditionalButtons,

    onApiPost,
    onApiGet,
    onApiPut,
    onApiArchive,
    onApiUnarchive,
    onApiDelete,
    onApiUndelete,

    onChangeMapValue,
    onBeforeFormSave,
    onFormSave,
    onFormCancel,
    onFormArchive,
    onFormUnarchive,
    onFormDelete,
    onFormUndelete,

    labels,

    _debug_consoleFormChange,
  } = props;


  // This function moves the execution of the callback function to the next thread's tick.
  // Actually, this is not needed!
  //    It is added because the React Development Mode is a console error that we are changing an unmount component.
  //    Error: Warning: Can't perform a React state update on an unmounted component.
  //    But this render's reported initiator (an XHR call) is not correct. So it looks to be React's Development Mode bug.
  // To avoid any confusion, we simply use this moveToNextTick method for Form's callbacks.
  const moveToNextTick = (fn: any): any => {
    return async (...args: any[]) => {
      await new Promise(r => setTimeout(r));
      return fn(...args);
    };
  };

  const formApi = useForm<TData, TDataId>({
    formType,
    updateMode,
    emptyFormData,
    loadDataId,

    userCanCreate,
    userCanEdit,
    userCanArchive,
    userCanUnarchive,
    userCanDelete,
    userCanUnDelete,

    disabledEditOnArchived,
    disabledEditOnDeleted,
    hideCancelButtonOnCreateFormMore,

    disableAutoMapping,

    validationRules,

    onApiPost,
    onApiGet,
    onApiPut,
    onApiArchive,
    onApiUnarchive,
    onApiDelete,
    onApiUndelete,

    onAlert: () => {
      scrollToElement({selector: `#${refFormId.current}`});
    },
    onChangeMapValue,
    onBeforeFormSave,
    onFormSave: onFormSave && moveToNextTick(onFormSave),
    onFormCancel: onFormCancel && moveToNextTick(onFormCancel),
    onFormArchive: onFormArchive && moveToNextTick(onFormArchive),
    onFormUnarchive: onFormUnarchive && moveToNextTick(onFormUnarchive),
    onFormDelete: onFormDelete && moveToNextTick(onFormDelete),
    onFormUndelete: onFormUndelete && moveToNextTick(onFormUndelete),

    _debug_consoleFormChange,
  });

  const {
    isLoading,
    alertViewer,
    confirmViewer,
    formProps,
    data,
    loadFatalError,
  } = formApi;

  const {
    archivedAt,
    deletedAt,
  } = data as any;

  const refFormId = useRef<string>('A' + guid());

  if (loadFatalError) {
    return (
      <GridContainer spacing={gridSpacing}>
        <GridItem>
          <ErrorBanner error={loadFatalError}/>
        </GridItem>
        <GridItem>
          <Button
            icon={<CloseIcon/>}
            onClick={onFormCancel}
          >
            Close
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <form {...formProps}>
      <IsLoading isLoading={isLoading || userIsLoading}>
        <GridContainer
          spacing={gridSpacing}
          dataComponentName={getDataComponentName(dataComponentName, "Form")}
        >
          <GridItem>
            <FlexContainerHorizontal>
              <FlexItemMax>
                <Box show={!!renderHeader}>
                  {renderHeader ? renderHeader(formApi) : null}
                </Box>
              </FlexItemMax>
              <FlexItemMin show={formToolbarPosition === EFormToolbarPosition.TOP_RIGHT}>
                <FormToolbar
                  useFormApi={formApi}
                  showCancelAsClose={isModalForm}
                  additionalButtons={renderAdditionalButtons?.(formApi)}
                />
              </FlexItemMin>
            </FlexContainerHorizontal>
          </GridItem>

          <GridItem
            sx={{
              paddingTop: '0 !important',
              paddingBottom: '0 !important',
            }}
          >
            <Box id={refFormId.current}/>
            {confirmViewer}
            {alertViewer}
            <Alert
              show={!!archivedAt}
              type={EAlertType.WARNING}
            >
              Archived on
              <FormatDateTime value={archivedAt}/>
            </Alert>
            <Alert
              show={!!deletedAt}
              type={EAlertType.ERROR}
            >
              Deleted on <FormatDateTime value={deletedAt}/>
            </Alert>
          </GridItem>

          {renderForm(formApi)}

          <GridItem>
            <FlexContainerHorizontal alignVertical="bottom">
              <FlexItemMax>
                <ButtonBar
                  spacing={1}
                  noHorizontalSpace
                >
                  <Button
                    {...formApi.buttons.archive}
                    icon={<ArchiveIcon/>}
                    variant={EButtonVariant.OUTLINED}
                    color={EButtonColor.WARNING}
                    hideLabelOnMobile
                  >
                    Archive
                  </Button>
                  <Button
                    {...formApi.buttons.unarchive}
                    icon={<UnarchiveIcon/>}
                    variant={EButtonVariant.CONTAINED}
                    color={EButtonColor.WARNING}
                    hideLabelOnMobile
                  >
                    Un-archive
                  </Button>
                  <Button
                    {...formApi.buttons.delete}
                    icon={<DeleteIcon/>}
                    variant={EButtonVariant.OUTLINED}
                    color={EButtonColor.ERROR}
                    hideLabelOnMobile
                  >
                    Delete
                  </Button>
                  <Button
                    {...formApi.buttons.undelete}
                    icon={<UndeleteIcon/>}
                    variant={EButtonVariant.CONTAINED}
                    color={EButtonColor.ERROR}
                    hideLabelOnMobile
                  >
                    Undelete
                  </Button>
                </ButtonBar>
              </FlexItemMax>
              <FlexItemMin show={formToolbarPosition === EFormToolbarPosition.BOTTOM_RIGHT}>
                <FormToolbar
                  useFormApi={formApi}
                  showCancelAsClose={isModalForm}
                  additionalButtons={renderAdditionalButtons?.(formApi)}
                  labels={labels}
                />
              </FlexItemMin>
            </FlexContainerHorizontal>
          </GridItem>
        </GridContainer>
      </IsLoading>
    </form>
  );
};
