import * as React from "react";

import {Box} from "../../Box";
import {PortalContainer} from "../../PortalContainer";
import {
  FlexContainerVertical,
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../../FlexContainer";
import {
  GridContainer,
  GridItem,
} from "../../Grid";
import {BreakpointDeviceContainer} from "../../BreakpointDeviceContainer";
import {Link} from "../../Link";
import {
  Button,
  EButtonVariant,
  EButtonSize,
} from "../../Button";

import {sxTransition} from "../../sxTransition";
import {alpha} from "@mui/material/styles";

import {IAppMenu} from "../interfaces";

import {sxScrollMoreEffect} from "../../sxScrollMoreEffect";
import CloseIcon from '@mui/icons-material/Cancel';

export interface IPopUpMenuProps {
  show: boolean;
  logoPopUpHeader?: JSX.Element;
  logoPopUpFooter?: JSX.Element;
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
  additionalOptions?: (props: { onClick?: () => void }) => JSX.Element;
  footer?: (props: { onClick?: () => void }) => JSX.Element;
  palette: {
    selected: string;
  };
  onClose: () => void;
}

export const PopUpMenu = (props: IPopUpMenuProps): JSX.Element => {
  const {
    show,
    logoPopUpHeader,
    logoPopUpFooter,
    selectedMenuId,
    menus,
    additionalOptions,
    footer,
    palette,
    onClose,
  } = props;

  const renderItem = (menu: IAppMenu | 'DIVIDER', index: number): JSX.Element => {
    if (menu === 'DIVIDER') {
      return (
        <Box sx={{border: theme => `1px solid ${theme.palette.text.primary}`}}/>
      );
    }
    return (
      <Box
        key={index}
        component="li"
        sx={{
          listStyleType: 'none',
          marginBottom: theme => theme.spacing(2),
        }}
      >
        <Link
          href={menu.navigateTo}
          sx={{
            fontFamily: "'Oswald', sans-serif",
            textTransform: 'uppercase',
            letterSpacing: 1,
            fontSize: theme => theme.typography.fontSize * 2,
            color:
              theme =>
                selectedMenuId === menu.menuId
                  ? palette.selected
                  : theme.palette.text.primary,
            padding: theme => theme.spacing(2),
            transition: theme => sxTransition(theme, 'background-color', 300),
            '&:hover': {backgroundColor: theme => theme.palette.text.disabled},
          }}
          onClick={onClose}
        >
          {menu.title}
        </Link>

      </Box>
    );
  };

  return (
    <PortalContainer>
      <Box
        show={show}
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: theme => alpha(theme.palette.background.default, 0.96),
          overflowY: 'auto',
          zIndex: 1200,
        }}
      >
        <FlexContainerVertical fullHeight>
          <FlexItemMin>
            <GridContainer spacing={3}>
              <GridItem mobile={4}>
                <Button
                  sx={{color: palette.selected}}
                  variant={EButtonVariant.TRANSPARENT}
                  size={EButtonSize.XXLARGE}
                  icon={<CloseIcon/>}
                  onClick={onClose}
                />
              </GridItem>
              <GridItem
                mobile={8}
                sx={{textAlign: 'right'}}
              >
                <Link
                  href="/"
                  onClick={onClose}
                >
                  <Box
                    show={!!logoPopUpHeader}
                    sx={{margin: 'auto'}}
                  >
                    {logoPopUpHeader}
                  </Box>
                </Link>
              </GridItem>
            </GridContainer>
          </FlexItemMin>

          <BreakpointDeviceContainer mobile>
            <FlexItemMax
              overFlowY
              sx={sxScrollMoreEffect()}
            >
              <GridContainer>
                <GridItem tablet={6}>
                  <Box
                    sx={{
                      maxWidth: 420,
                      margin: 'auto',
                    }}
                  >
                    <ul>
                      {menus.map(renderItem)}
                    </ul>
                  </Box>
                </GridItem>
                <GridItem tablet={6}>
                  <Box
                    sx={{
                      maxWidth: 420,
                      margin: 'auto',
                      padding: theme => theme.spacing(3),
                    }}
                  >
                    {additionalOptions && additionalOptions({onClick: onClose})}
                  </Box>
                </GridItem>
              </GridContainer>
            </FlexItemMax>
          </BreakpointDeviceContainer>


          <BreakpointDeviceContainer allExcept mobile>
            <FlexItemMax>
              <GridContainer fullHeight>
                <GridItem
                  tablet={6}
                  sx={{height: '100%'}}
                >
                  <Box
                    sx={{
                      maxWidth: 420,
                      height: '100%',
                      margin: 'auto',
                    }}
                  >
                    <FlexContainerHorizontal fullHeight>
                      <FlexItemMax
                        overFlowY
                        sx={sxScrollMoreEffect()}
                      >
                        <ul>
                          {menus.map(renderItem)}
                        </ul>
                      </FlexItemMax>
                    </FlexContainerHorizontal>
                  </Box>
                </GridItem>
                <GridItem tablet={6}>
                  <Box
                    sx={{
                      maxWidth: 420,
                      margin: 'auto',
                      padding: theme => theme.spacing(4),
                    }}
                  >
                    {additionalOptions && additionalOptions({onClick: onClose})}
                  </Box>
                </GridItem>
              </GridContainer>
            </FlexItemMax>
          </BreakpointDeviceContainer>


          <FlexItemMin sx={{paddingBottom: theme => theme.spacing(3)}}>
            <FlexContainerHorizontal spacing={3}>
              <FlexItemMin>
                {logoPopUpFooter}
              </FlexItemMin>
              <FlexItemMax sx={{textAlign: 'right'}}>
                {footer && footer({onClick: onClose})}
              </FlexItemMax>
            </FlexContainerHorizontal>
          </FlexItemMin>
        </FlexContainerVertical>

      </Box>
    </PortalContainer>
  );
};
