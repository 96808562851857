export interface ISlide {
  navArrowsMode?: ENavArrowsMode;                 // Default is ENavArrowsMode.OVERLAY
  renderContent: (args: {prepare: boolean; show: boolean}) => JSX.Element | null;
  renderThumbnail: (selected: boolean) => JSX.Element;
}

export enum ENavArrowsMode {
  HIDDEN = "HIDDEN",
  OVERLAY = "OVERLAY",
  ASIDE = "ASIDE",
}

