import {IAppRoute} from "../../../../core/application/config/IAppRoute";

import {routeDynaCMSArticle} from "../../../../core/dyna-cms/public-components";

export const routeAdoreContactAdsDepartment: IAppRoute = routeDynaCMSArticle({
  path: "contact-ads-department",
  multiLangArticles: [
    {
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '1b144ddc-2f8490ff91c9611922',
    },
  ],
});
