import * as React from "react";
import {
  useRef,
  useState,
  useEffect,
} from "react";
import {useDebouncedCallback} from "use-debounce";

import {Box} from "../Box";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {ContainerDetectResize} from "../ContainerDetectResize";
import {CenterContainer} from "../CenterContainer";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";
import {useBreakpointDevice} from "../useBreakpointDevice";
import {
  sxTransition,
  ECSSDuration,
  ECSSEasing,
} from "../sxTransition";
import LeftIcon from '@mui/icons-material/ArrowBackIosNew';
import RightIcon from '@mui/icons-material/ArrowForwardIos';
import DocIcon from '@mui/icons-material/FiberManualRecord';

export interface IContainerSliderProps {
  showArrows?: boolean;
  showThumbnails?: boolean;
  children: any[];
}

export const ContainerSlider = (props: IContainerSliderProps): JSX.Element => {
  const {
    showArrows = true,
    showThumbnails = true,
    children,
  } = props;
  const {isMobile} = useBreakpointDevice();
  const refScrollContainer = useRef<HTMLDivElement>(null);
  const [childNoInViewport, setChildNoInViewport] = useState<number>(-1);
  const arrowWidth = isMobile ? 32 : 64;

  useEffect(() => {
    updateChildNoInViewport();
  }, [refScrollContainer.current]);

  const scrollToDirection = (direction: number): void => {
    if (!refScrollContainer.current) return; // 4TS
    refScrollContainer.current.scrollTo({
      left: refScrollContainer.current.scrollLeft + (parseInt(getComputedStyle(refScrollContainer.current).width) * direction),
      behavior: 'smooth',
    });
  };
  const scrollToNumber = (no: number): void => {
    if (!refScrollContainer.current) return; // 4TS
    refScrollContainer.current.scrollTo({
      left: parseInt(getComputedStyle(refScrollContainer.current).width) * no,
      behavior: 'smooth',
    });
  };
  const updateChildNoInViewport = (): void => {
    if (!refScrollContainer.current) return;
    const no = Math.round(refScrollContainer.current.scrollLeft / (parseInt(getComputedStyle(refScrollContainer.current).width)));
    setChildNoInViewport(no);
  };

  const scrollBarHeight = (() => {
    if (!refScrollContainer.current) return 0;
    return refScrollContainer.current.offsetHeight - refScrollContainer.current.clientHeight + 1;
  })();

  const handleLeftClick = () => scrollToDirection(-1);

  const handleRightClick = () => scrollToDirection(+1);

  const handleScroll = useDebouncedCallback(
    updateChildNoInViewport,
    100,
    {maxWait: 100},
  );

  const sxArrowButton: SxProps<Theme> = {
    minWidth: arrowWidth,
    cursor: 'pointer',
    transition: theme => sxTransition(theme, ['background', 'opacity'], ECSSDuration.SHORTEST),
    ':hover': {background: theme => theme.palette.grayShades.gray1},
  };

  return (
    <Box dataComponentName="ContainerSlider">
      <ContainerDetectResize>
        {({width}) => {
          const itemWidth = width - (arrowWidth * 2);
          return (
            <FlexContainerHorizontal>
              <FlexItemMin
                show={showArrows}
                sx={{
                  ...sxArrowButton,
                  ...(
                    childNoInViewport === 0
                      ? {
                        opacity: 0.3,
                        pointerEvents: 'none',
                      }
                      : {}
                  ),
                }}
                onClick={handleLeftClick}
              >
                <CenterContainer fullHeight>
                  <LeftIcon/>
                </CenterContainer>
              </FlexItemMin>
              <FlexItemMax
                sx={{
                  minWidth: '1px',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    overflowX: 'scroll',
                    scrollSnapType: 'x mandatory',
                    userSelect: 'none',
                    paddingTop: `${scrollBarHeight}px`,
                  }}
                  ref={refScrollContainer}
                  onScroll={handleScroll}
                >
                  {children.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        flex: `0 0 ${itemWidth}px`,
                        width: itemWidth,
                        scrollMarginLeft: 0,
                        scrollSnapAlign: 'start',
                      }}
                    >
                      <CenterContainer>
                        {item}
                      </CenterContainer>
                    </Box>
                  ))}
                </Box>
                <Box
                  dataComponentName="Hide the scrollbar"
                  sx={{
                    position: 'absolute',
                    height: `${scrollBarHeight}px`,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: theme => theme.palette.background.default,
                  }}
                />
              </FlexItemMax>
              <FlexItemMin
                show={showArrows}
                sx={{
                  ...sxArrowButton,
                  ...(
                    childNoInViewport === children.length - 1
                      ? {
                        opacity: 0.3,
                        pointerEvents: 'none',
                      }
                      : {}
                  ),
                }}
                onClick={handleRightClick}
              >
                <CenterContainer fullHeight>
                  <RightIcon/>
                </CenterContainer>
              </FlexItemMin>
            </FlexContainerHorizontal>
          );
        }}
      </ContainerDetectResize>

      <Box
        show={showThumbnails}
        sx={{textAlign: 'center'}}
      >
        {Array(children.length)
          .fill(null)
          .map((v, index) => v || index)
          .map(no => (
            <Box
              key={no}
              inline
              sx={{cursor: 'pointer'}}
              onClick={() => scrollToNumber(no)}
            >
              <Box
                sx={{
                  opacity: no === childNoInViewport ? undefined : 0.6,
                  transform: `scale(${no === childNoInViewport ? 0.9 : 0.6})`,
                  transition: theme => sxTransition(theme, ['opacity', 'transform'], ECSSDuration.COMPLEX, ECSSEasing.IN_OUT),
                }}
              >
                <DocIcon/>
              </Box>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};
