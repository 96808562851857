import {ReactElement} from "react";

// @ts-ignore
import {FormattedMessage} from "react-intl";

export interface IDynaIntlFormatterProps {
  tk: string;
  default?: string;
  values?: {[varName: string]: string | number | Date};
}

export const DynaIntlFormatter = (props: IDynaIntlFormatterProps): ReactElement | null => {
  const {
    tk,
    default: _default,
    values,
  } = props;

  if (!tk) return null;

  return (
    <FormattedMessage
      id={tk}
      defaultMessage={_default}
      values={values}
    />
  );
};
