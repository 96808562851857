import * as React from "react";
import {useEffect} from "react";

import {Box} from "ui-components/dist/Box";

import {
  EAppMode,
  appConfig,
} from "../../../application/config/appConfig";

import {EDynaAdOrientation} from "../../interfaces";
import {EAdProvider} from "../../public-components";

import {DemoAdPlaceholder} from "../components/DemoAdPlaceholder";

export interface IGoogleAdSenseProps {
  orientation: EDynaAdOrientation;
}

const googleFormatPerOrientation: Record<EDynaAdOrientation, string> = {
  [EDynaAdOrientation.AUTO]: 'auto',
  [EDynaAdOrientation.HORIZONTAL]: 'horizontal',
  [EDynaAdOrientation.VERTICAL]: 'vertical',
  [EDynaAdOrientation.RECTANGLE]: 'rectangle',
};

export const GoogleAdSense: React.FC<IGoogleAdSenseProps> = (
  {orientation = EDynaAdOrientation.AUTO},
) => {
  const appConfigGoogleAdSense = appConfig.googleAdSense;
  const active = appConfig.mode === EAppMode.PRODUCTION;

  useEffect(() => {
    const w: any = window;
    if (!appConfigGoogleAdSense || !active) return; // To avoid the 403
    (w.adsbygoogle = w.adsbygoogle || []).push({});
  }, []);

  if (!active) {
    // To avoid the 403
    return (
      <DemoAdPlaceholder
        adProvider={EAdProvider.GOOGLE_AD_SENSE}
        orientation={orientation}
      />
    );
  }

  if (!appConfigGoogleAdSense) return null;

  const {
    googleAdId,
    orientationSetup,
  } = appConfigGoogleAdSense;

  return (
    <Box dataComponentName="GoogleAdSense">
      <h4 className="debug6060" hidden>googleAdSense</h4>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          textAlign: "center",
        }}
        data-ad-client={googleAdId}
        data-ad-slot={orientationSetup[orientation].dataAdSlot}
        data-ad-format={googleFormatPerOrientation[orientation]}
        data-full-width-responsive="true"
      />
    </Box>
  );
};
