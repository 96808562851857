import {
  API_PATH_ApiDynaProfileForUserGet,
  IApiDynaProfileForUserGetRequest,
  IApiDynaProfileForUserGetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfileForUserGet = async (args: IApiDynaProfileForUserGetRequest): Promise<IApiDynaProfileForUserGetResponse> => {
  return apiFetch.request<IApiDynaProfileForUserGetRequest, null, IApiDynaProfileForUserGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaProfileForUserGet,
    query: args,
  });
};
