import * as React from "react";

import {Box} from "ui-components/dist/Box";
import {
  Link,
  ELinkDisplay,
} from "ui-components/dist/Link";

import {IDynaCMSArticleBlockAddress} from "server-app";
import {
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";
import {CenterContainer} from "ui-components/dist/CenterContainer";
import {IconViewer} from "ui-components/dist/IconViewer";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {DynaCMSArticleBlockViewer} from "../DynaCMSArticleBlockViewer";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export interface IDynaCMSGoToPageCoverLinkProps {
  cover: IDynaCMSArticleBlockAddress;
  link: string;
}

export const DynaCMSGoToPageCoverLink: React.FC<IDynaCMSGoToPageCoverLinkProps> = (
  {
    cover,
    link,
  },
) => {
  const {isMobile} = useBreakpointDevice();
  const borderRadius = isMobile ? 2 : 4;
  return (
    <Link
      href={link}
      display={ELinkDisplay.BLOCK}
    >
      <Box
        sx={{
          borderRadius: theme => theme.spacing(borderRadius),
          overflow: 'hidden',
          backgroundColor: theme => theme.palette.appMainColor.main,
        }}
      >
        <FlexContainerHorizontal>
          <FlexItemMax
            sx={{
              borderRadius: theme => [
                0,
                theme.spacing(borderRadius),
                theme.spacing(borderRadius),
                0,
              ].join(' '),
              overflow: 'hidden',
              backgroundColor: theme => theme.palette.grayShades.gray9,
            }}
          >
            <DynaCMSArticleBlockViewer
              dataComponentName="GoToPageCoverLink-000124"
              blockAddress={cover}
              showPhotoCredits={false}
            />
          </FlexItemMax>
          <FlexItemMin>
            <CenterContainer
              sx={{
                color: 'white',
                mx: 1,
              }}
            >
              <IconViewer
                width={isMobile ? 32 : 64}
                MuiIcon={ArrowForwardIosIcon}
              />
            </CenterContainer>
          </FlexItemMin>
        </FlexContainerHorizontal>
      </Box>
    </Link>
  );
};
