export const API_PATH_ApiArticleHeaderPublish = '/services/dyna-cms/apiArticleHeaderPublish';

export interface IApiArticleHeaderPublishBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  publishAt: number;
  publishedAt: number;
}

export interface IApiArticleHeaderPublishResponse {
  ok: true;
}

