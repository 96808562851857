import {useMemo} from "react";
import * as qs from "qs";

import {connect} from "react-dynadux";

import {
  Route,
  Switch,
} from "react-router-dom";

import {AppContainerFlow} from "ui-components/dist/AppContainerFlow";
import {Box} from "ui-components/dist/Box";
import {useLoadData} from "ui-components/dist/useLoadData";

import {IAppStore} from "../../../state/IAppStore";
import {
  appNotificationIcons,
  getProfileNotificationIcon,
  getProfileNotificationIconOptions,
  getUserNotificationIcon,
  getUserNotificationIconOptions,
} from "../notification-icons";

import {AppRoute} from "./AppRoute";
import {IAppRoute} from "../config/IAppRoute";
import {IAppMenu} from "../config/IAppMenu";

import {route404} from "../routes/route404";

import {getAppRoutes} from "../config/appRoutes";
import {getAppMenusMain} from "../config/appMenus";
import {apiDynaIntlTranslationsGet} from "../../dyna-intl/api/apiDynaIntlTranslationsGet";

import {CheckAppVersion} from "../components/CheckAppVersion";

import {AppLogo} from "../config/AppLogo";
import {AppMenu} from "../config/AppMenu";
import {AppFooter} from "../config/AppFooter";
import {appConfig} from "../config/appConfig";

import {AccessPageCheck} from "./AccessPageCheck";

export interface IAppRouterProps {
  store: IAppStore;
}

export const AppRouterSwitch = connect((props: IAppRouterProps): JSX.Element => {
  const {
    store,
    store: {
      userAuth: {
        state: {
          user: {
            displayName,
            firstName,
          },
        },
        actions: {clearUserAuthError},
        utils: {
          userHasAllRights,
          userHasAnyOfRights,
        },
      },
      profiles: {state: {profile: {displayName: profileDisplayName}}},
      dynaCMS: {
        state: {
          countryIds,
          languageId,
        },
      },
    },
  } = props;

  const {
    data: appRoutes,
    isLoading: isLoadingAppRoutes,
  } = useLoadData<IAppRoute[]>({
    defaultData: [],
    load: () => getAppRoutes(),
  });

  const {data: translations} = useLoadData<Record<string, string>>({
    defaultData: {},
    reloadDep: {appRoutes},
    load: async () => {
      if (!appRoutes.length) return {};
      const {translations} = await apiDynaIntlTranslationsGet({
        countryId: countryIds[0] || 'gr',
        languageId,
        tks: [
          ...appRoutes.map(appRoute => appRoute.titleTk || "").filter(Boolean),
          ...getProfileNotificationIconOptions(store).map(option => option.labelTk || ""),
          ...getUserNotificationIconOptions(store).map(option => option.labelTk || ""),
        ],
      });
      return translations;
    },
  });

  const profileOptions =
    getProfileNotificationIconOptions(store)
      .map(option => ({
        ...option,
        label: translations[option.labelTk || ""] || option.label,
      }));

  const userOptions =
    getUserNotificationIconOptions(store)
      .map(option => ({
        ...option,
        label: translations[option.labelTk || ""] || option.label,
      }));

  const {data: appMenusMain} = useLoadData<Array<IAppMenu | "DIVIDER">>({
    defaultData: [],
    load: getAppMenusMain,
  });

  const handleLogoClick = (): void => {
    clearUserAuthError();
  };

  const routes = useMemo<JSX.Element[]>(() => {
    const topRoutes: IAppRoute[] = [];
    const restRoutes: IAppRoute[] = [];
    appRoutes.forEach(route => {
      if (route.routePath === '/') topRoutes.push(route); else restRoutes.push(route);
    });

    const routes = ([] as IAppRoute[]).concat(topRoutes, restRoutes);

    if (!isLoadingAppRoutes) routes.push(route404);

    return routes
      .map((
        {
          title,
          titleTk = "",
          menuId,
          routePath,
          exact = false,
          userHasAllRights,
          userHasAnyOfRights,
          requiresLatestClientVersion,
          render,
        }: IAppRoute,
        index: number = 0,
      ): JSX.Element => {
        const applyTitle = translations[titleTk] || title;
        return (
          <Route
            key={index}
            path={routePath}
            exact={exact}
            render={(route) => (
              <AccessPageCheck
                pageTitle={applyTitle}
                userHasAllRights={userHasAllRights}
                userHasAnyOfRights={userHasAnyOfRights}
              >
                <AppRoute
                  appTitle={applyTitle}
                  menuId={menuId}
                >
                  <CheckAppVersion check={!!requiresLatestClientVersion}/>
                  {render({
                    pathParams:
                      route.match.params as any,
                    queryParams:
                      window.location.search
                        ? qs.parse(window.location.search.substring(1)) as any
                        : {} as any,
                  })}
                </AppRoute>
              </AccessPageCheck>
            )}
          />
        );
      });
  }, [
    appRoutes,
    isLoadingAppRoutes,
    Object.values(translations),
  ]);

  const userName = displayName || firstName || undefined;

  return (
    <AppContainerFlow
      appLogo={<AppLogo/>}
      maxWidth={appConfig.pageWidth}

      notificationIcons={appNotificationIcons(store)}

      profileIcon={getProfileNotificationIcon(store)}
      profileName={profileDisplayName}
      profileOptions={profileOptions}

      userIcon={getUserNotificationIcon(store)}
      userName={userName}
      userOptions={userOptions}

      onAppLogoClick={handleLogoClick}
    >
      <Box dataComponentName="AppContent">

        <AppMenu
          selectedMenuId={store.app.state.menuId}
          menus={
            appMenusMain
              .filter(appMenu => (
                appMenu !== "DIVIDER" &&
                userHasAllRights(appMenu.userHasAllRights) &&
                userHasAnyOfRights(appMenu.userHasAnyOfRights) &&
                (appMenu.countryId === undefined || countryIds.includes(appMenu.countryId)) &&
                (appMenu.languageId === undefined || appMenu.languageId === languageId)
              ))
          }
        />

        <Switch>
          {routes}
        </Switch>

        <AppFooter/>

      </Box>

    </AppContainerFlow>
  );
});
