import {serverAppConfig} from "server-app";

import {templateString} from "utils-library/dist/commonJs/template-string";

import {appConfig} from "./core/application/config/appConfig";

// Dev note: This script is updating the missing variables on the DOM.
//           THIS IS HAPPENING ONLY ON DEV ENV!
//           On production the <companyId>-<filename>.<ext> files are used by the `server-app`.

const updateElement = (
  {
    selector,
    attributeName,
    variableName,
    value,
  }: {
    selector: string;
    attributeName: string;
    variableName?: string;
    value: string;
  },
): void => {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(
      `Internal error 202208311053: updateAppHtml: Cannot find element with [${selector}]. Probably the public/index.html is changed and the updateAppHtml is not updated.`,
      {
        selector,
        attributeName,
        value,
      },
    );
    return;
  }

  element.setAttribute(
    attributeName,
    variableName
      ? templateString(element.getAttribute(attributeName) || '', {[variableName]: value})
      : value,
  );
};

updateElement({
  selector: 'html > head > link[rel="icon"]',
  attributeName: 'href',
  variableName: 'companyId',
  value: appConfig.companyId,
});

updateElement({
  selector: 'html > head > link[rel="apple-touch-icon"]',
  attributeName: 'href',
  variableName: 'companyId',
  value: appConfig.companyId,
});

updateElement({
  selector: 'html > head > meta[name="description"]',
  attributeName: 'content',
  variableName: 'description',
  value: appConfig.appDescription.length > 275
    ? appConfig.appDescription.substring(0, 270) + '...'
    : appConfig.appDescription,
});

updateElement({
  // Help: https://content-security-policy.com/examples/blocked-csp/
  selector: 'html > head > meta[http-equiv="Content-Security-Policy"]',
  attributeName: 'content',
  value: [
    'default-src',
    'http://' + window.location.host,
    appConfig.apiBaseUrl,
    'ws' + appConfig.apiBaseUrl.substring(4),
    ...serverAppConfig.defaultSrc,
    appConfig.webBaseUrl,
    ...appConfig.mediaUrls,
    ';',
  ]
    .join(' ')
    .replace(/ ;/g, ';')
  ,
});

const defaultPageImageElement = window.document.getElementById('default-page-image');
if (defaultPageImageElement) defaultPageImageElement.innerText = '';
