import * as React from "react";
import {
  ReactElement,
  useState,
  useMemo,
} from "react";

import {EImageMode} from "dyna-image";

import {Box} from "../Box";
import {Image} from "../Image";
import {useContainerResizeEvent} from "../useContainerResizeEvent";
import {useWindowResize} from "../useWindowResize";

import {getImageSrcSet} from "../ui-interfaces";

export interface IImageResponsiveProps {
  alt: string;
  baseSrc: string;

  relation?:                        // Responsive relation, on screen or on given space
    | 'screen'
    | 'container';
  mode?: EImageMode;                // Default: EImageMode.FIT
  content?: ReactElement;

  showLoadingSpinner?: boolean;     // Default is false
  showBrokenImageOnFail?: boolean;  // Default is true

  horizontalMirror?: boolean;
  verticalMirror?: boolean;
  blackAndWhite?: boolean;

  onLoad?: () => void;
  onError?: (error: any) => void;
}

/**
 * Render the proper responsive images according to the available space.
 * The parent container of the image should have the desired width and height.
 * @param props
 * @constructor
 */
export const ImageResponsive: React.FC<IImageResponsiveProps> = (props) => {
  const {
    alt,
    baseSrc,

    relation = "container",
    mode,
    content,

    showLoadingSpinner,
    showBrokenImageOnFail,

    horizontalMirror,
    verticalMirror,
    blackAndWhite,

    onLoad,
    onError,
  } = props;

  const srcSet = useMemo(() => getImageSrcSet(baseSrc), [baseSrc]);

  const {width: screenWidth} = useWindowResize();
  const [containerWidth, setContainerWidth] = useState(-1);

  const {ref} = useContainerResizeEvent({
    skipOnMount: false,
    onResize: ({width}) => setContainerWidth(width),
  });

  const applySrc = ((): string => {
    const forWidth = relation === "container" ? containerWidth : screenWidth;
    if (forWidth < 192) return srcSet.W192;
    if (forWidth < 384) return srcSet.W384;
    if (forWidth < 768) return srcSet.W768;
    if (forWidth < 1024) return srcSet.W1024;
    if (forWidth < 2048) return srcSet.W2048;
    return srcSet.W4096;
  })();

  const imageProps = {...props};
  delete imageProps.relation;

  return (
    <Box
      ref={ref as any}
      fullWidth
      fullHeight
    >
      <Image
        alt={alt}
        src={applySrc}
        mode={mode}
        fullSizeButton={false}
        content={content}
        showLoadingSpinner={showLoadingSpinner}
        showBrokenImageOnFail={showBrokenImageOnFail}
        horizontalMirror={horizontalMirror}
        verticalMirror={verticalMirror}
        blackAndWhite={blackAndWhite}
        onLoad={onLoad}
        onError={onError}
      />
    </Box>
  );
};
