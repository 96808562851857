import {
  API_PATH_ApiDynaProfilesGetUserInfoGet,
  IApiDynaProfilesGetUserInfoGetRequest,
  IApiDynaProfilesGetUserInfoGetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilesGetUserInfoGet = async (args: IApiDynaProfilesGetUserInfoGetRequest): Promise<IApiDynaProfilesGetUserInfoGetResponse> => {
  return apiFetch.request<IApiDynaProfilesGetUserInfoGetRequest, null, IApiDynaProfilesGetUserInfoGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaProfilesGetUserInfoGet,
    query: args,
  });
};
