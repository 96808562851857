import {IHardcodedTranslations} from "../../dyna-intl/interfaces/IHardcodedTranslations";

export enum ETKAppCore {
  GO_BACK = "dyna-core-app--GO_BACK",
  MAIN_PAGE = "dyna-core-app--MAIN_PAGE",
  PAGE_NOT_FOUND = "dyna-core-app--PAGE_NOT_FOUND",
  PAGE_NOT_FOUND__DESCRIPTION = "dyna-core-app--PAGE_NOT_FOUND--description",
  CLIPBOARD_COPY_NO_PROBLEM__TITLE = "dyna-core-app--CLIPBOARD_COPY_NO_PROBLEM__TITLE",
  CLIPBOARD_COPY_NO_PROBLEM__BODY1 = "dyna-core-app--CLIPBOARD_COPY_NO_PROBLEM__BODY1",
  CLIPBOARD_COPY_NO_PROBLEM__BODY2 = "dyna-core-app--CLIPBOARD_COPY_NO_PROBLEM__BODY2",
  APP_SETTINGS = "dyna-core-app--APP_SETTINGS",
}

export const coreApplicationHardcodedTranslations: IHardcodedTranslations<ETKAppCore> = {
  [ETKAppCore.GO_BACK]: {
    en: 'Back',
    el: 'Πίσω',
  },
  [ETKAppCore.MAIN_PAGE]: {
    en: 'Home page',
    el: 'Κύρια σελίδα',
  },
  [ETKAppCore.PAGE_NOT_FOUND]: {
    en: 'Page does not exist',
    el: 'Η σελιδα δεν υπάρχει',
    de: 'Die Seite existiert nicht',
  },
  [ETKAppCore.PAGE_NOT_FOUND__DESCRIPTION]: {
    en: 'This page doesn\'t exist anymore or the address is wrong.',
    el: 'Η σελίδα δεν υπάρχει πλέον ή η διεύθυνση είναι λάθος.',
  },

  [ETKAppCore.CLIPBOARD_COPY_NO_PROBLEM__TITLE]: {
    en: 'Copy? No problem.',
    el: 'Αντιγράφετε? Κανένα πρόβλημα.',
    de: 'Kopieren Sie etwas? Kein Problem.',
  },
  [ETKAppCore.CLIPBOARD_COPY_NO_PROBLEM__BODY1]: {
    en: "We noticed that you copied text from our website. Don't worry, there's no problem as long as you add redirect links to the article, at the top and bottom. We want to share our content with our partners, but you need to include those redirect links. This is our friendly policy.",
    el: "Παρατηρήσαμε ότι αντιγράψατε κείμενο από την ιστοσελίδα μας. Μην ανησυχείτε, δεν υπάρχει κανένα πρόβλημα, αρκεί να προσθέσετε συνδέσμους ανακατεύθυνσης προς το άρθρο, στην κορυφή και στο κάτω μέρος του. Θέλουμε να μοιραζόμαστε το περιεχόμενό μας με τους συνεργάτες μας, όμως θα πρέπει να προσθέτετε αυτούς τους συνδέσμους ανακατεύθυνσης. Αυτή είναι η φιλική μας πολιτική.",
    de: "Wir haben bemerkt, dass Sie Text von unserer Webseite kopiert haben. Keine Sorge, es gibt kein Problem, solange Sie Weiterleitungslinks zum Artikel oben und unten hinzufügen. Wir möchten unseren Inhalt gerne mit unseren Partnern teilen, aber Sie müssen diese Weiterleitungslinks hinzufügen. Das ist unsere freundliche Richtlinie.",
  },
  [ETKAppCore.CLIPBOARD_COPY_NO_PROBLEM__BODY2]: {
    en: "Do not hesitate to contact us for more information. We are open to collaborations and would be delighted to discuss any cooperation opportunity with you.",
    el: "Μη διστάσετε να επικοινωνήσετε μαζί μας για περισσότερες πληροφορίες. Είμαστε ανοικτοί σε συνεργασίες και θα χαρούμε πολύ να συζητήσουμε οποιαδήποτε ευκαιρία συνεργασίας μαζί σας.",
    de: "Zögern Sie nicht, uns für weitere Informationen zu kontaktieren. Wir sind offen für Zusammenarbeit und würden uns freuen, jede Kooperationsmöglichkeit mit Ihnen zu besprechen.",
  },
  [ETKAppCore.APP_SETTINGS]: {
    en: "Application settings",
    el: "Ρυθμίσεις εφαρμογής",
    de: "Anwendungseinstellungen",
  },
};

