import {IUser} from "../../../../user-authentication/interfaces/IUser";

export const API_PATH_ApiDynaProfilesSearchUsersForProfileGet = '/services/dyna-profiles/apiDynaProfilesSearchUsersForProfileGet';

export interface IApiDynaProfilesSearchUsersForProfileGetRequest {
  profileId: string;
  searchUser: string;     // Search username
  rights: string[];       // Get the right statuses of the user on this profile
  sortByFieldName?: keyof IUser;
  sortDirection?: 0 | -1 | 1;
  skip: number;
  limit: number;
}

export interface IApiDynaProfilesSearchUsersForProfileGetResponse {
  usersAndRights: IUserAndRights[];
}

export interface IUserAndRights {
  user: IUser;
  rights: { [right: string]: boolean };
  /**
   * Can be removed or not from the profile
   *
   * True if the user has not any valid right to the profile (not only what asked)
   * and can be deleted from the profile.
   */
  canBeRemoved: boolean;
}
