import {EDynaCMSBlockImageLabelPosition} from "server-app";

import {Box} from "ui-components/dist/Box";
import {
  FlexContainer,
  FlexItemMin,
  FlexItemMax,
} from "ui-components/dist/FlexContainer";
import {ContainerDetectResize} from "ui-components/dist/ContainerDetectResize";

import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
} from "../../../public-components";
import {DynaCMSLabel} from "../DynaCMSLabel";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

export interface IDynaCMSTextContentHoverProps {
  sx?: SxProps<Theme>;
  showTexts?: boolean;
  title?: string;
  subtitle: string;
  showCaption?: boolean;
  label?: string;
  labelColor?: {
    color?: string;
    backgroundColor?: string;
  };
  labelPosition?: EDynaCMSBlockImageLabelPosition;

  blackBackground?: boolean;  // Default is false

  height?: string | number;   // Default is undefined, means "auto"

  credits?: string;

  children: any;

  onClick?: () => void;
}

export const DynaCMSTextContentHover = (props: IDynaCMSTextContentHoverProps): JSX.Element => {
  const {
    sx = {},
    showTexts = true,
    title,
    subtitle,
    showCaption,
    label,
    labelColor,
    labelPosition = EDynaCMSBlockImageLabelPosition.TOP_RIGHT,

    blackBackground = false,

    height,

    credits,

    children,

    onClick,
  } = props;

  return (
    <ContainerDetectResize
      fullHeight
      onClick={onClick}
    >
      {({width: containerWidth}) => (
        <Box
          dataComponentName="DynaCMSTextContentHover"
          sx={{
            ...sx,
            position: "relative",
            height,
            fontSize: 0,
          }}
        >
          <Box
            show={
              !!credits
              && containerWidth > 200
            }
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 1,
              fontSize: '10px',
              color: 'white',
              opacity: 0.8,
              textShadow: '0 0 8px black',
              writingMode: 'vertical-rl',
              textOrientation: 'mixed',
              transform: 'rotate(180deg)',
            }}
          >
            <FlexContainer>
              <FlexItemMin
                sx={{
                  transform: 'rotate(90deg)',
                  fontWeight: 'bold',
                }}
              >
                &copy;
              </FlexItemMin>
              <FlexItemMax>
                <DynaCMSFontFamily
                  fontFamily={EDynaCMSFontFamily.F100_PLAIN_ROBOTO}
                  component="span"
                >
                  {credits}
                </DynaCMSFontFamily>
              </FlexItemMax>
            </FlexContainer>
          </Box>
          {children}
          <Box show={showTexts && containerWidth > 200}>
            <Box
              sx={{
                position: 'absolute',
                left: credits ? '18px' : '8px',
                bottom: theme => theme.spacing(1),
              }}
            >
              <Box
                show={!!title}
                inline
                sx={{
                  fontSize: theme => theme.typography.fontSize * 3,
                  letterSpacing: -2,
                  color: 'white',
                  textShadow: `0 0 5px black`,
                  backgroundColor: blackBackground ? '#000000bd' : undefined,
                  paddingLeft: theme => blackBackground ? theme.spacing(2) : undefined,
                  paddingRight: theme => blackBackground ? theme.spacing(2) : undefined,
                }}
              >
                {title}
              </Box>
              <Box
                show={!!subtitle && showCaption}
                inline
                sx={{
                  fontSize: theme => theme.typography.fontSize * 2,
                  letterSpacing: -1,
                  color: 'white',
                  textShadow: `0 0 5px black`,
                  backgroundColor: blackBackground ? '#000000bd' : undefined,
                  paddingLeft: theme => blackBackground ? theme.spacing(2) : undefined,
                  paddingRight: theme => blackBackground ? theme.spacing(2) : undefined,
                }}
              >
                {subtitle}
              </Box>
            </Box>
            <DynaCMSLabel
              position={labelPosition}
              foreground={labelColor?.color}
              background={labelColor?.backgroundColor}
              children={label}
            />
          </Box>
        </Box>
      )}
    </ContainerDetectResize>
  );
};
