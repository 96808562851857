import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IArticleCreateProps} from "./ArticleCreate";

const ArticleCreateLazy = lazy(() => import("./ArticleCreate"));

export const ArticleCreate = connect((props: IArticleCreateProps) => (
  <LazyLoadComponent>
    <ArticleCreateLazy {...props}/>
  </LazyLoadComponent>
));
