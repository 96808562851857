
import {EDynaCMSRightsDEPRECATED} from "server-app";

import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaCMSIconMui} from "../public-components";

export const routeDynaCMSMainPagePaths: IAppRoute = {
  title: 'Dyna CMS',
  description: "Dyna Content Management System",
  icon: <DynaCMSIconMui/>,
  routePath: '/dyna-cms',
  getRoutePath: () => routeDynaCMSMainPagePaths.routePath,
  userHasAllRights: [],
  exact: true,
  userHasAnyOfRights: [
    EDynaCMSRightsDEPRECATED.AUTHOR,
    EDynaCMSRightsDEPRECATED.EDITOR,
    EDynaCMSRightsDEPRECATED.REVIEWER,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
