export const sxTextShadow =
  (
    {
      strong = 1,
      blur = 2,
      color = "white",
    }: {
      strong?: number;
      blur?: number;
      color?: string;
    }
    = {},
  ): string => {
    const shadows: string[] = [];
    for (let i = 0; i < strong; i++) {
      shadows.push(getStyle(-i, -i, blur, color));
      shadows.push(getStyle(+i, -i, blur, color));
      shadows.push(getStyle(-i, +i, blur, color));
      shadows.push(getStyle(+i, +i, blur, color));
    }
    return shadows.join(', ');
  };


const getStyle = (x: number, y: number, blur: number, color: string) => `${x}px ${y}px ${blur}px ${color}`;
