import * as React from "react";
import {useEffect} from "react";
import {
  useDropzone,
  Accept,
} from "react-dropzone";
// Help: https://react-dropzone.org

import {contentTypeByExtension} from "utils-library/dist/commonJs/files";

import {Condition} from "../Condition";
import {Collapse} from "../Collapse";

import * as styles from "./InputUploadFiles.module.less";
import {useTheme} from "../ThemeProvider";
import {alpha} from "@mui/system";

export interface IInputUploadFilesProps {
  label: string;
  ariaLabel?: string;
  showSelectedFiles?: boolean; // Default is false
  showRejectedFiles?: boolean; // Default is false

  multiple?: boolean; // Default is true
  maxFiles?: number;  // Default is 0 = unlimited

  fileTypes: TUploadFileType[];

  onChange?: (files: File[], rejectedFiles: File[]) => void;
}

export type TUploadFileType = keyof typeof contentTypeByExtension;

export const InputUploadFiles = (props: IInputUploadFilesProps): JSX.Element => {
  const {
    label,
    ariaLabel,
    showSelectedFiles = false,
    showRejectedFiles = false,
    multiple = true,
    maxFiles = 0,
    fileTypes,
    onChange,
  } = props;
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple,
    maxFiles,
    accept: fileTypes.reduce((acc: Accept, fileType) => {
      // Dev info: this is how should be, but mac's picker, at least, doesn't like it.
      // Const mime = contentTypeByExtension[fileType];
      // If (!acc[mime]) acc[mime] = [];
      // Acc[mime].push(`.${fileType}`);
      const generic = contentTypeByExtension[fileType].split('/')[0] + '/*';
      if (!acc[generic]) acc[generic] = [];
      acc[generic].push(`.${fileType}`);
      (window as any).acc = acc;
      return acc;
    }, {}),
  });

  const theme = useTheme();

  const renderAcceptedFiles = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size / 1024}kb
    </li>
  ));

  const renderRejectedFiles = fileRejections.map(file => (
    <li key={file.file.name} style={{color: 'red'}}>
      {file.file.name} - {file.file.size / 1024}kb
    </li>
  ));

  useEffect(() => {
    onChange && onChange(acceptedFiles, fileRejections.map(fr => fr.file));
  }, [acceptedFiles, fileRejections]);

  return (
    <div className={styles.container}>
      <div
        {...getRootProps({className: styles.dropzone})}
        style={{
          color: alpha(theme.palette.text.primary, 0.5),
          borderColor: alpha(theme.palette.text.primary, 0.5),
          backgroundColor: theme.palette.background.default,
        }}
      >
        <input
          {...getInputProps()}
          aria-label={ariaLabel}
        />
        <div><b>{label}</b></div>
        <div>Drag 'n' drop {multiple ? '' : 'a'} {fileTypes.join(', ')} file{multiple ? 's' : ''} here, or click to select files.</div>
      </div>
      <Condition if={showSelectedFiles}>
        <Collapse expanded={!!renderAcceptedFiles.length}>
          <aside>
            <h4>Files</h4>
            <ul>{renderAcceptedFiles}</ul>
          </aside>
        </Collapse>
      </Condition>
      <Condition if={showRejectedFiles}>
        <Collapse expanded={!!renderRejectedFiles.length}>
          <aside>
            <h4>Rejected files</h4>
            <ul>{renderRejectedFiles}</ul>
          </aside>
        </Collapse>
      </Condition>
    </div>
  );
};
