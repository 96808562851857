import {
  API_PATH_ApiProfileUserRightSet,
  IApiProfileUserRightSetRequest,
  IApiProfileUserRightSetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiProfileUserRightSet = async (args: IApiProfileUserRightSetRequest): Promise<IApiProfileUserRightSetResponse> => {
  return apiFetch.request<null, IApiProfileUserRightSetRequest, IApiProfileUserRightSetResponse>({
    method: 'PUT',
    path: API_PATH_ApiProfileUserRightSet,
    body: args,
  });
};
