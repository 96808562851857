import {
  useState,
  useEffect,
} from "react";

import {Snackbar} from "@mui/material";

import {
  Alert,
  EAlertType,
} from "ui-components/dist/Alert";

import {register} from "../../../serviceWorkerRegistration";

import {AppRouter} from "./AppRouter";

export const AppServiceWorker = (): JSX.Element => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  // Register for updated
  useEffect(() => {
    register({onUpdate: onSWUpdate});
  }, []);

  // Force the update of all registered workers
  useEffect(() => {
    // Check for sw updates on page change
    if (!navigator.serviceWorker) return;
    navigator.serviceWorker
      .getRegistrations()
      .then((regs) => {
        regs.forEach(reg => reg.update());
      });
  }, []);

  const handleClose = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload();
  };

  return (
    <>
      <Snackbar
        open={showReload}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          type={EAlertType.SUCCESS}
          closeButton={{
            label: 'Update now',
            onClick: handleClose,
          }}
        >
          A new version is available!
        </Alert>
      </Snackbar>
      <AppRouter/>
    </>
  );
};
