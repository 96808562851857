import {
  API_PATH_ApiDynaProfileUploadProfileImage,
  IApiDynaProfileUploadProfileImageRequest,
} from "server-app";

import {fileStorageUploadFile} from "../../../file-storage";
import {IDynaError} from "dyna-error";

export const apiDynaProfileUploadProfileImage = (
  {
    profileId,
    imageType,
    file,
    onProgress,
    onSuccess,
    onFail,
  }: {
    profileId: string;
    imageType:
      | "user-profile"
      | "profile-background";
    file: File;
    onProgress: (percentage: number, progressInfo: string) => void;
    onSuccess: (url: string) => void;
    onFail: (error: IDynaError) => void;
  },
) =>
  fileStorageUploadFile<IApiDynaProfileUploadProfileImageRequest>({
    apiPath: API_PATH_ApiDynaProfileUploadProfileImage,
    file,
    body: {
      profileId,
      imageType,
    },
    onProgress,
    onSuccess,
    onFail,
  });
