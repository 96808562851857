import {validateDataMethods} from "utils-library/dist/commonJs/validation-engine";
import {camelToHuman} from "utils-library/dist/commonJs/utils";

export interface IDynaCMSFont {
  fontFamily: EDynaCMSFontFamily;

  fontSize: EFontSize;

  bold: EBold;

  italic: EItalic;

  underline: EUnderline;

  textAlign: ETextAlign;

  textTransform: ETextTransform;

  letterSpacingInherit: boolean;
  letterSpacing: number;
  fontStretchInherit: boolean;
  fontStretchPercentage: number;        // Percentage

  strokeInherit: boolean;
  strokeWidth: number;
  strokeColor: string;

  opacityInherit: boolean;
  opacity: number;

  scale: boolean;
  scaleX: number;
  scaleY: number;
}

export enum EBold {
  INHERIT = "INHERIT",
  B100 = "B100",
  B200 = "B200",
  B300 = "B300",
  B400 = "B400",
  B500 = "B500",
  B600 = "B600",
  B700 = "B700",
  B800 = "B800",
  B900 = "B900",
  B1000 = "B1000",
  BOLD = "BOLD",
}

export enum EFontSize {
  INHERIT = "inherit",
  PX6 = '6px',
  PX8 = '8px',
  PX9 = '9px',
  PX10 = '10px',
  PX11 = '11px',
  PX12 = '12px',
  PX13 = '13px',
  PX14 = '14px',
  PX16 = '16px',
  PX18 = '18px',
  PX20 = '20px',
  PX24 = '24px',
  PX28 = '28px',
  PX32 = '32px',
  PX36 = '36px',
  PX42 = '42px',
  PX64 = '64px',
  PX96 = '96px',
  PX128 = '128px',
  PX192 = '192px',
  PX256 = '256px',
}

export enum EItalic {
  INHERIT = "inherit",
  ITALIC = "ITALIC",
  NON_ITALIC = "NON_ITALIC",
}

export enum EUnderline {
  INHERIT = "inherit",
  UNDERLINE = "UNDERLINE",
  NON_UNDERLINE = "NON_UNDERLINE",
}

export enum ETextAlign {
  INHERIT = "inherit",
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ETextTransform {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform
  INHERIT = "inherit",
  NONE = "none",
  CAPITALIZE = "capitalize",
  UPPERCASE = "uppercase",
  LOWERCASE = "lowercase",
  FULL_WIDTH = "full-width",
  FULL_WIDTH_KANA = "full-width-kana",
}

export enum EDynaCMSFontFamily {
  // The order of the fonts is based on client's dynaCMSFonts implementation
  INHERIT = "INHERIT",

  // PLain

  F100_PLAIN_ROBOTO = "F100",                   // Langauges EN EL

  // Times

  F101_TIMES_BASE_MARCELLUS = "F101",           // Langauges EN
  F125_TIMES_BASE_PRATA = "F125",               // Langauges EN
  F173_TIMES_MIDDLEAGE_ARINK = "F173",          // Langauges EN
  F153_TIMES_BOOK_TINOS = "F153",               // Langauges EN, EL
  F140_TIMES_BOOK_GENTIUM_PLUS = "F140",        // Langauges EN, EL
  F187_TIMES_TERMINAL = "F187",                 // Langauges EN

  // Celine

  F132_CELINE_BASE_JOSEFIN = "F132",            // Langauges EN
  F154_CELINE_TIMES_NUOSU = "F154",             // Langauges EN
  F155_CELINE_ROUND_FREDOKA = "F155",           // Langauges EN
  F161_CELINE_CONDENSED = "F161",               // Langauges EN
  F164_CELINE_XCONDENSED = "F164",              // Langauges EN
  F165_CELINE_XXCONDENSED = "F165",             // Langauges EN
  F160_CELINE_CALLIGRAPHY_POIRET_ONE = "F160",  // Langauges EN

  // Modern

  F123_MODERN_BASE_MUSEO_MODERNO = "F123",      // Langauges EN
  F135_MODERN_BASE_NOTO = "F135",               // Langauges EN EL

  // Fashion

  F126_FASHION_BASE_RALEWAY = "F126",           // Langauges EN
  F127_FASHION_CONDENSED_RADHANI = "F127",      // Langauges EN
  F186_FASHION_EMPIRE_FS = "F186",              // Langauges EN
  F188_FASHION_OSWALD = "F188",                 // Langauges EN

  // Elegant

  F128_ELEGANT_BASE_ITALIANA = "F128",          // Langauges EN
  F138_ELEGANT_BASE_ROBOTO_FLEX = "F138",       // Langauges EN EL

  // Impact

  F129_IMPACT_BASE_ANTON = "F129",              // Langauges EN
  F139_IMPACT_BASE_FIRA = "F139",               // Langauges EN EL

  // Calligraphy

  F130_CALLIGRAPHY_BASE_PINYON = "F130",        // Langauges EN
  F177_CALLIGRAPHY_CHERRY = "F177",             // Langauges EN
  F182_CALLIGRAPHY_LOVERS_QUARREL = "F182",     // Langauges EN
  F184_CALLIGRAPHY_MEA_CULPA = "F184",          // Langauges EN
  F185_CALLIGRAPHY_LETTERISH_YUJI_MAI = "F185", // Langauges EN
  F183_CALLIGRAPHY_E = "F183",                  // Langauges EN

  // Artistic

  F168_ARTISTIC_LINES_DOUBLE_TRAIN = "F168",    // Langauges EN
  F131_ARTISTIC_LINES_MULTI_MONOTON = "F131",   // Langauges EN
  F169_ARTISTIC_CINEMA_LINELIGHT = "F169",      // Langauges EN
  F170_ARTISTIC_CURLY_GRIFFY = "F170",          // Langauges EN

  // Animal print

  F171_ANIMALPRINT_CHEETAH = "F171",            // Langauges EN
  F172_ANIMALPRINT_COW = "F172",                // Langauges EN

}

export const getDefaultIDynaCMSFont = (): IDynaCMSFont => ({
  fontFamily: EDynaCMSFontFamily.INHERIT,
  fontSize: EFontSize.INHERIT,
  bold: EBold.INHERIT,
  italic: EItalic.INHERIT,
  underline: EUnderline.INHERIT,
  textAlign: ETextAlign.INHERIT,
  textTransform: ETextTransform.INHERIT,
  letterSpacingInherit: true,
  letterSpacing: -1,
  fontStretchInherit: true,
  fontStretchPercentage: 100,
  strokeInherit: true,
  strokeWidth: 0,
  strokeColor: 'background',
  opacityInherit: true,
  opacity: 1,
  scale: false,
  scaleX: 1,
  scaleY: 1,
});

const fontValidationRules: Record<keyof IDynaCMSFont, (value: any) => string> = {
  fontFamily: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, EDynaCMSFontFamily);
  },
  fontSize: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, EFontSize);
  },
  italic: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, EItalic);
  },
  underline: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, EUnderline);
  },
  bold: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, EBold);
  },
  textAlign: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, ETextAlign);
  },
  textTransform: value => {
    if (value === undefined) return "";
    return validateDataMethods.isEnumValue(value, ETextTransform);
  },
  letterSpacingInherit: value => {
    if (value === undefined) return "";
    return validateDataMethods.isBoolean(value);
  },
  letterSpacing: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, -16, 16);
  },
  fontStretchInherit: value => {
    if (value === undefined) return "";
    return validateDataMethods.isBoolean(value);
  },
  fontStretchPercentage: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, 50, 200);
  },
  strokeInherit: value => {
    if (value === undefined) return "";
    return validateDataMethods.isBoolean(value);
  },
  strokeWidth: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, 0, 24);
  },
  strokeColor: value => {
    if (value === undefined) return "";
    return validateDataMethods.isValidText(value, 0, 20);
  },
  opacityInherit: value => {
    if (value === undefined) return "";
    return validateDataMethods.isBoolean(value);
  },
  opacity: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, 0, 1);
  },
  scale: value => {
    if (value === undefined) return "";
    return validateDataMethods.isBoolean(value);
  },
  scaleY: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, 0.5, 1.5);
  },
  scaleX: value => {
    if (value === undefined) return "";
    return validateDataMethods.isNumberInRange(value, 0.5, 1.5);
  },
};

export const validateIDynaCMSFont = (value: IDynaCMSFont): string => {
  return Object
    .entries(value)
    .map((entry: any) => {
      const [property, value] = entry;
      const validator = fontValidationRules[property];
      if (!validator) return `validateIDynaCMSFont: property [${property}] is not expected`;
      const validation = validator(value);
      if (!validation) return "";
      return `${camelToHuman(property)}: ${validation} Value: [${value}]`;
    })
    .filter(Boolean)
    .join(', ');
};
