import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

const OAuthSignResolutionPage = lazy(() => import("../pages/SignResolutionPage"));

export const routeOAuthSignResolutionInPage: IAppRoute<void, {userAuthErrorMessage4502: string}> = {
  menuId: '',
  title: 'Signing In...',
  routePath: '/oauth-sign-resolution',
  getRoutePath: () => routeOAuthSignResolutionInPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  exact: true,
  render: ({queryParams: {userAuthErrorMessage4502}}) => (
    <LazyLoadComponent>
      <OAuthSignResolutionPage
        userAuthErrorMessage={userAuthErrorMessage4502}
      />
    </LazyLoadComponent>
  ),
};
