exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DynaImage-module-root--1YPv5yzG5UqC {\n  width: 100%;\n  height: 100%;\n}\n.DynaImage-module-imageContainer--36M3tFtJbOOT {\n  width: 100%;\n  height: 100%;\n  background-position: center center;\n  background-repeat: no-repeat;\n  position: relative;\n}\n.DynaImage-module-contentContainer--3xbQSbz0jif1 {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n.DynaImage-module-loadFailedContainer--1eK3Vl6IX8bk {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  color: gray;\n}\n.DynaImage-module-brokenIcon--2YJuF_apl3Qv {\n  width: 20% !important;\n  height: 20% !important;\n}\n", ""]);

// exports
exports.locals = {
	"root": "DynaImage-module-root--1YPv5yzG5UqC",
	"imageContainer": "DynaImage-module-imageContainer--36M3tFtJbOOT",
	"contentContainer": "DynaImage-module-contentContainer--3xbQSbz0jif1",
	"loadFailedContainer": "DynaImage-module-loadFailedContainer--1eK3Vl6IX8bk",
	"brokenIcon": "DynaImage-module-brokenIcon--2YJuF_apl3Qv"
};