import * as React from "react";
import {ReactElement} from "react";

import {
  FlexContainerHorizontal,
  FlexItemMin,
} from "../../../../../../FlexContainer";
import {Box} from "../../../../../../Box";
import {BreakpointDeviceContainer} from "../../../../../../BreakpointDeviceContainer";
import {
  IconButton,
  EIconButtonSize,
} from "../../../../../../IconButton";

export interface IEntityIconProps {
  icon?: ReactElement;
  name?: string;
  menuId: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EntityIcon: React.FC<IEntityIconProps> = (
  {
    icon,
    name = "",
    menuId,
    onClick,
  },
) => {
  if (!icon) return null;

  return (
    <FlexItemMin>
      <FlexContainerHorizontal alignVertical="middle">
        <FlexItemMin hidden={!name}>
          <BreakpointDeviceContainer tablet>
            <Box sx={{textAlign: 'right'}}>
              {name}
            </Box>
          </BreakpointDeviceContainer>
          <BreakpointDeviceContainer allExcept mobile tablet>
            <Box sx={{whiteSpace: 'pre'}}>
              {name}
            </Box>
          </BreakpointDeviceContainer>
        </FlexItemMin>
        <FlexItemMin>
          <IconButton
            sx={{
              color: theme => theme.palette.text.primary,
              '& img': {
                height: 40,
                borderRadius: 20,
              },
            }}
            icon={icon}
            ariaLabel="User profile"
            size={EIconButtonSize.LARGE}
            ariaControls={menuId}
            ariaHasPopUp
            onClick={onClick}
          />
        </FlexItemMin>
      </FlexContainerHorizontal>
    </FlexItemMin>
  );
};
