import {Box} from "ui-components/dist/Box";
import {SocialButtons} from "ui-components/dist/SocialButtons";

import {IAppStore} from "../../../../state/IAppStore";

import {
  DynaCMSFontFamily,
  EDynaCMSFontFamily,
} from "../../../../core/dyna-cms/public-components";

export interface IAdoreGrSocialButtonsProps {
  store: IAppStore;
  textAlign?: 'left' | 'center' | 'right';
  onClick?: () => void;
}

export const AdoreGrSocialButtons = (props: IAdoreGrSocialButtonsProps): JSX.Element => {
  const {
    store: {
      dynaCMS: {
        state: {
          settings: {
            applicationFacebookPage,
            applicationInstagramAccount,
            applicationTwitterAccount,
            applicationPinterestAccount,
            applicationYoutubeAccount,
            applicationTikTikAccount,
            applicationLinkedinCompanyAccount,
          },
        },
      },
    },
    textAlign = 'center',
    onClick,
  } = props;

  return (
    <Box sx={{textAlign}}>
      <DynaCMSFontFamily
        fontFamily={EDynaCMSFontFamily.F131_ARTISTIC_LINES_MULTI_MONOTON}
        sx={{
          fontSize: theme => theme.spacing(3),
          whiteSpace: 'nowrap',
        }}
      >
        FOLLOW US
      </DynaCMSFontFamily>
      <SocialButtons
        facebookPage={applicationFacebookPage}
        instagramAccount={applicationInstagramAccount}
        twitterAccount={applicationTwitterAccount}
        pinterestAccount={applicationPinterestAccount}
        youTubeAccount={applicationYoutubeAccount}
        tiktokAccount={applicationTikTikAccount}
        linkedinCompanyAccount={applicationLinkedinCompanyAccount}
        onClick={onClick}
      />
    </Box>
  );
};
