import {
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import {useDebouncedCallback} from "use-debounce";

import {
  TUnitOfTime,
  getDateTimeDiff,
  areValuesEqual,
} from "utils-library/dist/commonJs/utils";
import {hashMd5} from "utils-library/dist/commonJs/hash-md5";
import {IDBEntityBase} from "utils-library/dist/commonJs/db-entity-interfaces";
import {DataLimitedContainerMemory} from "utils-library/dist/commonJs/data-limited-container-memory";
import {createDebugSetup} from "utils-library/dist/commonJs/debug";

import {useForceRender} from "../useForceRender";
import {useIsMounted} from "../useIsMounted";

export interface IUseLoadDBEntityDocsDataPlacesCache<TData extends IDBEntityBase> {
  search: any;
  cache: {
    containerName: string;
    maxSizeInBytes: number;
    ignoreCacheOlderThan?: {
      number: number;
      unitOfTime: TUnitOfTime;
    };
    /**
     * Update silently the existed items with their new version.
     *
     * **Note:** This updates the existed items, doesn't change the items completely.
     *
     * If the hook had provided cached content, it will still request the latest version from the backend.
     *
     * Setting this to true will update the provided items with the new one if they match, and it is different.
     */
    updateSilentlyExistedContent?: boolean;
    /**
     * If the new content loads within this time limit, then apply new content.
     *
     * This will replace the existing cached content, and the user might notice the change (lick flicker),
     * but this reduces the need for the user's interaction to show new content if the load is too fast.
     */
    applyAllContentIfLoadedWithinMs?: number;
  };
  load: (args: ILoadArgs) => Promise<TData[]>;
}

export interface ILoadArgs {
  skip: number;
  limit: number;
  publishedBefore?: number;
}

export interface IUseLoadDBEntityDocsDataPlacesCacheApi<TData extends IDBEntityBase> {
  isLoading: boolean;
  isLoadingMore: boolean;
  loadError: Error | null;

  dataPlace: (id: string) => IDataPlace<TData>;

  hasMore: boolean;

  /**
   * Indicates whether the retrieved content is considered newer (actually different) than the provided cached content.
   * This flag triggers a scenario where the UI should prompt the user to load the updated view content
   * and invoke the applyTheUpdatedContent() method.
   */
  newItemsAvailable: boolean;
  applyUpdatedContent: () => void;
}

export interface IDataPlace<TData> {
  id: string;
  loadState: ELoadState;
  data: TData | null;
  loadError: Error | null;
  fromCacheAt: number;      // 0 = actual while other values is the timestamp of the cache creation
}

export enum ELoadState {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  LOADED = "LOADED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  FAILED = "FAILED",
}

export interface IDBEntityPublished extends IDBEntityBase {
  publishedAt: number;
}

const debugSetup = createDebugSetup("useLoadDBEntityDocsDataPlacesCache");

export const useLoadDBEntityDocsDataPlacesCache = <TData extends IDBEntityPublished>(
  {
    search,
    cache: {
      containerName,
      maxSizeInBytes,
      ignoreCacheOlderThan,
      updateSilentlyExistedContent = false,
      applyAllContentIfLoadedWithinMs,
    },
    load,
  }: IUseLoadDBEntityDocsDataPlacesCache<TData>,
): IUseLoadDBEntityDocsDataPlacesCacheApi<TData> => {
  const getIsMounted = useIsMounted();
  const _forceRender = useForceRender();
  const __forceRender = (): void => {
    if (!getIsMounted()) return;
    _forceRender();
  };

  const refPlaces = useRef<Record<string, IDataPlace<TData>>>({});
  const getDataPlaces = (): IDataPlace<TData>[] => Object.values(refPlaces.current);

  const [hasMore, setHasMore] = useState(false);
  const refUiIsLoading = useRef(false);
  const uiIsLoadingMore: boolean =
    refUiIsLoading.current
    && !!getDataPlaces().find(dataPlace => dataPlace.loadState === ELoadState.IN_PROGRESS);

  const refNetworkIsLoading = useRef(false);
  const [newItemsAvailable, setNewItemsAvailable] = useState(false);
  const [loadError, setLoadError] = useState<Error | null>(null);

  const cache = useMemo(
    () => new DataLimitedContainerMemory<TData[]>({
      containerName,
      maxSizeInBytes,
    }),
    [],
  );

  useEffect(() => {
    loadForNewPlaces();
  }, []);

  // Debug
  const debug = useMemo(
    () => debugSetup.startOperation({
      onLogCaptureData: () => ({
        search,
        dataPlaces: getDataPlaces(),
        uiIsLoading: refUiIsLoading.current,
        uiIsLoadingMore,
        hasMore,
        isMount: getIsMounted(),
        networkIsLoading: refNetworkIsLoading.current,
      }),
    }),
    [],
  );
  useEffect(() => {
    debug.log('useLoadDBEntityDocsDataPlacesCache hook loaded');
    return () => debug.log('useLoadDBEntityDocsDataPlacesCache hook unloaded');
  }, []);
  const forceRender = debug.logMethod('forceRender', __forceRender);

  const dataPlace = (placeId: string): IDataPlace<TData> => {
    if (refPlaces.current[placeId]) return refPlaces.current[placeId];
    const dataPlace: IDataPlace<TData> = {
      id: placeId,
      loadState: ELoadState.NOT_STARTED,
      data: null,
      loadError: null,
      fromCacheAt: -1,
    };
    refPlaces.current[placeId] = dataPlace;
    debug.log('adding data place ' + placeId);
    if (getIsMounted()) loadForNewPlaces();
    return dataPlace;
  };

  const mainDebug = debug;

  const loadForNewPlacesCore = async (): Promise<void> => {
    const debug = mainDebug.startChildOperation({operationName: 'loadForNewPlacesCore'});
    if (refNetworkIsLoading.current) return; // Exit, a loading process is already in progress

    const newPlaces =
      getDataPlaces()
        .filter(dataPlace => dataPlace.loadState === ELoadState.NOT_STARTED);
    newPlaces.forEach(dataPlace => dataPlace.loadState = ELoadState.IN_PROGRESS);
    debug.log('new places detected: ' + newPlaces.length, {newPlaces});
    if (!newPlaces.length) return; // Exit, there are no new places

    forceRender(); // Updates that they are IN_PROGRESS;
    const skip = 0;
    const limit = newPlaces.length;
    const publishedBefore =
      getDataPlaces()
        .filter(dataPlace => dataPlace.data?.publishedAt)
        .pop()
        ?.data
        ?.publishedAt;

    const searchId = hashMd5({
      search,
      skip,
      limit,
      publishedBefore,
    });

    try {
      debug.log("setUiIsLoading(true) - Start");
      refUiIsLoading.current = true;
      refNetworkIsLoading.current = true;
      forceRender();

      // Load the items, from cache first and then from the real resource
      const {
        items,
        fromCacheAt,
      }: {
        items: TData[];
        fromCacheAt: number;
      } = await (async () => {
        const cached = await debug.promiseTimeCounter('Load cache', async () => {
          const cached = await cache.load(searchId);
          return cached;
        });

        debug.log('load items general setup', {
          search,
          skip,
          limit,
          publishedBefore,
          cached: {cached},
          ignoreCacheOlderThan,
          cacheStats: await cache.stats(),
        });

        if (
          cached
          && (
            !ignoreCacheOlderThan
            || (
              getDateTimeDiff(
                cached.meta.updatedAt,
                Date.now(),
                ignoreCacheOlderThan.unitOfTime,
              )
              < ignoreCacheOlderThan.number
            )
          )
        ) {
          // The cache is not considered old, use it
          // But load in parallel the actual and save it then in the cache
          debug.log("CACHE will be used");
          debug.log("setUiIsLoading(false) - cache will be used");
          refUiIsLoading.current = false;
          const loadStarted = Date.now();
          load({
            skip,
            limit: limit + 1,
            publishedBefore,
          })
            .then(loadedNewItems => {
              if (!getIsMounted()) return;
              const newItems = loadedNewItems.slice(0, limit);
              setHasMore(loadedNewItems.length === limit + 1);
              // Save it in the cache for later use
              cache.save(searchId, newItems).catch(error => console.error('Error 20240123203756 saving to cache', error));
              // Turn the flag newItemsAvailable if has newer content
              if (
                !publishedBefore      // This is the initial load
                && cached.data.length // But the cache has items
              ) {
                const hasNewerItems = cached.data[0].id !== newItems[0].id;
                if (hasNewerItems) {
                  if (
                    applyAllContentIfLoadedWithinMs !== undefined &&
                    Date.now() - loadStarted < applyAllContentIfLoadedWithinMs
                  ) {
                    applyUpdatedContent();              // Overwrite the existing content
                  }
                  else {
                    setNewItemsAvailable(true);   // Ask the user when to overwrite with new content
                  }
                }
              }
              // Update the existed content if updateSilentlyExistedContent
              if (updateSilentlyExistedContent) {
                let changed = false;
                newItems.forEach(newItem => {
                  getDataPlaces()
                    .forEach(dataPlace => {
                      if (!dataPlace.data) return;
                      if (newItem.id !== dataPlace.data.id) return;
                      if (areValuesEqual(newItem, dataPlace.data)) return;
                      dataPlace.data = newItem;
                      dataPlace.fromCacheAt = 0;
                      changed = true;
                    });
                });
                if (changed) forceRender(); // To update the UI
              }
            })
            .catch(error => {
              if (!getIsMounted()) return;
              console.error('Error 20240123203755 loading items', error);
              setLoadError(error);
            })
            .finally(() => {
              if (!getIsMounted()) return;
              refNetworkIsLoading.current = false;
              loadForNewPlaces(); // Load the next
            });

          debug.log(
            'cache applied',
            {
              items: cached.data,
              fromCacheAt: cached.meta.createdAt,
            },
          );

          forceRender();
          return {
            items: cached.data,
            fromCacheAt: cached.meta.createdAt,
          };
        }
        else {
          // There is no cache or the cache it is too old
          // Load the items, and save then in cache in parallel
          debug.log("NETWORK will be used");
          const loadedItems = await load({
            skip,
            limit: limit + 1,
            publishedBefore,
          });
          const items = loadedItems.slice(0, limit);
          if (getIsMounted()) {
            cache.save(searchId, items)
              .catch(error => console.error('Error 20240123203756 saving to cache', error));
            setHasMore(loadedItems.length === limit + 1);
            debug.log("setUiIsLoading(false)");
            refUiIsLoading.current = false;
            debug.log("network applied");
            refNetworkIsLoading.current = false;
          }
          return {
            items,
            fromCacheAt: Date.now(),
          };
        }
      })();

      debug.log(
        'Finalizing - before update',
        {
          places: refPlaces.current,
          loadedItems: items,
        });

      items.forEach(data => {
        const place = newPlaces.shift();
        if (!place) throw new Error('Internal error 20240122185119');
        place.loadState = ELoadState.LOADED;
        place.data = data;
        place.fromCacheAt = fromCacheAt;
      });
      while (newPlaces.length) {
        const place = newPlaces.shift();
        if (!place) throw new Error('Internal error 20240122185120');
        place.loadState = ELoadState.NOT_AVAILABLE;
      }
      debug.log(
        'Finalizing - update complete',
        {
          places: refPlaces.current,
          loadedItems: items,
        });
    }
    catch (e: any) {
      while (newPlaces.length) {
        const place = newPlaces.shift();
        if (!place) throw new Error('Internal error 20240122185121');
        place.loadState = ELoadState.FAILED;
      }
      setLoadError(e);
    }
    finally {
      debug.log("setUiIsLoading(false)");
      refUiIsLoading.current = false;
      debug.log("COMPLETED");
    }

    forceRender();      // Apply the changes
    loadForNewPlaces(); // Load the next
  };
  const loadForNewPlaces = useDebouncedCallback(
    loadForNewPlacesCore,
    0,  // Dev info: The loadForNewPlacesCore is called in real after 50ms, but is also with setTimeout instead of useDebouncedCallback
    {leading: false},
  );

  const applyUpdatedContent = (): void => {
    getDataPlaces()
      .forEach(dataPlace => {
        dataPlace.loadState = ELoadState.NOT_STARTED;
        dataPlace.data = null;
        dataPlace.loadError = null;
        dataPlace.fromCacheAt = 0;
      });
    setNewItemsAvailable(false);
    loadForNewPlaces();
  }; // End of loadForNewPlaces

  debug.log('hook RENDER');

  return {
    isLoading: refUiIsLoading.current,
    isLoadingMore: uiIsLoadingMore,

    loadError,
    dataPlace,

    hasMore,

    newItemsAvailable,
    applyUpdatedContent,
  };
};
