import {
  useEffect,
  useRef,
} from 'react';

type GlobalEventName = keyof WindowEventMap;

export const useGlobalEvent = <T extends GlobalEventName>(
  eventName:
    | T
    | 'copy',
  cb: (event: WindowEventMap[T]) => void,
): void => {
  const savedCallback = useRef(cb);

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    const eventListener = (event: WindowEventMap[T]) => {
      savedCallback.current(event);
    };
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName]);
};
