import {IStorageApi} from "./interfaces";

export interface IDataLimitedContainerConfig {
  containerName: string;
  maxSizeInBytes: number;
  storage: IStorageApi;
}

export interface IItemMeta {
  id: string;
  size: number;
  createdAt: number;
  updatedAt: number;
  readAt: number;
}

export class DataLimitedContainer<TData> {
  //#region "Public"

  constructor(private config: IDataLimitedContainerConfig) {
  }

  public async save(id: string, data: TData): Promise<void> {
    const {
      maxSizeInBytes,
      containerName,
      storage: {onSave},
    } = this.config;
    const rawData = JSON.stringify(data);
    if (rawData.length > maxSizeInBytes) {
      console.error(`DataLimitedContainer.save error 20240123193638: Item with id [${id}] exceeds the maxSizeInBytes: ${maxSizeInBytes} and cannot be saved`, {data});
      return;
    }
    await this.makeSpaceIfNeeded(id, rawData.length);
    await onSave(containerName, id, rawData);
    await this.metaAddUpdateItem(id, rawData);
  }

  public async load(id: string): Promise<{ data: TData; meta: IItemMeta } | null> {
    const {
      containerName,
      storage: {onLoad},
    } = this.config;

    const rawData = await onLoad(containerName, id);
    if (!rawData) return null;

    this.metaSetUsedItem(id).catch(error => console.error(error)); // Set it in parallel for faster resolve

    const metaData = await this.loadMetaData();
    const meta = metaData[id];
    if (!meta) throw new Error(`Internal error 20240125192929: meta not found for this id: [${id}] but data exist. Meta data looks to be broken`);

    return {
      data: JSON.parse(rawData),
      meta,
    };
  }

  public async getItemMeta(id: string): Promise<IItemMeta | null> {
    const metaData = await this.loadMetaData();
    return metaData[id] || null;
  }

  public async delete(id: string): Promise<void> {
    const {
      containerName,
      storage: {onDelete},
    } = this.config;
    await onDelete(containerName, id);
    await this.metaDeleteItem(id);
  }

  public async deleteAll(): Promise<void> {
    const metaData = await this.loadMetaData();
    const ids = Object.keys(metaData);
    for (const id of ids) await this.delete(id);
  }

  //#endregion "Public"

  //#region "Utils"

  public async stats() {
    const {maxSizeInBytes} = this.config;
    const metaData = await this.loadMetaData();
    const occupiedBytes = Object.values(metaData).reduce((acc: number, item) => acc + item.size, 0);

    return {
      itemsMeta:
        Object.values(metaData)
          .sort((itemA, itemB) => itemA.readAt - itemB.readAt),
      space: {
        max: this.config.maxSizeInBytes,
        occupied: {
          bytes: occupiedBytes,
          percentage: 100 * occupiedBytes / maxSizeInBytes,
        },
        free: {
          bytes: maxSizeInBytes - occupiedBytes,
          percentage: 100 * (maxSizeInBytes - occupiedBytes) / maxSizeInBytes,
        },
      },
    };
  }

  //#endregion "Utils"

  //#region "Internal utils"

  private async makeSpaceIfNeeded(id: string, neededSpace: number): Promise<void> {
    const {maxSizeInBytes} = this.config;

    const metaData = await this.loadMetaData();
    const items =
      Object.values(metaData)
        .sort((itemA, itemB) => itemA.readAt - itemB.readAt);

    const occupiedSpace =
      items
        .filter(item => item.id !== id) // Do not add this as occupied, since it is going to be added again
        .reduce((acc: number, item) => acc + item.size, 0);
    const spaceToFreeUp = Math.max(neededSpace + occupiedSpace - maxSizeInBytes, 0);

    if (spaceToFreeUp === 0) return; // Exit, no need to free up space

    let freedSpace = 0;
    while (freedSpace < spaceToFreeUp && items.length) {
      const item = items.shift();
      if (item) {
        await this.delete(item.id);
        freedSpace += item.size;
      }
    }
  }

  //#endregion "Internal utils"

  //#region "Meta handling"

  private async metaAddUpdateItem(id: string, dataRaw: string): Promise<void> {
    const metaData = await this.loadMetaData();
    const itemMeta = metaData[id];
    const now = Date.now();
    if (itemMeta) {
      itemMeta.size = dataRaw.length;
      itemMeta.updatedAt = now;
    }
    else {
      metaData[id] = {
        id,
        size: dataRaw.length,
        createdAt: now,
        updatedAt: now,
        readAt: now,
      };
    }
    await this.saveMetaData();
  }

  private async metaDeleteItem(id: string): Promise<void> {
    const metaData = await this.loadMetaData();
    const itemMeta = metaData[id];
    if (!itemMeta) throw new Error(`internal error 20240123115047: Cannot deleteItemMeta, item not found with id`);
    delete metaData[id];
    await this.saveMetaData();
  }

  private async metaSetUsedItem(id: string): Promise<void> {
    const metaData = await this.loadMetaData();
    const itemMeta = metaData[id];
    if (!itemMeta) throw new Error(`internal error 20240123115048: Cannot setUsed, item not found with id`);
    itemMeta.readAt = Date.now();
    await this.saveMetaData();
  }

  private _metaDataLoaded = false;
  private _metaData: Record<string, IItemMeta> = {};

  private async loadMetaData(): Promise<Record<string, IItemMeta>> {
    if (this._metaDataLoaded) return this._metaData;
    const {
      containerName,
      storage: {onLoad},
    } = this.config;
    const raw = await onLoad(containerName + '__meta', 'items');
    this._metaDataLoaded = true;
    return this._metaData = raw ? JSON.parse(raw) as any : {};
  }

  private async saveMetaData(): Promise<void> {
    const {
      containerName,
      storage: {onSave},
    } = this.config;
    await onSave(containerName + '__meta', 'items', JSON.stringify(this._metaData || []));
  }

  //#endregion "Meta handling"
}
