import {
  IDynaCMSFont,
  getDefaultIDynaCMSFont,
} from "./IDynaCMSFont";

export enum EDynaCMSBlockType {
  HEADING = "HEADING",
  BODY = "BODY",
  MARKDOWN = "MARKDOWN",
  QUOTE = "QUOTE",
  LINKS = "LINKS",
  IMAGE = "IMAGE",
  IMAGES = "IMAGES",
  VIDEO_FILE = "VIDEO_FILE",
  VIDEO_YOUTUBE = "VIDEO_YOUTUBE",
  VIDEO_VIMEO = "VIDEO_VIMEO",
  SOUND_SOUNDCLOUD = "SOUND_SOUNDCLOUD",
  MULTIMEDIA = "MULTIMEDIA",
  SOCIAL_POST_FACEBOOK = "SOCIAL_POST_FACEBOOK",
  SOCIAL_POST_TWITTER = "SOCIAL_POST_TWITTER",
  SOCIAL_POST_INSTAGRAM = "SOCIAL_POST_INSTAGRAM",
  SOCIAL_POST_LINKEDIN = "SOCIAL_POST_LINKEDIN",
  SOCIAL_POST_TIKTOK = "SOCIAL_POST_TIKTOK",
}

export type TContentBlock =
  | IDynaCMSBlockHeader
  | IDynaCMSBlockBody
  | IDynaCMSBlockMarkdown
  | IDynaCMSBlockQuote
  | IDynaCMSBlockLinks
  | IDynaCMSBlockImage
  | IDynaCMSBlockImages
  | IDynaCMSBlockVideoFile
  | IDynaCMSBlockVideoYouTube
  | IDynaCMSBlockVideoVimeo
  | IDynaCMSBlockSoundSoundCloud
  | IDynaCMSBlockMultimedia
  | IDynaCMSBlockSocialPostInstagram
  | IDynaCMSBlockSocialPostTwitter
  | IDynaCMSBlockSocialPostLinkedIn
  | IDynaCMSBlockSocialPostTikTok
  ;

export interface IDynaCMSBlockBase {
  type: EDynaCMSBlockType;
}

// #region "Heading"

export interface IDynaCMSBlockHeader extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.HEADING;
  heading:
    | 1
    | 2
    | 3
    | 4
    | 5;
  text: string;
  font: IDynaCMSFont;
}

export const getDefaultDynaCMSBlockHeader = (): IDynaCMSBlockHeader => ({
  type: EDynaCMSBlockType.HEADING,
  heading: 1,
  font: getDefaultIDynaCMSFont(),
  text: "",
});

// #endregion "Heading"

// #region "Body"

export interface IDynaCMSBlockBody extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.BODY;
  font: IDynaCMSFont;
  headingFirstLetter: boolean;
  html: string;
}

export const getDefaultDynaCMSBlockBody = (): IDynaCMSBlockBody => ({
  type: EDynaCMSBlockType.BODY,
  font: getDefaultIDynaCMSFont(),
  headingFirstLetter: false,
  html: "",
});

// #endregion "Body"


// #region "Markdown"

export interface IDynaCMSBlockMarkdown extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.MARKDOWN;
  font: IDynaCMSFont;
  headingFirstLetter: boolean;
  markdown: string;
}

export const getDefaultDynaCMSBlockMarkdown = (): IDynaCMSBlockMarkdown => ({
  type: EDynaCMSBlockType.MARKDOWN,
  font: getDefaultIDynaCMSFont(),
  headingFirstLetter: false,
  markdown: '',
});

// #endregion "Markdown"


// #region "Quote"

export interface IDynaCMSBlockQuote extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.QUOTE;
  font: IDynaCMSFont;
  style: EDynaCMSBlockQuoteStyle;
  main: string;
  author: string;
  date: string;
}

export enum EDynaCMSBlockQuoteStyle {
  QUOTE_FRONT = "QUOTE_FRONT",
  LEFT_LINE = "LEFT_LINE",
}

export const getDefaultDynaCMSBlockQuote = (): IDynaCMSBlockQuote => ({
  type: EDynaCMSBlockType.QUOTE,
  font: getDefaultIDynaCMSFont(),
  style: EDynaCMSBlockQuoteStyle.QUOTE_FRONT,
  main: "",
  author: "",
  date: "",
});

// #endregion "Quote"

// #region "Links"

export interface IDynaCMSBlockLinks extends IDynaCMSBlockBase {
  viewMode: "inline" | "block";
  linkType: EDynaCMSBlockLinkType;
  font: IDynaCMSFont;
  links: IDynaCMSBlockLinkItem[];
}

export enum EDynaCMSBlockLinkType {
  APP_BUTTON = "APP_BUTTON",
  WHITE_BOX_BUTTON = "WHITE_BOX_BUTTON",
  TEXT_LINK = "TEXT_LINK",
}

export interface IDynaCMSBlockLinkItem {
  label: string;
  href: string;
  openInNewTab: boolean;
}

export const getDefaultDynaCMSLinks = (): IDynaCMSBlockLinks => ({
  type: EDynaCMSBlockType.LINKS,
  viewMode: "inline",
  linkType: EDynaCMSBlockLinkType.TEXT_LINK,
  font: getDefaultIDynaCMSFont(),
  links: [],
});

// #endregion "Links"

// #region "Image"

export interface IDynaCMSBlockImage extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.IMAGE;
  label: string;
  labelPosition: EDynaCMSBlockImageLabelPosition;
  labelForeground: string;
  labelBackground: string;
  title: string;
  caption: string;
  showCaption: boolean;             // If false, will use only as alt of the image
  contrastTextColor: string;        // CSS color default "white"
  contrastTextBorderColor: string;  // CSS color default ""
  imageUrl: string;
  imageLens: IDynaCMSImageLens;
  credits: string;
  mp: number;
  mpQuality:
    | "unknown"
    | "poor"
    | "middle"
    | "good"
    | "superb";
  orientation:
    | "unknown"
    | "square"
    | "portrait"
    | "landscape";
}

export enum EDynaCMSBlockImageLabelPosition {
  TOP_LEFT = "TOP_LEFT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export interface IDynaCMSImageLens {
  zoom: {
    active: boolean;
    xp: number;     // 0..100%
    yp: number;     // 0..100%
    zoomP: number;  // 100..300%
  };
  hMirror: boolean;
  vMirror: boolean;
  bw: boolean;
}

export const getDefaultDynaCMSBlockImage = (): IDynaCMSBlockImage => (
  {
    type: EDynaCMSBlockType.IMAGE,
    title: '',
    label: '',
    labelPosition: EDynaCMSBlockImageLabelPosition.TOP_RIGHT,
    labelForeground: 'whitesmoke',
    labelBackground: 'red',
    caption: '',
    showCaption: false,
    contrastTextColor: 'white',
    contrastTextBorderColor: '',
    imageUrl: '',
    credits: '',
    mp: -1,
    mpQuality: "unknown",
    orientation: "unknown",
    imageLens: {
      zoom: {
        active: false,
        xp: 0,
        yp: 0,
        zoomP: 100,
      },
      hMirror: false,
      vMirror: false,
      bw: false,
    },
  }
);

// #endregion "Image"

// #region "Images"

export interface IDynaCMSBlockImages extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.IMAGES;
  label: string;
  title: string;
  subtitle: string;
  images: IDynaCMSBlockImage[];
  showMode: "all" | "carousel";
}

export const getDefaultDynaCMSBlockImages = (): IDynaCMSBlockImages => ({
  type: EDynaCMSBlockType.IMAGES,
  label: '',
  title: '',
  subtitle: '',
  images: [],
  showMode: 'all',
});

// #endregion "Images"

export interface IDynaCMSBlockVideoBase {
  label: string;
  title: string;
  subtitle: string;
  showTextOnPlay: boolean;
  height: number;
  previewImageUrl: string;
  showControls: boolean;
  autoStart: boolean;
  loop: boolean;
  startAtSec: number | undefined;
  endAtSec: number | undefined;
  muted: boolean;
  fullScreenAbility: boolean;
  credits: string;
  lazyRender: boolean;
}

// #region "Video file"

export interface IDynaCMSBlockVideoFile extends IDynaCMSBlockBase, IDynaCMSBlockVideoBase {
  type: EDynaCMSBlockType.VIDEO_FILE;
  videoFileUrl: string;
}

export const getDefaultDynaCMSBlockVideoFile = (): IDynaCMSBlockVideoFile => ({
  type: EDynaCMSBlockType.VIDEO_FILE,
  label: "",
  title: "",
  subtitle: "",
  showTextOnPlay: false,
  height: 480,
  previewImageUrl: "",
  autoStart: false,
  loop: false,
  startAtSec: undefined,
  endAtSec: undefined,
  showControls: true,
  muted: false,
  fullScreenAbility: true,
  videoFileUrl: '',
  credits: '',
  lazyRender: false,
});

// #endregion "Video file"

// #region "Youtube video"

export interface IDynaCMSBlockVideoYouTube extends IDynaCMSBlockBase, IDynaCMSBlockVideoBase {
  type: EDynaCMSBlockType.VIDEO_YOUTUBE;
  youTubeVideoCode: string;
}

export const getDefaultDynaCMSBlockVideoYouTube = (): IDynaCMSBlockVideoYouTube => ({
  type: EDynaCMSBlockType.VIDEO_YOUTUBE,
  label: "",
  title: "",
  subtitle: "",
  showTextOnPlay: false,
  height: 480,
  previewImageUrl: "",
  autoStart: false,
  loop: false,
  startAtSec: undefined,
  endAtSec: undefined,
  muted: false,
  showControls: false,
  fullScreenAbility: true,
  youTubeVideoCode: '',
  credits: '@ YouTube',
  lazyRender: false,
});

// #endregion "Youtube video"

// #region "Vimeo"

export interface IDynaCMSBlockVideoVimeo extends IDynaCMSBlockBase, IDynaCMSBlockVideoBase {
  type: EDynaCMSBlockType.VIDEO_VIMEO;
  vimeoVideoCode: string;
}

export const getDefaultDynaCMSBlockVideoVimeo = (): IDynaCMSBlockVideoVimeo => ({
  type: EDynaCMSBlockType.VIDEO_VIMEO,
  label: "",
  title: "",
  subtitle: "",
  showTextOnPlay: false,
  height: 480,
  previewImageUrl: "",
  autoStart: false,
  loop: false,
  startAtSec: undefined,
  endAtSec: undefined,
  muted: false,
  showControls: false,
  fullScreenAbility: true,
  vimeoVideoCode: '',
  credits: '',
  lazyRender: false,
});

// #endregion "Vimeo"

// #region "SoundCloud"

export interface IDynaCMSBlockSoundSoundCloud extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.SOUND_SOUNDCLOUD;
  soundCloudUrl: string;
  height: number;
}

export const getDefaultDynaCMSBlockSoundSoundCloud = (): IDynaCMSBlockSoundSoundCloud => ({
  type: EDynaCMSBlockType.SOUND_SOUNDCLOUD,
  soundCloudUrl: '',
  height: 320,
});

// #endregion "SoundCloud"

// #region "Multimedia"

export interface IDynaCMSBlockMultimedia extends IDynaCMSBlockBase {
  type: EDynaCMSBlockType.MULTIMEDIA;
  label: string;
  title: string;
  subtitle: string;
  media: IDynaCMSBlockMultimediaMedia[];
  showMode: "all" | "carousel";
}

export type IDynaCMSBlockMultimediaMedia =
  | IDynaCMSBlockImage
  | IDynaCMSBlockVideoFile
  | IDynaCMSBlockVideoYouTube
  | IDynaCMSBlockVideoVimeo
  | IDynaCMSBlockSoundSoundCloud
  ;

export const getDefaultDynaCMSBlockMultimedia = (): IDynaCMSBlockMultimedia => ({
  type: EDynaCMSBlockType.MULTIMEDIA,
  label: '',
  title: '',
  subtitle: '',
  media: [],
  showMode: 'all',
});

// #endregion "Multimedia"

// #region "Post"


export interface IDynaCMSBlockSocialPostBase extends IDynaCMSBlockBase {
  postUrl: string;
}

// #region "Post FB"

export interface IDynaCMSBlockSocialPostFacebook extends IDynaCMSBlockSocialPostBase {
}

export const getDefaultDynaCMSBlockPostFacebook = (): IDynaCMSBlockSocialPostFacebook => ({
  type: EDynaCMSBlockType.SOCIAL_POST_FACEBOOK,
  postUrl: '',
});

// #endregion "Post FB"

// #region "Post Twitter"

export interface IDynaCMSBlockSocialPostTwitter extends IDynaCMSBlockSocialPostBase {
}

export const getDefaultDynaCMSBlockPostTwitter = (): IDynaCMSBlockSocialPostTwitter => ({
  type: EDynaCMSBlockType.SOCIAL_POST_TWITTER,
  postUrl: '',
});

// #endregion "Post Twitter"

// #region "Post instagram"


export interface IDynaCMSBlockSocialPostInstagram extends IDynaCMSBlockSocialPostBase {
}

export const getDefaultDynaCMSBlockPostInstagram = (): IDynaCMSBlockSocialPostInstagram => ({
  type: EDynaCMSBlockType.SOCIAL_POST_INSTAGRAM,
  postUrl: '',
});

// #endregion "Post instagram"

// #region "Linkedin"

export interface IDynaCMSBlockSocialPostLinkedIn extends IDynaCMSBlockSocialPostBase {
}

export const getDefaultDynaCMSBlockPostLinkedin = (): IDynaCMSBlockSocialPostLinkedIn => ({
  type: EDynaCMSBlockType.SOCIAL_POST_LINKEDIN,
  postUrl: '',
});

// #endregion "Linkedin"

// #region "ZikTok"

export interface IDynaCMSBlockSocialPostTikTok extends IDynaCMSBlockSocialPostBase {
}

export const getDefaultDynaCMSBlockPostTikTok = (): IDynaCMSBlockSocialPostTikTok => ({
  type: EDynaCMSBlockType.SOCIAL_POST_TIKTOK,
  postUrl: '',
});

// #endregion "ZikTok"

// #endregion "Post"
