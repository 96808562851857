import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IViewerVideoPlayerVimeoProps} from "./ViewerVideoPlayerVimeo";

const ViewerVideoPlayerVimeoLazy = lazy(() => import("./ViewerVideoPlayerVimeo"));

export const ViewerVideoPlayerVimeo = (props: IViewerVideoPlayerVimeoProps) => (
  <LazyLoadComponent>
    <ViewerVideoPlayerVimeoLazy {...props}/>
  </LazyLoadComponent>
);
