import * as React from "react";
import {useMemo} from "react";

import {dynaError} from "dyna-error";
import {dynaSwitchEnum} from "dyna-switch";

import {TRequireAll} from "utils-library/dist/commonJs/typescript";

import {Box} from "ui-components/dist/Box";
import {HtmlContent} from "ui-components/dist/HtmlContent";
import {useWindowResize} from "ui-components/dist/useWindowResize";
import {useContainerResizeEvent} from "ui-components/dist/useContainerResizeEvent";

import {EDynaAdOrientation} from "../../interfaces";
import {getWidthHeightFromScript} from "./utils/getWidthHeightFromScript";

export interface IAdViewerResponsiveByHTMLProps {
  show?: boolean;
  orientation?: EDynaAdOrientation; // Default is AUTO and the orientation of the device is applier
  ads: IHTMLAd[];
}

export interface IHTMLAd {
  width?: number;
  height?: number;
  html: string;
}

export const AdViewerResponsiveByHTML: React.FC<IAdViewerResponsiveByHTMLProps> = (
  {
    show = true,
    orientation = EDynaAdOrientation.AUTO,
    ads,
  },
) => {
  const {
    width: windowWidth,
    height: windowHeight,
  } = useWindowResize();
  const screenOrientation: "h" | "v" = windowWidth <= windowHeight ? "v" : "h";
  const {
    ref,
    width: containerWidth,
  } = useContainerResizeEvent<HTMLDivElement>();

  const validAds = useMemo(
    () =>
      ads
        .map((ad): TRequireAll<IHTMLAd> => {
          if (ad.width && ad.height) return ad as any;
          const {
            width,
            height,
            error: parseError,
          } = getWidthHeightFromScript(ad.html);
          if (parseError) {
            const error = dynaError({
              message: `Cannot get retrieve the width and height for this ad, error: ${parseError}`,
              data: {ad},
              parentError: parseError,
            });
            console.error(error.message, error);
            return ad as any;
          }
          ad.width = width;
          ad.height = height;
          return ad as any;
        })
        .filter(ad => !!ad.width)
    ,
    [ads],
  );

  const ad =
    validAds
      .filter(ad =>
        dynaSwitchEnum<EDynaAdOrientation, boolean>(
          orientation,
          {
            [EDynaAdOrientation.AUTO]: (() => {
              if (screenOrientation === "h") return ad.width >= ad.height;
              return ad.width <= ad.height;
            })(),
            [EDynaAdOrientation.RECTANGLE]: ad.height === ad.width,
            [EDynaAdOrientation.HORIZONTAL]: ad.height < ad.width,
            [EDynaAdOrientation.VERTICAL]: ad.height > ad.width,
          },
        ),
      )
      .sort((a, b) => b.width - a.width)
      .find(ad => ad.width <= containerWidth);

  return (
    <div ref={ref}>
      {show && ad && (
        <Box
          sx={{
            width: ad.width,
            height: ad.height,
            margin: "auto",
          }}
        >
          <HtmlContent
            html={ad.html}
            sanitize={false}
          />
        </Box>
      )}
    </div>
  );
};
