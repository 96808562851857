import {AdoreGrFooterLinks} from "../../../components/AdoreGrFooterLinks";

export interface IFooterProps {
  onClick?: () => void;
}

export const PopUpMenuFooter = (props: IFooterProps): JSX.Element => {
  const {onClick} = props;

  return (
    <AdoreGrFooterLinks onClick={onClick}/>
  );
};
