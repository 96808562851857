import {IDynaCMSArticleHeading} from "../../../../db/IDynaCMSArticle";
import {EPublishedFilter} from "../../../../interfaces/EPublishedFilter";

export const API_PATH_ApiArticleHeaderGetSearchV2 = '/services/dyna-cms/apiArticleHeaderGetSearchV2';

export interface IApiArticleHeaderGetSearchBodyRequestV2 {
  countryIds?: string[];
  groupIds?: string[];
  languageId: string;
  searchText?: string;
  tags?: string[];
  tagsMode?: 'any' | 'all';
  published?: EPublishedFilter;
  publishedBefore?: number;     // For PUBLISHED or PUBLISHED_SCHEDULED only
  publishedInLastDays?: number; // For PUBLISHED or PUBLISHED_SCHEDULED only, based on the calculated published date
  deleted?: boolean | "both";
  archived?: boolean | "both";
  skip: number;
  limit: number;
}

export interface IApiArticleHeaderGetSearchResponseV2 {
  articles: IDynaCMSArticleHeading[];
}
