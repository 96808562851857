import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IShortURLLoaderProps} from "./ShortURLLoader";

const ShortURLLoaderLazy = lazy(() => import("./ShortURLLoader"));

export const ShortURLLoader = connect((props: IShortURLLoaderProps) => (
  <LazyLoadComponent>
    <ShortURLLoaderLazy {...props}/>
  </LazyLoadComponent>
));
