import {connect} from "react-dynadux";

import {
  DynaProfileUserRightsAssigmentGrid as Component,
} from "./DynaProfileUserRightsAssigmentGrid";
export const DynaProfileUserRightsAssigmentGrid = connect(Component);

export type {
  IDynaProfileUserRightsAssigmentGridProps,
} from "./DynaProfileUserRightsAssigmentGrid";
export type {
  IManageRight,
} from "./interfaces";
