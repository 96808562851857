import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaProfilesIconMui} from "../icons/DynaProfilesIcon";
import {buildUrlByRoutePath} from "utils-library/dist/commonJs/utils";

export const routeProfileEditPaths: IAppRoute<{profileId: string}> = {
  title: 'Edit Profile',
  icon: <DynaProfilesIconMui/>,
  routePath: '/profiles/edit/:profileId',
  getRoutePath: (pathParams) => buildUrlByRoutePath({
    routePath: routeProfileEditPaths.routePath,
    pathParams,
  }),
  userHasAllRights: [],
  userHasAnyOfRights: [],
  requiresLatestClientVersion: true,
  render: () => null,
};
