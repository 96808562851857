import {ETKAppCore} from "server-app";

import {IAppRoute} from "../config/IAppRoute";
import {AppSettings} from "../pages/AppSettings";

import SettingsIcon from '@mui/icons-material/Tune';

export const routeAppSettingsPage: IAppRoute = {
  menuId: 'options',
  title: 'Application settings',
  titleTk: ETKAppCore.APP_SETTINGS,
  description: 'Application and user settings',
  icon: <SettingsIcon/>,
  routePath: '/settings',
  getRoutePath: () => routeAppSettingsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  exact: true,
  render: () => <AppSettings/>,
};
