import {
  API_PATH_ApiDynaProfileDelete,
  IApiDynaProfileDeleteRequest,
  IApiDynaProfileDeleteResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfileDelete = async (args: IApiDynaProfileDeleteRequest): Promise<IApiDynaProfileDeleteResponse> => {
  return apiFetch.request<null, IApiDynaProfileDeleteRequest, IApiDynaProfileDeleteResponse>({
    method: 'DELETE',
    path: API_PATH_ApiDynaProfileDelete,
    body: args,
  });
};
