import * as React from "react";
import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

export interface IRefreshComponentProps {
  refreshEveryMinutes: number;
  children: ReactElement;
}

export const RefreshComponent: React.FC<IRefreshComponentProps> = (
  {
    refreshEveryMinutes,
    children,
  },
) => {
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (refreshEveryMinutes > 0) {
      const timerId = setInterval(
        () => setKey(Math.random()),
        refreshEveryMinutes * 60 * 1000,
      );
      return () => clearInterval(timerId);
    }
    else return undefined;
  }, [refreshEveryMinutes]);

  const childrenWithKey = React.Children.map(
    children,
    child =>
      React.isValidElement(child)
        ? React.cloneElement(child, {key})
        : child,
  );

  return <>{childrenWithKey}</>;
};
