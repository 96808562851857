import * as React from "react";
import {useState} from "react";

import {dynaSwitch} from "dyna-switch";
import {
  areValuesEqual,
  convertStringToNumber,
} from "utils-library/dist/commonJs/utils";

import {IIconComponent} from "../IconComponent";
import {TGridSpacing} from "../ui-interfaces";
import {
  GridContainer,
  GridItem,
} from "../Grid";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../FlexContainer";
import {ErrorBanner} from "../ErrorBanner";
import {Box} from "../Box";
import {
  ButtonBar,
  EButtonBarAlign,
} from "../ButtonBar";
import {
  Button,
  EButtonColor,
} from "../Button";
import {IsLoading} from "../IsLoading";
import {Typography} from "../Typography";
import {IconViewer} from "../IconViewer";
import {useConfirm} from "../useConfirm";
import {useSaveData} from "../useSaveData";

import ClearIcon from '@mui/icons-material/RotateLeft';
import RevertIcon from '@mui/icons-material/RestartAlt';
import SubmitIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';

export interface IFormLocalProps<TData> {
  mode: 'create' | 'edit';
  isModalForm?: boolean;                  // Default is true. When true, the Cancel button is shown as Close. Set true if the Cancel is going to close the form.
  Icon?: IIconComponent;
  title: string;
  subtitle?: string;
  maxWidth?: number;
  gridSpacing?: TGridSpacing;
  IconSubmitButton?: IIconComponent;
  IconCancelButton?: IIconComponent;
  labelSubmitButton?: string;
  labelCancelButton?: string;
  labelCloseButton?: string;
  discardChangesConfirmation?: boolean;   // Default is true. On cancel with changes prompts discard confirmation.
  data: TData;
  validationRules: Record<
    keyof TData,
    (
      args: {
        value: any;
        data: TData;
        mode: 'create' | 'edit';
      }
    ) => string
  >;
  renderFields: (
    renderApi: {
      mode: 'create' | 'edit';
      data: TData;
      onChange: (value: any, fieldName?: keyof TData) => void;
      onDataChange: (data: Partial<TData>, silent?: boolean) => void;
      setChanged: (changed: boolean) => void;
      validationErrors: { [K in keyof TData]: string };
    }
  ) => JSX.Element;
  showRevertButton?: boolean;         // Shows the revert button that reverts user's changes to initial form's data
  onClear?: (data: TData) => TData;   // Shows the reset button and apply the output of this method
  onSubmit: (data: TData) => Promise<void>;
  onCancel?: () => void;
}

export const FormLocal = <TData, >(props: IFormLocalProps<TData>): JSX.Element => {
  const {
    mode,
    isModalForm = true,
    Icon,
    title,
    subtitle,
    maxWidth,
    gridSpacing = 2,
    IconSubmitButton = SubmitIcon,
    IconCancelButton = CancelIcon,
    labelSubmitButton = 'Ok',
    labelCancelButton = 'Cancel',
    labelCloseButton = 'Close',
    discardChangesConfirmation = true,
    data: userInitialData,
    validationRules,
    renderFields,
    showRevertButton,
    onClear,
    onSubmit,
    onCancel,
  } = props;

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<TData>(userInitialData);
  const [data, _setData] = useState<TData>(initialData);
  const [validationErrors, setValidationErrors] = useState<{ [K in keyof TData]: string }>(getDefaultValidationErrors(data));

  const {
    isSaving,
    error,
    save,
  } = useSaveData<TData>({save: onSubmit});

  const {
    confirm,
    confirmViewer,
  } = useConfirm();

  const setData = (partialData: Partial<TData>, silent = false): void => {
    if (!isChanged && !silent) setIsChanged(true);
    _setData(data => ({
      ...data,
      ...partialData,
    }));
  };

  const validate = (): boolean => {
    const validationErrors = getDefaultValidationErrors(data);
    Object.keys(initialData)
      .forEach(fieldName => {
        if (!validationRules[fieldName]) return ''; // Exit, there is no validation method. This can happen when the interface has been changed.
        validationErrors[fieldName] =
          validationRules[fieldName]({
            mode,
            value: data[fieldName],
            data,
          });
        return;
      });
    setValidationErrors(validationErrors);
    return !Object.values(validationErrors).join('');
  };

  const handleChangeField = (value: any, fieldName?: keyof TData): void => {
    if (!fieldName) {
      console.error('FormLocal: onChange() called but the fieldName was empty. This is usually happens when the Input field has not `name` and the `onChange` defined on it\'s `onChange`.', {
        fieldName,
        value,
      });
      return;
    }

    setData({[fieldName]: value} as any);
  };

  const handleFormChange = (event: React.FormEvent): void => {
    event.stopPropagation();
    setIsChanged(true);
  };

  const getValueFromInput = (event: any): string | number => {
    return dynaSwitch<string | number, string>(
      event.target.type,
      event.target.value,
      {
        checkbox: event.target.checked,
        number: convertStringToNumber(event.target.value, -1),
      },
    );
  };

  const handleFormBlur = (event: any): void => {
    event.stopPropagation();
    if (event.target.nodeName === "INPUT") {
      const inputName = event.target.name;
      const inputValue = getValueFromInput(event);
      const validName = Object.keys(initialData).includes(inputName);
      if (!validName) {
        console.error(`FormLocal error 20230914111753: Received on blur from input with name [${inputName}] that doesn't match to any data property.`);
        return;
      }
      if (data[inputName] !== inputValue) setData({[inputName]: inputValue} as any);
    }
  };
  const handleFormSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleFormReset = (event: React.FormEvent): void => event.stopPropagation();

  const handleSetChanged = (changed: boolean): void => {
    setIsChanged(changed);
  };

  const handleClear = (): void => {
    if (!onClear) return;
    const clearData = onClear(data);
    const isClearDataDiffToInitialData = !areValuesEqual(clearData, initialData);
    setData(clearData);
    setIsChanged(isClearDataDiffToInitialData);
  };

  const handleRevert = (): void => {
    setData(initialData);
    setIsChanged(false);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate()) return;
    try {
      await save(data);
      setInitialData(data);
      setIsChanged(false);
    }
    catch (e) {
      // Swallow the error, is already handled by the save()
    }
  };

  const handleCancel = async (): Promise<void> => {
    if (!isChanged) {
      onCancel && onCancel();
      return;
    }

    if (discardChangesConfirmation) {
      const userContinueEditing = await confirm({
        title: 'Discard changes?',
        children: 'You have unsaved changes, are you sure you want to discard them?',
        labelConfirmButton: 'Continue editing',
        labelCancelButton: 'Discard changes',
      });
      if (!userContinueEditing) onCancel && onCancel();
    }
    else {
      onCancel && onCancel();
    }
  };

  return (
    <>
      {confirmViewer}
      <IsLoading isLoading={isSaving}>
        <form
          onChange={handleFormChange}
          onBlur={handleFormBlur}
          onSubmit={handleFormSubmit}
          onReset={handleFormReset}
        >
          <Box sx={{maxWidth}}>
            <GridContainer spacing={gridSpacing}>
              <GridItem>
                <Typography variant="h1">
                  <IconViewer
                    sx={{
                      width: 28,
                      height: 28,
                      position: 'relative',
                      top: '4px',
                      marginRight: '8px',
                    }}
                    Icon={Icon}
                  />
                  {title}
                </Typography>
                {!!subtitle && <h3>{subtitle}</h3>}
              </GridItem>
              <GridItem noSpacing={!!error || !!validationErrors}>
                <ErrorBanner error={error}/>
                <ErrorBanner validationErrors={validationErrors}/>
              </GridItem>
              {
                renderFields({
                  mode,
                  data,
                  validationErrors,
                  setChanged: handleSetChanged,
                  onDataChange: (partialData: Partial<TData>, silent = false): void => {
                    setData(
                      {
                        ...data,
                        ...partialData,
                      },
                      silent,
                    );
                  },
                  onChange: handleChangeField,
                })
              }
            </GridContainer>
            <FlexContainerHorizontal>
              <FlexItemMin>
                <ButtonBar
                  spacing={gridSpacing}
                  nowrap
                  wrapOnMobile
                >
                  <Button
                    show={!!onClear}
                    icon={<ClearIcon/>}
                    color={EButtonColor.SECONDARY}
                    hideLabelOnMobile
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  <Button
                    show={showRevertButton}
                    icon={<RevertIcon/>}
                    disabled={!isChanged}
                    color={EButtonColor.SECONDARY}
                    hideLabelOnMobile
                    onClick={handleRevert}
                  >
                    Revert changes
                  </Button>
                </ButtonBar>
              </FlexItemMin>
              <FlexItemMax>
                <ButtonBar
                  reverseOnMac
                  wrapOnMobile
                  spacing={gridSpacing}
                  align={EButtonBarAlign.RIGHT}
                >
                  <Button
                    icon={<IconSubmitButton/>}
                    disabled={!isChanged}
                    onClick={handleSubmit}
                  >
                    {labelSubmitButton}
                  </Button>
                  <Button
                    icon={<IconCancelButton/>}
                    hideLabelOnMobile
                    color={EButtonColor.SECONDARY}
                    onClick={handleCancel}
                    children={(() => {
                      if (isModalForm) {
                        return isChanged
                          ? labelCancelButton
                          : labelCloseButton;
                      }
                      return labelCancelButton;
                    })()}
                  />
                </ButtonBar>
              </FlexItemMax>
            </FlexContainerHorizontal>
          </Box>
        </form>
      </IsLoading>
    </>
  );
};

const getDefaultValidationErrors = <TData, >(data: TData): { [K in keyof TData]: string } =>
  Object.keys(data)
    .reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {} as any);
