import {
  API_PATH_ApiDynaProfileDefaultForUserGet,
  IApiDynaProfileDefaultForUserGetRequest,
  IApiDynaProfileDefaultForUserGetResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfileDefaultForUserGet = async (): Promise<IApiDynaProfileDefaultForUserGetResponse> => {
  return apiFetch.request<IApiDynaProfileDefaultForUserGetRequest, null, IApiDynaProfileDefaultForUserGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaProfileDefaultForUserGet,
    query: {},
  });
};
