import {
  useState,
  useCallback,
} from 'react';

export const useForceRender = (): (() => void) => {
  const [, forceRender] = useState({});

  return useCallback(() => forceRender({}), []);
};
