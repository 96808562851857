import {
  useState,
  useEffect,
} from "react";

import {Box} from "ui-components/dist/Box";
import {
  FlexContainerResponsive,
  FlexItemMin,
  FlexItemMax,
} from "ui-components/dist/FlexContainer";
import {useIsMounted} from "ui-components/dist/useIsMounted";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {AdoreGrLogo} from "../AdoreGrLogo/AdoreGrLogo";

import {CompanyLogoForFooter} from "../../../../core/application/public-components";
import {DynaCMSCountriesLanguageSelect} from "../../../../core/dyna-cms/public-components";

import {AdoreGrSocialButtons} from "../../components/AdoreGrSocialButtons";
import {AdoreGrContactLinks} from "../../components/AdoreGrContactLinks";
import {AdoreGrFooterLinks} from "../../components/AdoreGrFooterLinks";

export const AdoreGrFooter = (): JSX.Element => {
  const getIsMounted = useIsMounted();
  const {isMobile} = useBreakpointDevice();
  const [show, setShow] = useState<boolean>(false);

  // Show it after a while because it is in inverted color and the app looks flickering
  useEffect(() => {
    setTimeout(() => {
      if (!getIsMounted()) return;
      setShow(true);
    }, 1000);
  }, []);

  return (
    <Box
      show={show}
      sx={{
        position: 'relative',
        color: theme => theme.palette.background.default,
      }}
    >
      <Box
        dataComponentName="Background-wide"
        sx={{
          position: 'absolute',
          width: '96vw',
          height: '100%',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 0,
          background: theme => theme.palette.text.primary,
        }}
      />
      <FlexContainerResponsive
        spacing={4}
        alignVertical="top"
        verticalOnMobile
        verticalOnTablet
        sx={{
          position: 'relative',
          textAlign: isMobile ? 'center' : undefined,
        }}
      >
        <FlexItemMin>
          <AdoreGrSocialButtons/>
        </FlexItemMin>

        <FlexItemMin>
          <AdoreGrContactLinks textAlign="center"/>
        </FlexItemMin>

        <FlexItemMax>
          <AdoreGrFooterLinks/>
        </FlexItemMax>

        <FlexItemMin>
          <Box inline>
            <DynaCMSCountriesLanguageSelect
              showLanguages={window.location.host.split(':')[0] === 'localhost'}
              showCountries={window.location.host.split(':')[0] === 'localhost'}
            />
          </Box>
        </FlexItemMin>
        <FlexItemMin>
          <CompanyLogoForFooter
            textAlign={isMobile ? 'center' : 'right'}
            logo={
              <AdoreGrLogo
                color="inherit-system-background"
                height={42}
              />
            }
            companyName="adore.gr"
            copyrightSince={2022}
            showRegisteredMark={false}
            showVersion={false}
          />
        </FlexItemMin>
      </FlexContainerResponsive>
    </Box>
  );
};
