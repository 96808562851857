import {IDynaProfile} from "../../../interfaces";

export const API_PATH_ApiDynaProfilesForUserWithAnyAccess = '/services/dyna-profiles/apiDynaProfilesForUserWithAnyAccess';

export interface IApiDynaProfilesForUserWithAnyAccessRequest {
}

export interface IApiDynaProfilesForUserWithAnyAccessResponse {
  profiles: {
    profile: IDynaProfile;
    isOwner: boolean;
    rights: Record<string, true>;
  }[];
}
