export const API_PATH_ApiProfileUserRightSet = '/services/dyna-profiles/apiProfileUserRightSet';

export interface IApiProfileUserRightSetRequest {
  userId: string;
  profileId: string;
  right: string;
  valid: boolean;
}

export interface IApiProfileUserRightSetResponse {
  ok: true;
}
