export const API_PATH_ApiDynaIntlTranslationsGet = '/services/dyna-intl/apiDynaIntlTranslationsGet';

export interface IApiDynaIntlTranslationsGetRequest {
  countryId: string;
  languageId: string;
  tks: string[];
}

export interface IApiDynaIntlTranslationsGetResponse {
  translations: {
    [tk: string]: string;
  };
}
