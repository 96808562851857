import {IHardcodedTranslations} from "../../dyna-intl/interfaces/IHardcodedTranslations";

export enum ETKDynaProfiles {
  DEFAULT_USER_PROFILE_INFO_TITLE = "dyna-profiles--20231216190849",
  DEFAULT_USER_PROFILE_INFO_BODY = "dyna-profiles--20231216190850",
  CREATE_PROFILE_HEADER_LABEL = "dyna-profiles--20231019122454",
  MANAGE_PROFILE_HEADER_LABEL = "dyna-profiles--20231019122455",
  FORM_LABEL_displayName = "dyna-profiles--20231019122456",
  FORM_LABEL_profileDescription = "dyna-profiles--20231019122457",
  FORM_LABEL_shortName = "dyna-profiles--20231019122458",
  FORM_LABEL_shortName_helper = "dyna-profiles--20231019122459",
  FORM_LABEL_slogan = "dyna-profiles--20231020183303",
  FORM_LABEL_profileType = "dyna-profiles--20231020183304",
  FORM_LABEL_shortBio = "dyna-profiles--20231210162925",
  FORM_LABEL_shortBioHelper = "dyna-profiles--20231210162926",
  FORM_LABEL_bioUrl = "dyna-profiles--20231210163206",
  HELP_TEXT_WhatIsProfile = "dyna-profiles--20231019122460",
  BUTTON_LABEL_CreateProfile = "dyna-profiles--20231019122461",
  BUTTON_LABEL_UpdateProfile = "dyna-profiles--20231020195244",
  PROFILE_MANAGEMENT_SECTION = "dyna-profiles--20231215123103",
  DELETE_PROFILE_LABEL = "dyna-profiles--20231020195245",
  DELETE_PROFILE_HELPER = "dyna-profiles--20231020195246",
  UNDELETE_PROFILE_LABEL = "dyna-profiles--20231020195247",
}

export const dynaProfilesHardcodedTranslations: IHardcodedTranslations<ETKDynaProfiles> = {
  [ETKDynaProfiles.DEFAULT_USER_PROFILE_INFO_TITLE]: {
    en: "This is your default user profile",
    el: "Αυτό είναι το προεπιλεγμένο προφίλ",
    de: "Dies ist Ihr Standardbenutzerprofil",
  },
  [ETKDynaProfiles.DEFAULT_USER_PROFILE_INFO_BODY]: {
    en: "You can create other profiles by clicking the Profile icon. You can create profiles for your multi-dimensional personality or profiles that can be used by other corporate users.",
    el: "Μπορείτε να δημιουργήσετε άλλα προφίλ κάνοντας κλικ στο εικονίδιο Προφίλ. Μπορείτε να δημιουργήσετε προφίλ για την πολυδιάστατη προσωπικότητά σας ή προφίλ που μπορούν να χρησιμοποιηθούν από άλλους εταιρικούς χρήστες.",
    de: "Sie können andere Profile erstellen, indem Sie auf das Profilsymbol klicken. Sie können Profile für Ihre multidimensionale Persönlichkeit erstellen oder Profile erstellen, die von anderen Unternehmensbenutzern verwendet werden können.",
  },
  [ETKDynaProfiles.CREATE_PROFILE_HEADER_LABEL]: {
    en: "Create a new profile",
    el: "Δημιουργία νέου προφίλ",
    de: "Erstelle ein neues Profil",
  },
  [ETKDynaProfiles.MANAGE_PROFILE_HEADER_LABEL]: {
    en: "Update profile",
    el: "Ενημέρωση προφίλ",
    de: "Profilaktualisierung",
  },
  [ETKDynaProfiles.FORM_LABEL_displayName]: {
    en: "Profile name",
    el: "Όνομα προφίλ",
    de: "Profilname",
  },
  [ETKDynaProfiles.FORM_LABEL_profileDescription]: {
    en: "Profile description",
    el: "Περιγραφή προφίλ",
    de: "Profilbeschreibung",
  },
  [ETKDynaProfiles.FORM_LABEL_shortName]: {
    en: "Short name",
    el: "Σύντομο ονομα",
    de: "Kurzname",
  },
  [ETKDynaProfiles.FORM_LABEL_shortName_helper]: {
    en: "This is an alternative to the Display name, it is a shorter version.",
    el: "Σαν το Όνομα Προφίλ αλλα σε συντομογραφία",
    de: "Dies ist eine Alternative zum Anzeigenamen, es handelt sich um eine kürzere Version.",
  },
  [ETKDynaProfiles.FORM_LABEL_slogan]: {
    en: "Slogan",
    el: "Σκόγκαν",
    de: "Slogan",
  },
  [ETKDynaProfiles.HELP_TEXT_WhatIsProfile]: {
    en: "Profiles are public, professional profiles on the platform that allow businesses, organizations, famous individuals, and others to communicate with their audience. These profiles differ from user's personal profiles, as they provide rich content promotion, advertising, and analytical statistics capabilities that aid in the development of their online presence.",
    el: "Τα προφίλ είναι δημόσια, επαγγελματικά προφίλ στην πλατφόρμα που επιτρέπουν σε επιχειρήσεις, οργανισμούς, διάσημα πρόσωπα και άλλους να επικοινωνούν με το κοινό τους. Αυτά τα προφίλ διαφέρουν από τα προσωπικά προφίλ χρηστών, καθώς παρέχουν πλούσιες δυνατότητες προώθησης περιεχομένου, διαφήμισης και αναλυτικών στατιστικών που βοηθούν στην ανάπτυξη της διαδικτυακής παρουσίας.",
    de: "Profile sind öffentliche, professionelle Profile auf der Plattform, die es Unternehmen, Organisationen, berühmten Persönlichkeiten und anderen ermöglichen, mit ihrem Publikum zu kommunizieren. Diese Profile unterscheiden sich von den persönlichen Profilen der Nutzer, da sie umfangreiche Möglichkeiten zur Förderung von Inhalten, Werbung und analytischen Statistiken bieten, die bei der Entwicklung ihrer Online-Präsenz helfen.",
  },
  [ETKDynaProfiles.FORM_LABEL_profileType]: {
    en: "Profile type",
    el: "Τύπος προφίλ",
    de: "Profiltyp",
  },
  [ETKDynaProfiles.FORM_LABEL_shortBio]: {
    en: "Short bio",
    el: "Σύντομο βιογραφικό",
    de: "Kurze Biografie",
  },
  [ETKDynaProfiles.FORM_LABEL_shortBioHelper]: {
    en: "A sentence or two as a biography, will appear where you sign.",
    el: "Μία-δύο προτάσεις ως βιογραφία, θα εμφανίζεται όταν υπογράφετε.",
    de: "",
  },
  [ETKDynaProfiles.FORM_LABEL_bioUrl]: {
    en: "Biography URL",
    el: "Σύνδεσμος βιογραφίας",
    de: "Biografie-URL",
  },
  [ETKDynaProfiles.BUTTON_LABEL_CreateProfile]: {
    en: "Create profile",
    el: "Δημιουργία προφίλ",
    de: "Profil erstellen",
  },
  [ETKDynaProfiles.BUTTON_LABEL_UpdateProfile]: {
    en: "Update profile",
    el: "Ενημέρωση προφίλ",
    de: "Profil aktualisieren",
  },
  [ETKDynaProfiles.PROFILE_MANAGEMENT_SECTION]: {
    en: "Profile management",
    el: "Διαχείριση προφίλ",
    de: "",
  },
  [ETKDynaProfiles.DELETE_PROFILE_LABEL]: {
    en: "Delete this profile",
    el: "Διαγραφή αυτού του προφίλ",
    de: "Dieses Profil löschen",
  },
  [ETKDynaProfiles.DELETE_PROFILE_HELPER]: {
    en: "Permanently delete this profile. You can restore it within 30 days (via profile management), and after this period, it will be permanently deleted.",
    el: "Διαγράψτε οριστικά αυτό το προφίλ. Μπορείτε να το ανακτήσετε εντός 30 ημερών (μέσω της διαχείρισης προφίλ), και μετά από αυτό το διάστημα θα διαγραφεί οριστικά.",
    de: "Löschen Sie dieses Profil dauerhaft. Sie können es innerhalb von 30 Tagen wiederherstellen (über die Profilverwaltung), und nach diesem Zeitraum wird es dauerhaft gelöscht.",
  },
  [ETKDynaProfiles.UNDELETE_PROFILE_LABEL]: {
    en: "Restore profile",
    el: "Επαναφορά προφίλ",
    de: "",
  },
};
