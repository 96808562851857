import {ReactElement} from "react";

import {IIconComponent} from "ui-components/dist/IconComponent";
import {IconViewer} from "ui-components/dist/IconViewer";

export const getNotificationIcon = (
  {
    imgUrl,
    alt,
    defaultIcon,
    opacity,
  }: {
    imgUrl?: string;  // Todo: Make this also IIconComponent
    alt: string;
    defaultIcon: IIconComponent;
    opacity?: number;
  },
): ReactElement => {
  return (
    imgUrl
      ? (
        <img
          style={{opacity}}
          src={imgUrl}
          alt={alt}
          referrerPolicy="no-referrer"
        />
      )
      : (
        <IconViewer
          sx={{opacity}}
          Icon={defaultIcon}
          width={42}
          title={alt}
        />
      )
  );
};
