import * as React from "react";

import {EDynaAdOrientation} from "../../../interfaces";
import {AdViewerResponsiveByHTML} from "../../../utils";

export interface IAdLinkWisePoliHomeProps {
  show?: boolean;
  orientation?: EDynaAdOrientation;
}

// Affiliate: https://affiliate.linkwi.se/en/AProgramCreatives.html?id=12221

export const AdLinkWisePoliHome: React.FC<IAdLinkWisePoliHomeProps> = (
  {
    show,
    orientation,
  },
) => (
  <AdViewerResponsiveByHTML
    show={show}
    orientation={orientation}
    ads={[
      {html: '<iframe id="lkws_66ae6bc9e6b43" name="lkws_66ae6bc9e6b43" src="//go.linkwi.se/delivery/ih.php?cn=12221-204&amp;an=CD26445&amp;target=_blank&amp;" style="width:120px;height:600px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e6e5f" name="lkws_66ae6bc9e6e5f" src="//go.linkwi.se/delivery/ih.php?cn=12221-205&amp;an=CD26445&amp;target=_blank&amp;" style="width:160px;height:600px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e7215" name="lkws_66ae6bc9e7215" src="//go.linkwi.se/delivery/ih.php?cn=12221-206&amp;an=CD26445&amp;target=_blank&amp;" style="width:180px;height:150px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e7571" name="lkws_66ae6bc9e7571" src="//go.linkwi.se/delivery/ih.php?cn=12221-207&amp;an=CD26445&amp;target=_blank&amp;" style="width:200px;height:200px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e787a" name="lkws_66ae6bc9e787a" src="//go.linkwi.se/delivery/ih.php?cn=12221-208&amp;an=CD26445&amp;target=_blank&amp;" style="width:234px;height:60px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e7b5e" name="lkws_66ae6bc9e7b5e" src="//go.linkwi.se/delivery/ih.php?cn=12221-209&amp;an=CD26445&amp;target=_blank&amp;" style="width:250px;height:250px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e7e43" name="lkws_66ae6bc9e7e43" src="//go.linkwi.se/delivery/ih.php?cn=12221-210&amp;an=CD26445&amp;target=_blank&amp;" style="width:300px;height:250px" scrolling="no" frameborder="0"></iframe>'},
      {html: '<iframe id="lkws_66ae6bc9e80d2" name="lkws_66ae6bc9e80d2" src="//go.linkwi.se/delivery/ih.php?cn=12221-211&amp;an=CD26445&amp;target=_blank&amp;" style="width:300px;height:250px" scrolling="no" frameborder="0"></iframe>'},
    ]}
  />
);
