import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IArticleViewerProps} from "./ArticleViewer";

const ArticleViewerLazy = lazy(() => import("./ArticleViewer"));

export const ArticleViewer = (props: IArticleViewerProps) => (
  <LazyLoadComponent>
    <ArticleViewerLazy {...props}/>
  </LazyLoadComponent>
);
