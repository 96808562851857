import * as React from "react";

import {Box} from "../../Box";
import {
  SxProps,
  Theme,
} from "../../ThemeProvider";

import {ISlide} from "../interfaces";
import {Thumbnail} from "./Thumbnail";

import {scrollTo} from "../utils/scrollTo";

export interface IThumbnailsProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  index: number;
  animation?: number;   // Default is 250ms
  slides: ISlide[];
  onChange: (index: number) => void;
}

const THUMBNAIL_WIDTH = 120;

export class Thumbnails extends React.Component<IThumbnailsProps> {
  private containerRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.checkSelectedToBeInPortView();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  public componentDidUpdate(prevProps: Readonly<IThumbnailsProps>) {
    if (prevProps.index !== this.props.index) this.checkSelectedToBeInPortView();
  }

  private handleWindowResize = (): void => {
    this.checkSelectedToBeInPortView();
  };

  private checkSelectedToBeInPortView(): void {
    if (!this.containerRef.current) return;
    const {
      index,
      animation = 250,
    } = this.props;
    const scrollPosition = this.containerRef.current.scrollLeft;
    const viewPortStart = scrollPosition;
    const viewPortEnd = scrollPosition + this.containerRef.current.offsetWidth;
    const selectedItemPosition = index * THUMBNAIL_WIDTH;
    const inViewPort: boolean = selectedItemPosition >= viewPortStart && (selectedItemPosition + THUMBNAIL_WIDTH) < viewPortEnd;

    if (inViewPort) return;

    const scrollLeft =
      (index * THUMBNAIL_WIDTH)
      - (this.containerRef.current.offsetWidth / 2)
      + (THUMBNAIL_WIDTH / 2);

    if (animation) {
      scrollTo(
        this.containerRef.current,
        'scrollLeft',
        scrollLeft,
        animation,
      );
    }
    else {
      this.containerRef.current.scrollLeft = scrollLeft;
    }
  }

  public render(): JSX.Element {
    const {
      sx = {},
      dataComponentName,
      index,
      slides,
      onChange,
    } = this.props;
    return (
      <Box
        sx={{
          overflowX: 'scroll',
          overflowY: 'hidden',
          boxSizing: 'content-box',
          ...sx,
        }}
        dataComponentName={dataComponentName}
        ref={this.containerRef}
      >
        <Box
          sx={{
            width: slides.length * THUMBNAIL_WIDTH,
            fontSize: 0,
            '& > *': {
              display: 'inline-block',
              fontSize: theme => theme.typography.fontSize,
            },
          }}
        >
          {slides.map((slide, slideIndex) => (
            <Thumbnail
              key={slideIndex}
              index={slideIndex}
              selected={slideIndex === index}
              content={slide.renderThumbnail(slideIndex === index)}
              onClick={onChange}
            />
          ))}
        </Box>
      </Box>
    );
  }
}
