import {EDynaCMSBlockType} from "../../../../db/EDynaCMSBlockType";
import {
  IDynaCMSArticleBlock,
  IDynaCMSArticleStats,
} from "../../../../db/IDynaCMSArticle";
import {IDynaCMSContentBlockValidation} from "../../../../interfaces/IDynaCMSContentValidation";

export const API_PATH_ApiArticleContentCreate = '/services/dyna-cms/apiArticleContentCreate';

export interface IApiArticleContentCreateBodyRequest<TBlockContent> {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockType: EDynaCMSBlockType;
  content: TBlockContent;
  afterBlockId: string;
}

export interface IApiArticleContentCreateResponse<TBlockContent> {
  newBlocksOrder: string[];
  block: IDynaCMSArticleBlock;
  validation: IDynaCMSContentBlockValidation<TBlockContent>;
  stats: IDynaCMSArticleStats;
}
