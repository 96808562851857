import {EDynaCMSRightsDEPRECATED} from "server-app";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeDynaCMSArticleMgtmPaths: IAppRoute<{
  countryId: string;
  languageId: string;
  groupId: string;
  articleId: string;
}> = {
  title: 'Edit Article',
  // Similar code 489750248524: path of the article
  routePath: '/dyna-cms/edit-article/:countryId/:languageId/:groupId/:articleId',
  getRoutePath: (
    {
      countryId,
      groupId,
      languageId,
      articleId,
    } = {
      countryId: '',
      languageId: '',
      groupId: '',
      articleId: '',
    }) =>
    routeDynaCMSArticleMgtmPaths.routePath
      .replace(':countryId', countryId)
      .replace(':languageId', languageId)
      .replace(':groupId', groupId)
      .replace(':articleId', articleId),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaCMSRightsDEPRECATED.AUTHOR,
    EDynaCMSRightsDEPRECATED.EDITOR,
    EDynaCMSRightsDEPRECATED.REVIEWER,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
