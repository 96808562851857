import {
  IDynaCMSArticleHeading,
  EDynaCMSBlockImageLabelPosition,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {ContainerSizeMonitor} from "ui-components/dist/ContainerSizeMonitor";
import {ContainerEllipsis} from "ui-components/dist/ContainerEllipsis";

import {BlockViewer} from "../../../../block-viewers/BlockViewer";
import {ArticleDateViewer} from "../components/ArticleDateViewer";
import {DynaCMSLabel} from "../../../../commons/DynaCMSLabel";

export interface IPhotoTileHeadingViewerProps {
  h: number;  // Heading number for h1, h2, etc..
  article: IDynaCMSArticleHeading;
  bigTitle: boolean;
  target: 'list' | 'view';
  textAlign?: 'left' | 'center' | 'right';
  showPhotoTexts: boolean;
  showPhotoCredits: boolean;
  showPhotoPortraitSmaller: boolean;
  showVideoPreviewOnly: boolean;
  showDescription: boolean;
  showDate: boolean;
  showDateIcon: boolean;
  showUpdatedAt: boolean;
}

enum EBreakpoints {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

const headerSize: Record<EBreakpoints, number> = {
  [EBreakpoints.XSMALL]: 2,
  [EBreakpoints.SMALL]: 3,
  [EBreakpoints.MEDIUM]: 4,
  [EBreakpoints.LARGE]: 5,
};

export const PhotoTileHeadingViewer = (props: IPhotoTileHeadingViewerProps): JSX.Element => {
  const {
    h,
    article,
    article: {
      title,
      subtitle,
      description,
      label,
      coverMediaBlockCopy,
    },
    bigTitle,
    target,
    textAlign = "left",
    showPhotoTexts,
    showPhotoCredits,
    showPhotoPortraitSmaller,
    showDescription,
    showVideoPreviewOnly,
    showDate,
    showDateIcon,
    showUpdatedAt,
  } = props;

  const textShadow = "2px 2px 4px #000";

  return (
    <ContainerSizeMonitor<EBreakpoints>
      dataComponentName="PhotoTileHeadingViewer"
      breakpoints={{
        [EBreakpoints.XSMALL]: 128,
        [EBreakpoints.SMALL]: 320,
        [EBreakpoints.MEDIUM]: 640,
        [EBreakpoints.LARGE]: 860,
      }}
      render={
        (
          {
            breakpoint,
            isInAndAbove,
            isInAndBelow,
          },
        ) => {
          return (
            <Box>
              <Box sx={{position: 'relative'}}>
                <DynaCMSLabel
                  show={!!label && isInAndAbove(EBreakpoints.SMALL)}
                  position={EDynaCMSBlockImageLabelPosition.TOP_RIGHT}
                  children={label}
                />
                <BlockViewer
                  block={coverMediaBlockCopy}
                  showPhotoTexts={showPhotoTexts}
                  showPhotoCredits={showPhotoCredits}
                  showPhotoPortraitSmaller={showPhotoPortraitSmaller}
                  showVideoPreviewOnly={showVideoPreviewOnly}
                />
                <Box
                  dataComponentName="HeaderTextContainer"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    pb: theme => theme.spacing(1),
                  }}
                >
                  <Box
                    dataComponentName="Title"
                    component={`h${h}` as any}
                    sx={{
                      margin: 0,
                      p: theme => theme.spacing(1),
                      pb: 0,
                      textAlign,
                      fontFamily: 'inherit !important',
                      letterSpacing: 'inherit !important',
                      fontSize:
                        theme =>
                          bigTitle
                            ? theme.spacing(4) + ' !important'
                            : theme.spacing(headerSize[breakpoint || EBreakpoints.SMALL]) + ' !important',
                      color: 'white',
                      textShadow,
                    }}
                  >
                    <ContainerEllipsis
                      sx={{px: 0.5}}
                      maxLines={3}
                    >
                      {title}
                    </ContainerEllipsis>
                  </Box>
                  <ContainerEllipsis
                    dataComponentName="Subtitle"
                    show={!!subtitle && isInAndAbove(EBreakpoints.SMALL)}
                    component="p"
                    sx={{
                      margin: 0,
                      px: theme => theme.spacing(1),
                      fontWeight: 'bold',
                      fontSize: theme => theme.spacing(headerSize[breakpoint || EBreakpoints.SMALL] * 0.6),
                      textAlign,
                      color: 'white',
                      textShadow,
                    }}
                    maxLines={isInAndBelow(EBreakpoints.SMALL) ? 1 : 3}
                  >
                    {subtitle}
                  </ContainerEllipsis>
                </Box>
              </Box>
              <ContainerEllipsis
                dataComponentName="Description"
                show={showDescription}
                sx={{
                  fontWeight: 'bold',
                  m: 0,
                  px: 1,
                  fontSize: theme => theme.spacing(headerSize[breakpoint || EBreakpoints.SMALL] * 0.7),
                  textAlign: 'right',
                  textDecoration: "italic",
                }}
                component="p"
                maxLines={4}
              >
                {description}
              </ContainerEllipsis>
              <ArticleDateViewer
                articleHeading={article}
                show={showDate || true}
                target={target}
                showIcon={showDateIcon}
                showUpdatedAt={showUpdatedAt}
              />
            </Box>
          );
        }}
    />
  );
};
