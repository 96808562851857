import {ReactElement} from "react";
import * as ReactDOMServer from 'react-dom/server';

import pretty from 'pretty';

export const renderToString = (
  component: ReactElement,
  {pretty: prettyExport}: {
    pretty?: boolean;
  } = {pretty: false},
): string => {
  const html = ReactDOMServer.renderToString(component);
  return prettyExport
    ? pretty(html)
    : html;
};
