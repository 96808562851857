import {connect} from "react-dynadux";

import {
  AdoreGrLogo as Component,
} from "./AdoreGrLogo";
export const AdoreGrLogo = connect(Component);

export type {
  IAdoreGrLogoProps,
} from "./AdoreGrLogo";
