import {connect} from "react-dynadux";

import {
  DynaCMSGroupPreviewer as Component,
} from "./DynaCMSGroupPreviewer";
export const DynaCMSGroupPreviewer = connect(Component);

export type {
  IDynaCMSGroupPreviewerProps,
} from "./DynaCMSGroupPreviewer";
