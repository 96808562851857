import {Helmet} from 'react-helmet';

export interface ICanonicalLinkProps {
  href?: string;
}

export const CanonicalLink = (props: ICanonicalLinkProps): JSX.Element | null => {
  const {href = ""} = props;

  return (
    <Helmet>
      <link
        rel="canonical"
        href={href || window.location.href}
      />
    </Helmet>
  );
};
