import {
  API_PATH_ApiDynaProfilesRemoveUser,
  IApiDynaProfilesRemoveUserRequest,
  IApiDynaProfilesRemoveUserResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilesRemoveUser = async (args: IApiDynaProfilesRemoveUserRequest): Promise<IApiDynaProfilesRemoveUserResponse> => {
  return apiFetch.request<null, IApiDynaProfilesRemoveUserRequest, IApiDynaProfilesRemoveUserResponse>({
    method: 'DELETE',
    path: API_PATH_ApiDynaProfilesRemoveUser,
    body: args,
  });
};
