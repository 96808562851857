import {useEffect} from "react";
import {connect} from "react-dynadux";

import {Box} from "ui-components/dist/Box";
import {Modal} from "ui-components/dist/Modal";
import {ContainerSection} from "ui-components/dist/ContainerSection";
import {
  Button,
  EButtonColor,
} from "ui-components/dist/Button";
import {
  ButtonBar,
  EButtonBarAlign,
} from "ui-components/dist/ButtonBar";

import {IAppStore} from "../../../../state/IAppStore";

import {useLoadAppVersion} from "../../public-components";

import UpdateIcon from '@mui/icons-material/Update';
import CloseAppIcon from '@mui/icons-material/PowerSettingsNew';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {createIcon} from "ui-components/dist/IconComponent";

export interface ICheckAppVersionProps {
  store: IAppStore;
  check: boolean;
}

export const CheckAppVersion = connect((props: ICheckAppVersionProps): JSX.Element | null => {
  const {
    store: {app: {actions: {navigateTo}}},
    check,
  } = props;

  const {
    load,
    loaded,
    server,
    client,
  } = useLoadAppVersion({loadOnStartup: false});

  useEffect(() => {
    if (!check) return;
    if (loaded) return;
    load();
  }, [check]);

  const hasDiffAddress = check && loaded && server.version !== client.version;

  if (!hasDiffAddress) return null;

  return (
    <Modal
      show
      maxWidth={420}
      minWidth=""
      sx={{padding: theme => theme.spacing(2)}}
    >
      <ContainerSection
        h={1}
        Icon={createIcon.byMuiIcon(UpdateIcon)}
        title="Update is available"
        subtitle="Update your device now"
      />
      <p>
        For this page, it is necessary to have the latest update of the Application.
      </p>
      <p>
        To get the update Close and Open the application again.
      </p>
      <ul>
        <li>Close and open the application again</li>
      </ul>
      <div><code>{client.version}</code> current version</div>
      <div><code>{server.version}</code> latest version</div>
      <ButtonBar align={EButtonBarAlign.RIGHT}>
        <Button
          icon={<ArrowBackIosIcon/>}
          onClick={() => navigateTo({url: '<'})}
        >
          Back
        </Button>
        <Button
          href="https://www.anel.co"
          icon={<CloseAppIcon/>}
          color={EButtonColor.ERROR}
        >
          Close Application
        </Button>
      </ButtonBar>
      <Box
        sx={{
          textAlign: 'right',
          paddingLeft: theme => theme.spacing(2),
          paddingRight: theme => theme.spacing(2),
        }}
      >
        <i>If you see this message again, make sure your have closed the {window.location.host} from all tabs of the browser.</i>
      </Box>
    </Modal>
  );
});
