import {
  API_PATH_ApiDynaProfilesForUserWithAnyAccess,
  IApiDynaProfilesForUserWithAnyAccessRequest,
  IApiDynaProfilesForUserWithAnyAccessResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilesForUserWithAnyAccess = async (args: IApiDynaProfilesForUserWithAnyAccessRequest = {}): Promise<IApiDynaProfilesForUserWithAnyAccessResponse> => {
  return apiFetch.request<IApiDynaProfilesForUserWithAnyAccessRequest, null, IApiDynaProfilesForUserWithAnyAccessResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaProfilesForUserWithAnyAccess,
    query: args,
  });
};
