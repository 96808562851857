import * as React from "react";

import {Box} from "../../Box";

export interface IThumbnailProps {
  index: number;
  selected: boolean;
  content: JSX.Element;
  onClick: (index: number) => void;
}

export const Thumbnail = (props: IThumbnailProps): JSX.Element => {
  const {
    index,
    selected,
    content,
    onClick,
  } = props;

  const handleClick = (): void => onClick(index);

  return (
    <Box
      sx={{
        width: 100,
        height: 50,
        border: '10px solid transparent',
        cursor: 'pointer',
        borderColor: selected ? 'lightgray' : undefined,
        transition: 'border-color 100ms ease-in-out',
        '&:hover': {borderColor: 'gray'},
      }}
      dataComponentName={["Thumbnail"]}
      onClick={handleClick}
    >
      {content}
    </Box>
  );
};
