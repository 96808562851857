import * as React from "react";
import {
  DynaImage,
  IDynaImageProps,
} from "dyna-image";

import {Box} from "../Box";
import {Legend} from "../Legend";
import {Link} from "../Link";

import {useTheme} from "../ThemeProvider";
import {
  sxTransition,
  ECSSDuration,
} from "../sxTransition";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export interface IImageProps extends IDynaImageProps {
  style?: React.CSSProperties;
  thumbNumber?: number;
  fullSizeButton?: boolean;
}

export const Image = (props: IImageProps): JSX.Element => {
  const {
    style,
    src,
    mode,
    alt,
    showLoadingSpinner,
    showBrokenImageOnFail,
    fullSizeButton,
    thumbNumber,
    onLoad,
    onError,
  } = props;

  const theme = useTheme();

  const content: JSX.Element = (
    <>
      <Legend
        show={thumbNumber !== undefined}
        text={thumbNumber || ""}
      />
      <Box
        sx={{
          textAlign: 'center',
          paddingTop: 8,
        }}
        show={fullSizeButton}
      >
        <Link
          sx={{
            opacity: 0.4,
            transition: sxTransition(theme, 'opacity', ECSSDuration.SHORTEST),
            cursor: 'pointer',
            color: 'white',
            '&:hover': {opacity: 1},
            '& > *': {
              width: 32,
              height: 32,
            },
          }}
          href={src}
          target="_blank"
          rel="noreferrer"
          tabIndex={-1}
        >
          <OpenInNewIcon/>
        </Link>
      </Box>
    </>
  );

  return (
    <DynaImage
      style={style}
      src={src}
      mode={mode}
      alt={alt}
      showLoadingSpinner={showLoadingSpinner}
      showBrokenImageOnFail={showBrokenImageOnFail}
      content={content}
      onLoad={onLoad}
      onError={onError}
    />
  );
};

