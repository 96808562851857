import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDynaCMSSettingsPaths} from "./routeDynaCMSSettings.paths";

import {DynaCMSSettings} from "../pages/DynaCMSSettings";

export const routeDynaCMSSettings: IAppRoute = {
  ...routeDynaCMSSettingsPaths,
  render: () => (
    <DynaCMSSettings/>
  ),
};
