import {connect} from "react-dynadux";

import {
  HeaderViewerEva as Component,
} from "./HeaderViewerEva";
export const HeaderViewerEva = connect(Component);

export type {
  IHeaderViewerEvaProps,
} from "./HeaderViewerEva";
