import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

const BroadcasterConnectionTestPageLazy = lazy(() => import("./BroadcasterConnectionTestPage"));

export const BroadcasterConnectionTestPage = () => (
  <LazyLoadComponent>
    <BroadcasterConnectionTestPageLazy/>
  </LazyLoadComponent>
);
