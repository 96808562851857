import {EDynaCMSRightsDEPRECATED} from "server-app";
import {IAppRoute} from "../config/IAppRoute";
import AdminIcon from "@mui/icons-material/Dns";

export const routeBackOfficePagePaths: IAppRoute = {
  title: 'Back office',
  icon: <AdminIcon/>,
  menuId: '##backoffice',
  routePath: '/back-office',
  getRoutePath: () => routeBackOfficePagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaCMSRightsDEPRECATED.AUTHOR,
    EDynaCMSRightsDEPRECATED.EDITOR,
    EDynaCMSRightsDEPRECATED.REVIEWER,
  ],
  exact: true,
  render: () => null,
};
