// Help Twitter cards, official help:
// - https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
// - https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup

export interface ITwitterCardProps {
  cardType: ECardType;
  /**
   * The owner of the post. Should star with "@".
   * Example: johnsmithaut
   * Help: https://www.conductor.com/academy/twitter-cards/#site
   */
  ownerTwitterAccount: string;
  /**
   * The creator of the post (optional). Should star with "@".
   * Example: johnsmithaut
   * Help: https://www.conductor.com/academy/twitter-cards/#twittercreator
   */
  creatorTwitterAccount?: string;
  /**
   * The Twitter Card title, similar to a page's title attribute
   * Max 55..70chars!
   * Help: https://www.conductor.com/academy/twitter-cards/#title
   */
  title: string;
  /**
   * The Twitter Card description. It's a short description of what the page is about.
   * Max 125..200chars!
   * https://www.conductor.com/academy/twitter-cards/#description
   */
  description: string;
  /**
   * The image to be shown in your snippet.
   * Make sure to select these images carefully. Size matters, and so do quality and dimensions.
   * Image requirements:
   *  - Aspect ratio: 1:1
   *  - Minimum size: 144 x 144 pixels
   *  - Maximum size: 4096 x 4096 pixels
   *  - Maximum file size: 5 MB
   *  - Supported formats: JPG, PNG, WEBP, and GIF (for animated GIFs, only the first frame will be used). Note that SVG is not supported.
   *  Help: https://www.conductor.com/academy/twitter-cards/#image
   */
  imageUrl?: string;
  /**
   * Describe the twitter:image in text for visually impaired users
   * Help: https://www.conductor.com/academy/twitter-cards/#twitterimagealt
   */
  imageAlt?: string;
}

export enum ECardType {
  SUMMARY = 'summary',                            // Summary Card: text with a square image
  SUMMARY_LARGE_IMAGE = 'summary_large_image',    // Summary Card with Large Image: text with a large, rectangular image
  // Ddv note: "app" & "player" not implemented, require more data as described here: https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
}
