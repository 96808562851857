import {appConfig} from "../../../application/config/appConfig";
import {random} from "utils-library/dist/commonJs/utils";

export enum EAdProvider {
  GOOGLE_AD_SENSE = "GOOGLE_AD_SENSE",
  MONETAG = "MONETAG",
  ASDSTERRA = "ASDSTERRA",
  DEMO_PROVIDER = "DEMO_PROVIDER",
}

const adProvidersAvailability: EAdProvider[] = [];

if (appConfig.googleAdSense) adProvidersAvailability.push(EAdProvider.GOOGLE_AD_SENSE);
if (appConfig.monetag) adProvidersAvailability.push(EAdProvider.MONETAG);
if (appConfig.adsterra) adProvidersAvailability.push(EAdProvider.ASDSTERRA);

export const getAdType = async (
  {
    groupIds,
    tags,
  }: {
    groupIds: string[];
    tags: string[];
  },
): Promise<EAdProvider | null> => {
  groupIds;
  tags;

  const debugAdType = localStorage.getItem('debug-adType');
  if (debugAdType) {
    console.warn(`The localstorage "debug-adType" has been defined, all ads will come from the [${debugAdType}] as provider.`);
    return debugAdType as any;
  }

  if (!adProvidersAvailability.length) return null;

  return adProvidersAvailability[random(0, adProvidersAvailability.length - 1)];
};
