import {Box} from "ui-components/dist/Box";
import {
  FlexContainerHorizontal,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";
import {useLoadAppVersion} from "../useLoadAppVersion";

export interface ICompanyLogoForFooterProps {
  textAlign?: 'left' | 'center' | 'right';
  logo: JSX.Element;
  companyName: string;
  copyrightSince: number;
  showCopyright?: boolean;
  showRegisteredMark?: boolean;
  showVersion?: boolean;
}

export const CompanyLogoForFooter = (props: ICompanyLogoForFooterProps): JSX.Element => {
  const {
    textAlign = 'left',
    logo,
    companyName,
    copyrightSince,
    showCopyright = true,
    showRegisteredMark = true,
    showVersion = true,
  } = props;

  const {
    server,
    client,
  } = useLoadAppVersion();

  return (
    <Box sx={{textAlign}}>
      <FlexContainerHorizontal inline>
        <FlexItemMin>
          {logo}
        </FlexItemMin>
        <FlexItemMin
          show={showRegisteredMark}
          sx={{
            fontSize: 10,
            position: 'relative',
            top: '9px',
          }}
        >
          &#174;
        </FlexItemMin>
      </FlexContainerHorizontal>
      <Box
        show={showCopyright}
        sx={{
          whiteSpace: 'nowrap',
          fontSize: 12,
        }}
      >
        &#169; {companyName} {copyrightSince}-{(new Date()).getFullYear()}
      </Box>
      <Box
        show={showVersion}
        sx={{
          marginTop: theme => theme.spacing(1),
          marginBottom: theme => theme.spacing(1),
          fontSize: 10,
          opacity: 0.5,
          color: theme => client.version === server.version ? undefined : theme.palette.error.main,
        }}
      >
        Version {client.version}/{server.version} {client.codeName}
      </Box>
    </Box>
  );
};
