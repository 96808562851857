import {DataLimitedContainer} from "../data-limited-container";

import {memoryStorageApi} from "./memoryStorageApi";

export interface IDataLimitedContainerMemoryConfig {
  containerName: string;
  maxSizeInBytes: number;
}

export class DataLimitedContainerMemory<TData> extends DataLimitedContainer<TData> {
  constructor(config: IDataLimitedContainerMemoryConfig) {
    super({
      containerName: config.containerName,
      maxSizeInBytes: config.maxSizeInBytes,
      storage: memoryStorageApi,
    });
  }
}
