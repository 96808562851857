import * as React from "react";
import {
  useState,
  useEffect,
} from "react";

import {Box} from "../Box";
import {Collapse} from "../Collapse";

import {PortalContainer} from "../PortalContainer";

export interface IStickyTopContainerProps {
  showAboveScrollY: number;
  maxWidth: number;
  children: any;
}

export const StickyTopContainer = (props: IStickyTopContainerProps): JSX.Element => {
  const {
    showAboveScrollY,
    maxWidth,
    children,
  } = props;

  const [show, setShow] = useState<boolean>(window.scrollY >= showAboveScrollY);

  useEffect(() => {
    const handleScroll = (): void => {
      const show = window.scrollY >= showAboveScrollY;
      setShow(show);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <PortalContainer>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Collapse
          sx={{
            maxWidth,
            margin: 'auto',
            backgroundColor: theme => theme.palette.background.default,
            borderBottomWidth: theme => show ? theme.spacing(0.5) : 0,
            borderBottomStyle: 'solid',
            borderBottomColor: theme => theme.palette.text.primary,
            transition: 'all 1000ms',
          }}
          expanded={show}
          animationDuration={250}
        >
          {children}
        </Collapse>
      </Box>
    </PortalContainer>
  );
};
