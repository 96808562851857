import {
  IRouteArgs,
  routeDynaCMSArticleViewerByArticleIdPaths,
} from "./routeDynaCMSArticleViewerByArticleId.paths";

import {IAppRoute} from "../../application/config/IAppRoute";

import {ArticleViewer} from "../pages/ArticleViewer";

export const routeDynaCMSArticleViewerByArticleId: IAppRoute<IRouteArgs> = {
  ...routeDynaCMSArticleViewerByArticleIdPaths,
  render: (
    {
      pathParams: {
        countryId,
        languageId,
        groupId,
        articleId,
      },
    },
  ) => (
    <ArticleViewer
      countryId={countryId}
      languageId={languageId}
      groupId={groupId}
      idType="articleId"
      id={articleId}
      loadPurpose="view"
    />
  ),
};
