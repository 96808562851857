import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

const AppSettingsLazy = lazy(() => import("./AppSettings"));

export const AppSettings = () => (
  <LazyLoadComponent>
    <AppSettingsLazy/>
  </LazyLoadComponent>
);
