import {
  IDynaCMSArticle,
  EShowDate,
  EDynaCMSHeadingStyle,
  EViewWidthType,
  EDynaCMSArticleChangeFrequency,
} from "../../../../db/IDynaCMSArticle";
import {IDynaCMSFont} from "../../../../db/IDynaCMSFont";

export const API_PATH_ApiArticleHeaderCreate = '/services/dyna-cms/apiArticleHeaderCreate';

export interface IApiArticleHeaderCreateBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;

  urlId: string;

  title: string;
  subtitle: string;
  label: string;
  description: string;

  fontFamilyGeneral: IDynaCMSFont;
  fontFamilyHeader: IDynaCMSFont;
  fontFamilyHeaders: IDynaCMSFont;
  viewWidthType: EViewWidthType;
  headingStyleList: EDynaCMSHeadingStyle;
  headingStyleView: EDynaCMSHeadingStyle;

  tags: string[];

  seoAvailable: boolean;
  usedAsPage: boolean;
  seoKeywords: string;
  changeFrequency: EDynaCMSArticleChangeFrequency;
  canonicalHref: string;

  showPublishDateInList: EShowDate;
  showUpdateDateInList: EShowDate;
  showPublishDateInView: EShowDate;
  showUpdateDateInView: EShowDate;

  showAds: boolean;
  showShareButtons: boolean;
  showRelatedInsideBody: boolean;
  showRelatedAtTheEnd: boolean;

  eventStart?: number;
  eventEnd?: number;
  geolocation?: {
    lon: number;
    lat: number;
  };
  mapAddress?: string;
}

export interface IApiArticleHeaderCreateResponse {
  article: IDynaCMSArticle;
}
