import {
  IDynaCMSBlockQuote,
  EDynaCMSBlockQuoteStyle,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "ui-components/dist/FlexContainer";

import {DynaCMSFont} from "../../../public-components";
import {getContentPaddingSx} from "../utils/getContentPadding";

export interface IViewerQuoteProps {
  content: IDynaCMSBlockQuote;
}

export const ViewerQuote = (props: IViewerQuoteProps): JSX.Element => {
  const {
    content: {
      font,
      style,
      main,
      author,
      date,
    },
  } = props;
  return (
    <DynaCMSFont
      sx={getContentPaddingSx()}
      font={font}
    >
      <blockquote style={{margin: 0}}>
        <FlexContainerHorizontal>
          <FlexItemMin show={style === EDynaCMSBlockQuoteStyle.QUOTE_FRONT}>
            <Box
              sx={{
                fontSize: 128,
                fontWeight: 'bold',
                height: 0,
                position: 'relative',
                top: '-18px',
              }}
            >"</Box>
          </FlexItemMin>
          <FlexItemMax>
            <Box
              sx={{
                position: 'relative',
                maxWidth: '480px',
                borderLeft:
                  style === EDynaCMSBlockQuoteStyle.LEFT_LINE
                    ? theme => `${theme.spacing(2)} solid ${theme.palette.grayShades.gray5}`
                    : undefined,
                paddingLeft: theme => theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'break-spaces',
                }}
                children={main}
              />
              <Box
                show={!!author}
                sx={{
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  fontSize: theme => theme.typography.fontSize * 1.5,
                }}
                children={author}
              />
              <Box
                show={!!date}
                sx={{
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  fontSize: theme => theme.typography.fontSize * 1.1,
                  opacity: 0.9,
                }}
                children={date}
              />
            </Box>
          </FlexItemMax>
        </FlexContainerHorizontal>
      </blockquote>
    </DynaCMSFont>
  );
};
