export enum EDynaProfileType {
  DEFAULT = "DEFAULT",  // Used for the default profiles of the user
  USER = "USER",        // Used only by the app
  INFLUENCER = "INFLUENCER",
  NOM_DE_PLUME = "NOM_DE_PLUME",
  FICTIONAL = "FICTIONAL",
  CONTENT_TEAM = "CONTENT_TEAM",
  PHOTOGRAPHER = "PHOTOGRAPHER",
  PHOTO_AGENCY = "PHOTO_AGENCY",
}
