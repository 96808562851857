import {
  API_PATH_ApiDynaProfilePut,
  IApiDynaProfilePutRequest,
  IApiDynaProfilePutResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfilePut = async (args: IApiDynaProfilePutRequest): Promise<IApiDynaProfilePutResponse> => {
  return apiFetch.request<null, IApiDynaProfilePutRequest, IApiDynaProfilePutResponse>({
    method: 'PUT',
    path: API_PATH_ApiDynaProfilePut,
    body: args,
  });
};
