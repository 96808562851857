import {
  TDBEntityDataOnly,
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {EDynaProfileType} from "./EDynaProfileType";

export interface IDynaProfile extends IDBEntityBase {
  type: EDynaProfileType;
  displayName: string;
  shortName: string;
  profileImageUrl: string;
  backgroundImageUrl: string;
  slogan: string;
  description: string;
  bioShort: string;
  bioUrl: string;
}

export const getDefaultDynaProfile =
  (data: Partial<TDBEntityDataOnly<IDynaProfile>> = {}) =>
    getDefaultDBEntityDocument<IDynaProfile>({
      type: EDynaProfileType.DEFAULT,
      displayName: '',
      shortName: '',
      profileImageUrl: "",
      backgroundImageUrl: "",
      slogan: "",
      description: "",
      bioShort: '',
      bioUrl: '',
      ...data,
    });
