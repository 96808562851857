import * as React from "react";

import {
  Button,
  EButtonSize,
  EButtonColor,
  EButtonTextAlign,
} from "../Button";

import {createIcon} from "../IconComponent";
import SavingIcon from '@mui/icons-material/CloudSync';
import EditModeIcon from '@mui/icons-material/Edit';
import LockModeIcon from '@mui/icons-material/Edit';

export interface IButtonEditModeProps {
  editMode: boolean;
  show?: boolean;
  size?: EButtonSize;
  minWidth?: number;
  isSaving?: boolean;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
  hideLabelOnTablet?: boolean;
  onChange: (editMode: boolean) => void;
  messages?: {
    editMode: string;
    viewMode: string;
  };
}

export const ButtonEditMode: React.FC<IButtonEditModeProps> = (
  {
    editMode,
    show,
    size,
    minWidth: userMinWidth,
    isSaving,
    disabled,
    hideLabelOnMobile = false,
    hideLabelOnTablet = false,
    onChange,
    messages = {
      editMode: 'Done',
      viewMode: 'Edit',
    },
  },
) => {
  const Icon =
    isSaving
      ? createIcon.byMuiIcon(SavingIcon)
      : editMode
        ? createIcon.byMuiIcon(EditModeIcon)
        : createIcon.byMuiIcon(LockModeIcon);

  const minWidth =
    userMinWidth
    || (54 + (Math.max(...[messages.editMode, messages.viewMode].map(t => t.length)) * 8));

  const handleEditModeClick = () => onChange(!editMode);

  return (
    <Button
      Icon={Icon}
      show={show}
      size={size}
      minWidth={minWidth}
      textAlign={EButtonTextAlign.LEFT}
      disabled={disabled}
      color={editMode ? EButtonColor.WARNING : EButtonColor.PRIMARY}
      hideLabelOnMobile={hideLabelOnMobile}
      hideLabelOnTablet={hideLabelOnTablet}
      onClick={handleEditModeClick}
    >
      {
        editMode
          ? messages.editMode
          : messages.viewMode
      }
    </Button>
  );
};
