import {
  IDynaCMSArticleHeading,
  EDynaCMSHeadingStyle,
} from "server-app";

import {ContainerSticky} from "ui-components/dist/ContainerSticky";
import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";

import {HeaderViewerEva} from "../../components/article-header-viewers/HeaderViewerEva";

export interface IDynaCMSArticleHeaderViewerEdemProps {
  h: number;  // Heading number for h1, h2, etc..
  sx?: SxProps<Theme>;
  sticky?: boolean;
  headingStyleList?: EDynaCMSHeadingStyle;
  textAlign?: 'left' | 'center' | 'right';
  bigTitle?: boolean;
  showPhotoPortraitSmaller?: boolean;
  showVideoPreviewOnly?: boolean;
  showDescription?: boolean;
  articleHeading: IDynaCMSArticleHeading | null;
}

export const DynaCMSArticleHeaderViewerEdem = (props: IDynaCMSArticleHeaderViewerEdemProps): JSX.Element | null => {
  const {
    h,
    sx,
    sticky = true,
    headingStyleList,
    textAlign,
    bigTitle = false,
    showPhotoPortraitSmaller = false,
    showVideoPreviewOnly = true,
    showDescription = false,
    articleHeading,
  } = props;

  if (articleHeading === null) return null;

  return (
    <ContainerSticky
      sx={sx}
      sticky={sticky}
      top={240}
    >
      <HeaderViewerEva
        h={h}
        article={{
          ...articleHeading,
          headingStyleList: headingStyleList ? headingStyleList : articleHeading.headingStyleList,
        }}
        bigTitle={bigTitle}
        textAlign={textAlign}
        target="list"
        showDescription={showDescription}
        showPhotoPortraitSmaller={showPhotoPortraitSmaller}
        showVideoPreviewOnly={showVideoPreviewOnly}
      />
    </ContainerSticky>
  );
};
