import {IAppRoute} from "../../application/config/IAppRoute";
import {ShortUrlNotFound404Page} from "../pages/ShortUrlNotFound404Page";

export const routeShortUrl404: IAppRoute = {
  title: 'Short URL not found',
  routePath: '/short-url-404',
  getRoutePath: ()=>routeShortUrl404.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  exact: true,
  render: () => <ShortUrlNotFound404Page/>,
};
