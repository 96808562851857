import {
  API_PATH_ApiDynaProfileUndelete,
  IApiDynaProfileUndeleteRequest,
  IApiDynaProfileUndeleteResponse,
} from "server-app";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaProfileUndelete = async (args: IApiDynaProfileUndeleteRequest): Promise<IApiDynaProfileUndeleteResponse> => {
  return apiFetch.request<null, IApiDynaProfileUndeleteRequest, IApiDynaProfileUndeleteResponse>({
    method: 'DELETE',
    path: API_PATH_ApiDynaProfileUndelete,
    body: args,
  });
};
