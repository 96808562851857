import {IAppRoute} from "../../application/config/IAppRoute";
import {routeProfileCreate} from "./routeProfileCreate";
import {routeProfileEdit} from "./routeProfileEdit";
import {routeProfileView} from "./routeProfileView";
import {routeProfileUserRights} from "./routeProfileUserRights";

export const routesDynaProfiles: IAppRoute<any>[] = [
  routeProfileCreate,
  routeProfileEdit,
  routeProfileView,
  routeProfileUserRights,
];
