export const API_PATH_ApiDynaProfileUploadProfileImage = '/services/dyna-profiles/apiDynaProfileUploadProfileImage';

export interface IApiDynaProfileUploadProfileImageRequest  {
  profileId: string;
  imageType:
    | "user-profile"
    | "profile-background";
}

export interface IApiDynaProfileUploadProfileImageResponse  {
  url: string;
}
