import * as React from "react";
import {
  EDynaCMSFontFamily,
  EBold,
} from "server-app";

export interface IDynaCMSFontSetup {
  resource: string;
  category: EDynaCMSFontCategory;
  label: string;
  cssFamilyName: string;
  bold: Partial<Record<EBold, IDynaCMSFontBoldSetup>>;
  italicAble: boolean;
  stretchable: boolean;
  languages:
    Array<
      | 'en'
      | 'el'
    >;
}

export interface IDynaCMSFontBoldSetup {
  regular: IDynaCMSFontResources;
  italic?: IDynaCMSFontResources;
}

export enum EDynaCMSFontCategory {
  DEFAULT = "DEFAULT",
  PLAIN = "PLAIN",
  MODERN = "MODERN",
  TIMES = "TIMES",
  CELINE = "CELINE",
  ARTISTIC = "ARTISTIC",
  ANIMAL_PRINT = "ANIMAL_PRINT",
  FASHION = "FASHION",
  ELEGANT = "ELEGANT",
  IMPACT = "IMPACT",
  CALLIGRAPHY = "CALLIGRAPHY",
}

export interface IDynaCMSFontResources {
  script: string;
  cssProperties?: React.CSSProperties;
}

export const dynaCMSFonts: Record<EDynaCMSFontFamily, IDynaCMSFontSetup> = {

  // Inherit

  [EDynaCMSFontFamily.INHERIT]: {
    resource: '',
    category: EDynaCMSFontCategory.DEFAULT,
    label: 'Inherit',
    cssFamilyName: "inherit",
    bold: {[EBold.B500]: {regular: {script: ''}}},
    italicAble: true,
    stretchable: false,
    languages: [],
  },

  // Plain

  [EDynaCMSFontFamily.F100_PLAIN_ROBOTO]: {
    resource: 'https://fonts.google.com/specimen/Roboto',
    label: 'Plain (Roboto)',
    category: EDynaCMSFontCategory.PLAIN,
    cssFamilyName: "'Roboto', sans-serif",
    bold: {
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en', 'el'],
  },

  // Times

  [EDynaCMSFontFamily.F101_TIMES_BASE_MARCELLUS]: {
    resource: 'https://fonts.google.com/specimen/Marcellus',
    label: 'Base (Marcellus)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "'Marcellus', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F125_TIMES_BASE_PRATA]: {
    resource: 'https://fonts.google.com/specimen/Prata',
    label: 'Base (Prata)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "'Prata', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F173_TIMES_MIDDLEAGE_ARINK]: {
    resource: 'https://fonts.google.com/specimen/Aref+Ruqaa+Ink?preview.text=CELINE%20celine%20calligraphy&preview.text_type=custom',
    label: 'Middle age (Aref Ruqaa Ink)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "'Aref Ruqaa Ink', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
             @import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F153_TIMES_BOOK_TINOS]: {
    resource: 'https://fonts.google.com/specimen/Tinos?subset=greek',
    label: 'Base (Tinos)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "'Tinos', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en', 'el'],
  },
  [EDynaCMSFontFamily.F140_TIMES_BOOK_GENTIUM_PLUS]: {
    resource: 'https://fonts.google.com/specimen/Gentium+Plus?query=Gentium+Plus&preview.text=%CE%B5%CE%BB%CE%BB%CE%B7%CE%BD%CE%B9%CE%BA%CE%AC&preview.text_type=custom',
    label: 'Book (Gentium Plus)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "'Gentium Plus', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Gentium+Plus&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Gentium+Plus:ital@1&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Gentium+Plus:wght@700&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Gentium+Plus:ital,wght@1,700&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en', 'el'],
  },
  [EDynaCMSFontFamily.F187_TIMES_TERMINAL]: {
    resource: 'https://developer.mozilla.org/en-US/docs/Web/CSS/font-stretch',
    label: 'Terminal (League Mono VF)',
    category: EDynaCMSFontCategory.TIMES,
    cssFamilyName: "LeagueMonoVF",
    bold: {
      [EBold.B500]: {
        regular: {
          script: `
            <style>
              @font-face {
                  src: url('/assets/fonts/league-mono-vf/LeagueMono-VF.ttf') format('truetype');
                  font-family: LeagueMonoVF;
                  font-style: normal;
                  font-weight: 400;
                  font-stretch: 50% 200%
              }
            </style>
          `,
        },
      },
    },
    italicAble: true,
    stretchable: true,
    languages: ['en'],
  },

  // Celine

  [EDynaCMSFontFamily.F132_CELINE_BASE_JOSEFIN]: {
    resource: 'https://fonts.google.com/specimen/Josefin+Sans?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Base (Josefin Sans)',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Josefin Sans', sans-serif",
    bold: {
      [EBold.B200]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,200&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F154_CELINE_TIMES_NUOSU]: {
    resource: 'https://fonts.google.com/specimen/Nuosu+SIL?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Times (Nuosu SIL)',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Nuosu SIL', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Nuosu+SIL&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F155_CELINE_ROUND_FREDOKA]: {
    resource: 'https://fonts.google.com/specimen/Fredoka?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Round (Fredoka)',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Fredoka', sans-serif",
    bold: {
      [EBold.B300]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F161_CELINE_CONDENSED]: {
    resource: 'https://fonts.google.com/specimen/Cabin+Condensed?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Condensed',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Cabin Condensed', sans-serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F164_CELINE_XCONDENSED]: {
    resource: 'https://fonts.google.com/specimen/Voltaire?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'XCondensed',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Voltaire', sans-serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F165_CELINE_XXCONDENSED]: {
    resource: 'https://fonts.google.com/specimen/BenchNine?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'XXCondensed',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'BenchNine', sans-serif",
    bold: {
      [EBold.B300]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=BenchNine&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F160_CELINE_CALLIGRAPHY_POIRET_ONE]: {
    resource: 'https://fonts.google.com/specimen/Poiret+One?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Calligraphy (Poiret One)',
    category: EDynaCMSFontCategory.CELINE,
    cssFamilyName: "'Poiret One', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },

  // Modern

  [EDynaCMSFontFamily.F123_MODERN_BASE_MUSEO_MODERNO]: {
    resource: 'https://fonts.google.com/specimen/MuseoModerno',
    label: 'Base (Museo Moderno)',
    category: EDynaCMSFontCategory.MODERN,
    cssFamilyName: "'MuseoModerno', cursive",
    bold: {
      [EBold.B100]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F135_MODERN_BASE_NOTO]: {
    resource: 'https://fonts.google.com/noto/specimen/Noto+Sans?subset=greek&noto.script=Grek',
    label: 'Noto',
    category: EDynaCMSFontCategory.MODERN,
    cssFamilyName: "'Noto Sans', sans-serif;",
    bold: {
      [EBold.B100]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100&display=swap')
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B900]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@900&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en', 'el'],
  },

  // Fashion

  [EDynaCMSFontFamily.F126_FASHION_BASE_RALEWAY]: {
    resource: 'https://fonts.google.com/specimen/Raleway',
    category: EDynaCMSFontCategory.FASHION,
    label: 'Base (Raleway)',
    cssFamilyName: "'Raleway', sans-serif",
    bold: {
      [EBold.B100]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,100&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B200]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,200&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B300]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital@1&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B800]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,800&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B900]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F127_FASHION_CONDENSED_RADHANI]: {
    resource: 'https://fonts.google.com/specimen/Rajdhani',
    category: EDynaCMSFontCategory.FASHION,
    label: 'Condensed (Rajdhani)',
    cssFamilyName: "'Rajdhani', sans-serif",
    bold: {
      [EBold.B300]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F186_FASHION_EMPIRE_FS]: {
    resource: 'https://www.fontspring.com/fonts/fontsite/empire-fs',
    category: EDynaCMSFontCategory.FASHION,
    label: 'Empire',
    cssFamilyName: 'Empire',
    bold: {
      [EBold.B500]: {
        regular: {
          script: `
            <style>
              @font-face {
                font-family: 'Empire';
                src: url('/assets/fonts/empire-fs/Empire-webfont.woff2') format('woff2'),
                     url('/assets/fonts/empire-fs/Empire-webfont.woff') format('woff');
                font-weight: normal;
                font-style: normal;
              }
            </style>
          `,
          cssProperties: {letterSpacing: 0},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F188_FASHION_OSWALD]: {
    resource: 'https://fonts.google.com/specimen/Oswald?query=Oswald',
    category: EDynaCMSFontCategory.FASHION,
    label: 'Oswald',
    cssFamilyName: "'Oswald', sans-serif",
    bold: {
      [EBold.B200]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
      [EBold.B300]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: -1},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en'],
  },

  // Elegant

  [EDynaCMSFontFamily.F128_ELEGANT_BASE_ITALIANA]: {
    resource: 'https://fonts.google.com/specimen/Italiana',
    label: 'Base (Italiana)',
    category: EDynaCMSFontCategory.ELEGANT,
    cssFamilyName: "'Italiana', serif",
    bold: {
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F138_ELEGANT_BASE_ROBOTO_FLEX]: {
    resource: 'https://fonts.google.com/specimen/Roboto+Flex?query=Roboto+Flex',
    label: 'Base (Roboto Flex)',
    category: EDynaCMSFontCategory.ELEGANT,
    cssFamilyName: "'Roboto Flex', sans-serif",
    bold: {
      [EBold.B100]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B200]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B300]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B500]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,500&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B600]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,600&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B800]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,800&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B900]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,900&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B1000]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,1000&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en', 'el'],
  },

  // Impact

  [EDynaCMSFontFamily.F129_IMPACT_BASE_ANTON]: {
    resource: 'https://fonts.google.com/specimen/Anton?query=anton',
    label: 'Base (Anton)',
    category: EDynaCMSFontCategory.IMPACT,
    cssFamilyName: "'Anton', sans-serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F139_IMPACT_BASE_FIRA]: {
    resource: 'https://fonts.google.com/specimen/Fira+Sans+Condensed?query=fira+sans',
    label: 'Base (Fira Sans Condensed)',
    category: EDynaCMSFontCategory.IMPACT,
    cssFamilyName: "'Fira Sans Condensed', sans-serif",
    bold: {
      [EBold.B900]: {
        regular: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@900&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
        italic: {
          script: `
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@1,900&display=swap');
            </style>
          `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: false,
    stretchable: false,
    languages: ['en', 'el'],
  },

  // Calligraphy

  [EDynaCMSFontFamily.F130_CALLIGRAPHY_BASE_PINYON]: {
    resource: 'https://fonts.google.com/specimen/Pinyon+Script?category=Handwriting&preview.text=English%20%CE%95%CE%BB%CE%BB%CE%B7%CE%BD%CE%B9%CE%BA%CE%AC&preview.text_type=custom',
    label: 'Pinyon',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Pinyon Script', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F177_CALLIGRAPHY_CHERRY]: {
    resource: 'https://fonts.google.com/specimen/Cherry+Swash?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Cherry Swash',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Cherry Swash', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cherry+Swash&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
      [EBold.B700]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cherry+Swash:wght@700&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F182_CALLIGRAPHY_LOVERS_QUARREL]: {
    resource: 'https://fonts.google.com/specimen/Lovers+Quarrel?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Lovers Quarrel',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Lovers Quarrel', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Lovers+Quarrel&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F184_CALLIGRAPHY_MEA_CULPA]: {
    resource: 'https://fonts.google.com/specimen/Mea+Culpa?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Mea Culpa',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Mea Culpa', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Mea+Culpa&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F185_CALLIGRAPHY_LETTERISH_YUJI_MAI]: {
    resource: 'https://fonts.google.com/specimen/Yuji+Mai?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Letterish (Yuji Mai)',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Yuji Mai', serif",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Yuji+Mai&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F183_CALLIGRAPHY_E]: {
    resource: 'https://fonts.google.com/specimen/Macondo+Swash+Caps?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'E-style (Macondo Swash Caps)',
    category: EDynaCMSFontCategory.CALLIGRAPHY,
    cssFamilyName: "'Macondo Swash Caps', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Macondo+Swash+Caps&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },

  // Artistic

  [EDynaCMSFontFamily.F168_ARTISTIC_LINES_DOUBLE_TRAIN]: {
    resource: 'https://fonts.google.com/specimen/Train+One?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Double lines (Train)',
    category: EDynaCMSFontCategory.ARTISTIC,
    cssFamilyName: "'Train One', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Train+One&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F131_ARTISTIC_LINES_MULTI_MONOTON]: {
    resource: 'https://fonts.google.com/specimen/Monoton',
    label: 'Multilines (Monoton)',
    category: EDynaCMSFontCategory.ARTISTIC,
    cssFamilyName: "'Monoton', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F169_ARTISTIC_CINEMA_LINELIGHT]: {
    resource: 'https://fonts.google.com/specimen/Limelight?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Cinema (Limelight)',
    category: EDynaCMSFontCategory.ARTISTIC,
    cssFamilyName: "'Limelight', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F170_ARTISTIC_CURLY_GRIFFY]: {
    resource: 'https://fonts.google.com/specimen/Griffy?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Curly (Griffy)',
    category: EDynaCMSFontCategory.ARTISTIC,
    cssFamilyName: "'Griffy', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Griffy&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },

  // Animal print

  [EDynaCMSFontFamily.F171_ANIMALPRINT_CHEETAH]: {
    resource: 'https://fonts.google.com/specimen/Rubik+Microbe?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Cheetah (Rubik Microbe)',
    category: EDynaCMSFontCategory.ANIMAL_PRINT,
    cssFamilyName: "'Rubik Microbe', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Rubik+Microbe&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
  [EDynaCMSFontFamily.F172_ANIMALPRINT_COW]: {
    resource: 'https://fonts.google.com/specimen/Moo+Lah+Lah?preview.text=CELINE%20celine&preview.text_type=custom',
    label: 'Cow (Moo Lah Lah)',
    category: EDynaCMSFontCategory.ANIMAL_PRINT,
    cssFamilyName: "'Moo Lah Lah', cursive",
    bold: {
      [EBold.B400]: {
        regular: {
          script: `
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Moo+Lah+Lah&display=swap');
          </style>
        `,
          cssProperties: {letterSpacing: '-1px'},
        },
      },
    },
    italicAble: true,
    stretchable: false,
    languages: ['en'],
  },
};

Object.values(dynaCMSFonts)
  .forEach(dynaCMSFontSetup => {
    if (!Object.keys(dynaCMSFontSetup.bold).length) {
      console.error(`Internal dev error: Font setup ${dynaCMSFontSetup.label} has no "bold" option at all.`);
    }
  });
