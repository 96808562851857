import {
  EDynaCMSFontFamily,
  EFontSize,
  EBold,
  EItalic,
  EUnderline,
  ETextAlign,
  ETextTransform,
} from "server-app";

import {TNodeElement} from "utils-library/dist/commonJs/typescript";

import {
  SxProps,
  Theme,
} from "ui-components/dist/ThemeProvider";
import {DynaCMSFont} from "../DynaCMSFont";

export interface IDynaCMSFontFamilyProps {
  sx?: SxProps<Theme>;
  dataComponentName?: string;
  show?: boolean;
  component?: TNodeElement;
  fontFamily: EDynaCMSFontFamily;
  fontSize?: EFontSize | number;
  bold?: boolean | EBold;
  italic?: EItalic;
  underline?: EUnderline;
  textAlign?: ETextAlign;
  textTransform?: ETextTransform;
  letterSpacing?: number;
  fontStretchPercentage?: number;
  strokeWidth?: number;
  strokeColor?: string;
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  children: any;
}

export const DynaCMSFontFamily = (props: IDynaCMSFontFamilyProps): JSX.Element => {
  const {
    sx,
    dataComponentName,
    show,
    component,
    fontFamily,
    fontSize,
    bold = EBold.INHERIT,
    italic = EItalic.INHERIT,
    underline = EUnderline.INHERIT,
    textAlign = ETextAlign.INHERIT,
    textTransform = ETextTransform.INHERIT,
    letterSpacing,
    fontStretchPercentage,
    strokeWidth,
    strokeColor,
    scaleX = 1,
    scaleY = 1,
    opacity,
    children,
  } = props;

  return (
    <DynaCMSFont
      sx={sx}
      dataComponentName={dataComponentName}
      show={show}
      component={component}
      font={{
        fontFamily,
        fontSize: (() => {
          if (fontSize === undefined) return EFontSize.INHERIT;
          if (typeof fontSize === "number") return `${fontSize < 9 ? fontSize * 8 : fontSize}px` as any;
          return fontSize;
        })(),
        bold: (() => {
          if (bold === false) return EBold.B500;
          if (bold === true) return EBold.B800;
          return bold;
        })(),
        italic,
        underline,
        textAlign,
        textTransform,
        letterSpacingInherit: letterSpacing === undefined,
        letterSpacing: letterSpacing === undefined ? -1 : letterSpacing,
        fontStretchInherit: fontStretchPercentage === undefined,
        fontStretchPercentage: fontStretchPercentage === undefined ? 100 : fontStretchPercentage,
        strokeInherit: strokeWidth !== undefined || strokeColor !== undefined,
        strokeWidth: strokeWidth === undefined ? 0 : strokeWidth,
        strokeColor: strokeColor === undefined ? "" : strokeColor,
        opacityInherit: opacity === undefined,
        scale: scaleX !== 1 || scaleY !== 1,
        scaleX,
        scaleY,
        opacity: opacity === undefined ? 1 : opacity,
      }}
    >
      {children}
    </DynaCMSFont>
  );
};
