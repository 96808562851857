import {
  IDBEntityBase,
  getDefaultDBEntityDocument,
} from "utils-library/dist/commonJs/db-entity-interfaces";

import {
  EDynaCMSBlockType,
  TContentBlock,
} from "./EDynaCMSBlockType";
import {
  IDynaCMSFont,
  getDefaultIDynaCMSFont,
} from "./IDynaCMSFont";
import {ISEOKeywordsCoverage} from "../interfaces/ISEOKeywordsCoverage";

export interface IDynaCMSArticle extends IDBEntityBase {
  urlId: string;
  shortUrl: string;

  groupId: string;
  languageId: string;
  countryId: string;

  title: string;
  subtitle: string;
  label: string;
  description: string;
  tags: string[];
  seoKeywords: string;
  canonicalHref: string;

  seoAvailable: boolean;
  usedAsPage: boolean;
  changeFrequency: EDynaCMSArticleChangeFrequency;

  showPublishDateInList: EShowDate;
  showUpdateDateInList: EShowDate;
  showPublishDateInView: EShowDate;
  showUpdateDateInView: EShowDate;

  showAds: boolean;
  showShareButtons: boolean;
  showRelatedInsideBody: boolean;
  showRelatedAtTheEnd: boolean;

  fontFamilyGeneral: IDynaCMSFont;
  fontFamilyHeader: IDynaCMSFont;
  fontFamilyHeaders: IDynaCMSFont;
  viewWidthType: EViewWidthType;
  headingStyleList: EDynaCMSHeadingStyle;
  headingStyleView: EDynaCMSHeadingStyle;

  eventStart?: number;  // PK for event of any type
  eventEnd?: number;

  geolocation?: {       // When this took place
    lon: number;
    lat: number;
  };

  coverMediaBlockId?: string;
  coverMediaBlockCopy?: IDynaCMSArticleBlock | null;

  mapAddress?: string;  // Google map address

  publishAt: number;      // Which day is desired to be published
  publishedAt: number;    // Timestamp. 0 means is not published, otherwise will have always the value of publishAt

  content: IDynaCMSArticleContent;

  stats: IDynaCMSArticleStats;

  isContentValid: boolean;

  // New optional prop? Update the getDefaultDynaCMSArticleHeading below!
}

export enum EDynaCMSArticleChangeFrequency {
  // Source: https://www.sitemaps.org/protocol.html
  ALWAYS = "always",
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
  NEVER = "never",
}

export enum EShowDate {
  INHERIT = "INHERIT",
  YES = "YES",
  NO = "NO",
}

export enum EViewWidthType {
  FULL = "FULL",
  EASY_READ = "EASY_READ",
}

export enum EDynaCMSHeadingStyle {
  HIDDEN = "HIDDEN",
  CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT",
  CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_ALL = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_ALL",
  CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_TEXTS = "CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT_NEGATIVE_TEXTS",
  CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO",
  CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_ALL = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_ALL",
  CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_TEXTS = "CLEAN_PLAIN_HORIZONTAL_TEXT_PHOTO_NEGATIVE_TEXTS",
  CLEAN_PLAIN_VERTICAL_PHOTO_TEXT = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT",
  CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL",
  CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_TEXTS = "CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_TEXTS",
  CLEAN_PLAIN_VERTICAL_TEXT_PHOTO = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO",
  CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_ALL = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_ALL",
  CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_TEXTS = "CLEAN_PLAIN_VERTICAL_TEXT_PHOTO_NEGATIVE_TEXTS",
  TINY_SMALL_PHOTO_TEXT = "TINY_SMALL_PHOTO_TEXT",
  PHOTO_TILE = "PHOTO_TILE",
  EXCEPTIONAL = "EXCEPTIONAL",
  EXCEPTIONAL_NEGATIVE_ALL = "EXCEPTIONAL_NEGATIVE_ALL",
  EXCEPTIONAL_NEGATIVE_TEXTS = "EXCEPTIONAL_NEGATIVE_TEXTS",
}

export interface IDynaCMSArticleContent {
  order: string[];      // Array with the blockIds
  blocks: {
    [blockId: string]: IDynaCMSArticleBlock;
  };
}

export type IDynaCMSArticleHeading = Omit<IDynaCMSArticle, "content">; // Note: Omit somthing more? Update this point also #DEV_POINT_0284760246245

export interface IDynaCMSArticleBlock {
  blockId: string;
  content: TContentBlock;
  show: boolean;
  updatedAt: number;
  updatedBy: string;
}

export interface IDynaCMSArticleStats {
  words: number;
  headings: number;
  blocks: Partial<Record<EDynaCMSBlockType, number>>;
  size: number;
  seoKeywordsCoveragePercentage: number;
  seoKeywordsCoverageAnalysis: ISEOKeywordsCoverage[];
}

export const getDefaultDynaCMSArticleHeading = () => getDefaultDBEntityDocument<IDynaCMSArticleHeading>({
  urlId: '',
  shortUrl: '',
  groupId: '',
  languageId: '',
  countryId: '',
  title: '',
  subtitle: '',
  label: '',
  description: '',
  showPublishDateInList: EShowDate.INHERIT,
  showUpdateDateInList: EShowDate.INHERIT,
  showPublishDateInView: EShowDate.INHERIT,
  showUpdateDateInView: EShowDate.INHERIT,
  showAds: true,
  showShareButtons: true,
  showRelatedInsideBody: true,
  showRelatedAtTheEnd: true,
  tags: [],
  seoAvailable: true,
  usedAsPage: false,
  changeFrequency: EDynaCMSArticleChangeFrequency.NEVER,
  seoKeywords: '',
  canonicalHref: '',
  coverMediaBlockId: '',
  coverMediaBlockCopy: null,
  fontFamilyGeneral: getDefaultIDynaCMSFont(),
  fontFamilyHeader: getDefaultIDynaCMSFont(),
  fontFamilyHeaders: getDefaultIDynaCMSFont(),
  viewWidthType: EViewWidthType.EASY_READ,
  headingStyleList: EDynaCMSHeadingStyle.CLEAN_PLAIN_HORIZONTAL_PHOTO_TEXT,
  headingStyleView: EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT,
  eventStart: undefined,
  eventEnd: undefined,
  geolocation: undefined,
  mapAddress: undefined,
  isContentValid: true,
  publishAt: 0,
  publishedAt: 0,
  stats: {
    words: 0,
    headings: 0,
    blocks: {},
    size: 0,
    seoKeywordsCoveragePercentage: 0,
    seoKeywordsCoverageAnalysis: [],
  },
});

export const getDefaultDynaCMSArticle = (): IDynaCMSArticle => ({
  ...getDefaultDynaCMSArticleHeading(),
  content: {
    order: [],
    blocks: {},
  },
});
