import {routeDynaCMSArticle} from "../../../../core/dyna-cms/public-components";

export const routeAdoreGrMarketingMainPage = routeDynaCMSArticle({
  path: '/marketing',
  multiLangArticles: [
    {
      countryId: 'gr',
      languageId: 'el',
      groupId: 'page',
      articleId: '9a461df5-3c494155756f818a16',
    },
  ],
});
