import * as React from "react";
import {
  useState,
  useRef,
} from "react";

import {dynaError} from "dyna-error";

import {
  IUser,
  EDynaProfileUserRight,
  IUserAndRights,
  DELEGATE_SLASH,
} from "server-app";

import {
  GridContainer,
  GridItem,
} from "ui-components/dist/Grid";
import {
  FlexContainerResponsive,
  FlexContainerHorizontal,
  FlexItemMax,
  FlexItemMin,
} from "ui-components/dist/FlexContainer";
import {Box} from "ui-components/dist/Box";
import {
  ButtonBar,
  EButtonBarAlign,
} from "ui-components/dist/ButtonBar";
import {Typography} from "ui-components/dist/Typography";
import {ProfileIcon} from "ui-components/dist/ProfileIcon";
import {
  TableLoadMore,
  TTableLoadMoreHandlerLoad,
  ETableFilterValueType,
  ETableFilterComparison,
  ITableLoadMoreRef,
} from "ui-components/dist/TableLoadMore";
import {Condition} from "ui-components/dist/Condition";
import {Button} from "ui-components/dist/Button";
import {ButtonEditMode} from "ui-components/dist/ButtonEditMode";
import {ErrorBanner} from "ui-components/dist/ErrorBanner";
import {
  InputSwitch,
  EInputSwitchColor,
} from "ui-components/dist/InputSwitch";
import {IconViewer} from "ui-components/dist/IconViewer";
import {
  Link,
  ELinkColor,
} from "ui-components/dist/Link";
import {useConfirm} from "ui-components/dist/useConfirm";
import {useLocalStorageState} from "ui-components/dist/useLocalStorageState";

import {debugMode} from "../../../../utils/debug-mode";
import {IAppStore} from "../../../../state/IAppStore";

import {apiDynaProfilesSearchUsersForProfileGet} from "../../api/profiles/apiDynaProfilesSearchUsersForProfileGet";
import {apiDynaProfilesGetUserInfoGet} from "../../api/users/apiDynaProfilesGetUserInfoGet";
import {apiProfileUserRightSet} from "../../api/rights/apiProfileUserRightSet";
import {apiDynaProfilesRemoveUser} from "../../api/users/apiDynaProfilesRemoveUser";

import {IManageRight} from "./interfaces";
import {RightValueEditor} from "./components/RightValueEditor";
import {AddUserButtonModal} from "./components/AddUserButtonModal";

import {createIcon} from "ui-components/dist/IconComponent";
import CloseIcon from '@mui/icons-material/Close';
import YouIcon from '@mui/icons-material/AccountCircle';
import OwnerIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OwnerFullAccessIcon from '@mui/icons-material/DoneOutline';
import DebugIcon from '@mui/icons-material/BugReport';

export interface IDynaProfileUserRightsAssigmentGridProps {
  store: IAppStore;
  manageRights: IManageRight[]; // For which rights the grid will work
  manageDelegateRights: boolean;  // Assign delegate rights to others (if the current user is owner of the profile)
  onClose?: () => void;
}

// Analysis: https://docs.google.com/document/d/1z0-DQta3G3NdmuWeKJNCoj8RL2J4vZ0uKPvZQJS0ob0/edit#heading=h.6qe1164e0hj1
export const DynaProfileUserRightsAssigmentGrid: React.FC<IDynaProfileUserRightsAssigmentGridProps> = (
  {
    store: {
      userAuth: {state: {user: activeUser}},
      profiles: {
        state: {
          isLoadingProfile,
          profile,
          rights: activeUserRights,
        },
        actions: {initializeCurrentUserProfiles},
      },
    },
    manageRights,
    manageDelegateRights,
    onClose,
  },
) => {
  const {
    confirm,
    confirmViewer,
  } = useConfirm();

  const refTable = useRef<ITableLoadMoreRef<IUserAndRights>>(null);

  const [editMode, setEditMode] = useState(false);
  const [addUserError, setAddUserError] = useState<null | Error>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<Error | null>(null);

  const [debugNoRestrictions, setDebugNoRestrictions] = useLocalStorageState<boolean>({
    key: 'DynaProfileUserRightsAssigmentGrid-debugNoRestrictions',
    default: false,
  });

  const handleEditModeClick = (editMode: boolean) => setEditMode((editMode));

  const handleTableLoad: TTableLoadMoreHandlerLoad<IUserAndRights> = async (
    {
      filters,
      pagination: {
        skip,
        limit,
      },
    },
  ): Promise<IUserAndRights[]> => {
    const searchUser = filters.find(f => f.filterName === "user")?.value || "";
    const {usersAndRights} = await apiDynaProfilesSearchUsersForProfileGet({
      profileId: profile.id,
      searchUser,
      rights:
        manageRights
          .map(r => r.right)
          .concat(
            manageDelegateRights
              ? manageRights.map(r => DELEGATE_SLASH + r.right) // Load the delegated rights too
              : [],
          ),
      skip,
      limit,
    });
    return usersAndRights;
  };

  const handleAddUser = async (userId: string): Promise<void> => {
    let userToAdd: IUser | null = null;
    try {
      setAddUserError(null);
      userToAdd = await apiDynaProfilesGetUserInfoGet({
        profileId: profile.id,
        userId,
      });
    }
    catch (e: any) {
      setAddUserError(e);
    }
    if (!userToAdd) return;
    if (!refTable.current) return;

    const existsAllReady = !!refTable.current.getItems().find(item => item.user.id === userToAdd?.id);
    if (existsAllReady) return;

    refTable.current.setItems(
      refTable.current.getItems().concat({
        user: userToAdd,
        rights: {},
        canBeRemoved: false,
      }),
    );
  };

  const handleRemoveUserClick = async (userId: string): Promise<void> => {
    try {
      setIsSaving(true);
      setSaveError(null);
      await apiDynaProfilesRemoveUser({
        profileId: profile.id,
        userId,
      });
      refTable.current?.setItems(
        refTable.current.getItems().filter(item => item.user.id !== userId),
      );
    }
    catch (e: any) {
      setSaveError(e);
    }
    finally {
      setIsSaving(false);
    }
  };

  const getStateRight = (
    {
      userId,
      right,
    }: {
      userId: string;
      right: string;
    },
  ): boolean => {
    if (!refTable.current) return false; // 4TS
    const userItem = refTable.current.getItems().find(item => item.user.id === userId);
    if (!userItem) { // 4TS
      console.error('Internal error 20231208162148: Cannot find the user in the loaded data');
      return false;
    }
    return !!userItem.rights[right];
  };

  const setStateRight = (
    {
      userId,
      right,
      valid,
    }: {
      userId: string;
      right: string;
      valid: boolean;
    },
  ): void => {
    if (!refTable.current) return; // 4TS
    refTable.current.setItems(
      refTable.current.getItems()
        .map(item => {
          if (item.user.id !== userId) return item;
          return {
            ...item,
            rights: {
              ...item.rights,
              [right]: valid,
            },
          };
        }),
    );
  };

  const handleRightChange = async (
    {
      userId,
      right,
      valid,
    }: {
      userId: string;
      right: string;
      valid: boolean;
    },
  ): Promise<void> => {
    if (
      right === EDynaProfileUserRight.OWNER
      && userId === activeUser.id
      && valid === false
    ) {
      const removeOwnership = await confirm({
        title: 'Remove ownership',
        message: 'Are you sure you want to lose ownership of this profile?',
        helperMessage: 'This cannot be undone!',
        labelConfirmButton: 'Remove ownership',
        labelConfirmIcon: createIcon.byMuiIcon(DeleteForeverIcon),
      });
      if (!removeOwnership) return;
    }

    if (!refTable.current) return; // 4TS
    const currentValue = getStateRight({
      userId,
      right,
    });

    try {
      setSaveError(null);
      setIsSaving(true);
      setStateRight({
        userId,
        right,
        valid,
      });
      await apiProfileUserRightSet({
        userId,
        profileId: profile.id,
        right,
        valid,
      });
      initializeCurrentUserProfiles();
    }
    catch (e: any) {
      setSaveError(e);
      // Revert it
      setStateRight({
        userId,
        right,
        valid: currentValue,
      });
    }
    finally {
      setIsSaving(false);
    }
  };

  const canChangeRight = (right: string): boolean => activeUserRights.isOwnerOrHasRight(DELEGATE_SLASH + right);

  if (!isLoadingProfile && !profile.id) {
    return (
      <ErrorBanner
        error={dynaError({
          message: 'Profile is required',
          userMessage: 'Profile is required',
        })}
      />
    );
  }

  if (!profile.id) return null;

  return (
    <Box dataComponentName="DynaProfileUserRightsAssigmentGrid">
      {confirmViewer}

      <GridContainer spacing={2}>
        <GridItem
          sx={{
            position: 'sticky',
            top: '-1px',
            paddingTop: theme => theme.spacing(2),
            paddingLeft: theme => theme.spacing(2),
            background: theme => theme.palette.background.paper,
            zIndex: 10,
          }}
        >
          <FlexContainerResponsive verticalOnMobile>
            <FlexItemMax>
              <Typography v="h1" noSpace>Profile's users & rights</Typography>
              <Typography v="h2" noSpace>Profile: {profile.displayName}</Typography>
              <Typography v="h4" noSpace>{profile.description}</Typography>
            </FlexItemMax>
            <FlexItemMin>
              <ButtonBar
                spacing={1}
                align={EButtonBarAlign.RIGHT}
                nowrap
              >
                <ButtonEditMode
                  editMode={editMode}
                  isSaving={isSaving}
                  hideLabelOnMobile
                  onChange={handleEditModeClick}
                />
                <AddUserButtonModal
                  show={
                    (
                      activeUserRights.isOwner
                      || activeUserRights
                        .filter({
                          rightType: "delegate",
                          valid: true,
                        })
                        .has
                    )
                    || (debugMode.active && debugNoRestrictions)
                  }
                  disabled={!editMode}
                  onUserSelection={handleAddUser}
                />
                <Button
                  show={!!onClose}
                  Icon={createIcon.byMuiIcon(CloseIcon)}
                  onClick={onClose}
                >
                  Close
                </Button>
              </ButtonBar>
            </FlexItemMin>
          </FlexContainerResponsive>
        </GridItem>
        <GridItem>
          <ErrorBanner error={addUserError}/>
          <ErrorBanner error={saveError}/>
        </GridItem>
        <GridItem show={debugMode.active}>
          <InputSwitch
            ariaLabel="Debug - Remove UI restrictions"
            color={EInputSwitchColor.ERROR}
            Icon={createIcon.byMuiIcon(DebugIcon)}
            label="Remove UI restrictions that protect the user for invalid requets."
            helperLabel="Removing these restrictions the UI can send incorrect requests for testing the backend. The expected behaviour for all invalid requests it to see an error banner."
            value={debugNoRestrictions}
            onChange={setDebugNoRestrictions}
          />
        </GridItem>
        <GridItem>
          <TableLoadMore<IUserAndRights>
            key={[activeUser.id, profile.id].join('/')}
            ref={refTable}
            ariaLabel="User rights using the current profile"
            loadSize={10}
            rowHover={false}
            columns={[
              {
                fieldName: "user",
                headerLabel: "User",
                cellRender: (
                  user: IUser,
                  {
                    rights,
                    canBeRemoved,
                  },
                ) => (
                  <FlexContainerHorizontal alignVertical="middle">
                    <FlexItemMin sx={{marginRight: theme => theme.spacing(2)}}>
                      <ProfileIcon
                        src={user.avatarUrl}
                        size={64}
                        alt="User photo"
                      />
                    </FlexItemMin>
                    <FlexItemMin
                      show={!!rights[EDynaProfileUserRight.OWNER] && profile.id !== user.id}
                      sx={{marginRight: 1}}
                    >
                      <IconViewer
                        MuiIcon={OwnerIcon}
                        width={32}
                        title="Owner"
                      />
                    </FlexItemMin>
                    <FlexItemMin
                      show={profile.id === user.id}
                      sx={{marginRight: 1}}
                    >
                      <IconViewer
                        MuiIcon={YouIcon}
                        width={32}
                        title="Defaut user's profile"
                      />
                    </FlexItemMin>
                    <FlexItemMax>
                      <div>
                        <b>{user.displayName}</b>
                        <Condition if={profile.id === user.id}>
                          {" "}<b>(you)</b>
                        </Condition>
                      </div>
                      <div>
                        {user.email}
                      </div>
                      <Condition
                        if={
                          debugNoRestrictions
                          || (
                            canBeRemoved
                            && activeUserRights.isOwnerOrHasRight(EDynaProfileUserRight.REMOVE_USER)
                          )
                        }
                      >
                        <br/>
                        <Link
                          color={ELinkColor.ERROR}
                          onClick={() => handleRemoveUserClick(user.id)}
                        >
                          Remove user from profile
                        </Link>
                      </Condition>
                    </FlexItemMax>
                  </FlexContainerHorizontal>
                ),
              },
              ...manageRights
                .map(
                  (
                    {
                      right,
                      label,
                      description,
                    },
                  ) => ({
                    fieldName: `right-${right}`,
                    headerLabel: label,
                    headerTooltip: description,
                    cellRender: (
                      cellValue: any,
                      {
                        user,
                        rights,
                      }: IUserAndRights,
                    ) => {
                      cellValue;
                      return right !== EDynaProfileUserRight.OWNER && rights[EDynaProfileUserRight.OWNER]
                        ? (
                          <Box>
                            <IconViewer
                              Icon={createIcon.byMuiIcon(OwnerFullAccessIcon)}
                              width={36}
                            />
                            <Box sx={{fontSize: 12}}>{label} as owner</Box>
                          </Box>
                        )
                        : <>
                          <RightValueEditor
                            right={right}
                            label={label}
                            title={description}
                            color={right === EDynaProfileUserRight.OWNER ? EInputSwitchColor.ERROR : undefined}
                            readonly={!editMode}
                            disabled={
                              !canChangeRight(right)
                              || (
                                right === EDynaProfileUserRight.OWNER
                                && profile.id === user.id
                              )
                            }
                            debugNoRestrictions={debugNoRestrictions}
                            value={!!rights[right]}
                            onChange={
                              (valid, right) =>
                                handleRightChange({
                                  userId: user.id,
                                  right,
                                  valid,
                                })
                            }
                          />
                          <RightValueEditor
                            show={
                              debugNoRestrictions
                              || (
                                manageDelegateRights
                                && right !== EDynaProfileUserRight.OWNER
                                && !(profile.id === user.id && right === EDynaProfileUserRight.OWNER)
                              )
                            }
                            right={DELEGATE_SLASH + right}
                            label="Can delegate this right"
                            title="The user can delegate this right to other users"
                            readonly={!editMode}
                            disabled={!canChangeRight(DELEGATE_SLASH + right)}
                            debugNoRestrictions={debugNoRestrictions}
                            value={!!rights[DELEGATE_SLASH + right]}
                            onChange={
                              (valid, right) =>
                                handleRightChange({
                                  userId: user.id,
                                  right,
                                  valid,
                                })
                            }
                          />
                        </>;
                    },
                  }),
                ),
            ]}
            filters={[
              {
                filterName: "user",
                label: "User name",
                type: ETableFilterValueType.TEXT,
                comparison: ETableFilterComparison.CONTAINS,
                value: "",
              },
            ]}
            onLoad={handleTableLoad}
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
};
