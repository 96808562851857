import {connect} from "react-dynadux";

import {
  DynaProfileUserAccess as Component,
} from "./DynaProfileUserAccess";
export const DynaProfileUserAccess = connect(Component);

export type {
  IDynaProfileUserAccessProps,
} from "./DynaProfileUserAccess";
