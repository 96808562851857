import {
  EThemeName,
  Theme,
} from "ui-components/dist/ThemeProvider";
import {
  blue,
  red,
} from "@mui/material/colors";

export const themeChange = (themeName: EThemeName, theme: Theme): void => {
  if (themeName === EThemeName.MUI4_DARK) {
    theme.palette.primary.main = blue.A400;
    theme.palette.secondary.main = red.A400;
  }
};
