import {
  API_PATH_ApiArticleContentGet,
  IApiArticleContentGetRequest,
  IApiArticleContentGetResponse,
} from "server-app";

import {apiFetch} from "../../../../../api/apiFetch";

export const apiArticleContentGet = async (args: IApiArticleContentGetRequest): Promise<IApiArticleContentGetResponse> => {
  return apiFetch.request<IApiArticleContentGetRequest, null, IApiArticleContentGetResponse>({
    method: 'GET',
    path: API_PATH_ApiArticleContentGet,
    query: args,
  });
};
