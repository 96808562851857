import {Box} from "ui-components/dist/Box";
import {Link} from "ui-components/dist/Link";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

export interface IAppFooterLinksProps {
  links: ILinkItem[];
  onClick?: () => void;
}

export interface ILinkItem {
  label: string;
  hidden?: boolean;
  link: string;
}

export const AppFooterLinks = (props: IAppFooterLinksProps): JSX.Element => {
  const {
    links,
    onClick,
  } = props;
  const {isMobile} = useBreakpointDevice();

  return (
    <nav>
      <ul
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {links
          .filter(link => link.hidden !== true)
          .map((
            {
              label,
              link,
            },
            index,
          ) => (
            <Box
              component="li"
              key={index}
              sx={{display: 'inline'}}
            >
              <Link
                sx={{
                  color: 'inherit',
                  transition: '150ms opacity',
                  marginRight: theme => theme.spacing(2),
                  lineHeight: theme => theme.spacing(isMobile ? 5 : 3),
                  '&:hover': {opacity: 0.5},
                }}
                href={link}
                onClick={onClick}
              >
                <Box inline sx={{whiteSpace: 'nowrap'}}>
                  | {label}
                </Box>
              </Link>
            </Box>
          ))}
      </ul>
    </nav>
  );
};
