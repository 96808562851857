import * as React from "react";

import ReactPlayer from "react-player/soundcloud";

import {ContainerDetectResize} from "../ContainerDetectResize";

// Help: https://github.com/CookPete/react-player/blob/HEAD/src/demo/App.js

export interface ISoundPlayerSoundCloudProps {
  url: string;

  autoStart?: boolean;
  startAtSec?: number;            // Todo: Not implemented
  endAtSec?: number;              // Todo: Not implemented

  speed?: number;                 // Todo: Not implemented
  muted?: boolean;
  loop?: boolean;                 // Todo: Not working, always is on

  showControls?: boolean;
}

export const SoundPlayerSoundCloud = (props: ISoundPlayerSoundCloudProps): JSX.Element => {
  const {
    url,

    autoStart = false,
    startAtSec = 0,
    endAtSec,

    speed = 1,
    muted = true,
    loop,

    showControls = true,
  } = props;

  startAtSec;
  endAtSec;

  // Help https://developer.vimeo.com/player/sdk/embed

  return (
    <ContainerDetectResize fullHeight>
      {
        (
          {
            width,
            height,
          },
        ) => (
          <ReactPlayer
            url={url}
            muted={muted}
            controls={showControls}
            playing={autoStart}
            loop={loop}
            width={width}
            height={height}
            playbackRate={speed}
          />
        )
      }
    </ContainerDetectResize>
  );
};
