
import {EDynaCMSRightsDEPRECATED} from "server-app";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeDynaCMSSettingsPaths: IAppRoute = {
  title: "Dyna CMS Settings",
  routePath: '/dyna-cms-settings',
  getRoutePath: () => routeDynaCMSSettingsPaths.routePath,
  userHasAllRights: [],
  exact: true,
  userHasAnyOfRights: [
    EDynaCMSRightsDEPRECATED.ADMINISTRATOR,
  ],
  requiresLatestClientVersion: true,
  render: () => null,
};
