import {connect} from "react-dynadux";

import {
  DynaIntlDateTimeFormatter as Component,
} from "./DynaIntlDateTimeFormatter";
export const DynaIntlDateTimeFormatter = connect(Component);

export type {
  IDynaIntlDateTimeFormatterProps,
} from "./DynaIntlDateTimeFormatter";
