import * as React from "react";
import {useState} from "react";

import {dynaSwitchEnum} from "dyna-switch";

import {
  EDynaProfileType,
  ETKDynaProfiles,
  IDynaProfile,
  EDynaProfileUserRight,
  getDefaultDynaProfile,
} from "server-app";

import {Box} from "ui-components/dist/Box";
import {GridItem} from "ui-components/dist/Grid";
import {
  Form,
  EFormType,
  EFormMode,
  EViewMode,
} from "ui-components/dist/Form";
import {Typography} from "ui-components/dist/Typography";
import {
  Alert,
  EAlertVariant,
  EAlertType,
} from "ui-components/dist/Alert";
import {
  Input,
  EInputVariant,
} from "ui-components/dist/Input";
import {
  InputSelect,
  EIInputSelectVariant,
} from "ui-components/dist/InputSelect";
import {Condition} from "ui-components/dist/Condition";
import {
  Link,
  ELinkColor,
} from "ui-components/dist/Link";
import {HelperText} from "ui-components/dist/HelperText";

import {IAppStore} from "../../../../state/IAppStore";

import {
  DynaIntlProvider,
  DynaIntlFormatter,
  useDynaIntl,
} from "../../../dyna-intl/components-public";

import {DynaProfileHeader} from "../../public-components";
import {DynaProfileUserAccess} from "../../public-components";

import {routeProfileEditPaths} from "../../routes/routeProfileEdit.paths";
import {routeProfileUserRights} from "../../routes/routeProfileUserRights";

import {apiDynaProfilePost} from "../../api/profiles/apiDynaProfilePost";
import {apiDynaProfileForUserGet} from "../../api/profiles/apiDynaProfileForUserGet";
import {apiDynaProfilePut} from "../../api/profiles/apiDynaProfilePut";
import {apiDynaProfileDelete} from "../../api/profiles/apiDynaProfileDelete";
import {apiDynaProfileUndelete} from "../../api/profiles/apiDynaProfileUndelete";

import {createIcon} from "ui-components/dist/IconComponent";
import WarningIcon from '@mui/icons-material/Warning';

export interface IDynaProfileManageFormProps {
  store: IAppStore;
  profileId?: string; // Undefined for new
  viewMode:
    | "view"
    | "edit";
}

const tks = {
  defaultUserProfileTitle: ETKDynaProfiles.DEFAULT_USER_PROFILE_INFO_TITLE,
  defaultUserProfileBody: ETKDynaProfiles.DEFAULT_USER_PROFILE_INFO_BODY,
  createProfile: ETKDynaProfiles.CREATE_PROFILE_HEADER_LABEL,
  updateProfile: ETKDynaProfiles.MANAGE_PROFILE_HEADER_LABEL,
  labelDisplayMessage: ETKDynaProfiles.FORM_LABEL_displayName,
  labelProfileDescription: ETKDynaProfiles.FORM_LABEL_profileDescription,
  labelShortName: ETKDynaProfiles.FORM_LABEL_shortName,
  labelShortNameHelp: ETKDynaProfiles.FORM_LABEL_shortName_helper,
  labelSlogan: ETKDynaProfiles.FORM_LABEL_slogan,
  labelProfileType: ETKDynaProfiles.FORM_LABEL_profileType,
  helpWhatIsProfile: ETKDynaProfiles.HELP_TEXT_WhatIsProfile,
  bioShort: ETKDynaProfiles.FORM_LABEL_shortBio,
  bioShortHelper: ETKDynaProfiles.FORM_LABEL_shortBioHelper,
  bioUrl: ETKDynaProfiles.FORM_LABEL_bioUrl,
  createProfileButtonLabel: ETKDynaProfiles.BUTTON_LABEL_CreateProfile,
  updateProfileButtonLabel: ETKDynaProfiles.BUTTON_LABEL_UpdateProfile,
  profileManagement: ETKDynaProfiles.PROFILE_MANAGEMENT_SECTION,
  deleteProfileLabel: ETKDynaProfiles.DELETE_PROFILE_LABEL,
  deleteProfileHelper: ETKDynaProfiles.DELETE_PROFILE_HELPER,
  undeleteProfile: ETKDynaProfiles.UNDELETE_PROFILE_LABEL,
};

const DynaProfileManageFormCore: React.FC<IDynaProfileManageFormProps> = (
  {
    store: {
      app: {actions: {navigateTo}},
      userAuth: {state: {user: {id: currentUserId}}},
      profiles: {
        state: {
          profile: {id: currentProfileId},
          rights,
        },
        actions: {
          updateProfile,
          initializeCurrentUserProfiles,
        },
      },
    },
    profileId,
    viewMode,
  },
) => {
  const intl = useDynaIntl();

  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  return (
    <DynaProfileUserAccess
      profileId={profileId}
      right={viewMode === "edit" ? EDynaProfileUserRight.CHANGE_PROFILE : ""}
    >
      <Box
        sx={{
          maxWidth: 640,
          margin: 'auto',
        }}
      >
        <Form<IDynaProfile>
          formType={
            viewMode === "edit"
              ? EFormType.EDIT
              : EFormType.VIEW
          }
          emptyFormData={getDefaultDynaProfile()}
          loadDataId={profileId || null}
          isModalForm={false}
          hideCancelButtonOnCreateFormMore
          userCanDelete={false}
          userCanUnDelete={false}
          labels={{
            close: 'Close',
            cancel: 'Cancel',
            save: profileId
              ? intl.formatMessage({id: tks.updateProfileButtonLabel})
              : intl.formatMessage({id: tks.createProfileButtonLabel}),
          }}
          onApiGet={async profileId => {
            const response = await apiDynaProfileForUserGet({profileId});
            setProfileImageUrl(response.profile.profileImageUrl);
            setBackgroundImageUrl(response.profile.backgroundImageUrl);
            return response.profile;
          }}
          onApiPost={
            async (
              {
                type,
                displayName,
                shortName,
                profileImageUrl,
                backgroundImageUrl,
                slogan,
                description,
                bioShort,
                bioUrl,
              },
            ) => {
              const {dataId} = await apiDynaProfilePost({
                type,
                displayName,
                shortName,
                profileImageUrl,
                backgroundImageUrl,
                slogan,
                description,
                bioShort,
                bioUrl,
              });
              initializeCurrentUserProfiles();
              navigateTo({
                url: routeProfileEditPaths.getRoutePath({profileId: dataId}),
                replace: true,
              });
              return {dataId};
            }
          }
          onApiPut={
            async profile => {
              const {
                id: profileId,
                type,
                displayName,
                profileImageUrl,
                backgroundImageUrl,
                slogan,
                description,
              } = profile;
              await apiDynaProfilePut({
                profileId,
                type,
                displayName,
                profileImageUrl,
                backgroundImageUrl,
                slogan,
                description,
              });
              if (currentProfileId === profileId) await updateProfile(profile);
            }
          }
          onApiDelete={async (profileId) => {
            await apiDynaProfileDelete({profileId});
          }}
          onApiUndelete={async (profileId) => {
            await apiDynaProfileUndelete({profileId});
          }}
          renderHeader={
            (
              {
                formMode,
                viewMode,
                data: profile,
                changeByInput,
              },
            ) => {
              const handleHeaderChange = (value: string, propertyName: keyof IDynaProfile): void => {
                if (propertyName === "profileImageUrl") {
                  setProfileImageUrl(value);
                  return;
                }
                if (propertyName === "backgroundImageUrl") {
                  setBackgroundImageUrl(value);
                  return;
                }
                changeByInput(value, propertyName);
              };
              return (
                <>
                  <GridItem>
                    <DynaProfileHeader
                      show={!!profile.id}
                      profile={{
                        ...profile,
                        profileImageUrl,
                        backgroundImageUrl,
                      }}
                      editable={viewMode === EViewMode.EDIT}
                      onChange={handleHeaderChange}
                    />
                  </GridItem>
                  <GridItem sx={{height: theme => theme.spacing(3)}}/>
                  <GridItem>
                    <h1>
                      <DynaIntlFormatter
                        tk={
                          dynaSwitchEnum<EFormMode, string>(
                            formMode,
                            {
                              [EFormMode.CREATE]: tks.createProfile,
                              [EFormMode.EDIT_MANAGE]:
                                dynaSwitchEnum<EViewMode, string>(
                                  viewMode,
                                  {
                                    [EViewMode.VIEW]: '',
                                    [EViewMode.EDIT]: tks.updateProfile,
                                  },
                                ),
                            },
                          )
                        }
                      />
                    </h1>
                  </GridItem>
                  <GridItem>
                    <Link href={routeProfileUserRights.getRoutePath()}>Edit Profile User Rights</Link>
                  </GridItem>
                </>
              );
            }
          }
          renderForm={
            (
              {
                formMode,
                viewMode,
                data: {
                  type,
                  displayName,
                  description,
                  shortName,
                  slogan,
                  bioShort,
                  bioUrl,
                  deletedAt,
                },
                delete: deleteProfile,
                undelete: undeleteProfile,
                validationResult: {dataValidation},
              },
            ) => {
              return (
                <>
                  <GridItem show={formMode === EFormMode.CREATE}>
                    <Alert
                      type={EAlertType.INFO}
                      variant={EAlertVariant.OUTLINED}
                    >
                      <DynaIntlFormatter tk={tks.helpWhatIsProfile}/>
                    </Alert>
                  </GridItem>

                  <GridItem show={currentProfileId === currentUserId}>
                    <Alert
                      type={EAlertType.INFO}
                      variant={EAlertVariant.OUTLINED}
                      title={intl.formatMessage({id: tks.defaultUserProfileTitle})}
                    >
                      <DynaIntlFormatter tk={tks.defaultUserProfileBody}/>
                    </Alert>
                  </GridItem>

                  <GridItem>
                    <Typography v="h1">Basics</Typography>
                  </GridItem>

                  <GridItem tablet={4}>
                    <InputSelect<IDynaProfile>
                      variant={EIInputSelectVariant.FILLED}
                      name="type"
                      label={intl.formatMessage({id: tks.labelProfileType})}
                      disabled={currentProfileId === currentUserId}
                      optionsEnum={EDynaProfileType}
                      deprecatedValues={currentProfileId === currentUserId ? undefined : [EDynaProfileType.USER]}
                      listDeprecatedValues={false}
                      readOnly={viewMode === EViewMode.VIEW}
                      value={type}
                      validationError={dataValidation.type}
                    />
                  </GridItem>

                  <GridItem tablet={8}>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="displayName"
                      label={intl.formatMessage({id: tks.labelDisplayMessage})}
                      autoFocus
                      required
                      readOnly={viewMode === EViewMode.VIEW}
                      fontSize={24}
                      value={displayName}
                      validationError={dataValidation.displayName}
                    />
                  </GridItem>

                  <GridItem tablet={4}>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="shortName"
                      label={intl.formatMessage({id: tks.labelShortName})}
                      required
                      readOnly={viewMode === EViewMode.VIEW}
                      value={shortName}
                      helperLabel={intl.formatMessage({id: tks.labelShortNameHelp})}
                      validationError={dataValidation.shortName}
                    />
                  </GridItem>
                  <GridItem tablet={8}>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="slogan"
                      label={intl.formatMessage({id: tks.labelSlogan})}
                      required
                      readOnly={viewMode === EViewMode.VIEW}
                      value={slogan}
                      validationError={dataValidation.slogan}
                    />
                  </GridItem>

                  <GridItem>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="description"
                      label={intl.formatMessage({id: tks.labelProfileDescription})}
                      required
                      minRows={3}
                      maxRows={5}
                      showLengthHelperLabel
                      maxLength={400}
                      maxLengthWarn={350}
                      maxLengthRestriction
                      readOnly={viewMode === EViewMode.VIEW}
                      value={description}
                      validationError={dataValidation.description}
                    />
                  </GridItem>

                  <GridItem>
                    <Typography v="h1">Bio</Typography>
                  </GridItem>

                  <GridItem tablet={8}>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="bioShort"
                      label={intl.formatMessage({id: tks.bioShort})}
                      readOnly={viewMode === EViewMode.VIEW}
                      value={bioShort}
                      maxLengthWarn={150}
                      maxLength={200}
                      maxLengthRestriction
                      helperLabel={intl.formatMessage({id: tks.bioShortHelper})}
                      validationError={dataValidation.bioShort}
                    />
                  </GridItem>
                  <GridItem tablet={4}>
                    <Input<IDynaProfile>
                      variant={EInputVariant.FILLED}
                      name="bioUrl"
                      label={intl.formatMessage({id: tks.bioUrl})}
                      readOnly={viewMode === EViewMode.VIEW}
                      value={bioUrl}
                      validationError={dataValidation.bioUrl}
                    />
                  </GridItem>
                  <Condition
                    // Delete account tool
                    if={
                      formMode === EFormMode.EDIT_MANAGE
                      && viewMode === EViewMode.EDIT
                      && rights.isOwner
                      && currentProfileId !== currentUserId
                    }
                  >
                    <GridItem>
                      <Typography v="h1">
                        <DynaIntlFormatter tk={tks.profileManagement}/>
                      </Typography>
                    </GridItem>
                    <GridItem
                      sx={{color: theme => theme.palette.error.main}}
                      show={!deletedAt}
                    >
                      <Link
                        color={ELinkColor.INHERIT}
                        onClick={deleteProfile}
                      >
                        <b>
                          <DynaIntlFormatter tk={tks.deleteProfileLabel}/>
                        </b>
                      </Link>
                      <HelperText Icon={createIcon.byMuiIcon(WarningIcon)}>
                        <DynaIntlFormatter tk={tks.deleteProfileHelper}/>
                      </HelperText>
                    </GridItem>
                    <GridItem
                      sx={{color: theme => theme.palette.warning.main}}
                      show={!!deletedAt}
                    >
                      <Link onClick={undeleteProfile}>
                        <b>
                          <DynaIntlFormatter tk={tks.undeleteProfile}/>
                        </b>
                      </Link>
                    </GridItem>
                  </Condition>
                </>
              );
            }
          }
        />
      </Box>
    </DynaProfileUserAccess>
  );
};

export const DynaProfileManageForm: React.FC<IDynaProfileManageFormProps> = props => (
  <DynaIntlProvider tks={tks}>
    <DynaProfileManageFormCore {...props}/>
  </DynaIntlProvider>
);
