import {INotificationIcon} from "ui-components/dist/AppContainerDashboard";
import {IAppStore} from "../../../state/IAppStore";

import {EAppTheme} from "../state/appSection";

import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";

interface IThemeInfo {
  [themeName: string]: {
    name: string;
    icon: JSX.Element;
  };
}

const themeInfo: IThemeInfo = {
  [EAppTheme.LIGHT]: {
    name: 'Light',
    icon: <WbSunnyIcon/>,
  },
  [EAppTheme.DARK]: {
    name: 'Dark',
    icon: <NightsStayIcon/>,
  },
};

export const themeNotificationIcon = (store: IAppStore): INotificationIcon | null => {
  const theme = store.app.state.theme;

  const themes = Array<string>().concat(Object.keys(themeInfo), Object.keys(themeInfo));
  const nextIndex: number = themes.indexOf(theme) + 1;
  const nextThemeEnum: EAppTheme = themes[nextIndex] as any;
  const nextTheme = themeInfo[nextThemeEnum];

  return {
    icon: nextTheme.icon,
    label: `Change theme to ${nextTheme.name}`,
    disabled: store.app.state.disableThemeChange,
    onClick: () => store.app.actions.setTheme(nextThemeEnum),
  };
};
