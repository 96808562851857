import {useState} from "react";
import {useEffect} from "react";

import {RefreshComponent} from "ui-components/dist/RefreshComponent";
import {useIsMounted} from "ui-components/dist/useIsMounted";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {EDynaAdOrientation} from "../../interfaces";

import {
  EAdProvider,
  getAdType,
} from "./getAdType";

import {DemoAdPlaceholder} from "../../drivers/components/DemoAdPlaceholder";
import {GoogleAdSense} from "../../drivers/GoogleAdSense";
import {Monetag} from "../../drivers/Monetag";
import {Adsterra} from "../../drivers/Adsterra";

export interface IDynaAdProps {
  show?: boolean;
  refreshEveryMinutes?: number;
  orientation: EDynaAdOrientation;
  groupIds?: string[];
  tags?: string[];
}

export const Ad = (props: IDynaAdProps): JSX.Element | null => {
  const {
    show = true,
    refreshEveryMinutes = 0,
    orientation: userOrientation,
    groupIds = [],
    tags = [],
  } = props;

  const getIsMounted = useIsMounted();

  const {
    isMobile,
    isTablet,
  } = useBreakpointDevice();

  const orientation: EDynaAdOrientation = (() => {
    if (isMobile) return EDynaAdOrientation.VERTICAL;
    if (
      isTablet
      && (
        userOrientation === EDynaAdOrientation.HORIZONTAL
        || userOrientation === EDynaAdOrientation.AUTO
      )) {
      return EDynaAdOrientation.RECTANGLE;
    }
    return userOrientation;
  })();

  const [adType, setAdType] = useState<EAdProvider | null>(null);

  useEffect(() => {
    (async () => {
      const adType = await getAdType({
        groupIds,
        tags,
      });
      if (getIsMounted()) setAdType(adType);
    })();
  }, []);

  if (!show) return null;

  switch (adType) {
    case EAdProvider.DEMO_PROVIDER:
      return (
        <DemoAdPlaceholder
          adProvider={EAdProvider.DEMO_PROVIDER}
          orientation={orientation}
        />
      );
    case EAdProvider.GOOGLE_AD_SENSE:
      return (
        <RefreshComponent refreshEveryMinutes={refreshEveryMinutes}>
          <GoogleAdSense orientation={orientation}/>
        </RefreshComponent>
      );
    case EAdProvider.MONETAG:
      return (
        <RefreshComponent refreshEveryMinutes={refreshEveryMinutes}>
          <Monetag
            orientation={orientation}
            articleGroupIds={groupIds}
            tags={tags}
          />
        </RefreshComponent>
      );
    case EAdProvider.ASDSTERRA:
      return (
        <RefreshComponent refreshEveryMinutes={refreshEveryMinutes}>
          <Adsterra orientation={orientation}/>
        </RefreshComponent>
      );
    case null:
      return null;
    default:
      console.error(`Component <Ad> error 20230607192212: adType [${adType}] is not supported!`, {props});
      return null;
  }

};
