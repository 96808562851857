import {connect} from "react-dynadux";

import {
  DynaCMSLatestSlider as Component,
} from "./DynaCMSLatestSlider";
export const DynaCMSLatestSlider = connect(Component);

export type {
  IDynaCMSLatestSliderProps,
} from "./DynaCMSLatestSlider";
