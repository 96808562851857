import * as React from "react";

import {Box} from "../../Box";
import {Link} from "../../Link";
import {
  FlexContainerHorizontal,
  FlexItemMin,
  FlexItemMax,
} from "../../FlexContainer";
import {CenterContainer} from "../../CenterContainer";

import {IAppMenu} from "../interfaces";

import {sxTransition} from "../../sxTransition";
import {useTheme} from "../../ThemeProvider";

import MenuIcon from "@mui/icons-material/Menu";

export interface IMenuLineProps {
  logo?: JSX.Element;
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
  fontSize?: 1 | 2 | 3 | 4;
  center?: boolean;
  palette: {
    selected: string;
  };
  onPopUpOpen: () => void;
}

export const MenuLine = (props: IMenuLineProps): JSX.Element => {
  const {
    logo,
    selectedMenuId,
    menus,
    fontSize = 2,
    center = false,
    palette: {selected},
    onPopUpOpen,
  } = props;

  const theme = useTheme();

  const itemPadding = theme.spacing(2);

  return (
    <>
      <FlexContainerHorizontal dataComponentName="MenuLine">
        <FlexItemMin
          sx={{
            padding: theme => theme.spacing(2),
            cursor: 'pointer',
            '&:hover': {backgroundColor: theme => theme.palette.text.disabled},
          }}
          onClick={onPopUpOpen}
        >
          <CenterContainer vertically="top">
            <MenuIcon/>
          </CenterContainer>
        </FlexItemMin>

        <FlexItemMin show={!!logo}>
          <Link href="/">
            {logo}
          </Link>
        </FlexItemMin>
        <FlexItemMax
          sx={{
            marginRight: theme => theme.spacing(2),
            overflow: 'hidden',
          }}
        >
          <Box
            component="nav"
            sx={{margin: center ? 'auto' : undefined}}
          >
            <ul
              style={{
                margin: 0,
                padding: 0,
                paddingTop: itemPadding,
                paddingBottom: itemPadding,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {menus
                .filter(menu => {
                  if (menu === "DIVIDER") return true;
                  if (menu.hidden === true) return false;
                  return true;
                })
                .map((menu, index) => {
                  if (menu === 'DIVIDER') {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          borderLeft: `1px solid ${theme.palette.text.primary}`,
                          height: theme.spacing(fontSize),
                        }}
                      />
                    );
                  }

                  return (
                    <li
                      key={index}
                      style={{
                        display: 'inline-block',
                        listStyleType: 'none',
                      }}
                    >
                      <Link
                        href={menu.navigateTo}
                        sx={{
                          fontFamily: "'Oswald', sans-serif",
                          textTransform: 'uppercase',
                          letterSpacing: 1,
                          fontSize: theme.spacing(fontSize),
                          color:
                            selectedMenuId === menu.menuId
                              ? selected
                              : theme.palette.text.primary,
                          padding: itemPadding,
                          transition: sxTransition(theme, ['background-color'], 300),
                          '&:hover': {backgroundColor: theme.palette.text.disabled},
                        }}
                      >
                        {menu.title}
                      </Link>
                    </li>
                  );
                })
              }
            </ul>
          </Box>
        </FlexItemMax>
      </FlexContainerHorizontal>

    </>
  );
};
