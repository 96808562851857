import {IDynaError} from "dyna-error";

import {TObject} from "utils-library/dist/commonJs/typescript";

import {apiFetch} from "../../../api/apiFetch";

/**
 * Endpoint implementation that uploads a file (client version)
 * Warning: Do not use this directly, but implement api method under the feature section that will use this.
 */
export const fileStorageUploadFile = <TPostBodyRequest extends TObject>(
  {
    apiPath,
    body,
    file,
    removeJPGExif,
    onProgress,
    onSuccess,
    onFail,
  }:
    {
      apiPath: string;
      body: TPostBodyRequest;
      file: File;
      removeJPGExif?: boolean;
      onProgress: (percentage: number, progressInfo: string) => void;
      onSuccess: (url: string) => void;
      onFail: (error: IDynaError) => void;
    },
): {
  cancel: () => void; // Will fail the operation
} => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(body)) {
    formData.append(key, value);
  }
  formData.append("__uploadFile__23985924", file);
  if (removeJPGExif !== undefined) formData.append("removeJPGExif", String(removeJPGExif));

  let cancelMethod = () => undefined as any;

  apiFetch.request<null, any, { url: string }>({
    method: "POST",
    path: apiPath,
    getCancelMethod: c => cancelMethod = c,
    onUploadProgress: percentage => {
      onProgress(
        percentage,
        percentage < 100
          ? 'Uploading...'
          : 'Processing...',
      );
    },
    body: formData,
  })
    .then(response => onSuccess(response.url))
    .catch(onFail);

  return {cancel: () => cancelMethod()};
};
