import {appConfig} from "../../config/appConfig";

export interface IAppPageDescriptionProps {
  children?: string;
}

export const AppPageDescription = (props: IAppPageDescriptionProps): null => {
  const {children} = props;
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute('content', children || appConfig.appDescription);
  // Why don't use Helmet? Because it adds second tag on the DOM!
  return null;
};
