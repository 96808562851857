import * as React from "react";
import {useMemo} from "react";

import {dynaError} from "dyna-error";

import {
  EDynaCMSHeadingStyle,
  EDynaCMSFontFamily,
  EFontSize,
} from "server-app";

import {arrayMap} from "utils-library/dist/commonJs/array";
import {Box} from "ui-components/dist/Box";
import {
  Link,
  ELinkColor,
  ELinkDisplay,
} from "ui-components/dist/Link";
import {IsLoading} from "ui-components/dist/IsLoading";
import {ErrorBanner} from "ui-components/dist/ErrorBanner";
import {useBreakpointDevice} from "ui-components/dist/useBreakpointDevice";

import {DynaCMSFontFamily} from "../DynaCMSFontFamily";
import {DynaCMSArticleHeaderViewerEdem} from "../DynaCMSArticleHeaderViewerEdem";
import {useLoadArticlesHeadersCache} from "../useLoadArticlesHeadersCache";

import {IAppStore} from "../../../../state/IAppStore";

export interface IDynaCMSGroupPreviewerProps {
  store: IAppStore;
  groupId: string;
  itemsCount: number;
}

export const DynaCMSGroupPreviewer: React.FC<IDynaCMSGroupPreviewerProps> = (
  {
    store: {
      dynaCMS: {
        state: {
          isLoading: isLoadingSettings,
          countryIds,
          languageId,
          settings: {groups},
        },
      },
    },
    groupId,
    itemsCount,
  },
) => {
  const {isMobile} = useBreakpointDevice();

  const group = useMemo(() => {
    return groups.find(group => group.id === groupId);
  }, [isLoadingSettings]);

  const {
    isLoading,
    dataPlace,
  } = useLoadArticlesHeadersCache({
    search: {
      countryIds,
      languageId,
      groupIds: [groupId],
    },
    cache: {
      containerName: `DynaCMSGroupPreviewer--group-${groupId}`,
      maxSizeInBytes: 100000,
    },
  });

  if (isLoading) return null;

  if (!group) {
    return (
      <ErrorBanner
        error={dynaError({
          code: 20230806105215,
          message: 'Internal error',
          userMessage: `Internal error group id [${groupId}] is not supported by the backend`,
        })}
      />
    );
  }

  return (
    <IsLoading isLoading={isLoading}>
      <Box component="aside">
        <DynaCMSFontFamily
          component="h1"
          fontFamily={EDynaCMSFontFamily.F186_FASHION_EMPIRE_FS}
          fontSize={EFontSize.PX42}
        >
          <Link
            href={'/' + group.pagePath}
            color={ELinkColor.INHERIT}
            display={ELinkDisplay.BLOCK}
          >
            {group.label} {">"}
          </Link>
        </DynaCMSFontFamily>
        {arrayMap(itemsCount, index => (
          <DynaCMSArticleHeaderViewerEdem
            key={index}
            sx={{marginBottom: theme => theme.spacing(1)}}
            h={2}
            sticky={false}
            headingStyleList={
              isMobile
                ? EDynaCMSHeadingStyle.CLEAN_PLAIN_VERTICAL_PHOTO_TEXT_NEGATIVE_ALL
                : EDynaCMSHeadingStyle.EXCEPTIONAL_NEGATIVE_ALL
            }
            showDescription
            articleHeading={(() => {
              const {data: article} = dataPlace(`place-${index}`);
              if (!article) return null;
              return {
                ...article,
                label: article.label || group.label,
              };
            })()}
          />
        ))}
      </Box>
    </IsLoading>
  );
};
