import {IAppMenu} from "../../../../core/application/config/IAppMenu";

import {LightHorizontalMenu} from "ui-components/dist/LightHorizontalMenu";

import {appConfig} from "../../../../core/application/config/appConfig";
import {CompanyLogoForFooter} from "../../../../core/application/public-components";

import {AdoreGrLogo} from "../AdoreGrLogo/AdoreGrLogo";

import {PopUpMenuFooter} from "./components/PopUpMenuFooter";
import {PopUpMenuAdditional} from "./components/PopUpMenuAdditional";

import {useTheme} from "ui-components/dist/ThemeProvider";

export interface IAdoreGrMainMenuProps {
  selectedMenuId: string;
  menus: (IAppMenu | 'DIVIDER')[];
}

export const AdoreGrMainMenu = (props: IAdoreGrMainMenuProps): JSX.Element => {
  const {
    selectedMenuId,
    menus,
  } = props;
  const theme = useTheme();

  return (
    <LightHorizontalMenu
      logoMain={<AdoreGrLogo height={42}/>}
      logoPopUpHeader={<AdoreGrLogo height={64}/>}
      logoPopUpFooter={
        <CompanyLogoForFooter
          textAlign="right"
          logo={<AdoreGrLogo height={32}/>}
          companyName="adore.gr"
          copyrightSince={2022}
        />
      }
      selectedMenuId={selectedMenuId}
      menus={menus}
      fontSize={2}
      footer={({onClick})=> <PopUpMenuFooter onClick={onClick}/>}
      additionalOptions={({onClick}) => <PopUpMenuAdditional onClick={onClick}/>}
      stickyMenuOnScrollY={152}
      stickyMaxWidth={appConfig.pageWidth}
      palette={{selected: theme.palette.appMainColor.main}}
    />
  );
};
