import {IAppRoute} from "../../../../core/application/config/IAppRoute";

import {routeDynaCMSArticle} from "../../../../core/dyna-cms/public-components";

export const routeAdoreContactBusiness: IAppRoute = routeDynaCMSArticle({
  path: "contact-business",
  multiLangArticles: [
    {
      groupId: 'page',
      countryId: 'gr',
      languageId: 'el',
      articleId: '195de905-2f8490ff712bbf8b19',
    },
  ],
});
