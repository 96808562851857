import {TObject} from "utils-library/dist/commonJs/typescript";

import {
  IDynaProfile,
  EDynaProfileType,
} from "../../../interfaces";

export const API_PATH_ApiDynaProfilePut = '/services/dyna-profiles/apiDynaProfilePut';

export interface IApiDynaProfilePutRequest {
  profileId: string;

  type?: EDynaProfileType;
  displayName?: string;
  shortName?: string;
  profileImageUrl?: string;
  backgroundImageUrl?: string;
  slogan?: string;
  description?: string;
  bioShort?: string;
  bioUrl?: string;

  profile?: TObject;
}

export interface IApiDynaProfilePutResponse {
  profile: IDynaProfile;
}
