import * as React from "react";
import {useMemo} from "react";

import MarkdownIt from 'markdown-it';

import {Box} from "../Box";
import {HtmlContent} from "../HtmlContent";

import {
  SxProps,
  Theme,
} from "../ThemeProvider";

export interface IMarkdownViewerProps {
  sx?: SxProps<Theme>;

  /**
   * The markdown text to be turned into HTML
   */
  markdownText?: string;

  /**
   * Bump up the heading level in the markdown text
   *
   * For example, if the markdown has `# MyHeading` but we need it as h2 due to a parent element, set increaseHeadings to 1.
   */
  increaseHeadings?: number;

  /**
   * Remove the default browser margins on h1..h5 and p tags.
   */
  noMarginForTexts?: boolean;
}

const md = new MarkdownIt();

export const MarkdownViewer: React.FC<IMarkdownViewerProps> = (
  {
    sx = {},
    markdownText = "",
    increaseHeadings,
    noMarginForTexts = false,
  },
) => {
  const markdown =
    increaseHeadings
      ? markdownText
        .split('\n')
        .map(increaseHeadingLevel)
        .join('\n')
      : markdownText;

  const html = useMemo<string>(() => md.render(markdown), [markdown]);

  return (
    <Box
      dataComponentName="MarkdownViewer"
      sx={{
        h1: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        h2: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        h3: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        h4: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        h5: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        h6: {
          fontFamily: 'inherit',
          margin: noMarginForTexts ? 0 : undefined,
        },
        "& p": {margin: noMarginForTexts ? 0 : undefined},
        blockquote: {
          borderLeft: '8px solid',
          paddingLeft: 1,
        },
        a: {color: theme => theme.palette.info.main},
        // Add styles for your table here
        table: {
          borderCollapse: 'collapse',
          marginBottom: '20px', // Example margin for spacing
        },
        th: {
          backgroundColor: theme => theme.palette.grayShades.gray1,
          border: '1px solid',
          padding: '8px',
          textAlign: 'left',
        },
        td: {
          backgroundColor: theme => theme.palette.grayShades.gray0,
          border: '1px solid', // Example border for data cells
          padding: '8px',
          textAlign: 'left',
        },
        ...sx,
      }}
    >
      <HtmlContent html={html}/>
    </Box>
  );
};

const increaseHeadingLevel = (markdownLine: string): string => {
  const regex = /^(#+)(.*)/;
  const match = markdownLine.match(regex);

  if (match) {
    const originalLevel = match[1];
    const headingText = match[2];
    const newLevel = originalLevel + '#'; // Increase the heading level by one
    return newLevel + headingText;
  }
  else {
    return markdownLine; // Return unchanged if it's not a valid heading line
  }
};
