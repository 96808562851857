import {
  IDynaError,
  dynaError,
} from "dyna-error";

import {
  EDynaProfileUserRight,
  IDynaProfileUserRightsReader,
  dynaProfileUserRightsReader,
} from "server-app";

import {useLoadData} from "ui-components/dist/useLoadData";

import {apiProfileUserRightsGet} from "../../api/rights/apiProfileUserRightsGet";

export const useHasProfileUserRight = (
  {
    profileId,
    right,
  }: {
    profileId: string;
    right: string;      // Empty string means, not check, return valid
  },
): {
  isLoading: boolean;
  valid: boolean;
  error: IDynaError | undefined;
} => {
  const {
    data: rights,
    isLoading,
    error,
  } = useLoadData<IDynaProfileUserRightsReader>({
    defaultData: dynaProfileUserRightsReader([]),
    reloadDep: {
      profileId,
      right,
    },
    load: async () => {
      if (!profileId) throw dynaError('Internal error 20231229175926: No profile id provided');
      if (!right) return dynaProfileUserRightsReader([]);
      if (!profileId) {
        throw dynaError({
          code: 20231229105023,
          message: 'Profile Id not provided',
        });
      }
      const {rights} = await apiProfileUserRightsGet({
        profileId,
        rights: [
          right,
          EDynaProfileUserRight.OWNER,
        ],
        rightsType: "regular",
        skip: 0,
        limit: 1,
      });
      return dynaProfileUserRightsReader(rights);
    },
  });

  return {
    isLoading,
    valid: !right || rights.isOwnerOrHasRight(right),
    error,
  };
};
