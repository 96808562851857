import {IDynaProfile} from "../../../interfaces";
import {IDynaProfileUserRight} from "../../../interfaces";

export const API_PATH_ApiDynaProfileForUserGet = '/services/dyna-profiles/apiDynaProfileForUserGet';

export interface IApiDynaProfileForUserGetRequest {
  profileId: string;
}

export interface IApiDynaProfileForUserGetResponse {
  profile: IDynaProfile;             // User has no access to this profile
  rights: IDynaProfileUserRight[];
}
