import * as React from "react";
import {
  IDynaError,
  dynaError,
} from "dyna-error";

import {ProgressBar} from "../ProgressBar";
import {EProgressBarColor} from "../ProgressBar";

import {TUploadMethod} from "../UploadFilesModal";

export interface IUploadFileProps {
  file: File;
  validateFile?: (file: File) => Promise<string>;
  uploadMethod: TUploadMethod;
  onComplete: (url: string) => void;
  onFail: (error: IDynaError) => void;
}

export interface IUploadFileState {
  percentage: number;
  progressInfo: string;
  completed: boolean;
  error: IDynaError | null;
}

export class UploadFile extends React.Component<IUploadFileProps, IUploadFileState> {
  public state: IUploadFileState = {
    percentage: 0,
    progressInfo: '',
    completed: false,
    error: null,
  };

  public componentDidMount() {
    this.upload();
  }

  private cancel: () => void = () => undefined;

  private async upload(): Promise<void> {
    const {
      file,
      validateFile = async () => "",
      uploadMethod,
      onComplete,
      onFail,
    } = this.props;

    let validationError = "";
    try {
      validationError = await validateFile(file);
    }
    catch (e: any) {
      validationError = "Internal error: Method validateFile() failed";
      const internalError = dynaError({
        code: 20231118192945,
        message: 'Internal error: UploadFile.upload() the provided validateFile() failed',
        parentError: e,
      });
      console.error(internalError.message, internalError);
    }

    if (validationError.trim()) {
      const error = dynaError({
        message: 'Client file validation error',
        userMessage: "Invalid file: " + validationError,
      });
      this.setState({error});
      onFail(error);
      return;
    }

    const uploadHandler = uploadMethod({
      file,
      onProgress: (percentage, progressInfo) => {
        this.setState({
          percentage,
          progressInfo,
        });
      },
      onSuccess: (url) => {
        this.setState({completed: true});
        onComplete(url);
      },
      onFail: (error) => {
        this.setState({error});
        onFail(error);
      },
    });
    this.cancel = uploadHandler.cancel;
  }

  public render(): JSX.Element {
    const {file} = this.props;
    const {
      percentage,
      progressInfo,
      completed,
      error,
    } = this.state;

    return (
      <ProgressBar
        color={
          (() => {
            if (error) return EProgressBarColor.ERROR;
            if (completed) return EProgressBarColor.SUCCESS;
            return undefined;
          })()
        }
        label="Upload file"
        progressLabelAction={
          (() => {
            if (error) return;
            if (completed) return 'Completed';
            return progressInfo;
          })()
        }
        progressLabelItem={file.name}
        value={percentage}
        error={error}
        onCancel={!completed && !error ? this.cancel : undefined}
      />
    );
  }
}
