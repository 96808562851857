import {EDynaAdOrientation} from "../../interfaces";
import {appConfig} from "../../../application/config/appConfig";
import {HtmlContent} from "ui-components/dist/HtmlContent";

export interface IMonetagProps {
  orientation: EDynaAdOrientation;
  articleGroupIds: string[];
  tags: string[];
}

export const Monetag = (props: IMonetagProps): JSX.Element | null => {
  props;

  const plainTag = appConfig.monetag?.plainTag;

  // Ticket: DCMS-00449 monetag ad provider https://trello.com/c/pQ0CVo5Q
  // Currently, they do not offer a way to place a static ad on the dom.
  // They offer some silly things, with pop overs and UX terrible solutions.

  if (!plainTag) {
    console.error('Monetag Ad component is used, but there is the appConfig.monetag.plainTag is not defined');
    return null;
  }

  return (
    <HtmlContent
      html={plainTag}
      sanitize={false}
    />
  );
};
