import * as React from "react";
import {useState} from "react";

import {IsLoadingBox} from "ui-components/dist/IsLoadingBox";
import {ErrorBanner} from "ui-components/dist/ErrorBanner";
import {ContainerSection} from "ui-components/dist/ContainerSection";
import {Link} from "ui-components/dist/Link";
import {
  Alert,
  EAlertVariant,
} from "ui-components/dist/Alert";
import {EAlertType} from "ui-components/dist/Alert";
import {
  Button,
  EButtonDisplay,
  EButtonColor,
  EButtonSize,
} from "ui-components/dist/Button";

import {debugMode} from "../../../../utils/debug-mode";
import {IAppStore} from "../../../../state/IAppStore";
import {routeSignInPage} from "../../../user-authnentication/routes/routeSignInPage";

import {useHasProfileUserRight} from "../useHasProfileUserRight";

import {createIcon} from "ui-components/dist/IconComponent";
import ForbiddenIcon from '@mui/icons-material/DoNotDisturb';
import DebugIcon from '@mui/icons-material/BugReport';

export interface IDynaProfileUserAccessProps {
  store: IAppStore;
  profileId?: string;       // Undefined to use the user's default one
  right: string;
  showIsLoading?: boolean;
  children: any;
}

export const DynaProfileUserAccess: React.FC<IDynaProfileUserAccessProps> = (
  {
    store: {profiles: {state: {profile: {id: currentProfileId}}}},
    profileId: userProfileId,
    right,
    showIsLoading,
    children,
  },
) => {
  const profileId = userProfileId || currentProfileId;
  const [debugBypassDenial, setDebugBypassDenial] = useState(false);

  const {
    isLoading,
    valid,
    error,
  } = useHasProfileUserRight({
    profileId,
    right,
  });

  if (debugBypassDenial) {
    return (
      <>
        <Alert
          show={debugBypassDenial}
          type={EAlertType.ERROR}
          variant={EAlertVariant.OUTLINED}
          title="Access denied bypassed for debbugging"
        />
        {children}
      </>
    );
  }

  const handleDebugIconClick = (): void => setDebugBypassDenial(true);
  const debugButton =
    debugMode.active
      ? (
        <Button
          sx={{marginTop: 1}}
          display={EButtonDisplay.BLOCK}
          color={EButtonColor.ERROR}
          size={EButtonSize.SMALL}
          show={debugMode.active}
          Icon={createIcon.byMuiIcon(DebugIcon)}
          onClick={handleDebugIconClick}
        >
          Debug mode: Bypass access denial
        </Button>
      )
      : null;

  if (!profileId) {
    return (
      <ContainerSection
        Icon={createIcon.byMuiIcon(ForbiddenIcon)}
        title="Access denied"
        subtitle="You need to sign in and use a Profile."
      >
        You need to <Link href={routeSignInPage.getRoutePath()}>sign-in</Link> and use the proper profile to access this.
        {debugButton}
      </ContainerSection>
    );
  }

  if (isLoading) {
    return showIsLoading
      ? (
        <IsLoadingBox
          isLoading={isLoading}
          animation={false}
        >
          Loading...
        </IsLoadingBox>
      )
      : null;
  }

  if (error) {
    return <ErrorBanner error={error}/>;
  }

  return valid
    ? children
    : (
      <ContainerSection
        Icon={createIcon.byMuiIcon(ForbiddenIcon)}
        title="Access denied"
        subtitle="You don't have the right to access this."
      >
        Kindly request access from the owner or key persons of the profile.
        {debugButton}
      </ContainerSection>
    );
};
