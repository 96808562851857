import {IDynaCMSLoadArticleResult} from "../../../../utils/loadArticle.interfaces";

export const API_PATH_ApiArticleGet = '/services/dyna-cms/apiArticleGet';

export interface IApiArticleGetRequestRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId?: string;
  urlId?: string;
  purpose?: "view" | "preview" | "manage";
}

export type IApiArticleGetResponse = IDynaCMSLoadArticleResult;
