import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

const ShortUrlNotFound404PageLazy = lazy(() => import("./ShortUrlNotFound404Page"));

export const ShortUrlNotFound404Page = () => (
  <LazyLoadComponent>
    <ShortUrlNotFound404PageLazy/>
  </LazyLoadComponent>
);
