export const API_PATH_ApiArticleContentMoveAfter = '/services/dyna-cms/apiArticleContentMoveAfter';

export interface IApiArticleContentMoveAfterBodyRequest {
  groupId: string;
  languageId: string;
  countryId: string;
  articleId: string;
  blockId: string;
  moveAfterBlockId: string;
}

export interface IApiArticleContentMoveAfterResponse {
  newBlocksOrder: string[];
}
