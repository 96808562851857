import {
  EDynaCMSRightsDEPRECATED,
  EPublishedFilter,
} from "server-app";

import {IAppRoute} from "../../application/config/IAppRoute";

export interface IDynaCMSArticleListSearchObject {
  countryId: string;
  languageId: string;
  groupIds: string[];
  searchText: string;
  tags: string[];
  published: EPublishedFilter;
  archived: boolean | "both";
  deleted: boolean | "both";
}

export const routeDynaCMSArticlesMgtmPaths: IAppRoute<{ searchObject: string }> = {
  title: 'Articles management',
  routePath: '/dyna-cms/search-articles/:searchObject?',
  getRoutePath: ({searchObject} = {searchObject: ''}) =>
    routeDynaCMSArticlesMgtmPaths.routePath
      .replace(':searchObject?', searchObject),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaCMSRightsDEPRECATED.AUTHOR,
    EDynaCMSRightsDEPRECATED.EDITOR,
    EDynaCMSRightsDEPRECATED.REVIEWER,
  ],
  requiresLatestClientVersion: true,
  render: ()=>null,
};
