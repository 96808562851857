import * as React from "react";
import {IDynaError} from "dyna-error";

import {IntlProvider} from "react-intl";

import {IAppStore} from "../../../../state/IAppStore";
import {apiDynaIntlTranslationsGet} from "../../api/apiDynaIntlTranslationsGet";

export interface IDynaIntlProviderProps {
  store: IAppStore;
  tks: { [tk: string]: string };
  children: any;
}

interface IDynaIntlProviderState {
  isInitialized: boolean;
  isLoading: boolean;
  translations: ITranslations;
  loadError: IDynaError | null;
}

interface ITranslations {
  [languageId: string]: {
    [tk: string]: string;
  };
}

export class DynaIntlProvider extends React.Component<IDynaIntlProviderProps, IDynaIntlProviderState> {
  public state: IDynaIntlProviderState = {
    isInitialized: false,
    isLoading: false,
    translations: {},
    loadError: null,
  };

  public componentDidMount() {
    this.loadTranslationsForLanguageId(this.props.store.dynaCMS.state.languageId);
  }

  public componentDidUpdate(prevProps: Readonly<IDynaIntlProviderProps>) {
    if (
      prevProps.store.dynaCMS.state.languageId !== this.props.store.dynaCMS.state.languageId
      || prevProps.store.dynaCMS.state.countryIds.join(',') !== this.props.store.dynaCMS.state.countryIds.join(',')
    ) {
      this.loadTranslationsForLanguageId(this.props.store.dynaCMS.state.languageId);
    }
  }

  private loadTranslationsForLanguageId = async (languageId: string): Promise<void> => {
    const {
      store: {dynaCMS: {state: {countryIds}}},
      tks = {},
    } = this.props;
    if (!Object.keys(tks).length) {
      this.setState({isInitialized: true});
      return;
    }

    try {
      this.setState({isLoading: true});
      const response = await apiDynaIntlTranslationsGet({
        countryId: countryIds[0] || 'gr', // Todo: this is wrong, somehow we have to know the main countryId... something that currently is not supported b the dyna cms dynadux state
        languageId,
        tks: Object.values(tks),
      });
      this.setState({
        translations: {
          ...this.state.translations,
          [languageId]: {
            ...this.state.translations[languageId],
            ...response.translations,
          },
        },
        isInitialized: true,
        isLoading: false,
      });
    }
    catch (e: any) {
      this.setState({
        loadError: e,
        isInitialized: true,
        isLoading: false,
      });
    }
  };

  public render(): JSX.Element | null {
    const {
      store: {dynaCMS: {state: {languageId}}},
      children,
    } = this.props;
    const {
      isInitialized,
      isLoading,
      translations,
    } = this.state;

    if (!isInitialized) return null;
    if (isLoading) return null;

    return (
      <IntlProvider
        locale={languageId}
        messages={translations[languageId]}
      >
        {children}
      </IntlProvider>
    );
  }
}
