// Credits: https://gist.github.com/andjosh/6764939

export const scrollTo = (
  container: HTMLDivElement,
  direction: 'scrollTop' | 'scrollLeft',
  scrollTo: number,
  duration: number = 250,
): void => {
  const
    start = container[direction],
    change = scrollTo - start,
    increment = 20;
  let
    currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    container[direction] = easeInOutQuad(currentTime, start, change, (duration || 1));
    if (currentTime < (duration || 1)) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

const easeInOutQuad = (current: number, start: number, change: number, duration: number): number => {
  current /= duration / 2;
  if (current < 1) return change / 2 * current * current + start;
  current--;
  return -change / 2 * (current * (current - 2) - 1) + start;
};
