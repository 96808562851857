import * as React from "react";

import {AppServiceWorker} from "./AppServiceWorker";

export class App extends React.Component {

  public render(): JSX.Element {
    return (
      <AppServiceWorker/>
    );
  }
}
